import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const DeleteModal = ({
  deleteConfirm,
  showModal,
  deleteTitle,
  type = '',
  confirmation,
  buttonTitle,
  langCode,
  button3 = '',
  button3Action = () => {},
}: {
  deleteConfirm?: () => void;
  showModal?: any;
  deleteTitle?: any;
  type?: string;
  confirmation?: boolean;
  buttonTitle?: string;
  langCode?: any;
  button3?: string;
  button3Action?: any;
}) => {
  const [open, setOpen] = React.useState(true);
  const { t }: any = useTranslation();

  const handleClose = () => {
    setOpen(false);
    showModal(false);
  };
  const handleCancel = () => {
    setOpen(false);
    showModal(false);
  };
  const deleteTitleKey = convertToKey(deleteTitle);
  console.log('delete', deleteTitleKey);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <h2 className="font-semi-bold p-3 text-2xl">
            {!confirmation && (t('want_to_delete', langCode) || 'Do you want to delete')} {t(type) || type}{' '}
            <b>{langCode ? t(deleteTitleKey, langCode) || deleteTitle : t(deleteTitleKey) || deleteTitle}</b>?
          </h2>
          <div className="flex items-center justify-end pt-5 pb-2">
            <button
              onClick={handleCancel}
              className="mx-2 min-w-[70px] rounded-md border-1 border-green-main px-2.5 py-1.5 text-sm">
              {langCode ? t('cancel', langCode) || 'Cancel' : t('cancel') || 'Cancel'}
            </button>
            <button
              className="mx-2 min-w-[70px] rounded-md border-1 border-red-main bg-red-main px-2.5 py-1.5 text-sm text-white"
              onClick={deleteConfirm}
              autoFocus>
              {buttonTitle
                ? t(buttonTitle, langCode) || buttonTitle
                : langCode
                ? t('delete', langCode) || 'Delete'
                : t('delete') || 'Delete'}
            </button>
            {button3 && (
              <button
                className="mx-2 min-w-[70px] rounded-md border-1 border-green-main bg-green-main px-2.5 py-1.5 text-sm text-white"
                onClick={button3Action}
                autoFocus>
                {button3}
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
