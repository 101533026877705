import { Header, Sidebar } from '../index';

function Layout({ children }) {
  return (
    <main className="flex h-full w-full flex-col">
      <Header></Header>
      <section className="flex flex-1">
        <Sidebar></Sidebar>
        <section className="flex-1 p-10">{children}</section>
      </section>
    </main>
  );
}

export default Layout;
