import { createBrowserRouter, Outlet } from 'react-router-dom';
import App from './App';
//content
import ContentList from './pages/Content/List';
import Content from './pages/Content/View';
// categories
import Categories from './pages/Categories/View';
import CatagoriesList from './pages/Categories/List';
// tags
import TagsList from 'pages/Tags/List';
import Tags from './pages/Tags/View';
// premium
import PremiumList from 'pages/Premium/List';
import Premium from './pages/Premium/View';
// sequence
import SequenceList from 'pages/Sequence/List';
import Sequence from './pages/Sequence/View';

// interrupter
import InterrupterList from './pages/Interrupters/List';
import Interrupters from './pages/Interrupters/View';

// badges
import BadgeList from 'pages/Badge/List';
import Badge from './pages/Badge/View';

import ErrorPage from './pages/ErrorPage';
// Library./pages/Library/View
import LibraryList from './pages/Library/List';
import Library from './pages/Library/View';
import Company from './pages/Company';
import Group from './pages/Groups';
import AddGroup from './pages/Groups/Add/add';
import Media from './pages/Media';

import Quiz from './pages/Quiz';
import SurveyList from './pages/Survey/List';
import Survey from './pages/Survey/View';
// page./pages/Page/List
import PageList from './pages/Page/List';
import Page from './pages/Page/View';

import AllUsers from './pages/AllUser';
import GeneralSettings from './pages/Settings';
import DefaultPage from './pages/Default';
// import MediaList from './pages/Media/List';
import MediaGrid from 'pages/Media/Grid';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import AddCompany from 'pages/Company/Add';
import { AddUser } from 'pages/AddUser';
import AddMedia from 'pages/Media/View';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'auth',
        element: <Outlet />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'forgot-pass',
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: 'content',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ContentList />,
          },
          {
            path: 'add',
            element: <Content duplicate={false} />,
          },
          {
            path: 'duplicate/:id',
            element: <Content duplicate={true} />,
          },
          {
            path: 'edit/:id',
            element: <Content duplicate={false} />,
          },
        ],
      },
      {
        path: 'categories',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <CatagoriesList />,
          },
          {
            path: 'add',
            element: <Categories />,
          },
          {
            path: 'edit/:id',
            element: <Categories />,
          },
        ],
      },
      {
        path: 'tags',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <TagsList />,
          },
          {
            path: 'add',
            element: <Tags />,
          },
          {
            path: 'edit/:id',
            element: <Tags />,
          },
        ],
      },
      {
        path: 'premium',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <PremiumList />,
          },
          {
            path: 'add',
            element: <Premium />,
          },
          {
            path: 'edit/:id',
            element: <Premium />,
          },
        ],
      },
      {
        path: 'sequences',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <SequenceList />,
          },
          {
            path: 'add',
            element: <Sequence up={false} />,
          },
          {
            path: 'edit/:id',
            element: <Sequence up={false} />,
          },
          {
            path: 'addup/:contentId',
            element: <Sequence up={true} />,
          },
          {
            path: 'adddown/:contentId',
            element: <Sequence up={false} />,
          },
        ],
      },
      {
        path: 'interrupters',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <InterrupterList />,
          },
          {
            path: 'add',
            element: <Interrupters />,
          },
          {
            path: 'edit/:id',
            element: <Interrupters />,
          },
        ],
      },
      {
        path: 'libraries',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <LibraryList />,
          },
          {
            path: 'add',
            element: <Library />,
          },
          {
            path: 'edit/:id',
            element: <Library />,
          },
        ],
      },
      {
        path: 'companies',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Company />,
          },
          {
            path: 'add',
            element: <AddCompany />,
          },
          {
            path: 'edit/:id',
            element: <AddCompany />,
          },
        ],
      },
      {
        path: 'groups',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Group />,
          },
          {
            path: 'add',
            element: <AddGroup />,
          },
          {
            path: 'edit/:id',
            element: <AddGroup />,
          },
        ],
      },

      {
        path: 'media',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Media />,
            // children: [
            //   {
            //     path: 'list',
            //     element: <MediaList />,
            //   },
            //   {
            //     path: 'grid',
            //     element: <MediaGrid />,
            //   },
            // ],
          },
          {
            path: 'add-media',
            element: <AddMedia />,
          },
          {
            path: 'edit/:id',
            element: <AddMedia />,
          },
        ],
      },
      {
        path: 'badges',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <BadgeList />,
          },
          {
            path: 'add',
            element: <Badge duplicate={false} />,
          },
          {
            path: 'edit/:id',
            element: <Badge duplicate={false} />,
          },
          {
            path: 'duplicate/:id',
            element: <Badge duplicate={true} />,
          },
        ],
      },
      {
        path: 'quizzes',
        element: <Quiz />,
      },
      {
        path: 'surveys',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <SurveyList />,
          },
          {
            path: 'add',
            element: <Survey duplicate={false} />,
          },
          {
            path: 'edit/:id',
            element: <Survey duplicate={false} />,
          },
          {
            path: 'duplicate/:id',
            element: <Survey duplicate={true} />,
          },
        ],
      },
      {
        path: 'pages',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <PageList />,
          },
          {
            path: 'add',
            element: <Page />,
          },
          {
            path: 'edit/:id',
            element: <Page />,
          },
        ],
      },
      {
        path: 'users',
        element: <Outlet />,
        children: [
          { path: '', element: <AllUsers /> },
          { path: 'add', element: <AddUser /> },
          { path: 'edit/:id', element: <AddUser /> },
        ],
      },
      {
        path: 'settings',
        element: <GeneralSettings />,
      },
      {
        path: '*',
        element: <DefaultPage />,
      },
    ],
  },
]);

export default router;
