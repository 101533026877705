import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { Outlet, useNavigate, NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Select, Pagination, Input, Filter } from '../../components';
import { TopLayer, TabsLayer, FunctionLayer } from '../../components/common/table';
import { TableActionButton } from '../../components/common/button';
import MediaBurgerIcon from 'assets/images/icons/MediaBurgerIcon';
import GridIcon from 'assets/images/icons/GridIcon';
import classNames from 'classnames';
import { filterArray, TableContent } from './utils';
import MediaGrid from './Grid';
import MediaList from './List';
import { deleteAPIData, getAPIData } from 'utils/api';
import { ToastTypes, useToast } from 'context/toast';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { useTranslation } from 'context/translation';
import { SelectedLangContext } from 'context/user';
function Media() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const { callToast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPagination, setShowPagination] = useState(true);
  const [mediaData, setMediaData] = useState<any>([]);
  const [errPage, setErrPage] = useState<any>(false);
  const [conttainer, setContainer] = useState<any>('list');
  const [selectFilter, setSelectFilter] = useState<any>(searchParams.get('mediaFilter') ?? 'all');
  const [filterMedia, setfilterMedia] = useState<any>(searchParams.get('mediaFilter') ?? 'all');
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<any>(0);
  const [smallLoader, setSmallLoader] = useState<boolean>(false);
  const [mediaDataRaw, setMediaDataRaw] = useState<any>([]);
  const { t }: any = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);

  // const [paginationParams, setPaginationParams] = useState<any>({
  //   page:1,
  //   search:'',
  //   limit:10
  // });
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, {
    tab: undefined,
    startDate: undefined,
    endDate: undefined,
    leaderFilter: undefined,
    contentType: undefined,
    sequenceFilter: undefined,
    groupFilter: undefined,
    categoryFilter: undefined,
    mediaFilter: filterMedia,
  });
  const arr = filterArray;
  const handleToAddContent = useCallback(() => {
    navigate('/media/add-media');
  }, [navigate]);
  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };
  useEffect(() => {
    if (conttainer == 'grid') {
      setPaginationParams({
        page: 1,
        search: paginationParams.search,
        limit: 50,
      });
    } else {
      setPaginationParams({
        page: 1,
        search: paginationParams.search,
        limit: 10,
      });
    }
  }, [conttainer]);
  // const onNextPage = () => {
  //   handlePaginationChange(paginationParams?.page - 1);
  // };
  // const onPrevPage = () => {
  //   handlePaginationChange(paginationParams?.page + 1);
  // };
  // const handleDeleteList = async (data: any) => {
  //   setLoading(true);
  //   try {
  //     callToast(ToastTypes.INFO, 'Deleting media...');
  //     await deleteAPIData({ url: `media/${data}` });
  //     callToast(ToastTypes.SUCCESS, 'Media deleted successfully');
  //     getMediaList();
  //   } catch (e: any) {
  //     callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
  //     setLoading(false);
  //   }
  // };
  const onNextPage = () => {
    handlePaginationChange(paginationParams?.page - 1);
  };
  const onPrevPage = () => {
    handlePaginationChange(paginationParams?.page + 1);
  };
  const handleDeleteList = async (data: any) => {
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_media') || 'Deleting media...');
      await deleteAPIData({ url: `media/${data}` });
      callToast(ToastTypes.SUCCESS, t('media_deleted') || 'Media deleted successfully');
      getMediaList();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };
  const getMediaList = async () => {
    if (conttainer == 'grid' && paginationParams.page > 1) {
      setSmallLoader(true);
    } else {
      setLoading(true);
    }
    try {
      let res = await getAPIData({
        url: 'media',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: conttainer == 'list' ? paginationParams.limit : 50,
          type: selectFilter,
          languageId: selectedLanguage?.id,
        },
      });
      setTotal(res?.data?.total);
      setMediaData(TableContent(res?.data?.data));
      if (conttainer == 'grid') {
        if (paginationParams.page == 1) {
          if (!paginationParams.search) {
            setMediaDataRaw(res?.data?.data);
          }
          const data: any = TableContent(res?.data?.data);
          setMediaData(data);
        } else if (paginationParams.page > 1) {
          setMediaDataRaw([...mediaDataRaw, ...res?.data?.data]);
          const data: any = TableContent([...mediaDataRaw, ...res?.data?.data]);
          setMediaData(data);
        }
      }
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setSmallLoader(false);
    setLoading(false);
  };
  useEffect(() => {
    getMediaList();
  }, [paginationParams, filterMedia, conttainer]);
  const handleInputChange = (val: string) => {
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  return (
    <section className="Companies">
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText={t('media_library') || 'Media Library'}
        buttonText={t('add_media') || 'Add Media'}
      />
      {/* <TabsLayer tabs={tabs} /> */}
      <FunctionLayer
        leftPortion={
          <>
            <div
              onClick={() => {
                setShowPagination(true);
                setContainer('list');
              }}
              className={`${conttainer == 'list' ? 'text-blue-main' : ''}`}>
              <MediaBurgerIcon className={classNames('cursor-pointer')} />
            </div>
            <div
              onClick={() => {
                setShowPagination(false);
                setContainer('grid');
              }}
              className={`${conttainer == 'grid' ? 'text-blue-main' : ''}`}>
              <GridIcon className="cursor-pointer" />
            </div>
            {/* <Select
              value={dateValue}
              options={[
                { label: 'All Dates', value: '0' },
                { label: '1 day', value: '1' },
              ]}
              onChange={setDateValue}
              placeholder="All Dates"
            />
            <Select
              value={dateValue}
              options={[
                { label: 'All Dates', value: '0' },
                { label: '1 day', value: '1' },
              ]}
              onChange={setDateValue}
              placeholder="All Media Items"
            /> */}

            <Filter
              width="200px"
              children={
                <div className="p-5">
                  {arr.map((e: any, i: any) => {
                    return (
                      <section className="flex items-center">
                        <label className="customCheck flex cursor-pointer text-[14px] text-gray-text">
                          <input
                            type="checkbox"
                            value={e?.content}
                            onChange={(a) => (a.target.checked ? setSelectFilter(e?.content) : setSelectFilter('all'))}
                            checked={e?.content == selectFilter}
                          />
                          <span>{e?.content[0].toUpperCase() + e?.content.slice(1)}</span>
                        </label>
                      </section>
                    );
                  })}
                </div>
              }
              handleReset={() => {
                setSelectFilter('all');
              }}
              handleApply={() => {
                setPaginationParams({ ...paginationParams, page: 1 });
                setfilterMedia(selectFilter);
              }}
            />
          </>
        }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder={t('search_media') || 'Search Media'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              onClick={handleClick}
              type="search"></Input>
            {showPagination && (
              <Pagination
                currentPage={paginationParams.page}
                showError={setErrPage}
                newCurrentPage={setPaginationParams}
                totalPage={Math.ceil(total / paginationParams.limit)}
                pageSize={total}
                onNext={onNextPage}
                onPrev={onPrevPage}
                type="media"
                searchedValue={paginationParams.search}
                limit={paginationParams.limit}></Pagination>
            )}
          </>
        }
      />
      {conttainer == 'list' ? (
        <>
          <MediaList loader={loading} data={mediaData} mediaType={selectFilter} onclick={handleDeleteList} />
          {mediaData?.length>0 && (
            <div className="flex justify-end pt-5">
              <Pagination
                belowTable={true}
                currentPage={paginationParams?.page}
                showError={setErrPage}
                newCurrentPage={setPaginationParams}
                totalPage={Math.ceil(total / paginationParams.limit)}
                pageSize={total}
                searchedValue={paginationParams.search}
                onNext={() => handlePaginationChange(paginationParams?.page - 1)}
                onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
                type="media"
                limit={paginationParams.limit}></Pagination>
            </div>
          )}
        </>
      ) : (
        <MediaGrid
          total={total}
          paginationParam={paginationParams}
          setPaginationParam={setPaginationParams}
          smallLoader={smallLoader}
          loader={loading}
          data={mediaData}
        />
      )}
    </section>
  );
}

export default Media;
