import { useTranslation } from 'context/translation';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components';
import { TopLayer, FunctionLayer, TableActions } from '../../../components/common/table';
import { createDummyData } from '../../../utils/createDummyData';

function PremiumList() {
  const navigate = useNavigate();
  const { t }: any = useTranslation();

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('name') || 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: t('description') || 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: t('slug') || 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: 300,
    },
    {
      title: t('count') || 'Count',
      dataIndex: 'count',
      key: 'count',
      width: 300,
    },
    {
      title: t('Actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: any) => (
        <TableActions
          icons={[
            { iconClassName: 'far fa-eye', onClick: () => {} },
            {
              iconClassName: 'fas fa-edit',
              onClick: () => {
                handleEditPremium(record.id);
              },
            },
            { iconClassName: 'fas fa-trash-alt', onClick: () => {} },
          ]}
        />
      ),
    },
  ];

  const dataSource = createDummyData(columns);

  const handleAddPremium = useCallback(() => {
    navigate('/premium/add');
  }, [navigate]);

  const handleEditPremium = useCallback(
    (id: string | number) => {
      navigate(`/premium/edit/${id}`);
    },
    [navigate],
  );

  return (
    <section className="Category">
      <TopLayer
        titleText={t('premium') || 'Premium'}
        buttonText={t('add_premium') || 'Add Premium'}
        onButtonClicked={handleAddPremium}
      />
      <FunctionLayer leftPortion={<></>} rightPortion={<></>} />
      <Table dataSource={dataSource} columns={columns} />
    </section>
  );
}

export default PremiumList;
