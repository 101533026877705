import { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Pagination, Input, Avatar, Spinner } from '../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../components/common/table';
import { TableActionButton } from '../../components/common/button';
import { deleteAPIData, getAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import { DisplayImage } from 'components/DisplayImage';
import user from '../../assets/images/user.png';
import { debounce } from 'lodash';
import { ToastTypes, useToast } from 'context/toast';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { useTranslation } from 'context/translation';
import { getEnglishId } from 'pages/Media/utils';

function AllUsers() {
  const navigate = useNavigate();
  const { authUser, setAuthUser } = useAuth();
  const [errPage, setErrPage] = useState<any>(false);
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const [dataSource, setDataSource] = useState<TODO_ANY>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const { callToast } = useToast();
  const { t }: any = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  // const debounceOnChange = useRef(debounce(onChange, 400)).current;

  // function onChange(val: string) {
  //   setInputValue(val);
  //   setPaginationParams({
  //     page: 1,
  //     search: val,
  //     limit: paginationParams.limit,
  //   });
  // }
  // useEffect(() => {
  //   return () => {
  //     debounceOnChange.cancel();
  //   };
  // }, [debounceOnChange]);

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getUsers();
    }
  }, [paginationParams]);
  const getUsers = async () => {
    setLoading(true);

    const payload = {
      url: 'user',
      data: {
        page: paginationParams.page || 1,
        searchText: paginationParams.search,
        limit: paginationParams.limit,
      },
      limit: paginationParams.limit,
    };
    try {
      const res = await getAPIData(payload);
      const data = res?.data?.result?.map((element: any) => {
        return {
          Role: element?.role?.role,
          email: element?.email,
          id: element?.id,
          name: element?.firstName + ' ' + element?.lastName,
          username: element?.username,
          imageUrl: element?.media?.url,
          languageId: getEnglishId() ?? selectedLanguage?.id,
        };
      });
      setTotal(res?.data?.total);
      setRoles(res?.data?.roleWiseCount);
      setDataSource(data);
      setLoading(false);
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };

  const getTabsData = (roles: TODO_ANY) => {
    let updatedRoles = roles?.map((role: TODO_ANY) => {
      return { label: role.role, num: role.count };
    });
    updatedRoles?.push({
      label: t('all') || 'All',
      num: roles?.reduce(
        (accumulator: TODO_ANY, currentValue: TODO_ANY) => accumulator + Number(currentValue.count),
        0,
      ),
    });
    return updatedRoles;
  };

  const handleToAddContent = useCallback(() => {
    navigate('/users/add');
  }, [navigate]);

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('username') || 'Username',
      dataIndex: '',
      key: 'username',
      width: 250,
      render: (userDetails: TODO_ANY) => (
        <>
          <div className="flex items-center">
            <DisplayImage
              image={userDetails?.imageUrl || user}
              smallCircular={true}
              label={userDetails?.username}></DisplayImage>
            <span className="ml-[10px]">{userDetails?.username}</span>
          </div>
        </>
      ),
    },
    {
      title: t('name') || 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: t('email') || 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      title: t('role') || 'Role',
      dataIndex: 'Role',
      key: 'Role',
      width: 250,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (user: TODO_ANY) => {
        return (
          <TableActions
            icons={[
              {
                iconClassName: `fas fa-edit ${user?.id === authUser?.id ? 'disabled:opacity-100' : ''}`,
                disabled: user?.id === authUser?.id,
                onClick: () => {
                  handleEditUser(user?.id);
                },
              },
              {
                iconClassName: `fas fa-trash-alt ${user?.id === authUser?.id ? 'disabled:opacity-100' : ''}`,
                disabled: user?.id === authUser?.id,
                onClick: () => {
                  setShowDel(true);
                  setDeleteId(user);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleEditUser = useCallback(
    (id: string | number) => {
      navigate(`/users/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    try {
      callToast(ToastTypes.INFO, t('user_deleting') || 'Deleting user...');
      await deleteAPIData({ url: `user/${id}` });
      callToast(ToastTypes.SUCCESS, t('user_deleted') || 'User deleted successfully');
      getUsers();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  const handleInputChange = (val: string) => {
    // debounceOnChange(val);
    setInputValue(val);
  };

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  return (
    <section className="AllUsers">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={`user ${deleteId?.name}`}
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText="Users"
        buttonText="Add User"
        disableBtn={loading}
        langCode={selectedLanguage?.langCode}
      />

      {/* <TabsLayer tabs={getTabsData(roles)} /> */}
      <FunctionLayer
        // leftPortion={
        //   <>
        //     <Select
        //       value={dateValue}
        //       options={[
        //         { label: 'All Dates', value: '0' },
        //         { label: '1 day', value: '1' },
        //       ]}
        //       onChange={setDateValue}
        //     />

        //     <TableActionButton onClick={() => {}} title="Filter" />
        //   </>
        // }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              onClick={handleClick}
              placeholder={t('search_users') || 'Search Users'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={dataSource} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default AllUsers;
