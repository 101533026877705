import { InputBoxType } from 'enums/companyForm.enum';
export const pageFormTextEditorInput = {
  type: InputBoxType.Paragraph,
  name: 'content',
  id: 'content',
  placeholder: '',
  label: 'Content',
  required: true,
  btnLabel: '',
};
