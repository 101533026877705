import { CircularProgress } from '@mui/material';
import { useTranslation } from 'context/translation';

export const Spinner = ({ className = 'm-5', size = '2rem' }: { className?: string; size?: string; }) => {
  return (
    <div className={className}>
      <CircularProgress sx={{ color: '#4DBD74' }} size={size} />
    </div>
  );
};

export const SmallLoader = ({langCode}:{langCode?:any}) => {
  const { t }: any = useTranslation();
  return (
    <div className="flex items-center text-sm text-gray-text">
      <Spinner className="mr-2 mt-2" size="1rem" />
      {langCode ? t('loading', langCode) || 'Loading...' : t('loading') || 'Loading...'}
    </div>
  );
};
