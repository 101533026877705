import ToastProvider from 'context/toast';
import TranslationProvider from 'context/translation';
import UserProvider, { LanguageListsProvider, SelectedLangProvider } from 'context/user';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './AppContainer';
function App() {
  return (
    <TranslationProvider>
      <LanguageListsProvider>
        <SelectedLangProvider>
          <UserProvider>
            <ToastProvider>
              <AppContainer />
            </ToastProvider>
          </UserProvider>
        </SelectedLangProvider>
      </LanguageListsProvider>
    </TranslationProvider>
  );
}

export default App;
