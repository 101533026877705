import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
import { useEffect } from 'react';

const QuoteContent = ({ register, errors, langCode }: { register: any; errors: any; langCode?: any }) => {
  const { t }: any = useTranslation();

  return (
    <>
      <div className="border-grey rounded-b-[5px] border">
        <div className="border-grey border-b-1 px-[26px] pt-[32px] pb-[30px]">
          <div className="w-full flex-shrink-0">
            <label className="mb-[20px] block text-lg font-bold text-gray-text">
              {langCode ? t('quote', langCode) || 'Quote' : t('quote') || 'Quote'}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <textarea
              className="placeholder-text-grey-text height-[127px] flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none"
              {...register('quote')}
            />
          </div>
          {errors?.['quote'] && (
            <p className="mt-2 text-sm italic text-red-600">{t(convertToKey(errors?.['quote']?.message), langCode)}</p>
          )}
        </div>
        <div className=" px-[26px] pt-[32px] pb-[30px]">
          <div className="w-full flex-shrink-0">
            <label className="mb-[20px] block text-lg font-bold text-gray-text">
              {langCode ? t('quote_author', langCode) || 'Quote Author' : t('quote_author') || 'Quote Author'}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <input
              className="placeholder-text-grey-text flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none"
              {...register('quoteAuthor')}
            />
          </div>
          {errors?.['quoteAuthor'] && (
            <p className="mt-2 text-sm italic text-red-600">
              {t(convertToKey(errors?.['quoteAuthor']?.message), langCode) || errors?.['quoteAuthor']?.message}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default QuoteContent;
