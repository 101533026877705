import { values } from 'lodash';
import { STATUS_TYPE_CONTENT } from 'pages/Content/utils';
import * as yup from 'yup';
export const questionValidation = yup.object().shape({
  text: yup
    .string()
    .required('Question is required')
    .test('no-only-whitespace', 'Question cannot contain only whitespace', (value: any) => {
      return value.trim() !== '';
    }),
  answer: yup.string().required('Answer is required'),
});

export const contentFormValidationVideo = {
  video: yup.object().shape({
    url: yup.string().required('Video is required'),
    title: yup.string(),
    fileName: yup.string(),
    fileSize: yup.string(),
    thumbnail: yup.string(),
  }),
};

export const contentFormValidationQuote = {
  quote: yup.string().required('Quote is required'),
  quoteAuthor: yup.string().required('Quote Author is required'),
};

export const contentFormValidationQuestions = {
  questions: yup.array().of(questionValidation).min(1, 'At least 1 question is required'),
};

export const formSchema = {
  name: yup
    .string()
    .required('Title is required.')
    .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
      return !!value?.trim();
    }),
  statusId: yup.string().required('Status Id is required'),
  textField: yup.string().nullable(),
  contentTypeId: yup.string().nullable(),
  contentType: yup.string().nullable(),
  scheduleDays: yup.number().min(1, 'Minimum value should be 1'),
};

export const contentFormValidation = yup
  .object()
  .shape({
    ...formSchema,
    ...contentFormValidationQuestions,
    ...contentFormValidationQuote,
    ...contentFormValidationVideo,
  })
  .when((values, schema) => {
    if (values.statusId === STATUS_TYPE_CONTENT.Published) {
      return schema.shape({
        publishTime: yup
          .date()
          .required('This field is required')
          .nullable()
          .default(() => new Date()),
      });
    }
  })
  .when((values, schema) => {
    switch (values.contentTypeId) {
      case '1': {
        return schema.shape({
          ...formSchema,
          quote: yup.string().notRequired(),
          quoteAuthor: yup.string().notRequired(),
          questions: yup.array().nullable(),
          video: yup
            .object()
            .shape({
              url: yup.string().notRequired(),
              title: yup.string(),
              fileName: yup.string(),
              fileSize: yup.string(),
              thumbnail: yup.string(),
            })
            .nullable(),
        });
      }
      case '3': {
        return schema.shape({
          ...formSchema,
          ...contentFormValidationVideo,
          quote: yup.string().notRequired(),
          quoteAuthor: yup.string().notRequired(),
          questions: yup.array().nullable(),
        });
      }
      case '2': {
        return schema.shape({
          ...formSchema,
          ...contentFormValidationQuote,
          questions: yup.array().nullable(),
          video: yup
            .object()
            .shape({
              url: yup.string().notRequired(),
              title: yup.string(),
              fileName: yup.string(),
              fileSize: yup.string(),
              thumbnail: yup.string(),
            })
            .nullable(),
        });
      }
      case '4': {
        return schema.shape({
          ...formSchema,
          ...contentFormValidationQuestions,
          quote: yup.string().notRequired(),
          quoteAuthor: yup.string().notRequired(),
          video: yup
            .object()
            .shape({
              url: yup.string().notRequired(),
              title: yup.string(),
              fileName: yup.string(),
              fileSize: yup.string(),
              thumbnail: yup.string(),
            })
            .nullable(),
        });
      }
      default: {
        return schema.shape({
          ...formSchema,
          quote: yup.string().notRequired(),
          quoteAuthor: yup.string().notRequired(),
          questions: yup.array().nullable(),
          video: yup
            .object()
            .shape({
              url: yup.string().notRequired(),
              title: yup.string(),
              fileName: yup.string(),
              fileSize: yup.string(),
              thumbnail: yup.string(),
            })
            .nullable(),
        });
      }
    }
  });

export const schemaForFormVal = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('Title is required.')
      .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
        return !!value?.trim();
      }),
    // name: yup.string().required("Title is required"),
    badgeImgUrl: yup.string().required('Image is required'),
    shareText: yup
      .string()
      .required('Share Text is required.')
      .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
        return !!value?.trim();
      }),
    // shareText: yup.string().required("Share Text is required"),
    congratsText: yup
      .string()
      .required('Congrats Text is required.')
      .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
        return !!value?.trim();
      }),
    // congratsText: yup.string().required("Congrats Text is required"),
    // interrupterContentId: yup.string().required('This field is required'),
    // categoryId: yup.string().required('This field is required')
  })
  .when((values, schema) => {
    if (values.isMonthlyBadge) {
      return schema.shape({
        startDate: yup.date().nullable(),
        // categoryId: yup.string().required('This field is required').nullable(),
        endDate: yup
          .date()
          .when(
            'startDate',
            // (startDate, schema) => startDate && schema?.min(startDate,"End date should be greater than start date")
            {
              is: (startDate: any) => {
                return !!startDate ? true : false;
              },
              then: yup
                .date()
                .min(yup.ref('startDate'), 'End date should be greater than start date')
                .required('End date is required'),
            },
          )
          .nullable(),
      });
    }
  });

export const schemaForInterrupters = yup.object().shape({
  contentId: yup.string().required('This field is required'),
  date: yup
    .date()
    .required('This field is required')
    .nullable()
    .default(() => new Date()),
});
export const schemaForSequences = yup.object().shape({
  contentId: yup.string().required('This field is required'),
});

export const schemaForMedia = yup.object().shape({
  url: yup.string().required('This field is required'),
  alternativeText: yup.string(),
});
