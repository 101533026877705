import { TODO_ANY } from 'typings/common';

export const filterArray = [
  {
    id: 1,
    content: 'video',
  },
  {
    id: 2,
    content: 'image',
  },
];
const uploadedToTitleAuth = (data: any, arr: any, title: any) => {
  for (let key of data) {
    arr.push({
      id: key?.id,
      name: key?.name,
      content: title,
    });
  }
  return arr;
};
const UploadedToAuthor = (data: TODO_ANY) => {
  let A: any = [];
  if (data?.badge?.length) {
    uploadedToTitleAuth(data?.badge, A, 'badges');
  }
  if (data?.imageContent?.length) {
    uploadedToTitleAuth(data?.imageContent, A, 'content');
  }
  if (data?.videoContent?.length) {
    uploadedToTitleAuth(data?.videoContent, A, 'content');
  }
  return A;
};
export const TableContent = (data: TODO_ANY) => {
  let Arr = [];
  for (let key of data) {
    let obj: any = {
      id: key?.id,
      size: key?.fileSize,
      mediaType: key?.type?.charAt(0).toUpperCase() + key?.type?.slice(1),
      fileData: {
        type: key?.type,
        name: key?.fileName,
        imgUrl: key?.type == 'video' ? key?.thumbnail : key?.url,
      },
      altText: key?.alternativeText,
      author: UploadedToAuthor(key),
    };
    if (key?.type == 'video') {
      obj.fileData.videoUrl = key?.url;
    }
    Arr.push(obj);
  }
  return Arr;
};

export const UploadedTo = (data: TODO_ANY) => {
  let A: any = [];
  if (data?.badge?.length) {
    uploadedToTitle(data?.badge, A);
  }
  if (data?.imageContent?.length) {
    uploadedToTitle(data?.imageContent, A);
  }
  if (data?.videoContent?.length) {
    uploadedToTitle(data?.videoContent, A);
  }
  return A.join(',');
};
const uploadedToTitle = (data: any, arr: any) => {
  for (let key of data) {
    arr.push(key?.name);
  }
  return arr;
};

export const getEnglishId = () => {
  try {
    let langList: any = localStorage?.getItem('@langLists');
    langList = JSON.parse(langList);
    const english = langList?.find((data: any) => data?.langCode == 'en');
    return english?.id;
  } catch (err: any) {
    console.error('err---->', err);
  }
};
