import { TODO_ANY } from 'typings/common';

export const getTableData = (data: TODO_ANY[]) => {
  let tableData = [];
  for (let el of data) {
    tableData.push({
      adminEmail: el.adminEmail,
      companyId: el.code,
      title: el.name,
      enabled: el.enabled ? 'Yes' : 'No',
      id: el?.id,
    });
  }
  return tableData;
};
export const getTableSequenceData = (data: TODO_ANY[]) => {
  let sequences = [];

  for (let key of data) {
    sequences.push({
      contentId: key?.contentId,
      order: key?.order,
      title: key?.name,
      id: key?.id,
    });
  }
  return sequences;
};
