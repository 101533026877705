import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import { Switch as MuiSwitch, Typography } from '@mui/material';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { TODO_ANY } from 'typings/common';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#20A8D8',
    '&:hover': {
      backgroundColor: alpha('#20A8D8', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#20A8D8',
  },
}));

const customLabel = { inputProps: { 'aria-label': 'Color switch demo' } };

export default function Switch({
  label = '',
  control,
  name = '',
  register,
  checked,
  required,
  action = () => {},
  disabled,
  description = '',
  langCode,
}: {
  label?: string;
  control?: Control<any, any>;
  name?: string;
  register?: any;
  required?: boolean;
  checked?: boolean;
  action?: any;
  disabled?: boolean;
  description?: string;
  langCode?: any;
}) {
  const { t }: any = useTranslation();
  const labelKey = convertToKey(label);

  return (
    <>
      {label && (
        <div className="w-full flex-shrink-0">
          <p className="mb-1 text-lg font-bold text-gray-text">
            {langCode ? t(labelKey, langCode) || label : t(labelKey) || label}
            {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
          </p>
        </div>
      )}
      {description && (
        <p className="text-[14px] font-light text-gray-subject-text">
          {description}
        </p>
      )}

      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Stack direction="row" alignItems="center" paddingLeft={1}>
            <Typography>{langCode ? t('no', langCode) || 'No' : t('no') || 'No'}</Typography>
            <CustomSwitch
              onChange={(data: any) => {
                action(data.target.checked);
                onChange(data);
              }}
              checked={value}
              disabled={disabled}
              {...customLabel}
              defaultChecked={checked}
            />
            <Typography>{langCode ? t('yes', langCode) || 'Yes' : t('yes') || 'Yes'}</Typography>
          </Stack>
        )}
      />
    </>
  );
}

export const FilterSwitch = ({ setState, state, label, langCode }: TODO_ANY) => {
  const { t }: any = useTranslation();

  return (
    <>
      {label && (
        <div className="w-full flex-shrink-0">
          <p className="mb-1 text-lg font-bold text-gray-text">
            {langCode ? t(label, langCode) || label : t(label) || label}
          </p>
        </div>
      )}
      <Stack direction="row" alignItems="center" paddingLeft={1}>
        <Typography>{langCode ? t('no', langCode) || 'No' : t('no', langCode) || 'No'}</Typography>
        <CustomSwitch
          onChange={(data: any) => {
            setState(data.target.checked);
          }}
          {...customLabel}
          checked={state || false}
        />
        <Typography>{langCode ? t('yes', langCode) || 'Yes' : t('yes') || 'Yes'}</Typography>
      </Stack>
    </>
  );
};
