import { Checkbox } from 'components';
import arrowUpSolid from '../../assets/images/arrow-upSolid.svg';
import removeImg from '../../assets/images/remove.svg';
import addCircle from '../../assets/images/add-circle.svg';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const QuizForm = ({
  register,
  control,
  option,
  errors,
  langCode,
}: {
  register: any;
  control: any;
  option: any;
  errors: any;
  langCode?: any;
}) => {
  const { append, remove, fields } = useFieldArray({
    name: 'questions',
    control: control,
  });
  const { t }: any = useTranslation();

  return (
    <div className="border-grey rounded-b-[5px] border">
      <div className=" px-[26px] pt-[32px] pb-[30px]">
        <div className="w-full flex-shrink-0">
          <label className="mb-[20px] block text-lg font-bold text-gray-text">
            {langCode ? t('quiz_question', langCode) || 'Quiz Questions' : t('quiz_question') || 'Quiz Questions'}
            <span style={{ color: 'red' }}>*</span>
          </label>
          {/*  Quiz Questions loop */}
          {fields.map((data: any, index: any) => {
            return (
              <div className="mb-[20px] flex justify-between">
                <div className="flex items-center justify-center rounded-[5px] bg-gray-title-bg p-[20px]">
                  {index + 1}.
                </div>
                <div className="w-full px-[20px]">
                  <p className="mb-[10px] text-[16px] font-medium text-gray-text">
                    {langCode ? t('question_text', langCode) || 'Question Text' : t('question_text') || 'Question Text'}
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <input
                    className="placeholder-text-grey-text flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none"
                    {...register(`questions[${index}].text`)}
                    defaultValue="data.text"
                  />
                  {errors?.questions?.[index]?.text && (
                    <p className="mt-2 text-sm italic text-red-600">{errors?.questions?.[index]?.text?.message}</p>
                  )}
                </div>
                <div className="min-w-[150px] pr-[20px]">
                  <div className="mb-[20px] text-[16px] font-medium text-gray-text">
                    {langCode ? t('answer', langCode) || 'Answer' : t('answer') || 'Answer'}
                  </div>
                  <div className="flex justify-between">
                    <label className="customRadio mr-[30px] flex cursor-pointer items-center text-[16px] font-medium text-gray-text">
                      {' '}
                      <input
                        type="radio"
                        value="T"
                        name={`questions[${index}].answer`}
                        {...register(`questions[${index}].answer`)}
                      />
                      <span> {langCode ? t('true', langCode) || 'True' : t('true') || 'True'} </span>{' '}
                    </label>
                    <label className="customRadio flex cursor-pointer items-center text-[16px] font-medium text-gray-text">
                      {' '}
                      <input
                        type="radio"
                        value="F"
                        name={`questions[${index}].answer`}
                        {...register(`questions[${index}].answer`)}
                      />
                      <span> {langCode ? t('false', langCode) || 'False' : t('false', langCode) || 'False'} </span>{' '}
                    </label>
                  </div>
                  {errors?.questions?.[index]?.answer && (
                    <p className="mt-2 text-sm italic text-red-600">
                      {t(convertToKey(errors?.questions?.[index]?.answer?.message), langCode)}
                    </p>
                  )}
                </div>
                <div className="flex min-w-[150px] items-center justify-end">
                  <button onClick={() => remove(index)}>
                    <img className="cursor-pointer" src={removeImg} alt="arrow" title="arrow"></img>
                  </button>
                </div>
              </div>
            );
          })}

          {/* add  Quiz Questions */}
          <span
            onClick={() => append({ text: '', answer: '' })}
            className="mt-[40px]  flex w-full cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
            <img src={addCircle} alt="arrow" className="mr-[5px]" title="arrow"></img>
            <button className="text-[12px] text-white">
              {langCode ? t('add_question', langCode) || 'Add Question' : t('add_question') || 'Add Question'}
            </button>
          </span>
          {/* Video Fields */}
        </div>
        {errors?.['questions'] && fields.length === 0 && (
          <p className="mt-2 text-sm italic text-red-600">
            {t(convertToKey(errors?.['questions']?.message), langCode)}
          </p>
        )}
      </div>
    </div>
  );
};

export default QuizForm;
