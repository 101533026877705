import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Textarea, ContentCell, TopLayer, Select } from 'components';
import { useTranslation } from 'context/translation';

function Premium() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t }: any = useTranslation();
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(
    () => (isEditPage ? t('edit_premium') || 'Edit Premium' : t('add_premium') || 'Add Premium'),
    [isEditPage],
  );
  const titleButtonText = useMemo(() => (isEditPage ? t('save') || 'Save' : t('add') || 'Add'), [isEditPage]);
  const [title, setTitle] = useState('');

  const handleCategoriesList = useCallback(() => {
    navigate('/premium');
  }, [navigate]);

  return (
    <section>
      <TopLayer
        onButtonClicked={() => {}}
        buttonText={titleButtonText}
        titleText={pageTitle}
        className="mb-8"
        onCancelClicked={handleCategoriesList}
      />
      <div className="flex flex-col gap-5">
        <Input label={t('name') || 'Name'} size="medium" value={title} onChange={setTitle} />
        <Input label={t('slug') || 'Slug'} size="medium" value={title} onChange={setTitle} />
        <ContentCell title={t('parent_item') || 'Parent Item'} className="border-b-0 p-0">
          <Select
            className="w-1/2"
            value={''}
            options={[
              { label: t('aaa') || 'aaa', value: '0' },
              { label: t('bbb') || 'bbb', value: '1' },
            ]}
            onChange={() => {}}></Select>
        </ContentCell>
        <ContentCell title={t('description') || 'Description'} className="border-b-0 p-0">
          <Textarea value={title} onChange={setTitle} />
        </ContentCell>
      </div>
    </section>
  );
}

export default Premium;
