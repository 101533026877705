import { useEffect, useState } from 'react';
import { ContentState, convertFromRaw, convertToRaw, EditorProps, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'context/translation';

type TextEditorPropsTypes = {
  footer?: boolean;
  toolBarOptions?: any;
  saveEditorValue?: Function;
  getValues?: Function;
  name?: string;
  langCode?: any;
};

function TextEditor({
  footer = true,
  toolBarOptions = null,
  saveEditorValue = () => {},
  getValues = () => {},
  name = '',
  langCode,
}: TextEditorPropsTypes) {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [val, setVal] = useState<any>('');
  const { t }: any = useTranslation();

  useEffect(() => {
    const { contentBlocks, entityMap } = htmlToDraft(getValues()?.[name] ?? '');
    const contentState = ContentState?.createFromBlockArray(contentBlocks, entityMap);
    setEditorState(EditorState?.createWithContent(contentState));
    setVal(getValues()?.[name] ?? '');
  }, [langCode]);
  const onEditorStateChange = (e: any) => {
    setVal(e);
    // const contentRaw = convertToRaw(val);
    // const contentHTML = draftToHtml(contentRaw);
    setEditorState(e);
    saveEditorValue && saveEditorValue(e);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={val}
        onChange={(e) => {
          setVal(e);
          onEditorStateChange(e);
        }}
        modules={modules}
        formats={formats}
      />
      {footer && (
        <div className="flex items-center justify-between bg-gray-title-bg px-5 py-3 text-sm font-semibold text-gray-text">
          <p>{t('word_count') || 'Word Count:'} 23</p>
          <p>{t('last_edited_by') || 'Last edited by John Smith on July 5, 2022 at 3:47 pm'}</p>
        </div>
      )}
    </div>
  );
}

export default TextEditor;
