import { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { CircularProgress } from '@mui/material';
import { contentTableData, convertToNestedOptions, downloadXLSX, ICategory, NestedSelectionType } from '../utils';
import {
  Table,
  Select,
  Pagination,
  Input,
  TableActionButton,
  FunctionLayer,
  TabsLayer,
  TopLayer,
  TableActions,
  Spinner,
  Filter,
  SmallLoader,
} from 'components';
import { Checkbox, Chip, FormControlLabel } from '@mui/material';
import { TODO_ANY } from 'typings/common';
import { ColumnsType, DefaultRecordType } from 'rc-table/lib/interface';
import { getAPIData, deleteAPIData, putAPIData } from '../../../utils/api';
import { ToastTypes, useToast } from 'context/toast';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import MyTable from 'components/DraggableTable';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { options } from 'constants/survey.constant';
import MultiSelectContainerNested from 'components/MultiSelectDropdown/NestedSelect';
import { useTranslation } from 'context/translation';
import { getEnglishId } from 'pages/Media/utils';
const sequenceFilterOptions = [
  {
    id: 1,
    title: 'Yes',
    data: 'yes',
  },
  {
    id: 2,
    title: 'No',
    data: 'no',
  },
];
function ContentList() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const containerRef = useRef(null);
  const [typeData, setTypeData] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryId, setCategoryId] = useState<any>('');
  const [typeFilter, setTypeFilter] = useState<any>(searchParams.get('contentType') ?? '');
  const [typeFilterTitle, setTypeFilterTitle] = useState<any>('');
  const [isAddedSequence, setIsAddedSequence] = useState<any>(searchParams.get('sequenceFilter') ?? 'both');
  const [isAddedGroup, setIsAddedGroup] = useState<any>(searchParams.get('groupFilter') ?? 'both');
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const [typeDataLoader, setTypeDataLoader] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  // const [categoryLoading, setcategoryLoading] = useState(false);
  const [contentState, setContentState] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState<any>('');
  const [filterCategoryData, setFilterCategoryData] = useState<any>(
    searchParams.get('categoryFilter')?.split(',') ?? [],
  );
  const [total, setTotal] = useState(0);
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') ?? '');
  const [totalData, setTotalData] = useState(0);
  const [urlData, setUrlData] = useState<any>({
    urlcontentType: searchParams.get('contentType') ?? '',
    urlsequenceFilter: searchParams.get('sequenceFilter') ?? 'both',
    urlgroupFilter: searchParams.get('groupFilter') ?? 'both',
    urlcategoryFilter: searchParams.get('categoryFilter') ?? '',
  });
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, {
    tab: selectedTab,
    startDate: undefined,
    endDate: undefined,
    leaderFilter: undefined,
    contentType: urlData.urlcontentType,
    sequenceFilter: urlData.urlsequenceFilter,
    groupFilter: urlData.urlgroupFilter,
    categoryFilter: urlData.urlcategoryFilter,
    mediaFilter: undefined,
  });
  const [errPage, setErrPage] = useState<any>(false);
  const tabHandler = (tab: any) => {
    setSelectedTab(tab);
    setPaginationParams((data: any) => {
      return { ...data, page: 1 };
    });
    getContentData(tab);
  };
  const { callToast } = useToast();
  const [parentOptions, setParentOptions] = useState<any>([]);
  const [nestedOptions, setNestedOptions] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [nestedLoading, setNestedLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [customCategorySelected, setCustomCategorySelected] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [categorySearch, setCategorySearch] = useState<any>('');
  const [categoryLoader, setCategoryLoader] = useState<any>(false);
  const [categoryInfiniteLoader, setCategoryInfiniteLoader] = useState<any>(false);
  const [publishedCount, setPublishedCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [isDragg, setIsDragg] = useState<any>(false);
  const [sortData, setSortData] = useState<any>({ value: '', order: 'ASC' });
  const [data, setData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [categoryDataRw, setCategoryDataRw] = useState<any>([]);
  const [previousCategoryInput, setPreviousCategoryInput] = useState('');
  const [categoryPaginationParams, setCategoryPaginationParams] = useState<any>({
    page: 1,
    search: '',
  });
  const [categoryTotal, setCategoryTotal] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [downloadXLSXLoading, setDownloadXLSXLoading] = useState(false);
  const { t }: any = useTranslation();
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    const ref = containerRef.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef, options, categoryData]);

  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (categoryData.length > 0) {
      if (entry.isIntersecting && categoryData.length < categoryTotal) {
        setCategoryPaginationParams({
          page: categoryPaginationParams.page + 1,
          search: categoryPaginationParams.search,
        });
      }
    }
  };
  useEffect(() => {
    getNestedCategories();
  }, []);

  useEffect(() => {
    let count = 0;
    if (typeFilter) {
      count += 1;
    }
    if (isAddedSequence !== 'both') {
      count += 1;
    }
    if (isAddedGroup !== 'both') {
      count += 1;
    }
    if (filterCategoryData?.length !== 0) {
      count += 1;
    }
    setFilterCount(count);
  }, [typeFilter, isAddedSequence, isAddedGroup, filterCategoryData]);

  const getNestedCategories = async () => {
    try {
      setNestedLoading(true);
      let res = await getAPIData({
        url: 'categories/nested',
        data: { page: 1, searchText: '', limit: 1000, languageId: selectedLanguage?.id },
      });
      setNestedLoading(false);
      setCategoriesData(res.data.data);
      const { parentData, nestedData } = convertToNestedOptions(res.data.data);
      setParentOptions(parentData);
      setNestedOptions(nestedData);
    } catch (error) {
      setNestedLoading(false);
      console.error(error);
    }
  };

  const selectAll = () => {
    const values = categoriesData.map((el: any) => {
      return el.id;
    });
    // setValue('categories', values);
    setFilterCategoryData(values);
  };

  const deselectAll = () => {
    // setValue('categories', []);
    setFilterCategoryData([]);
  };
  const addToSelectedCategories = (category: ICategory) => {
    let values = category.company_id ? [] : [...filterCategoryData];
    setCustomCategorySelected(!!category.company_id);
    if (category.company_id && category.company_name) {
      setSelectedCompany({ id: category.company_id, name: category.company_name });
    }
    let updated: string[] = [];
    for (let id of values) {
      const foundCat = categoriesData.find((c: ICategory) => c?.id === id);
      if (!foundCat.company_id) {
        updated.push(id);
      }
    }
    getMultiSelectCategory(category, updated);
    // setValue('categories', updated);
    setFilterCategoryData(updated);
    // selectParentCategory(category);
  };

  const getMultiSelectCategory = (category: ICategory, updated: string[]) => {
    categoriesData.forEach((c: ICategory) => {
      if (!updated.includes(c.id) && (c.id === category.id || c.parent_id === category.id)) {
        updated.push(c.id);
        getMultiSelectCategory(c, updated);
      }
    });
  };
  const removeFromSelectedCategories = (category: ICategory) => {
    setCustomCategorySelected(!category.company_id);
    let values = [...filterCategoryData];
    values = values.filter((el: string) => {
      return el !== category.id;
    });
    // setValue('categories', values);
    setFilterCategoryData(values);
  };

  const tabs = [
    {
      label: t('all') || 'All',
      num: total || totalData,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: '',
      default: selectedTab === '',
    },
    {
      label: t('published') || 'Published',
      num: publishedCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'published',
      default: selectedTab === 'published',
    },
    {
      label: t('draft') || 'Draft',
      num: draftCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'draft',
      default: selectedTab === 'draft',
    },
    {
      label: t('in_review') || 'In Review',
      num: reviewCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'review',
      default: selectedTab === 'review',
    },
  ];

  const handleAddContent = useCallback(() => {
    navigate('/content/add');
  }, [navigate]);

  const handleEditContent = useCallback(
    (id: string | number) => {
      navigate(`/content/edit/${id}`);
    },
    [navigate],
  );
  const handleDuplicateContent = useCallback(
    (id: string | number) => {
      navigate(`/content/duplicate/${id}`);
    },
    [navigate],
  );
  const getContentData = useCallback(
    async (data?: any) => {
      let categoryIds = filterCategoryData.join(',');
      if (!filterCategoryData.length) {
        categoryIds = filterCategoryData.join(',');
      }

      const payload = {
        url: 'content',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          orderKey: sortData?.value,
          orderDirection: sortData?.order,
          limit: paginationParams.limit,
          status: data || selectedTab,
          categoryIds: categoryIds,
          contentTypeId: typeFilter,
          isInSequence: isAddedSequence,
          isInGroup: isAddedGroup,
          languageId: selectedLanguage?.id,
        },
      };

      try {
        setContentLoading(true);
        const res: any = await getAPIData(payload);
        setContentState(res?.data?.result);
        setData(contentTableData(res?.data?.result));
        setTotal(
          getStatusCount(res?.data?.statusCount, 'published') +
            getStatusCount(res?.data?.statusCount, 'draft') +
            getStatusCount(res?.data?.statusCount, 'review'),
        );
        // setTotal(res?.data?.total)
        setPublishedCount(getStatusCount(res?.data?.statusCount, 'published'));
        setDraftCount(getStatusCount(res?.data?.statusCount, 'draft'));
        setReviewCount(getStatusCount(res?.data?.statusCount, 'review'));
        setTotalData(res?.data?.total);

        if (payload.data.status === 'published') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'published'));
        }
        if (payload.data.status === 'draft') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'draft'));
        }
        if (payload.data.status === 'review') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'review'));
        }
        setContentLoading(false);
      } catch (e: any) {
        setContentLoading(false);
        callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      }
    },
    [paginationParams, filterCategoryData, typeFilter, isAddedSequence, isAddedGroup],
  );

  const getStatusCount = (statusCount: any[], status: string) => {
    return +(
      statusCount?.find((el) => {
        if (el.status.toLowerCase() == status.toLowerCase()) {
          return el;
        }
      })?.count || 0
    );
  };

  const columns: ColumnsType<DefaultRecordType> = [
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: t('categories') || 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: 250,
    },
    {
      title: t('content_type') || 'Content Type',
      dataIndex: 'contentType',
      key: 'contentType',
      width: 250,
    },
    {
      title: t('companies') || 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      width: 250,
    },
    {
      title: t('status') || 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 250,
    },
  ];

  const handleDeleteClick = async (data: any) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_content') || 'Deleting content...');
      await deleteAPIData({ url: `content/${data}` });
      callToast(ToastTypes.SUCCESS, t('deleted_content') || 'Content deleted successfully');
      getContentData();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };
  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };
  const onChange = (val: string) => {
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const onNextPage = () => {
    handlePaginationChange(paginationParams?.page - 1);
  };
  const onPrevPage = () => {
    handlePaginationChange(paginationParams?.page + 1);
  };
  const getTypeData = async () => {
    setTypeDataLoader(true);
    try {
      const res = await getAPIData({
        url: 'content/type',
        data: { languageId: getEnglishId() ?? selectedLanguage?.id },
      });
      setTypeData(res?.data);
    } catch (e: any) {
      console.error(e);
    }
    setTypeDataLoader(false);
  };
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getContentData();
    }
  }, [paginationParams]);
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getTypeData();
    }
  }, []);

  const getBeforePosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex > oldIndex ? newIndex : newIndex - 1;
    return items[index]?.id;
  };
  const getAfterPosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex < oldIndex ? newIndex : newIndex + 1;
    return items[index]?.id;
  };

  var dataTable = data;
  const handleDragChange = useCallback(
    async (items: TODO_ANY, oldIndex: TODO_ANY, newIndex: TODO_ANY) => {
      if (oldIndex === newIndex) {
        return;
      }
      let x = dataTable[oldIndex]?.id;
      setIsDragg(true);
      try {
        await putAPIData({
          url: `content/position/${items[oldIndex]?.id}`,
          data: {
            beforeId: getAfterPosition(dataTable, newIndex, oldIndex),
            afterId: getBeforePosition(dataTable, newIndex, oldIndex),
            contentId: x,
            languageId: selectedLanguage?.id,
          },
        });
        await getContentData();
        setIsDragg(false);
      } catch (e: any) {
        callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
        console.error(e);
        setIsDragg(false);
      }
    },
    [dataTable],
  );

  const isCategoryIdThereCheck = (id: any) => {
    let isThere = false;
    filterCategoryData?.forEach((element: any) => {
      if (element?.id === id) {
        isThere = true;
      }
    });

    return isThere;
  };

  const updateFilterCategoryData = (obj: any) => {
    let isCategoryIdThere = isCategoryIdThereCheck(obj.id);
    let updateCategoryIds: any;
    if (isCategoryIdThere) {
      updateCategoryIds = filterCategoryData.filter((data: any) => data.id !== obj.id);
    } else {
      updateCategoryIds = [...filterCategoryData, obj];
    }
    setFilterCategoryData([...updateCategoryIds]);
  };

  const onCategoryChange = (val: any) => {
    setCategorySearch(val);
    setCategoryPaginationParams({
      page: 1,
      search: val,
    });
  };
  const debounceOnCategoryChange = useRef(debounce(onCategoryChange, 400)).current;

  const handleInputCategoryChange = (val: any) => {
    setCategorySearch(val);
    debounceOnCategoryChange(val);
  };

  useEffect(() => {
    return () => {
      debounceOnCategoryChange.cancel();
    };
  }, [debounceOnCategoryChange]);

  const getContentXLSXData = async () => {
    try {
      setDownloadXLSXLoading(true);
      let res: any = await getAPIData({
        url: 'content/all/grouped',
      });
      downloadXLSX(res?.data);
      setDownloadXLSXLoading(false);
    } catch (error) {
      console.error(error);
      setDownloadXLSXLoading(false);
    }
  };
  return (
    <section>
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.title}
          type="content"
        />
      )}
      <TopLayer
        onButtonClicked={handleAddContent}
        buttonText={t('add_content') || 'Add Content'}
        titleText={t('content') || 'Content'}
        disableBtn={contentLoading && categoryLoader && typeDataLoader}
      />
      <div className="flex items-center  justify-between">
        <div className="flex items-center  ">
          <TabsLayer tabs={tabs} />
          <Filter
            count={filterCount}
            className="mt-5"
            width="550px"
            children={
              <div className="px-5 py-2">
                <div className="text-gray-text">
                  <b className="mb-2 font-bold">{t('select_content_type') || 'Select Content Type'}</b>
                  <br />
                  <div className="flex">
                    {typeData?.map((e: any) => {
                      return (
                        <label
                          key={e?.id}
                          className="customFilterCheck mr-3 flex cursor-pointer items-center text-[14px]">
                          <input
                            type="checkbox"
                            onChange={(a: any) => {
                              a.target.checked ? setTypeFilter(e?.id) : setTypeFilter('');
                            }}
                            checked={e?.id == typeFilter}
                          />
                          <span>{e?.type.split(' ')[0]}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-[10px] mt-[10px] text-gray-text">
                  <b className="mb-2 font-bold">{t('content_under_group') || 'Is Content under any Group'}?</b> <br />
                  <div className="flex">
                    {sequenceFilterOptions?.map((e: any) => {
                      return (
                        <label
                          key={e?.id}
                          className="customFilterCheck mr-3 flex cursor-pointer items-center text-[14px]">
                          <input
                            type="checkbox"
                            onChange={(a: any) => {
                              a.target.checked ? setIsAddedGroup(e?.data) : setIsAddedGroup('both');
                            }}
                            checked={e?.data == isAddedGroup}
                          />
                          <span>{t(e?.title)}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-[10px] mt-[10px] text-gray-text">
                  <b className="mb-2 font-bold">{t('content_under_sequence') || 'Is Content under any Sequence'}?</b>{' '}
                  <br />
                  <div className="flex">
                    {sequenceFilterOptions?.map((e: any) => {
                      return (
                        <label
                          key={e?.id}
                          className="customFilterCheck mr-3 flex cursor-pointer items-center text-[14px]">
                          <input
                            type="checkbox"
                            onChange={(a: any) => {
                              a.target.checked ? setIsAddedSequence(e?.data) : setIsAddedSequence('both');
                            }}
                            checked={e?.data == isAddedSequence}
                          />
                          <span>{t(e?.title) || e?.title}</span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-[20px]">
                  <div className="mb-2 font-bold text-gray-text">{t('categories') || 'Categories'}</div>
                  <div className="flex flex-col">
                    {parentOptions && (
                      <MultiSelectContainerNested
                        type={NestedSelectionType.MULTI_SELECT}
                        loading={nestedLoading}
                        parentOptions={parentOptions}
                        selectedOptions={filterCategoryData}
                        selectAll={selectAll}
                        addToSelectedCategories={addToSelectedCategories}
                        removeFromSelectedCategories={removeFromSelectedCategories}
                        allOptions={categoriesData}
                        deselectAll={deselectAll}
                        nestedOptions={nestedOptions}></MultiSelectContainerNested>
                    )}
                  </div>
                </div>
              </div>
            }
            handleReset={() => {
              setIsAddedSequence('both');
              setIsAddedGroup('both');
              setTypeFilter('');
              setCategoryTitle('');
              setFilterCategoryData([]);
            }}
            handleApply={() => {
              setPaginationParams((data: any) => {
                return { ...data, page: 1 };
              });
              setUrlData({
                urlcontentType: typeFilter,
                urlsequenceFilter: isAddedSequence,
                urlgroupFilter: isAddedGroup,
                urlcategoryFilter: filterCategoryData.join(','),
              });
              setCategoryTitle('');
              getContentData();
            }}
          />
        </div>
        <div className="mt-5 ">
          <FunctionLayer
            leftPortion={<></>}
            rightPortion={
              <>
                <Input
                  value={inputValue}
                  onChange={onChange}
                  onClick={handleClick}
                  placeholder={t('search_content') || 'Search Content'}
                  type="search"
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleClick();
                    }
                  }}></Input>
                <Pagination
                  currentPage={paginationParams.page}
                  showError={setErrPage}
                  newCurrentPage={setPaginationParams}
                  totalPage={Math.ceil(totalData / paginationParams?.limit)}
                  pageSize={totalData}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  type="content"
                  tab={selectedTab}
                  searchedValue={paginationParams.search}
                  limit={paginationParams.limit}></Pagination>
              </>
            }
          />
        </div>
      </div>
      {/* {showDel && } */}
      {(contentLoading || categoryLoader || typeDataLoader) && !isDragg ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        // <Table dataSource={contentTableData(contentState)} columns={columns} />
        <>
          <div className="flex justify-end">
            <button
              disabled={downloadXLSXLoading}
              className="flex min-w-[70px] items-center rounded-md bg-green-main px-2.5 py-1.5 text-sm text-white"
              onClick={() => getContentXLSXData()}>
              Download{' '}
              {downloadXLSXLoading && (
                <CircularProgress sx={{ color: '#fff' }} size={15} className="ml-[5px] h-[15px] w-[15px]" />
              )}
            </button>
          </div>
          <MyTable
            dataSource={data}
            showDrag={isDragg}
            columns={columns}
            sortDetails={setSortData}
            tableActions={[
              {
                iconClassName: 'fas fa-edit',
                type: 'edit',
                info: t('edit') || 'Edit',
                onClick: (el: TODO_ANY) => {
                  handleEditContent(el.id);
                },
              },
              {
                iconClassName: 'fas fa-trash-alt',
                type: 'delete',
                info: t('delete') || 'Delete',
                onClick: (el: TODO_ANY) => {
                  setShowDel(true);
                  setDeleteId(el);
                },
              },
              {
                iconClassName: 'fa fa-clone',
                type: 'duplicate',
                info: t('duplicate') || 'Duplicate',
                onClick: (el: TODO_ANY) => {
                  handleDuplicateContent(el.id);
                },
              },
            ]}
            handleDragChange={handleDragChange}
          />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(totalData / paginationParams.limit)}
              pageSize={totalData}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default ContentList;
