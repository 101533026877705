import React, { useState, useCallback, useEffect, useRef } from 'react';
import TableRow from './TableRow';
import { Tooltip } from '@mui/material';
import arrowUp from '../../assets/images/insert-above-icon.svg';
import arrowDown from '../../assets/images/insert-below-icon.svg';
import styled from 'styled-components';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ITEMS } from './data';
// import BaseTable from "react-base-table";
import BaseTable, { Column, AutoResizer } from "react-base-table";
import arrayMove from './arrayMove';
import './table.css';
import { useTranslation } from 'context/translation';
const DraggableElement = SortableElement(({ children }) => children)
const DraggableHandle = SortableHandle(({ children }) => children)
// const SortableCont = SortableContainer(({ children }) => {
//   return <tbody className="rc-table-tbody">{children}</tbody>;
// });
const SortableCont= SortableContainer(({children})=>children)

const Row = ({ key, index, children, ...rest }) => {
    const { t } = useTranslation();

  return (
  <DraggableElement key={key} disable={rest.hideDrag} index={index}>
    <div {...rest}>
      {children}
      <div className='flex' style={{width: rest?.width}}>
      <DraggableHandle>
      <Tooltip title={t('drag')||"Drag"}>
    <div className={!rest.hideDrag ? "handle flex h-7 w-7 items-center justify-center rounded bg-blue-main text-white" :
     "handle flex h-7 w-7 items-center justify-center rounded bg-gray-main text-white" }>
      <i class="fa fa-arrows" aria-hidden="true"></i>
    </div>
  </Tooltip>
      </DraggableHandle>
      {
        rest?.actions?.map(e=>{
          return (
            <Tooltip title={e?.info}>
            <button
            key={e.type}
            className="flex mr-[10px] h-7 w-7 items-center justify-center rounded bg-blue-main text-white"
            onClick={()=>{
              e.onClick({
                id:rest?.data[index]?.id
              })
            }}>
              {e?.iconClassName ? (
                  <i className={e.iconClassName}></i>
                ) : e?.btnIcon == 'Up' ? (
                  <img src={arrowUp} alt="arrow" width="20" />
                ) : e?.btnIcon == 'Down' ? (
                  <img src={arrowDown} alt="arrow" width="20" />
                ) : (
                  ''
                )}
            </button>
            </Tooltip>
          )
        })
      }
      {/* <button onClick={()=>e?.onClick(rest?.data[index]?.id)}>Edit</button>
      <button onClick={()=>rest.deleteAction(rest?.data[index]?.id)}>Delete</button> */}
      </div>
    </div>
  </DraggableElement>
)}

// const rowProps = ({ rowIndex }) => ({
//   tagName: Row,
//   index: rowIndex,
// })

// const SortableItem = SortableElement((props) => <TableRow {...props} />);

const MyTable = ({ dataSource, showDrag, columns, tableActions, sortDetails, handleDragChange }) => {
  const [items, setItems] = useState(dataSource);
  const ref = useRef(null)
  const [sort, setSort] = useState({ sort: 'ASC', order: '' });
    const { t } = useTranslation();

  useEffect(() => {
    setItems(dataSource);
  }, [dataSource]);
  useCallback(()=>{
    setItems(dataSource);
  },[dataSource])
  // const onSortEnd = useCallback(
  //   ({ oldIndex, newIndex }) => {
  //     handleDragChange(items, oldIndex, newIndex);
  //     setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex, sort));
  //   },
  //   [sort, dataSource],
  // );
  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if(oldIndex==newIndex) return
    const data = [...items]
    handleDragChange(items, oldIndex, newIndex);
    const [removed] = data.splice(oldIndex, 1)
    data.splice(newIndex, 0, removed)
    if(data[0]?.order){
      let min=Infinity
      for(let key of data){
        if(+key?.order<min) min = +key?.order
      }
      for(const element of data){
        element.order=min++
      }
    }
    setItems(data);
  }, [dataSource])
  const rowProps = args => {
    return {
      tagName: Row,
      index: args.rowIndex,
      data: items,
      actions: tableActions, 
      width: columns[0]?.width,
      hideDrag: showDrag

    }
  }
  const headerRendererForTable = useCallback(
    (props) => {
      const { headerIndex, columns, cells } = props;
      let a=[]
      for(let key of columns){
        a.push(
            <>
        <div style={{width:columns[0]?.width}} className='headerTitle' key={key?.title}>
                {t(key?.title)||key?.title}
                </div>
            </>
        )

      }
      a.push(<div style={{width:columns[0]?.width}} className='headerTitle' key={'action'}>{t('action')||'Action'}</div>)
      return a
    },
    []
  );
  return (
    // <table className="fixed_header table-dark  table">
    //   <thead className="rc-table-thead">
    //     <tr>
    //       {columns.map((col) => {
    //         return (
    //           <th className="p-[20px]">
    //             <div className="flex items-center">
    //               {col.title}
    //               {col?.sort && (
    //                 <div className="ml-[10px]">
    //                   <select
    //                     className="w-[auto] outline-none"
    //                     onChange={(e) => {
    //                       sortDetails({ value: col.title === 'Order' ? 'position' : 'name', order: e.target.value });
    //                       setSort({ sort: e.target.value, order: col.title });
    //                     }}>
    //                     <option value="">Select Sort</option>
    //                     <option value="ASC">Asc</option>
    //                     <option value="DESC">Desc</option>
    //                   </select>
    //                 </div>
    //               )}
    //             </div>
    //           </th>
    //         );
    //       })}
    //       {tableActions.length ? <th className="p-[20px]">Actions</th> : <></>}
    //     </tr>
    //   </thead>
    //   {items?.length ? (
    //     <SortableCont
    //       onSortEnd={onSortEnd}
    //       onSortMove={() => {}}
    //       axis="y"
    //       lockAxis="y"
    //       lockToContainerEdges={true}
    //       lockOffset={['30%', '50%']}
    //       helperClass="helperContainerClass"
    //       useDragHandle={true}>
    //       {items.map((value, index) => {
    //         return (
    //           <SortableItem
    //             key={`item-${index}`}
    //             index={index}
    //             rows={columns.map((el) => {
    //               return value[el.key];
    //             })}
    //             disabled={showDrag}
    //             showDrag={showDrag}
    //             tableActions={tableActions}
    //             dataItem={value}
    //           />
    //         );
    //       })}
    //     </SortableCont>
    //   ) : (
    //     <tr className='nodataRow'>
    //     <td colSpan={columns.length + 1} className="p-[20px]">
    //     <div className='text-center'>No Data</div>
    //     </td>
    //     </tr>
    //   )}
    // </table>
    <SortableCont
          onSortEnd={handleSortEnd}
          onSortMove={() => {}}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          // getContainer={getContainer}
          // helperContainer={getHelperContainer}
          useDragHandle={true}
    >
     <div className='tableMain' style={{height:items.length*80 + 100}}>
      <AutoResizer>
        {({ width, height }) => (
      <BaseTable
          fixed={false}
          width={width}
          height={height}
          ref={ref}
          data={items}
          // columns={columns}
          rowCount={items.length}
          rowProps={rowProps}
          headerHeight={64}
          rowHeight={80}
          headerRenderer={headerRendererForTable}
          forceUpdateTable
      >
        {
          columns?.map((e=>{
            return (
              <Column title={t(e?.title)||e?.title} key={e?.key} dataKey={e?.dataIndex} width={e?.width} />
            )
          }))
        }
      </BaseTable>
      )}
      </AutoResizer>
      </div>
    </SortableCont>
  );
};

export default MyTable;
