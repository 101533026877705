import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, TopLayer, TitleBlock, Icon } from 'components';
import CheckboxGroup from 'components/Block/CheckboxGroup';
import { useTranslation } from 'context/translation';

const fakeData = [
  {
    label: 'Action',
    withoutMargin: true,
    children: [
      {
        label: 'Getting Started For Team Leaders',
      },
      {
        label: 'Creating Safety',
      },
      {
        label: 'Brain Hacks: A.P.I. and Mindset',
        children: [
          {
            label: 'Patti',
          },
          {
            label: 'Vivianna',
          },
        ],
      },
    ],
  },
  {
    withoutMargin: true,
    label: ' Inclusion Playbook',
  },
];

function Library() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(
    () => (isEditPage ? t('edit_library') || 'Edit Library' : t('add_new_library') || 'Add New Library'),
    [isEditPage],
  );
  const titleButtonText = useMemo(() => (isEditPage ? t('save') || 'Save' : t('add') || 'Add'), [isEditPage]);
  const [title, setTitle] = useState('');
  const { t }: any = useTranslation();

  const handleCategoriesList = useCallback(() => {
    navigate('/libraries');
  }, [navigate]);

  return (
    <section>
      <TopLayer
        onButtonClicked={() => {}}
        buttonText={t(titleButtonText) || titleButtonText}
        titleText={t(pageTitle) || pageTitle}
        className="mb-8"
        onCancelClicked={handleCategoriesList}
      />
      <div className="flex gap-12">
        <div className="flex-1">
          <div className="mb-8 w-full">
            <Input size="large" value={title} onChange={setTitle} />
          </div>
          <TitleBlock title={t('library_fields') || 'Library Fields'}>
            <CheckboxGroup data={fakeData} className="p-5" title="Content" />
          </TitleBlock>
        </div>
        <div className="flex w-80 flex-shrink-0 flex-col gap-10">
          <TitleBlock title={t('publish') || 'Publish'}>
            <div className="px-5">
              <div className="flex items-start justify-between gap-2.5 border-b-1 border-solid border-gray-border py-3">
                <Icon type={Icon.Type.GREAT} className="text-blue-main" />
                <div className="flex-1 text-sm font-semibold">
                  <span className=" text-green-deep ">{t('status') || 'Status'}: </span>
                  <span className="text-blue-main">{t('draft') || 'Draft'}</span>
                </div>
                <button className="rounded-md border-1 border-green-main bg-transparent px-3 py-0.5 leading-5 text-blue-main">
                  {t('edit') || 'Edit'}
                </button>
              </div>
              <div className="flex items-start justify-between gap-2.5 border-b-1 border-solid border-gray-border py-3">
                <Icon type={Icon.Type.EYE} className="text-blue-main" />
                <div className="flex-1 text-sm font-semibold">
                  <span className=" text-green-deep">{t('visibility') || 'Visibility'}: </span>
                  <span className="text-blue-main">{t('public') || 'Public'}</span>
                </div>
                <button className="rounded-md border-1 border-green-main bg-transparent px-3 py-0.5 leading-5 text-blue-main">
                  {t('edit') || 'Edit'}
                </button>
              </div>
              <div className="flex items-start justify-between gap-2.5 border-b-1 border-solid border-gray-border py-3">
                <Icon type={Icon.Type.CALENDAR_EDIT} className="text-blue-main" />
                <div className="flex-1 text-sm font-semibold">
                  <p className=" text-green-deep">{t('published_on') || 'Published on:'} </p>
                  <p className="text-blue-main">{t('immediately') || 'immediately'}</p>
                </div>
                <button className="rounded-md border-1 border-green-main bg-transparent px-3 py-0.5 leading-5 text-blue-main ">
                  {t('edit') || 'Edit'}
                </button>
              </div>
              <div className="-mx-5 flex items-center justify-end bg-gray-title-bg px-5 py-3">
                <button className="rounded-md bg-blue-main px-2.5 py-1 text-sm text-white">
                  {t('publish') || 'Publish'}
                </button>
              </div>
            </div>
          </TitleBlock>
        </div>
      </div>
    </section>
  );
}

export default Library;
