import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import galleryImport from '../../assets/images/gallery-import.svg';
import { TextEditor } from '../index';
import { Controller } from 'react-hook-form';

import { InputType } from '../../typings/form';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const CustomEditor = ({
  input,
  setValue,
  getValues,
  errors,
  header = true,
  control,
  isReset,
  langCode,
}: {
  input: InputType;
  setValue: Function;
  getValues: Function;
  header?: boolean;
  errors?: any;
  control?: any;
  isReset?: any;
  langCode?: any;
}) => {
  const { label, required } = input;
  const { t }: any = useTranslation();

  const toolBarOptions = {
    options: ['blockType', 'inline', 'list', 'link'],
    inline: {
      options: ['bold', 'italic', 'strikethrough'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    blockType: {
      inDropdown: false,
      options: ['Paragraph'],
    },
  };
  const [editorVal, setEditorVal] = useState('');

  const saveEditorValue = (val: any) => {
    setEditorVal(val);
    setValue(input.name, val);
  };

  const labelKey = convertToKey(label);
  return (
    <>
      <label className="mb-[20px] block pt-[32px] text-lg font-bold text-gray-text">
        {langCode ? t(labelKey, langCode) || label : t(labelKey) || label}
        {required && <span className="text-red-500">&nbsp;*</span>}
      </label>
      {/* {header && (
        <div className="flex items-center justify-between">
          <div>
            <span className="mb-[5px] flex cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
              <img src={galleryImport} alt="arrow" className="mr-[8px]" title="arrow"></img>
              <span className="text-[12px] text-white">Add media</span>
            </span>
          </div>
          <div className="mb-[-4px] flex items-center">
            <span className="flex cursor-pointer items-center justify-center rounded-t-[5px] bg-blue-main py-[5px] px-[10px] text-[12px]  text-white">
              Visual
            </span>
            <span className="text-grey-text border-grey flex cursor-pointer items-center justify-center rounded-t-[5px] border py-[5px] px-[10px]  text-[12px]">
              Text
            </span>
          </div>
        </div>
      )} */}

      <Controller
        control={control}
        name={input.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <TextEditor
            footer={false}
            toolBarOptions={toolBarOptions}
            saveEditorValue={(e: any) => {
              onChange(e);
            }}
            getValues={getValues}
            name={input?.name}
            langCode={langCode}
          />
        )}
      />

      {(errors?.[input?.name] || !getValues()?.[input?.name]) && (
        <span className="mt-2 text-sm italic text-red-600">
          {(langCode
            ? t(convertToKey(errors?.[input?.name]?.message), langCode)
            : t(convertToKey(errors?.[input?.name]?.message))) || errors?.[input?.name]?.message}
        </span>
      )}
    </>
  );
};

export default CustomEditor;
