import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const usePaginationParams = (
  setSearchText: Function,
  extraParams:
    | {
        tab: string | undefined;
        startDate: string | undefined;
        endDate: string | undefined;
        leaderFilter: string | undefined;
        contentType:string|undefined;
        sequenceFilter: string|undefined;
        groupFilter: string|undefined;
        categoryFilter: string|undefined;
        mediaFilter: string| undefined;
      }
    | undefined,
): [{ page: number; search: string; limit: number }, Function] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationParams, setPaginationParams] = useState({
    page: +(searchParams.get('page') || 1),
    search: searchParams.get('search') || '',
    limit: +(searchParams.get('limit') || 10),
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchText(searchParams.get('search') ?? '');
    setPaginationParams({
      page: +(searchParams.get('page') ?? 1),
      search: searchParams.get('search') ?? '',
      limit: +(searchParams.get('limit') ?? 10),
    });
  }, []);
  useEffect(() => {
    if (
      paginationParams.page === 1 &&
      paginationParams.search === '' &&
      paginationParams.limit === 10 &&
      (!extraParams ||
       ( extraParams?.tab === searchParams.get('tab') &&
        extraParams?.startDate === searchParams.get('startDate') &&
        extraParams?.endDate === searchParams.get('endDate') &&
        extraParams?.leaderFilter === searchParams.get('leaderFilter') &&
        extraParams?.contentType === searchParams.get('contentType') && 
        extraParams?.sequenceFilter === searchParams.get('sequenceFilter') &&
        extraParams?.groupFilter === searchParams.get('groupFilter') &&
        extraParams?.categoryFilter === searchParams.get('categoryFilter') &&
        extraParams?.mediaFilter === searchParams.get('mediaFilter'))
        )
    ) {
      return;
    }
    navigate(
      `${location.pathname}?page=${paginationParams.page}&search=${paginationParams.search}&limit=${
        paginationParams.limit
      }${extraParams?.tab ? '&tab=' + extraParams?.tab : ''}${
        extraParams?.startDate ? '&startDate=' + extraParams?.startDate : ''
      }${extraParams?.endDate ? '&endDate=' + extraParams?.endDate : ''}${
        extraParams?.leaderFilter ? '&leaderFilter=' + extraParams?.leaderFilter : ''
      }${
        extraParams?.contentType ? '&contentType=' + extraParams?.contentType : ''
      }${
        extraParams?.sequenceFilter ? '&sequenceFilter=' + extraParams?.sequenceFilter : ''
      }${
        extraParams?.groupFilter ? '&groupFilter=' + extraParams?.groupFilter : ''
      }${
        extraParams?.categoryFilter ? '&categoryFilter=' + extraParams?.categoryFilter : ''
      }${
        extraParams?.mediaFilter ? '&mediaFilter=' + extraParams?.mediaFilter : ''
      }`,
    );
  }, [paginationParams, extraParams?.tab, extraParams?.endDate, extraParams?.startDate, extraParams?.leaderFilter, extraParams?.contentType, extraParams?.categoryFilter, extraParams?.sequenceFilter, extraParams?.groupFilter, extraParams?.mediaFilter]);

  return [paginationParams, setPaginationParams];
};
