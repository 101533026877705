import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import { Avatar, Notice } from '../';
import { useAuth } from 'context/user';
import user from 'assets/images/user.png';
function Header() {
  const { authUser } = useAuth();
  return (
    <div className="flex h-20 flex-shrink-0 items-center justify-between px-12 shadow-header">
      <div>
        <img src={Logo} alt="logo"></img>
      </div>
   
      <div className="flex items-center gap-5">
        {/* <Notice num={1}></Notice> */}
      <Avatar src={authUser?.media?.url || user}></Avatar>
      </div>
    </div>
  );
}

export default Header;
