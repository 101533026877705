import { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Select, Pagination, Input, Spinner, Switch, Filter } from '../../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../../components/common/table';
import { TableActionButton } from '../../../components/common/button';
import { createDummyData } from '../../../utils/createDummyData';
import { deleteAPIData, getAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import { debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import DeleteModal from 'components/DeleteModal';
import { ToastTypes, useToast } from 'context/toast';
import { FilterSwitch } from 'components/Switch';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { Checkbox } from '@mui/material';
import { useTranslation } from 'context/translation';
import { SelectedLangContext } from 'context/user';

function InterrupterList() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [intererrupData, setInterrupData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<any>(
    searchParams.get('startDate') ? new Date(searchParams.get('startDate') ?? '') : '',
  );
  const [endDate, setEndDate] = useState<any>(
    searchParams.get('endDate') ? new Date(searchParams.get('endDate') ?? '') : '',
  );
  const [leaderFilter, setLeaderFilter] = useState<boolean>(
    searchParams.get('leaderFilter') ? searchParams.get('leaderFilter') === 'true' : false,
  );
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, {
    tab: undefined,
    startDate: startDate,
    endDate: endDate,
    leaderFilter: leaderFilter.toString(),
    contentType: undefined,
    sequenceFilter: undefined,
    groupFilter: undefined,
    categoryFilter: undefined,
    mediaFilter: undefined,
  });
  const [clearfilter, setClearFilter] = useState<any>(false);
  const [error, setError] = useState<boolean>(false);
  const [errPage, setErrPage] = useState<any>(false);
  const [paginationChange, setPaginationChange] = useState<any>(false);
  const { t }: any = useTranslation();
  // const [leaderFilter, setLeaderFilter]= useState<boolean>(false)
  useEffect(() => {
    getInterrupters();
  }, [paginationParams, filter]);
  const { callToast } = useToast();
  const getInterrupters = async () => {
    setLoading(true);
    let fromDate = convertToMidNight(startDate);
    let toDate = convertToMidNight(endDate);
    let payload = {
      page: paginationParams.page,
      searchText: paginationParams.search,
      fromDate: fromDate,
      toDate: toDate,
      limit: paginationParams.limit,
      leaderOnly: leaderFilter,
      languageId: selectedLanguage?.id,
    };
    try {
      let res = await getAPIData({
        url: 'interrupter',
        data: payload,
      });

      setInterrupData(interrupterData(res?.data?.result));
      setTotal(res?.data?.total);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    setFilter(false);
  };
  const convertToMidNight = (data: TODO_ANY) => {
    if (data) {
      return moment(data).utcOffset(0, true).format();
    }
  };
  const interrupterData = (data: TODO_ANY) => {
    let arr = [];
    for (let key of data) {
      arr.push({
        id: key?.id,
        title: key?.content?.name,
        leaderOnly: key?.content?.leaderOnly ? 'Yes' : 'No',
        date: `${moment(key?.date).utc(false).format('L')}`,
      });
    }
    return arr;
  };

  const handleToAddContent = useCallback(() => {
    navigate('/interrupters/add');
  }, [navigate]);

  const handleToEditInterrupter = useCallback(
    (id: string | number) => {
      navigate(`/interrupters/edit/${id}`);
    },
    [navigate],
  );

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    // {
    //   title: 'Title',
    //   dataIndex: 'title',
    //   key: 'title',
    //   width: 250,
    // },
    {
      title: t('interrupt_date') || 'Interrupt Date',
      dataIndex: 'date',
      key: 'date',
      width: 250,
    },
    {
      title: t('interrupt_content_title') || 'Interrupter Content Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },
    {
      title: t('team_laders') || 'Team Leader Only',
      dataIndex: 'leaderOnly',
      key: 'leaderOnly',
      width: 500,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: any) => (
        <TableActions
          icons={[
            { iconClassName: 'fas fa-edit', onClick: () => handleToEditInterrupter(record.id) },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                setShowDel(true);
                setDeleteId(record);
              },
            },
          ]}
        />
      ),
    },
  ];

  // const dataSource = createDummyData(columns);
  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_interrupter') || 'Deleting interrupter...');
      await deleteAPIData({ url: `interrupter/${id}` });
      callToast(ToastTypes.SUCCESS, t('interrupter_deleted') || 'Interrupter deleted successfully');
      getInterrupters();
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const handleInputChange = (val: string) => {
    setInputValue(val);
    // debounceOnChange(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (startDate && endDate) {
      setClearFilter(true);
    }
  }, [startDate, endDate]);
  return (
    <section className="Interrupters">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.title}
          type="interrupter"
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText={t('interrupters') || 'Interrupters'}
        buttonText={t('add_interrupter') || 'Add Interrupter'}
        disableBtn={loading}
      />
      {error && (
        <p className="text-sm text-red-600">
          {t('end_date_greater_start_date') || 'End Date Should be greater than Start Date'}
        </p>
      )}
      <FunctionLayer
        leftPortion={<></>}
        rightPortion={
          <>
            <div className="mt-[28px] flex items-center gap-5">
              <Input
                value={inputValue}
                onChange={handleInputChange}
                onClick={handleClick}
                placeholder={t('search_content') || 'Search Content'}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleClick();
                  }
                }}
                type="search"></Input>
              <Pagination
                currentPage={paginationParams?.page}
                showError={setErrPage}
                newCurrentPage={setPaginationParams}
                totalPage={Math.ceil(total / paginationParams.limit)}
                pageSize={total}
                searchedValue={paginationParams.search}
                onNext={() => handlePaginationChange(paginationParams?.page - 1)}
                onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
                type="interrupter"
                paginationChange={paginationChange}
                limit={paginationParams.limit}></Pagination>
            </div>
          </>
        }
      />
      <div className="-mt-16 flex w-[100%] gap-2 pb-6">
        <div className="pl-2">
          <p>{t('from_date') || 'From Date'}</p>
          <DatePicker
            placeholderText={t('pick_start_date') || 'Pick Start Date'}
            selected={startDate}
            onKeyDown={onKeyDown}
            maxDate={endDate ? new Date(+new Date(endDate) - 86400000) : null}
            onChange={(date: Date) => {
              setStartDate(date);
              if (!date && !endDate) {
                setError(false);
              } else if (new Date(endDate).setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0) < 1) {
                setError(true);
              } else {
                setError(false);
              }
            }}
            className="mt-2 w-full rounded-md border-1 p-2 outline-none"
          />
        </div>
        <div className="">
          <p>{t('to_date') || 'To Date'}</p>
          <DatePicker
            selected={endDate}
            placeholderText={t('pick_end_date') || 'Pick End Date'}
            minDate={new Date(+new Date(startDate) + 86400000)}
            onKeyDown={onKeyDown}
            disabled={!startDate}
            onChange={(date: Date) => {
              setEndDate(date);
              if (!startDate && !date) {
                setError(false);
              } else if (new Date(date).setHours(0, 0, 0, 0) - new Date(startDate).setHours(0, 0, 0, 0) < 1) {
                setError(true);
              } else {
                setError(false);
              }
            }}
            className="mt-2 w-full rounded-md border-1 p-2 outline-none"
          />
        </div>
        <div className="mt-[30px] flex w-full items-center">
          <TableActionButton
            disabled={error || !startDate || !endDate}
            onClick={() => {
              if (startDate && endDate && new Date(endDate).getTime() - new Date(startDate).getTime() > 0) {
                setFilter(true);
                setClearFilter(true);
                setPaginationParams({
                  page: 1,
                  search: paginationParams.search,
                  limit: paginationParams.limit,
                });
                setPaginationChange(!paginationChange);
              } else {
                setError(true);
              }
            }}
            title="Filter"
          />
          <TableActionButton
            disabled={!clearfilter}
            onClick={() => {
              setFilter(true);
              setClearFilter(false);
              setStartDate('');
              setEndDate('');
              setPaginationParams({
                page: 1,
                search: paginationParams.search,
                limit: paginationParams.limit,
              });
              setPaginationChange(!paginationChange);
            }}
            title={t('clear_filter') || 'Clear Filter'}
          />
          <Filter
            count={leaderFilter ? 1 : 0}
            className="m-0"
            width="300px"
            children={
              <div>
                <label className="flex cursor-pointer items-center text-[14px] text-gray-text">
                  <Checkbox id="0" value="0" checked={leaderFilter} onChange={(e) => setLeaderFilter(!leaderFilter)} />
                  <span className="">{t('team_leaders') || 'Team leader only'}</span>
                </label>
              </div>
            }
            handleReset={() => setLeaderFilter(false)}
            handleApply={() => {
              setPaginationParams((data: any) => {
                return { ...data, page: 1 };
              });
              getInterrupters();
            }}
          />
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={intererrupData} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default InterrupterList;
