import { useForm } from 'react-hook-form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import arrowUp from '../../assets/images/arrow-up.svg';
import arrowDown from '../../assets/images/arrow-down.svg';
import arrowUpSolid from '../../assets/images/arrow-upSolid.svg';
import { companyFormValidation } from 'utils/validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyDefaultValueType, TermFormValueType } from '../../typings/form';
import InputBox from 'components/common/formInput';
import LogoPicker from 'components/LogoPicker';
import CustomEditor from 'components/CustomEditor';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  defaultValues,
  titleInput,
  dAndIStatementInput,
  addCompanyInput,
  adminEmailInput,
  tempPasswordInput,
} from '../../constants/companyFormInputs';
import { TODO_ANY } from 'typings/common';
import { postAPIData, getAPIData, putAPIData } from 'utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastTypes, useToast } from 'context/toast';
import classNames from 'classnames';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { Header, Spinner, Switch, TopLayer } from 'components';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import LanguageDropdown from 'components/LanguageDropdown';
import { getImageTranslatedPayload } from 'utils/uploadFile';

type PropsType = {
  title?: string;
};

const CompanyForm = ({ title }: PropsType) => {
  const { id } = useParams();
  const { authUser } = useAuth();
  const [imageId, setImageId] = useState<any>('');
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(() => (isEditPage ? 'Edit Company' : 'Add Company'), [isEditPage]);
  const titleButtonText = useMemo(() => (isEditPage ? 'Save' : 'Add'), [isEditPage]);
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { callToast } = useToast();
  const [disableBtn, setDisableBtn] = useState(false);
  const [passwordResetLoader, setPasswordResetLoader] = useState<boolean>(false);
  const [currentCompany, setCurrentCompany] = useState<any>(null);
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);

  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(companyFormValidation),
  });
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      setIsReset(false);
    }
  }, []);

  useEffect(() => {
    if (id && authUser && Object.keys(authUser)?.length) {
      getCompanyById();
    }
  }, [id, currentSelected?.langCode || selectedLanguage?.langCode]);

  const getCompanyById = async () => {
    setLoading(true);
    setValue('customTermCompany', []);
    try {
      let res = await getAPIData({
        url: `companies/${id}`,
        data: {
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      setImageId(res?.data?.logoId);
      let termMap: any = {};
      res?.data?.customTermCompany?.forEach((element: any) => {
        if (termMap.hasOwnProperty(element?.customTerm?.term)) {
          termMap?.[element.customTerm.term].push({ option: element?.customOption?.option });
        } else {
          termMap[element?.customTerm?.term] = [{ option: element?.customOption?.option }];
        }
      });
      const newobj: any = Object.keys(termMap).map((key) => {
        return { term: key, options: termMap[key] };
      });
      if (res?.data) {
        setCurrentCompany(res.data);
      }
      setValue(
        'title',
        res?.data?.companyLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.name ??
          res?.data?.name ??
          '',
      );
      setValue('companyId', res?.data?.code);
      setValue('customTermCompany', newobj);
      setValue(
        'd&iStatementTitle',
        res?.data?.companyLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.diStatementTitle ??
          res?.data?.diStatementTitle ??
          '',
      );

      setValue(
        'd&iStatement',
        res?.data?.companyLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.diStatement ??
          res?.data?.diStatement ??
          '',
      );
      setValue('adminEmailInput', res?.data?.adminEmail);
      setValue('tempPasswordInput', res?.data?.tempPassword);
      setValue('logoUrl', res?.data?.media?.url);
      setValue('enabled', !!res?.data?.enabled);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    setLoading(false);
  };
  const removeURL = (data: any) => {
    if (data?.logoUrl) data.logoUrl = data?.logoUrl?.split('.net/')[1];
    return data;
  };

  const createCompany = async (data: TODO_ANY) => {
    let companiesData = {
      name: data?.title,
      code: data?.companyId,
      adminEmail: data?.adminEmailInput,
      tempPassword: data?.tempPasswordInput,
      logoId: imageId,
      diStatementTitle: data['d&iStatementTitle'],
      diStatement: data['d&iStatement'],
      customTermCompany: data?.customTermCompany?.map((item: TermFormValueType) => {
        return { term: item?.term?.trim(), options: item?.options.map((item) => item.option?.trim()) };
      }),
      enabled: !!data?.enabled,
      languageId: currentSelected?.id || selectedLanguage?.id,
    };

    const customTermNames = companiesData?.customTermCompany?.map((el: any) => el.term) ?? [];
    if ([...new Set(customTermNames)].length !== customTermNames.length) {
      callToast(ToastTypes.ERROR, 'Duplicate custom terms names are not valid');
      return;
    }
    let duplicateCustomTermOptions = companiesData?.customTermCompany?.map((el: any) => {
      const customTermOptions = el.options ?? [];
      if ([...new Set(customTermOptions)].length !== customTermOptions?.length) {
        return el.term;
      }
    });

    duplicateCustomTermOptions = duplicateCustomTermOptions?.filter((el: any) => el);

    if (duplicateCustomTermOptions?.length) {
      callToast(ToastTypes.ERROR, ` Custom term '${duplicateCustomTermOptions[0]}' contains duplicate options`);
      return;
    }

    try {
      companiesData.diStatement = await getImageTranslatedPayload(companiesData.diStatement);
      setLoading(true);
      let msg: string;
      if (isEditPage) {
        companiesData = removeURL(companiesData);
        await putAPIData({
          url: `companies/${id}`,
          data: { id: id, ...companiesData, languageId: currentSelected?.id || selectedLanguage?.id },
        });
        msg =
          t('company_updated', currentSelected?.langCode || selectedLanguage?.langCode) ||
          'Company updated successfully';
      } else {
        await postAPIData({
          url: 'companies',
          data: companiesData,
        });
        msg =
          t('company_added', currentSelected?.langCode || selectedLanguage?.langCode) || 'Company added successfully';
      }

      callToast(ToastTypes.SUCCESS, msg);

      navigate('/companies');
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    setLoading(false);
  };

  const passwordReset = async () => {
    setPasswordResetLoader(true);
    let res;
    let email = currentCompany.adminEmail.includes('@')
      ? currentCompany.adminEmail
      : currentCompany.adminEmail + '@moxie.com';

    if (currentCompany.users?.length) {
      email = currentCompany.users[0].email;
    }
    try {
      res = await postAPIData({
        url: 'user/company/reset',
        data: {
          firstName: currentCompany.adminEmail,
          lastName: ' ',
          email,
          roleId: '2',
          companyId: currentCompany.id,
          username: currentCompany.adminEmail.includes('@')
            ? currentCompany.adminEmail.split('@')[1].split('.com')[0]
            : currentCompany.adminEmail,
          skipToken: true,
          password: currentCompany.tempPassword,
        },
      });
      setPasswordResetLoader(false);
      callToast(ToastTypes.SUCCESS, 'Password reset successful');
      getCompanyById();
    } catch (err: any) {
      setPasswordResetLoader(false);
      console.error(err);
    }
  };

  return (
    <div className="addCompany">
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(createCompany)}
          buttonText={titleButtonText}
          titleText={pageTitle}
          className="mb-8"
          disableBtn={disableBtn || loading}
          onCancelClicked={() => {
            setIsReset(true);
            reset();
            navigate('/companies');
          }}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      </div>
      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(defaultValues);
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
          }}
          deleteTitle={'All changes will be lost'}
          type=""
          confirmation={true}
          buttonTitle={'Continue'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(createCompany)}>
          <div className="my-[45px]">
            <InputBox
              input={titleInput(t, currentSelected?.langCode || selectedLanguage?.langCode)}
              register={register}
              setValue={setValue}
              getValues={getValues}
              others={true}
              errors={errors}
              isReset={isReset}
              control={control}
              langCode={currentSelected?.langCode || selectedLanguage?.langCode}
            />
          </div>
          <div className="flex items-center justify-between rounded-t-lg bg-gray-title-bg py-[14px] px-[26px]">
            <span className="text-lg font-bold text-gray-text">
              {t('company_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Company Fields'}
            </span>
          </div>
          <div className="border-grey border">
            <div className="flex flex-col">
              {addCompanyInput(t, currentSelected?.langCode || selectedLanguage?.langCode).map((input, index) => {
                return (
                  <InputBox
                    key={index}
                    input={input}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    isReset={isReset}
                    control={control}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                );
              })}
              <div className="border-b-1 border-slate-100 px-[26px] pb-[30px]">
                <CustomEditor
                  input={dAndIStatementInput(t, currentSelected?.langCode || selectedLanguage?.langCode)}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  isReset={isReset}
                  control={control}
                />
              </div>
              <InputBox
                input={adminEmailInput(t, currentSelected?.langCode || selectedLanguage?.langCode)}
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                isReset={isReset}
                disable={isEditPage}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
              <InputBox
                input={tempPasswordInput(t, currentSelected?.langCode || selectedLanguage?.langCode)}
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                isReset={isReset}
                disable={isEditPage}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
              {isEditPage && currentCompany?.enabled && (
                <div className="border-b-1 border-slate-100 px-[26px] pt-[32px] pb-[30px]">
                  <div className="flex items-center">
                    <label className="mr-[50px] block text-lg font-bold text-gray-text ">Password Reset</label>
                    {passwordResetLoader ? (
                      <Spinner />
                    ) : (
                      <span
                        onClick={() => passwordReset()}
                        className="flex cursor-pointer items-center justify-center rounded-[5px] bg-blue-main px-[20px] py-2 text-[12px] text-white disabled:opacity-[0.5]">
                        Reset Password
                      </span>
                    )}
                    {currentCompany?.userStatus && (
                      <div className="ml-4 text-sm text-gray-subject-text">*{currentCompany?.userStatus}</div>
                    )}
                  </div>
                </div>
              )}
              <LogoPicker
                setValue={setValue}
                getValues={getValues}
                setImageId={setImageId}
                name="logoUrl"
                required={true}
                errors={errors}
                title={t(getValues().title) || getValues().title}
                disableBtn={setDisableBtn}
                isBlock={disableBtn}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
              <div className="border-t-1 border-slate-100 p-[22px]">
                <Switch
                  control={control}
                  register={register}
                  name="enabled"
                  checked={!!getValues().enabled || true}
                  label="Enable?"
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CompanyForm;
