import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'context/translation';
import { ReactElement, useState } from 'react';

interface FilterProps {
  children: ReactElement;
  handleReset: () => void;
  handleApply: () => void;
  count?: number;
  className?: string;
  width?: string;
  langCode?: any;
}

function Filter({ children, handleReset, handleApply, count, className, width, langCode }: FilterProps) {
  const [showFilter, setShowfilter] = useState<boolean>(false);
  const { t }: any = useTranslation();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowfilter(false);
        // handleReset();
      }}>
      <div className="relative" style={{ zIndex: '1000' }}>
        <div
          className={classNames(
            className,
            'mx-4 flex cursor-pointer items-center whitespace-nowrap font-semibold text-blue-main',
          )}
          onClick={() => {
            setShowfilter(!showFilter);
          }}>
          + {t('add_filter', langCode) || 'Add Filter'}{' '}
          {count && count > 0 ? (
            <div className="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-blue-main text-xs text-white">
              {count}
            </div>
          ) : (
            ''
          )}
        </div>
        {showFilter && (
          <div className="absolute top-10 left-0 z-10 rounded bg-white shadow-lg" style={{ width }}>
            <div className="rounded-t bg-gray-border py-2 px-3 font-semibold text-gray-text">
              {t('filters') || 'Filters'}
            </div>
            {children}
            <div className="mt-1 flex items-center justify-end p-3 text-gray-text">
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleReset();
                }}>
                {t('reset') || 'Reset'}
              </div>
              <button
                className="ml-3 min-w-[70px] rounded-md bg-green-main px-2.5 py-1.5 text-sm text-white"
                onClick={() => {
                  setShowfilter(false);
                  handleApply();
                }}>
                {t('apply') || 'Apply'}
              </button>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default Filter;
