import Avatar from 'components/Avatar';
import { DisplayImage } from 'components/DisplayImage';
import { ToastTypes, useToast } from 'context/toast';
import { useEffect, useState } from 'react';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import galleryImport from '../../assets/images/gallery-import.svg';
import { Spinner } from 'components/common';
import user from '../../assets/images/user.png';
import uuid from 'react-uuid';
import { UploadPopUp } from 'pages/Media/ImageUpload/imageUpload';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
// import UploadPopUp from 'pages/Media/ImageUpload/imageUpload';
const UploadImage = ({
  setValue,
  getValues,
  label,
  controlName,
  setImageId = () => {},
  errors,
  required = false,
  isEdit = false,
  block = () => {},
  isBlock = false,
  langCode,
}: {
  setValue: Function;
  getValues: Function;
  label: string;
  controlName: string;
  setImageId?: Function;
  required: boolean;
  isEdit?: boolean;
  errors?: any;
  block?: Function;
  isBlock?: boolean;
  langCode?: any;
}) => {
  const { callToast } = useToast();
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [image, setImage] = useState<any>('');
  const [cancel, setCancel] = useState<boolean>(false);
  const { t }: any = useTranslation();
  useEffect(() => {
    if (isEdit) {
      setImage(`${getValues()[controlName]}` || user);
    }
  }, [getValues()[controlName]]);
  const [err, setErr] = useState(false);
  // const uploadImage = async (e: any) => {
  //   const file = e?.target?.files[0];
  //   const imageSize = file.size;
  //   const maxSize = 10 * 1024 * 1024;
  //   if (maxSize < imageSize) {
  //     callToast(ToastTypes.ERROR, 'Image size exceed');
  //     setImage(user);
  //     return;
  //   }
  //   setCancel(true);
  //   const reader = new FileReader();
  //   reader.readAsDataURL(e?.target?.files[0]);
  //   reader.onloadend = () => {
  //     setImage(reader?.result);
  //   };

  //   try {
  //     const key = `${uuid()}/profileimage/${Date.now()}/profile_image.${getFileExtension(file.name)}`;
  //     const signedUrlData = await getSignedUrl(file, key);
  //     block(true);
  //     await uploadFile(file, signedUrlData?.data?.result?.url);
  //     setValue(controlName, `
  //     block(false);
  //     setErr(true);
  //   } catch (e: any) {
  //     callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
  //   }
  // };
  useEffect(() => {
    if (image) {
      setErr(true);
    }
  }, [image]);
  return (
    <div className="flex items-center">
      <UploadPopUp
        show={showPopUp}
        setShow={setShowPopUp}
        url={image}
        setImageId={setImageId}
        fileUrl={setImage}
        setValue={setValue}
        controlName={[controlName]}
        fileType={'image'}
        langCode={langCode}
      />
      <label className="mr-[50px] block text-lg font-bold text-gray-text ">
        {t(label, langCode) || label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative ">
        {isBlock && (
          <div className="absolute left-0 top-0 flex h-[100px] w-[110px] items-center justify-center bg-white bg-opacity-50">
            <Spinner />
          </div>
        )}
        <DisplayImage image={image || user} circular={true} label={label} isUploading={isBlock}></DisplayImage>
        <div className="relative mt-[15px] ">
          <p className={`text-[14px] text-blue-main ${isBlock && 'opacity-50'}`}>
            <i className="fa fa-upload" aria-hidden="true"></i> {t('upload_picture', langCode) || 'Upload Picture'}
          </p>
          {!isBlock && (
            // <input
            //   id="imagePicker"
            //   type="file"
            //   accept="image/png, image/jpeg"
            //   className={`absolute left-0 top-0 h-full w-full opacity-0`}
            //   onChange={(e) => uploadImage(e)}
            // />
            <button
              id="imagePicker"
              className={`absolute left-0 top-0 h-full w-full opacity-0`}
              onClick={(e) => {
                e.preventDefault();
                setShowPopUp(true);
              }}></button>
          )}
          {errors?.[controlName] && !err && (
            <p className="mt-2 text-sm italic text-red-600">
              {t(convertToKey(errors?.[controlName].message), langCode) || errors?.[controlName].message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
