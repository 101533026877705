function Avatar({ src }) {
  return (
    <div className="h-10 w-10 rounded-full">
      {src ? (
        <img className="h-full w-full rounded-full bg-gray-400 object-cover" src={src} alt="avatar"></img>
      ) : (
        <div className="h-full w-full rounded-full bg-gray-400"></div>
      )}
    </div>
  );
}

export default Avatar;
