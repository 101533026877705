import { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Select, Pagination, Input, Spinner } from '../../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../../components/common/table';
import { TableActionButton } from '../../../components/common/button';
import { deleteAPIData, getAPIData } from 'utils/api';
import { ToastTypes, useToast } from 'context/toast';
import { debounce } from 'lodash';
import moment from 'moment';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { addOffSet } from 'utils/ConvertDate';
import { useTranslation } from 'context/translation';

function Survey() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [errPage, setErrPage] = useState<any>(false);
  const [inputValue, setInputValue] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') ?? '');
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, {
    tab: selectedTab,
    startDate: undefined,
    endDate: undefined,
    leaderFilter: undefined,
    contentType: undefined,
    sequenceFilter: undefined,
    groupFilter: undefined,
    categoryFilter: undefined,
    mediaFilter: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const tabHandler = (tab: any) => {
    setSelectedTab(tab);
    setPaginationParams((data: any) => {
      return { ...data, page: 1 };
    });
    getSurvey(tab);
  };

  const [total, setTotal] = useState(0);
  const { t }: any = useTranslation();

  const [publishedCount, setPublishedCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [isTemplatCount, setIsTemplateCount] = useState(0);

  const tabs = [
    {
      label: t('all') || 'All',
      num: total,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: '',
      default: selectedTab === '',
    },
    {
      label: t('published') || 'Published',
      num: publishedCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'published',
      default: selectedTab === 'published',
    },
    {
      label: t('draft') || 'Draft',
      num: draftCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'draft',
      default: selectedTab === 'draft',
    },
    {
      label: t('in_review') || 'In Review',
      num: reviewCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'review',
      default: selectedTab === 'review',
    },
    {
      label: t('template') || 'Template',
      num: isTemplatCount,
      tabType: 'radio',
      action: (e: any) => tabHandler(e.target.value),
      value: 'isTemplate',
      default: selectedTab === 'isTemplate',
    },
  ];

  const [showDel, setShowDel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState<any>({});

  // const debounceOnChange = useRef(debounce(onChange, 400)).current;
  // useEffect(() => {
  //   return () => {
  //     debounceOnChange.cancel();
  //   };
  // }, [debounceOnChange]);

  // function onChange(val: string) {
  //   setInputValue(val);
  //   setPaginationParams({
  //     page: 1,
  //     search: val,
  //     limit: paginationParams.limit,
  //   });
  // }

  const handleInputChange = (val: string) => {
    // debounceOnChange(val);
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const handleToAddCSurvey = useCallback(() => {
    navigate('/surveys/add');
  }, [navigate]);

  const handleToEditSurvey = (id: string | number) => {
    navigate(`/surveys/edit/${id}`);
  };

  const handleDuplicateTemplate = (id: string | number) => {
    navigate(`/surveys/duplicate/${id}`);
  };

  const { callToast } = useToast();
  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: t('company') || 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 170,
    },
    {
      title: t('status') || 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 270,
    },
    {
      title: t('is_template') || 'Is Template',
      dataIndex: 'isTemplate',
      key: 'isTemplate',
      width: 150,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 150,
      render: (record: any) => {
        return (
          <TableActions
            icons={[
              {
                iconClassName: 'fas fa-edit',
                onClick: () => {
                  handleToEditSurvey(record.id);
                },
              },
              {
                iconClassName: 'fas fa-trash-alt',
                onClick: () => {
                  setShowDel(true);
                  setDeleteRecord(record);
                },
              },
              {
                iconClassName: 'fa fa-clone',
                onClick: () => {
                  handleDuplicateTemplate(record.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const getSurvey = useCallback(
    async (data?: any) => {
      setLoading(true);
      const payload = {
        url: 'survey',
        data: {
          page: paginationParams.page || 1,
          limit: paginationParams.limit,
          searchText: paginationParams.search,
          status: data || selectedTab,
          languageId: selectedLanguage?.id,
        },
      };
      try {
        const res = await getAPIData(payload);
        const data = res?.data?.result?.map((element: any) => {
          const publish = addOffSet(element?.publishTime);
          const publishDate = publish
            ? `${t('publishedOn') || 'Published on'} ${moment(new Date(publish).toISOString()).format('L')} at ${moment(
                new Date(publish).toISOString(),
              ).format('LT')}`
            : t('yet_to_publish') || 'Yet To Be Published';
          return {
            title: element?.title,
            companyName: element?.company?.name,
            id: element?.id,
            published: publishDate,
            status: element?.status?.status === t('publish') || 'Published' ? publishDate : element?.status?.status,
            isTemplate: element?.isTemplate ? t('yes') || 'Yes' : t('no') || 'No',
          };
        });
        setDataSource(data);
        setLoading(false);
        setTotal(
          getStatusCount(res?.data?.statusCount, 'published') +
            getStatusCount(res?.data?.statusCount, 'draft') +
            getStatusCount(res?.data?.statusCount, 'review'),
        );
        setPublishedCount(getStatusCount(res?.data?.statusCount, 'published'));
        setDraftCount(getStatusCount(res?.data?.statusCount, 'draft'));
        setReviewCount(getStatusCount(res?.data?.statusCount, 'review'));
        setIsTemplateCount(res.data.templatesCount[0].count);
        setTotalData(res?.data?.total);
        if (payload.data.status === 'published') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'published'));
        }
        if (payload.data.status === 'draft') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'draft'));
        }
        if (payload.data.status === 'review') {
          setTotalData(getStatusCount(res?.data?.statusCount, 'review'));
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [paginationParams],
  );

  const getStatusCount = (statusCount: any[], status: string) => {
    return +(
      statusCount.find((el) => {
        if (el.status.toLowerCase() === status.toLowerCase()) {
          return el;
        }
      })?.count || 0
    );
  };

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getSurvey();
    }
  }, [getSurvey]);

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const handleDeleteClick = async (id: string) => {
    try {
      callToast(ToastTypes.INFO, t('deleting_survey') || 'Deleting survey...');
      await deleteAPIData({ url: `survey/${id}` });
      callToast(ToastTypes.SUCCESS, t('survey_deleted') || 'Survey deleted successfully');
      getSurvey();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    setShowDel(false);
    setDeleteRecord({});
  };

  return (
    <section className="Surveys">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteRecord?.id)}
          type="survey"
          deleteTitle={deleteRecord?.title}
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddCSurvey}
        titleText={t('surveys') || 'Surveys'}
        buttonText={t('add_survey') || 'Add Survey'}
        disableBtn={loading}
      />
      <div className="flex items-center justify-between">
        <div>
          {/* <TabsLayer tabs={TemplateTab}/> */}
          <TabsLayer tabs={tabs} />
        </div>
        <div className="mt-6">
          <FunctionLayer
            rightPortion={
              <>
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  onClick={handleClick}
                  placeholder={t('search_surveys') || 'Search Surveys'}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleClick();
                    }
                  }}
                  type="search"></Input>
                <Pagination
                  currentPage={paginationParams?.page}
                  showError={setErrPage}
                  totalPage={Math.ceil(totalData / paginationParams.limit)}
                  newCurrentPage={setPaginationParams}
                  pageSize={totalData}
                  tab={selectedTab}
                  type={'survey'}
                  searchedValue={paginationParams.search}
                  onNext={() => {
                    handlePaginationChange(paginationParams?.page - 1);
                  }}
                  onPrev={() => {
                    handlePaginationChange(paginationParams?.page + 1);
                  }}
                  limit={paginationParams.limit}></Pagination>
              </>
            }
          />
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={dataSource} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default Survey;
