import CircularProgress from '@mui/material/CircularProgress';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { ToastTypes, useToast } from 'context/toast';
import { useAuth } from 'context/user';
import { useTranslation } from 'context/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { getAPIData } from 'utils/api';
import logo from '../../assets/images/loginLogo.jpg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
function Login() {
  const navigate = useNavigate();
  const [username] = useState('');
  const [password] = useState('');
  const [newPassword] = useState('');
  const [confirmNewPassword] = useState('');
  const [defaultValues, setDefaultValues] = useState<any>({
    newPassword: '',
    password: '',
    username: '',
  });
  const [loading, setLoading] = useState(false);
  const { t }: any = useTranslation();
  const LoginValidationSchema = yup
    .object()
    .shape({})
    .when((values, schema) => {
      if (!values.ShowNewPassword) {
        return schema.shape({
          password: yup
            .string()
            .required(t('required_pass') || 'Password is required')
            .min(
              8,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            //  .matches(/[0-9]/, 'Password must contain 8 characters, uppercase, lowercase, number, and a special character.')
            .matches(
              /[0-9]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[a-z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[A-Z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[!-\/:-@[-`{-~]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
          username: yup.string().required(t('required_email_username') || 'Email or Username is required'),
        });
      } else {
        return schema.shape({
          newPassword: yup
            .string()
            .required(t('required_pass') || 'Password is required')
            .min(
              8,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[0-9]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[a-z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[A-Z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[!-\/:-@[-`{-~]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
          confirmNewPassword: yup
            .string()
            .required(t('confirm_pass') || 'Please Confirm Your Password')
            .min(8, t('pass_not_match') || 'Passwords does not match')
            .oneOf([yup.ref('newPassword'), null], t('pass_not_match') || 'Passwords does not match'),
        });
      }
    });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    shouldUnregister: true,
    defaultValues: defaultValues,
    resolver: yupResolver(LoginValidationSchema),
  });
  const { callToast } = useToast();
  const { setAuthUser } = useAuth();
  const [newPassRequired, setNewPassRequired] = useState(false);
  const [cognitoUser, setCognitoUser]: any = useState({});
  const onSubmit = async (data: TODO_ANY) => {
    setLoading(true);
    let authenticationDetails = new AuthenticationDetails({
      Username: data?.username,
      Password: data?.password,
    });
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    let userData = { Username: data?.username, Pool: userPool };
    var cognitoUser = new CognitoUser(userData);
    setCognitoUser(cognitoUser);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: TODO_ANY) => {
        const { payload, jwtToken } = result.idToken;
        if (payload['custom:roleId'] !== '1') {
          cognitoUser.signOut();
          return;
        }
        const { token } = result.refreshToken;
        let userInfo = {
          email: payload.email,
          exp: payload.exp,
          token: jwtToken,
          refreshToken: token,
          userId: payload['custom:userId'],
        };
        localStorage.setItem('userData', JSON.stringify(userInfo));
        getUserById(userInfo?.userId);
      },
      onFailure: (err) => {
        callToast(ToastTypes.ERROR, err?.message);
        setLoading(false);
      },
      newPasswordRequired: (userAttributes: TODO_ANY, requiredAttributes: TODO_ANY) => {
        if (userAttributes['custom:roleId'] !== '1') {
          cognitoUser.signOut();
          callToast(ToastTypes.ERROR, t('user_not_exist') || 'User does not exist');
          setLoading(false);
          return;
        }
        setNewPassRequired(true);
        setLoading(false);
      },
    });
  };

  const getUserById = async (userId: string) => {
    try {
      let res: TODO_ANY = await getAPIData({ url: `user/${userId}` });
      setAuthUser(res.data);
      callToast(ToastTypes.SUCCESS, t('user_loggedIn') || 'User logged in successfully!');
      setLoading(false);
      navigate('/content');
    } catch (err: TODO_ANY) {
      callToast(ToastTypes.ERROR, err?.message);
    }
  };

  const onNewPasswordSubmit = (data: TODO_ANY) => {
    setLoading(true);
    cognitoUser.completeNewPasswordChallenge(
      data.newPassword,
      {},
      {
        onSuccess: (result: TODO_ANY) => {
          const { payload, jwtToken } = result.idToken;
          const { token } = result.refreshToken;
          let userInfo = {
            email: payload.email,
            exp: payload.exp,
            token: jwtToken,
            refreshToken: token,
            userId: payload['custom:userId'],
          };
          localStorage.setItem('userData', JSON.stringify(userInfo));
          getUserById(userInfo?.userId);
        },
        onFailure: (err: TODO_ANY) => {
          callToast(ToastTypes.ERROR, err?.message);
          setLoading(false);
        },
      },
    );
  };

  useEffect(() => {
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    const cognitoUser: TODO_ANY = userPool.getCurrentUser();
    if (!cognitoUser) {
      setLoading(false);
      navigate('/auth/login');
    }
    cognitoUser?.getSession((err: TODO_ANY, session: TODO_ANY) => {
      if (err) {
        console.error(err);
      }
      if (session.isValid()) {
        let data = localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData') || '');
        if (data?.token) {
          setLoading(false);
          navigate('/');
        }
      }
    });
  }, []);

  useEffect(() => {
    setValue('ShowNewPassword', newPassRequired);
  }, [newPassRequired]);

  if (loading) {
    return (
      <div className="flex min-h-[100vh] items-center justify-center">
        {' '}
        <CircularProgress color="success" />
      </div>
    );
  }
  if (newPassRequired) {
    return (
      <div className="m-auto w-[90%] max-w-[350px] rounded-[5px] border-1 border-gray-text/[.3]   bg-white p-[42px] px-[45px]">
        <div className="mb-[15px] flex items-center justify-center">
          <img src={logo} className="max-w-[200px]"></img>
        </div>
        <form onSubmit={handleSubmit(onNewPasswordSubmit)}>
          <div className="mb-[25px]">
            {t('set_new_pass') || 'Set new password'}{' '}
            {getValues().username ? `${t('for') || 'for'} ${getValues().username}` : ''}
          </div>
          <div className="mb-[25px]">
            <div className="relative">
              <input
                className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px] pl-[50px] text-[14px] font-light outline-none"
                {...register('newPassword')}
                placeholder={t('new_pass') || 'New Password'}
                type={'password'}
              />
              <i
                className="fa fa-lock absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
                aria-hidden="true"></i>
            </div>
            <span className="text-[12px] text-red-600">
              {errors?.['newPassword'] && errors?.['newPassword'].message?.toString()}
            </span>
          </div>
          <div className="mb-[25px]">
            <div className="relative">
              <input
                className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px] pl-[50px] text-[14px] font-light outline-none"
                {...register('confirmNewPassword')}
                placeholder="Confirm New Password"
                type={'password'}
              />
              <i
                className="fa fa-lock absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
                aria-hidden="true"></i>
            </div>
            <span className="text-[12px] text-red-600">
              {errors?.['confirmNewPassword'] && errors?.['confirmNewPassword'].message?.toString()}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="min-h-[35px] min-w-[85px] rounded-[5px] bg-green-main p-[5px] text-[14px] font-light text-white hover:bg-green-deep"
              type={'submit'}
              onClick={handleSubmit(onNewPasswordSubmit)}>
              {t('set_pass') || 'Set Password'}
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="m-auto w-[90%] max-w-[350px] rounded-[5px] border-1 border-gray-text/[.3]   bg-white p-[42px] px-[45px]">
      <div className="mb-[15px] flex items-center justify-center">
        <img src={logo} className="max-w-[200px]"></img>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-[15px]">
          <div className="relative">
            <input
              className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px]  pl-[50px] text-[14px] font-light outline-none"
              placeholder={t('username') || 'Username'}
              {...register('username')}
            />
            <i
              className="fa fa-user-o absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
              aria-hidden="true"></i>
          </div>
          <span className="text-[12px] text-red-600">
            {errors?.['username'] && errors?.['username'].message?.toString()}
          </span>
        </div>
        <div className="mb-[25px]">
          <div className="relative">
            <input
              className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px] pl-[50px] text-[14px] font-light outline-none"
              {...register('password', { required: true, minLength: 8, value: password })}
              placeholder={t('password') || 'Password'}
              type={'password'}
            />
            <i
              className="fa fa-lock absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
              aria-hidden="true"></i>
          </div>
          <span className="text-[12px] text-red-600">
            {errors?.['password'] && errors?.['password'].message?.toString()}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="min-h-[35px] min-w-[85px] rounded-[5px] bg-green-main p-[5px] text-[14px] font-light text-white hover:bg-green-deep"
            type={'submit'}
            onClick={handleSubmit(onSubmit)}>
            {t('Login')}
          </button>
          <a
            className="cursor-pointer text-[12px] text-green-main underline"
            onClick={() => navigate('/auth/forgot-pass')}>
            {t('forgot_pass') || 'Forgot Password'}
          </a>
        </div>
      </form>
    </div>
  );
}

export default Login;
