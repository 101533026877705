import { useLocation } from 'react-router-dom';
import NoData from 'assets/images/no-data.png';
import { useTranslation } from 'context/translation';

const DefaultPage = () => {
  const location = useLocation();
  const { t }: any = useTranslation();
  return (
    <div className="flex h-[100%] items-center justify-center text-center">
      <div>
        <img src={NoData} className="mb-[20px] max-w-[400px]" alt={t('no_data') || 'No data'} />
        <div className="text-[25px]">404, {t('content_not_found') || 'Content Not Found'}</div>
      </div>
    </div>
  );
};

export default DefaultPage;
