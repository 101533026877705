import React, { useContext } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Spinner, TopLayer } from 'components';
import { FileDrop } from 'react-file-drop';
import { ToastTypes, useToast } from 'context/toast';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAPIData, postAPIData } from 'utils/api';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import uuid from 'react-uuid';
import { schemaForMedia } from 'validations/contentFormValidation';
import { TODO_ANY } from 'typings/common';
import SrtUpload from '../uploadSrt';
import { useTranslation } from 'context/translation';
import { convertToKey, removeExtension } from 'pages/Content/utils';
import VideoContent from 'components/Content/VideoContent';
const initialDefaultValues = {
  url: '',
  alternativeText: '',
  fileName: '',
};
const imageFormat = ['jpeg', 'png', 'jpg'];
const videoFormat = ['m4v', 'avi', 'mpg', 'mp4'];
const UploadLocal = ({
  tab = () => {},
  setShow = () => {},
  srtTitle = () => {},
  srtSize = () => {},
  dataUrl,
  title = () => {},
  size = () => {},
  errSet = () => {},
  setDataUrl = () => {},
  setContentId = () => {},
  setFormValue = () => {},
  controlName,
  mediaType,
  langCode,
}: {
  tab?: Function;
  setShow?: Function;
  title?: Function;
  size?: Function;
  dataUrl?: any;
  errSet?: Function;
  srtTitle?: Function;
  srtSize?: Function;
  setDataUrl?: Function;
  setContentId?: Function;
  setFormValue?: Function;
  controlName?: any;
  mediaType?: any;
  langCode?: any;
}) => {
  const { authUser } = useAuth();
  const inputRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [fileType, setFileType] = useState<any>('all');
  const [err, setErr] = useState<boolean>(true);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<any>('');
  const [fileTitle, setFileTitle] = useState<any>('');
  const [defaultValues, setDefaultValues] = useState<any>(initialDefaultValues);
  const [initKey, setInitKey] = useState('');
  const [fileUrl, setFileUrl] = useState<any>('');
  const navigate = useNavigate();
  const [srtValue, setSrtValue] = useState<any>({});
  const { callToast } = useToast();
  const { t }: any = useTranslation();
  const { langLists } = useContext(LanguageListsContext);
  const { selectedLanguage } = useContext(SelectedLangContext);
  const {
    unregister,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaForMedia),
  });
  const fileHandler = async (e: any, event: any) => {
    // console.log(e, 'Files');
    let maxSize = 0;
    event.preventDefault();
    const extension = e[0]?.name.split('.')[e[0]?.name.split('.').length - 1]?.toLowerCase();
    if (imageFormat.includes(extension) || videoFormat.includes(extension)) {
      let file = e[0];
      if (imageFormat.includes(extension)) {
        setFileType('image');
        maxSize = 20 * 1024 * 1024;
        let fileName = `${+new Date()}`;
        const blob = file.slice(0, file.size, file.type);
        file = new File([blob], fileName, { type: file.type });
      }
      if (videoFormat.includes(extension)) {
        setFileType('video');
        maxSize = 50 * 1024 * 1024;
      }
      setFileSize(file?.size);
      setFileTitle(file?.name.split('.')[0]);
      const imageSize = file.size;
      // const
      if (maxSize < imageSize) {
        callToast(ToastTypes.ERROR, t('file_size_exceed', langCode) || 'File size exceed');
        return;
      }
      setIsImageUploading(true);
      const reader = new FileReader();
      reader.readAsDataURL(e?.[0]);
      reader.onloadend = () => {
        setFileUrl(reader?.result);
      };

      try {
        let keyTemp = `media/content/${uuid()}/${Date.now()}/media_image`;
        setInitKey(keyTemp);
        let key = `${keyTemp}.${getFileExtension(file.name)}`;
        const signedUrlData = await getSignedUrl(file, key);
        await uploadFile(file, signedUrlData?.data?.result?.url);
        setErr(false);
        setValue('url', `${key}`);
        clearErrors('url');
        setValue('fileName', file?.name.split('.')[0]);
        setIsImageUploading(false);
      } catch (e: any) {
        setIsImageUploading(false);
        callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      }
    } else {
      callToast(ToastTypes.ERROR, t('file_type_not_support', langCode) || 'File Type Not Supported');
    }
  };
  const getMediaById = async (id: TODO_ANY) => {
    setLoading(true);
    try {
      const res = await getAPIData({
        url: `media/${id}`,
      });
      if (res?.data?.type == 'image') {
        setDataUrl(fileUrl);
        setContentId(res?.data?.id);
        setFormValue(controlName[0], res?.data?.url);
      } else {
        setDataUrl(res?.data?.thumbnail);
        title(res?.data?.fileName);
        size(res?.data?.fileSize);
        setFormValue(controlName[0], {
          url: res?.data?.url,
          title: t('content_video', langCode) || 'Content Video',
          fileName: res?.data?.fileName,
          fileSize: res?.data?.fileSize,
        });
        setContentId(res?.data?.id);
        if (res?.data?.srtVideo?.url) {
          srtSize(res?.data?.srtVideo?.fileSize);
          srtTitle(res?.data?.srtVideo?.fileName);
          // setDataUrl([res?.data?.thumbnail, res?.data?.srtVideo?.url]);
          setFormValue(controlName[1], {
            url: res?.data?.srtVideo?.url,
            title: t('content_srt', langCode) || 'Content Srt',
            fileName: res?.data?.srtVideo?.fileName,
            fileSize: res?.data?.srtVideo?.fileSize,
          });
          setContentId([res?.data?.id, res?.data?.srtVideo?.id]);
        }
      }
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setLoading(false);
  };
  const addMedia = async (data: any) => {
    setLoading(true);
    try {
      setLoading(true);
      const selectedLang = langLists.find((lang: any) => lang.langCode === langCode);

      let res = await postAPIData({
        url: 'media',
        data: {
          ...data,
          createdBy: authUser.id,
          updatedBy: authUser.id,
          languageId: selectedLang?.id ?? selectedLanguage?.id,
        },
      });
      await getMediaById(res?.data?.id);
      setLoading(false);
      callToast(ToastTypes.SUCCESS, t('media_added', langCode) || 'Media added successfully');
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
    setLoading(false);
    setShow(false);
  };
  const removeURL = (data: TODO_ANY) => {
    if (data?.url?.split('.net/')?.length > 1) data.url = data?.url?.split('.net/')?.[1];
    return data;
  };
  const getFormData = (data: TODO_ANY) => {
    if (fileType == 'video' && Object.entries(srtValue).length > 0) {
      data = { ...data, fileSize, type: fileType, srt: srtValue };
    } else {
      data = { ...data, fileSize, type: fileType };
    }
    if (data?.url) {
      data = removeURL(data);
    }
    addMedia(data);
  };

  const RemoveFile = () => {
    setFileTitle('');
    setFileSize('');
    setFileUrl('');
    setErr(true);
    setValue('url', '');
    setValue('fileName', '');
    setError('url', {
      type: 'manual',
      message:
        (langCode ? t('this_field_is_required', langCode) : t('this_field_is_required')) || 'This field is required',
    });
  };
  const filePicker = () => {
    inputRef.current.click();
  };
  const handleContentList = useCallback(() => {
    //------------------>
    setShow(false);
  }, [navigate]);
  return (
    <section className="MainContainerAddMedia">
      <TopLayer
        onButtonClicked={handleSubmit(getFormData)}
        buttonText={t('save', langCode) || 'Save'}
        titleText={t('add_media', langCode) || 'Add Media'}
        className="mb-8"
        onCancelClicked={handleContentList}
        disableBtn={loading || isImageUploading}
        langCode={langCode}
      />
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <div className="mb-5">
              <div className="mb-2">
                {isImageUploading ? (
                  <Spinner />
                ) : (
                  <>
                    {!fileTitle ? (
                      <FileDrop onTargetClick={filePicker} onDrop={(f, e) => fileHandler(f, e)}>
                        <p className="placeholder">
                          <i className="fa fa-upload mb-2 text-blue-main" aria-hidden="true"></i>
                          <br />
                          {t('drag_and_drop', langCode) || 'Drag & drop files, or'}{' '}
                          <span className="browsePopUP text-blue-main">{t('browse', langCode) || 'browse'}</span>
                        </p>
                        <input
                          accept={mediaType == 'image' ? 'image/png,image/jpeg' : 'video/mp4,video/x-m4v,video/*'}
                          value=""
                          style={{ visibility: 'hidden', opacity: 0 }}
                          ref={inputRef}
                          type="file"
                          onChange={(e: any) => fileHandler(e.target.files, e)}
                        />
                      </FileDrop>
                    ) : fileType == 'image' ? (
                      <img className="file-drop object-cover" src={fileUrl} alt="" />
                    ) : fileType == 'video' ? (
                      <iframe className="file-drop" title={t('video_media', langCode) || 'Video Media'} src={fileUrl} />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              {errors?.['url'] && (
                <p className="mt-2 text-sm italic text-red-600">
                  {(langCode
                    ? t(convertToKey(errors?.['url']?.message?.toString()), langCode)
                    : t(convertToKey(errors?.['url']?.message?.toString()))) || errors?.['url']?.message?.toString()}
                </p>
              )}
              <div className="flex justify-between text-right text-[12px] text-gray-subject-text">
                {fileTitle ? (
                  <span className="mr-5">
                    {t('file_size', langCode) || 'File Size : '} {(+fileSize / (1024 * 1024)).toFixed(2)}{' '}
                    {t('mb', langCode)}
                  </span>
                ) : (
                  <span className="mr-5">
                    {t('file_size', langCode) || 'File Size : '} 0.00 {t('mb', langCode)}
                  </span>
                )}
                <div className="text-right text-[12px] ">
                  {langCode
                    ? t('max_image_video_size', langCode) || 'Max Image Size: 20MB & Max Video Size: 50MB'
                    : t('max_image_video_size') || 'Max Image Size: 20MB & Max Video Size: 50MB'}
                </div>
              </div>
              <button
                disabled={err}
                onClick={() => RemoveFile()}
                className={`my-2 rounded-md ${
                  err ? 'bg-gray-main' : 'cursor-pointer bg-red-main'
                } px-5 py-1.5 text-sm text-white`}>
                {t('remove_file', langCode) || 'Remove File'}
              </button>
            </div>
            {fileType == 'video' && fileTitle && !isImageUploading ? (
              <SrtUpload
                srtKey={initKey ?? removeExtension(getValues()?.url)}
                srtValue={srtValue}
                setSrtValue={setSrtValue}
                langCode={langCode}
              />
            ) : (
              ''
            )}
            <div>
              <div className="mb-8 w-full">
                <Input
                  value={fileTitle}
                  label={t('file_name', langCode) || 'File Name'}
                  name="fileName"
                  size="large"
                  required={false}
                  disabled={true}
                  // errors={errors}
                  placeholder=""
                  langCode={langCode}
                />
              </div>
              <div className="mb-8 w-full">
                <Input
                  label={t('alternative_text', langCode) || 'Alternative Text'}
                  textarea={true}
                  control={{ ...register('alternativeText') }}
                  name="alternativeText"
                  size="large"
                  required={false}
                  errors={errors}
                  placeholder=""
                  langCode={langCode}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UploadLocal;
