import { useEffect, useRef } from 'react';
import { TODO_ANY } from 'typings/common';

export const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};
export const useIntersectionObserver = (callbackFunc: (entries: TODO_ANY) => void, dependency?: any) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    const ref = containerRef.current;
    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef, dependency]);

  return containerRef;
};
