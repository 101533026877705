import DeleteModal from 'components/DeleteModal';
import { useTranslation } from 'context/translation';
import { SelectedLangContext, useAuth } from 'context/user';
import { DefaultPages } from 'enums/constant.enum';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAPIData, getAPIData } from 'utils/api';
import { Input, Pagination, Spinner, Table } from '../../../components';
import { FunctionLayer, TableActions, TopLayer } from '../../../components/common/table';
import { ToastTypes, useToast } from '../../../context/toast';

function PageList() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [errPage, setErrPage] = useState<any>(false);
  const [pageData, setPageData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [loading, setLoading] = useState(false);
  const { callToast } = useToast();
  const [total, setTotal] = useState(0);
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const [showDel, setShowDel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState<any>({});
  const { t }: any = useTranslation();

  const pageFormattedData = (data: any) => {
    return data.map((item: any) => {
      return { title: item?.title, id: item?.id };
    });
  };

  // const debounceOnChange = useRef(debounce(onChange, 400)).current;

  // function onChange(val: string) {
  //   setInputValue(val);
  //   setPaginationParams({
  //     page: paginationParams.page,
  //     search: val,
  //     limit: paginationParams.limit,
  //   });
  // }
  // useEffect(() => {
  //   return () => {
  //     debounceOnChange.cancel();
  //   };
  // }, [debounceOnChange]);

  const getPages = async () => {
    try {
      setLoading(true);
      const payload = {
        url: 'pages',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          languageId: selectedLanguage?.id,
        },
      };
      const res = await getAPIData(payload);
      setPageData(res?.data?.result);
      setTotal(res?.data?.total);
      setLoading(false);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      setLoading(false);
    }
  };
  const handleToAddPage = useCallback(() => {
    navigate('/pages/add');
  }, [navigate]);

  const handleToEditPage = useCallback(
    (id: string | number) => {
      navigate(`/pages/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteClick = async (id: string) => {
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_page') || 'Deleting page...');
      await deleteAPIData({
        url: `pages/${id}`,
      });
      callToast(ToastTypes.SUCCESS, t('page_removed') || 'Page removed successfully');
      getPages();
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
    setShowDel(false);
    setDeleteRecord({});
  };

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const handleInputChange = (val: string) => {
    // debounceOnChange(val);
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: any) => (
        <TableActions
          icons={[
            { iconClassName: 'fas fa-edit', onClick: () => handleToEditPage(record?.id) },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                setShowDel(true);
                setDeleteRecord(record);
              },
              disabled: [DefaultPages.Terms, DefaultPages.Privacy].includes(record.title),
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getPages();
    }
  }, [paginationParams]);

  return (
    <section className="Pages">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteRecord?.id)}
          deleteTitle={deleteRecord?.title}
          type="page"
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddPage}
        titleText={t('pages') || 'Pages'}
        buttonText={t('add_page') || 'Add Page'}
        disableBtn={loading}
      />
      <FunctionLayer
        rightPortion={
          <>
            <Input
              value={inputValue}
              onClick={handleClick}
              onChange={handleInputChange}
              placeholder={t('search_pages') || 'Search Pages'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams.page}
              showError={setErrPage}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="page"
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={pageFormattedData(pageData)} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default PageList;
