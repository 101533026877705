import { Chip } from '@mui/material';
import { Header, SmallLoader, Spinner, TitleBlock, TopLayer } from 'components';
import { ToastTypes, useToast } from 'context/toast';
import { getTableSequenceData } from 'pages/Company/companyDto';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { schemaForSequences } from 'validations/contentFormValidation';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { options } from 'constants/survey.constant';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import { convertToKey } from 'pages/Content/utils';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';

function Sequence({ up }: { up: boolean }) {
  const { id, contentId } = useParams();
  const { authUser } = useAuth();
  const { t }: any = useTranslation();

  const { langLists, setLangLists } = useContext(LanguageListsContext);

  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);

  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const isAddup = useMemo(() => !!contentId, [contentId]);
  const pageTitle = useMemo(
    () =>
      isEditPage
        ? t('edit_sequence', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Sequence'
        : t('add_sequence', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Sequence',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const titleButtonText = useMemo(
    () =>
      isEditPage
        ? t('Save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('Add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const [title, setTitle] = useState('');
  const [defaultValues, setDefaultValues] = useState<any>({
    contentId: '',
  });
  const [sequenceData, setSequenceData] = useState<any>([]);
  const [sequenceDataRaw, setSequenceDataRaw] = useState<any>([]);
  const [sequenceLoader, setSequenceLoader] = useState({
    sequenceInitialLoader: false,
    sequenceInfiniteLoader: false,
  });
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [content, setContent] = useState<any>([]);
  const [total, setTotal] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [addedSequences, setAddedSequences] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState({
    contentSearch: false,
  });
  const { callToast } = useToast();
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaForSequences),
  });
  const containerRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    const ref = containerRef.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef, options, sequenceData]);

  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (sequenceData.length > 0) {
      if (entry.isIntersecting && sequenceData.length < total) {
        setPaginationParams({
          page: paginationParams.page + 1,
          search: paginationParams.search,
        });
      }
    }
  };

  const isSelect = useCallback(
    (value: string) => {
      return selectedList.find((item) => item === value);
    },
    [selectedList],
  );

  const handleSelect = useCallback((value: string) => {
    setSelectedList((state) => [value]);
  }, []);

  const handleRemove = useCallback((value: string) => {
    setSelectedList((state) => state.filter((item) => item !== value));
  }, []);

  const handleCategoriesList = useCallback(() => {
    navigate('/sequences');
  }, [navigate]);
  const getEditSequence = async () => {
    if (!id) return;
    setLoading(true);
    try {
      let res = await getAPIData({
        url: `sequence/${id}`,
        data: {
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setValue('contentId', res?.data?.contentId);
      setTitle(res?.data?.content?.name);
      setSelectValue(res?.data?.contentId);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const createSequence = async (data: TODO_ANY) => {
    try {
      setLoading(true);
      if (isEditPage) {
        await putAPIData({
          url: `sequence/content/${id}`,
          data: { ...data, languageId: currentSelected?.id || selectedLanguage?.id },
        });
      } else if (isAddup && up) {
        await postAPIData({
          url: 'sequence',
          data: { ...data, beforeId: contentId, languageId: currentSelected?.id || selectedLanguage?.id },
        });
      } else if (isAddup && !up) {
        await postAPIData({
          url: 'sequence',
          data: { ...data, afterId: contentId, languageId: currentSelected?.id || selectedLanguage?.id },
        });
      } else {
        await postAPIData({
          url: 'sequence',
          data: { ...data, languageId: currentSelected?.id || selectedLanguage?.id },
        });
      }
      handleCategoriesList();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    setLoading(false);
  };
  const getFormData = (data: TODO_ANY) => {
    createSequence(data);
  };
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getInitialData();
      getEditSequence();
    }
  }, [currentSelected?.id || selectedLanguage?.id]);

  const getInitialData = async () => {
    setLoading(true);
    await getSequences();
    setLoading(false);
  };
  useEffect(() => {
    getSequences();
  }, [paginationParams]);
  const getSequences = async () => {
    setLoader({
      contentSearch: true,
    });
    if (paginationParams.page == 1) {
      setSequenceLoader({ ...sequenceLoader, sequenceInitialLoader: true });
    } else {
      setSequenceLoader({ ...sequenceLoader, sequenceInfiniteLoader: true });
    }
    try {
      let res = await getAPIData({
        url: 'content',
        data: {
          page: paginationParams.page,
          searchText: paginationParams.search,
          isTitleSearch: true,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setSequenceData(res?.data?.result);
      if (paginationParams.page === 1) {
        setSequenceDataRaw(res?.data?.result);
        const data: any = getAllSequences(res?.data?.result);
        setContent(data);
      } else if (paginationParams.page > 1) {
        setSequenceDataRaw([...sequenceDataRaw, ...res?.data?.result]);
        const data: any = getAllSequences([...sequenceDataRaw, ...res?.data?.result]);
        setContent(data);
      }
      setValue('contentId', selectValue);
      setTotal(res?.data?.total);
      setSequenceLoader({ ...sequenceLoader, sequenceInfiniteLoader: false, sequenceInitialLoader: false });
      setLoader({
        contentSearch: false,
      });
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setSequenceLoader({ ...sequenceLoader, sequenceInfiniteLoader: false, sequenceInitialLoader: false });
      console.error(e);
      setLoader({
        contentSearch: false,
      });
    }
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  useEffect(() => {
    const data: any = getAllSequences([...sequenceData]);
    setContent([...data]);
  }, [paginationParams.search]);
  const getAllSequences = (data: TODO_ANY) => {
    let arr = [];
    for (let key of data) {
      arr.push({ id: key?.id, name: key?.name, inSequence: key?.inSequence });
    }
    return arr;
  };
  return (
    <section>
      <div className="flex justify-between">
        {' '}
        <TopLayer
          onButtonClicked={handleSubmit(getFormData)}
          buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
          titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
          className="mb-8"
          onCancelClicked={handleCategoriesList}
          disableBtn={loading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset({
              contentId: '',
            });
            setTitle('');
            setSelectValue('');
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
          }}
          deleteTitle={'All changes will be lost'}
          type=""
          confirmation={true}
          buttonTitle={'Continue'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <TitleBlock
              title={
                t('sequence_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Sequence Fields'
              }>
              <div className="border-b-1 border-slate-100 p-[22px]">
                <div className="mb-[17px]">
                  <h2 className="text-[18px] font-bold text-gray-text">
                    {t('content_search', currentSelected?.langCode || selectedLanguage?.langCode) || 'Content Search'}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </h2>
                  <p className="text-[14px] font-light text-gray-subject-text">
                    {t('search_content_sequence', currentSelected?.langCode || selectedLanguage?.langCode) ||
                      'Search for a content that will be in the sequence.'}
                  </p>
                </div>
                <div>
                  <div className="placeholder-text-grey-text flex w-full items-center gap-1 rounded-t-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none">
                    {title && (
                      <Chip
                        label={t(title, currentSelected?.langCode || selectedLanguage?.langCode) || title}
                        onDelete={() => {
                          setTitle('');
                          setSelectValue('');
                          setValue('contentId', '');
                        }}
                      />
                    )}
                    <input
                      className=" placeholder-text-grey-text w-full outline-none disabled:bg-white"
                      value={searchContent}
                      onChange={(e: any) => {
                        setSearchContent(e.target.value);
                        setPaginationParams({
                          page: 1,
                          search: e.target.value,
                        });
                      }}
                      placeholder={t('search', currentSelected?.langCode || selectedLanguage?.langCode) || 'Search...'}
                    />
                  </div>
                  <div className=" rounded-b-md border-l-1 border-r-1 border-b-1 p-5">
                    <div className="scrollbarCustom mt-[-2px] max-h-[234px] overflow-y-auto ">
                      {sequenceLoader.sequenceInitialLoader ? (
                        <Spinner />
                      ) : (
                        <div>
                          {content?.length > 0 ? (
                            <div>
                              {content?.map((data: { id: string; name: string; inSequence: boolean }) => {
                                setValue('contentId', selectValue);
                                return (
                                  <section className="flex items-center">
                                    <label className="customRadio flex cursor-pointer text-[14px] text-gray-text">
                                      <Controller
                                        name={`contentId`}
                                        control={control}
                                        render={({ field, ...props }) => {
                                          return (
                                            <input
                                              type="radio"
                                              value={data?.id}
                                              // checked={e?.id == nestingOptionsSelected}
                                              // {...register('categoryId')}
                                              onChange={(a: any) => {
                                                field.onChange(data?.id);
                                                setSelectValue(data?.id);
                                                setTitle(data?.name);
                                                setSearchContent('');
                                                if (paginationParams.search) {
                                                  setPaginationParams({
                                                    page: 1,
                                                    search: '',
                                                  });
                                                }
                                              }}
                                              checked={data?.id === selectValue}
                                              name="contentId"
                                            />
                                          );
                                        }}
                                      />
                                      <span className="flex pl-3">
                                        {data.name}{' '}
                                        {data.inSequence && (
                                          <div className="italic text-orange-main">
                                            &nbsp;(
                                            {t(
                                              'in_sequence',
                                              currentSelected?.langCode || selectedLanguage?.langCode,
                                            ) || 'in sequence'}
                                            )
                                          </div>
                                        )}
                                      </span>
                                    </label>
                                  </section>
                                );
                              })}
                              <div style={{ marginBottom: '10px' }} ref={containerRef}></div>
                              {sequenceLoader.sequenceInfiniteLoader && (
                                <SmallLoader langCode={currentSelected?.langCode || selectedLanguage?.langCode} />
                              )}
                            </div>
                          ) : (
                            <>
                              {t('no_data', currentSelected?.langCode || selectedLanguage?.langCode) || 'No data found'}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors?.contentId && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {t(
                      convertToKey(errors?.contentId?.message?.toString()),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ) || errors?.contentId?.message?.toString()}
                  </p>
                )}
              </div>
            </TitleBlock>
          </div>
          <div className="flex w-80 flex-shrink-0 flex-col gap-10"></div>
        </div>
      )}
    </section>
  );
}

export default Sequence;
