import { useTranslation } from 'context/translation';
import { TODO_ANY } from 'typings/common';

type titleBlock = {
  leftComponent?: TODO_ANY;
  title?: string;
  children?: TODO_ANY;
  required?: TODO_ANY;
  action?: TODO_ANY;
  close?: TODO_ANY;
  langCode?:any
};

export const TitleBlock = ({
  leftComponent,
  title,
  children,
  required,
  action = () => {},
  close = false,
  langCode
}: titleBlock) => {
  const { t }: any = useTranslation();
  const renderButtons = () => {
    return (
      <div className="flex gap-1.5">
        <button
          className="flex h-5 w-5 items-center justify-center rounded bg-green-main text-sm text-white"
          onClick={() => {}}>
          <i className="fas fa-chevron-up"></i>
        </button>
        <button
          className="flex h-5 w-5 items-center justify-center rounded bg-green-main text-sm text-white"
          onClick={() => {}}>
          <i className="fas fa-chevron-down"></i>
        </button>
        <button
          className="flex h-5 w-5 items-center justify-center rounded bg-green-main text-sm text-white"
          onClick={() => action()}>
          {close ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}
        </button>
      </div>
    );
  };
  return (
    <div className="w-full">
      <div className="flex items-center justify-between rounded-t-[5px]  rounded-tr-md bg-gray-title-bg px-5 py-3">
        {!!title && (
          <p className="font-bold text-green-deep">
            {(langCode ? t(title, langCode) : t(title)) || title}
            {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
          </p>
        )}
        {!title && !!leftComponent && leftComponent}
      </div>

      <div className="rounded-b-[5px] border-1 border-solid border-gray-border bg-white">{children}</div>
    </div>
  );
};
