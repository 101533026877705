import { useCallback, useContext, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import addCircle from '../../../assets/images/add-circle.svg';
import addCircleBlue from '../../../assets/images/add-circle-blue.svg';
import removeImg from '../../../assets/images/remove.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import { InputComponentType, CompanyDefaultValueType } from '../../../typings/form';
import { Control, useFieldArray, Controller } from 'react-hook-form';
import { InputBoxType } from 'enums/companyForm.enum';
import UploadImage from 'components/UploadImage';
import { InputLabelPosition } from 'enums/input.enum';
import { TODO_ANY } from 'typings/common';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const DraggableElement: any = SortableElement(({ children }: TODO_ANY) => children);
const DraggableHandle: any = SortableHandle(({ children }: TODO_ANY) => children);
const SortableList: any = SortableContainer(
  ({
    data,
    setIsHoveringLists,
    register,
    errors,
    index,
    control,
    remove,
    langCode,
  }: {
    data: any;
    setIsHoveringLists: any;
    register: Function;
    // setValue:Function;
    errors: any;
    index: number;
    control: Control<CompanyDefaultValueType>;
    remove: Function;
    langCode?: any;
  }) => {
    const { t }: any = useTranslation();
    return (
      <div>
        <div onMouseEnter={() => setIsHoveringLists(true)} onMouseLeave={() => setIsHoveringLists(false)}>
          {data.map((item: any, i: any) => (
            <DraggableElement index={i} distance={1} lockAxis="y" lockToContainerEdges={true} lockOffset="0%">
              <div className="mb-5 flex items-center gap-2.5 px-[26px]">
                <DraggableHandle>
                  <div className={'handle flex h-7 w-7 items-center justify-center rounded bg-blue-main text-white'}>
                    <i className="fa fa-arrows" aria-hidden="true"></i>
                  </div>
                </DraggableHandle>
                <div className="flex items-center self-stretch rounded-md bg-gray-title-bg px-3 text-gray-text">
                  {i + 1}
                </div>
                <div className="flex-1">
                  <div className="w-full flex-shrink-0 pl-1">
                    <div className="relative">
                      <Controller
                        name={`customTermCompany.${index}.options.${i}.option`}
                        control={control}
                        render={({ field, ...props }) => {
                          return (
                            <input
                              type="text"
                              className="placeholder-text-grey-text flex  w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none "
                              name={`customTermCompany.[${index}].options.[${i}].option`}
                              id={`customTermCompany.[${index}].options.[${i}].option`}
                              key={item.id}
                              value={item?.option}
                              onChange={(a: any) => {
                                field.onChange(a.target?.value);
                                item.option = a.target?.value;
                              }}
                            />
                          );
                        }}
                      />

                      {/* <input
                        className="placeholder-text-grey-text flex  w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none "
                        type={'input'}
                        name={`customTermCompany.[${index}].options.[${i}].option`}
                        id={`customTermCompany.[${index}].options.[${i}].option`}
                        placeholder={'Option'}
                        {...register(`customTermCompany.${index}.options.${i}.option`)}
                        key={item.id}
                      /> */}
                      {data.length > 1 && (
                        <>
                          <div className="absolute top-[13px] right-[15px] h-[23px] w-[23px] cursor-pointer overflow-hidden rounded bg-green-main">
                            <input
                              className="z-index-[1] relative h-[23px] w-[23px] cursor-pointer bg-transparent p-[0px] text-transparent caret-transparent outline-[0]"
                              style={{ zIndex: 999999 }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                remove(i);
                              }}></input>
                            <i className="fa fa-minus absolute top-[5.7px] left-[5.2px] flex h-[13px] w-[13px] items-center justify-center rounded-[50%] border border-white pt-[1px] text-[6px] text-white"></i>
                          </div>
                        </>
                      )}
                    </div>
                    {errors?.customTermCompany?.[index]?.options?.[i]?.option && (
                      <p className="mt-2 text-sm italic text-red-600">
                        {t(convertToKey(errors?.customTermCompany?.[index]?.options?.[i].option.message), langCode) ||
                          errors?.customTermCompany?.[index]?.options?.[i].option.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </DraggableElement>
          ))}
        </div>
        {/* <hr />
      {letters.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={numbers.length + index}
          value={value}
          collection={isDragging ? "letters" : "numbers"}
        />
      ))} */}
      </div>
    );
  },
);
const InputBox = ({
  input,
  register,
  setValue,
  getValues,
  others,
  errors,
  setImageId,
  isReset,
  control,
  labelPosition,
  disable = false,
  block,
  isBlock = false,
  langCode,
  rollSelect,
}: InputComponentType) => {
  const formValues = getValues()[input.name];
  const { type, name, id, placeholder, label, required, options = [], btnLabel, inputType } = input;
  const { t }: any = useTranslation();
  const renderInput = () => {
    switch (type) {
      case InputBoxType.Input: {
        return (
          <div className={!others ? `border-b-1 border-slate-100 px-[26px] pt-[32px] pb-[30px]` : ''} key={id}>
            <div className="w-full flex-shrink-0">
              {label !== '' && (
                <label className="mb-[20px] block text-lg font-bold text-gray-text">
                  {label}
                  {required && <span className="text-red-500">&nbsp;*</span>}
                </label>
              )}
              <input
                className={`  ${
                  disable ? '!important cursor-not-allowed bg-slate-100 text-gray-500' : 'bg-white'
                } placeholder-text-grey-text flex w-full items-center rounded-md border-1 border-solid border-gray-200 py-3 px-6 text-lg leading-6 outline-none ${
                  others && 'text_bold  '
                } `}
                type={inputType ?? 'text'}
                name={name}
                id={id}
                placeholder={placeholder}
                disabled={disable}
                {...register(name)}
              />
              {errors[name] && (
                <p className="mt-2 text-sm italic text-red-600">
                  {langCode
                    ? t(convertToKey(errors[name].message), langCode) || errors[name].message
                    : t(convertToKey(errors[name].message)) || errors[name].message}
                </p>
              )}
            </div>
          </div>
        );
      }
      case InputBoxType.Select: {
        return (
          <div className={`${!rollSelect && 'border-b-1 border-slate-100 px-[26px] pt-[32px] pb-[30px]'}`}>
            <div
              className={
                labelPosition === InputLabelPosition.Left
                  ? 'flex w-full items-center justify-between'
                  : 'w-full flex-shrink-0'
              }>
             {!rollSelect && (
                <label
                className={
                  labelPosition === InputLabelPosition.Left
                    ? 'mr-[20px] block text-lg font-bold text-gray-text'
                    : 'mb-[20px] block text-lg font-bold text-gray-text'
                }>
                {!rollSelect && label}
                {required && !rollSelect && <span className="text-red-500">*</span>}
              </label>
             )} 
              <div className={labelPosition === InputLabelPosition.Left ? 'relative w-[calc(100%-80px)]' : 'relative'}>
                <select
                  className="flex w-full min-w-[190px] appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-[16px] text-lg leading-6 outline-none"
                  {...register(name)}
                  placeholder={placeholder}
                  name={name}>
                  {[{ label: t('Select', langCode) || 'Select', value: '' }, ...options].map((option, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
                <img
                  src={dropdown}
                  alt="arrow"
                  className="pointer-events-none absolute top-[13px] right-[15px]"
                  title="arrow"></img>
              </div>
            </div>
            {errors[name] && (
              <p className="mt-2 text-sm italic text-red-600">
                {langCode
                  ? t(convertToKey(errors[name].message), langCode) || errors[name].message
                  : t(convertToKey(errors[name].message)) || errors[name].message}
              </p>
            )}
          </div>
        );
      }
      case InputBoxType.Image: {
        return (
          <>
            <UploadImage
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              label={label}
              controlName={name}
              required={required}
              setImageId={setImageId}
              isEdit={disable}
              block={block}
              isBlock={isBlock}
              langCode={langCode}
            />
          </>
        );
      }
      case InputBoxType.CustomTerms: {
        return (
          <TermList
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            langCode={langCode}></TermList>
        );
      }
      default: {
        return <h1>Input type not Found !</h1>;
      }
    }
  };
  return <>{renderInput()}</>;
};

export default InputBox;

export const TermList = ({
  control,
  register,
  errors,
  setValue,
  getValues,
  langCode,
}: {
  control: Control<CompanyDefaultValueType>;
  register: Function;
  errors: any;
  setValue: Function;
  getValues: any;
  langCode?: any;
}) => {
  const { fields, append, remove } = useFieldArray({ name: 'customTermCompany', control });
  const { t }: any = useTranslation();

  return (
    <>
      <div className="border-b-1 border-slate-100 pt-[32px]">
        {fields.map((item, i) => {
          return (
            <>
              <div key={`custom_term_wrapper_${i}_1`}>
                <div className="w-full flex-shrink-0 px-[26px] pb-[30px]" key={`custom_term_wrapper_${i}_2`}>
                  <label
                    className="mb-[20px] block text-lg font-bold text-gray-text"
                    key={`custom_term_label_${i}`}>{`${t('custom_term', langCode) || 'Custom Term'} ${i + 1}`}</label>
                  <div className="relative">
                    <input
                      className="placeholder-text-grey-text mt-[15px] flex  w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none "
                      type="text"
                      name={`customTermCompany.[${i}].term`}
                      id={`customTermCompany.[${i}].term`}
                      placeholder={`${t('custom_term', langCode) || 'Custom Term'}`}
                      {...register(`customTermCompany.${i}.term`)}
                      key={item.id}
                    />
                    <button
                      className="absolute top-[13px] right-[15px] cursor-pointer rounded bg-green-main"
                      type="button"
                      onClick={() => {
                        remove(i);
                      }}
                      key={`custom_term_button_${i}`}>
                      <img src={removeImg} alt="Remove" />
                    </button>
                  </div>
                  {errors?.customTermCompany?.[i]?.term?.message && (
                    <p className="mt-2 text-sm italic text-red-600" key={`custom_term_error_${i}`}>
                      {t(convertToKey(errors?.customTermCompany?.[i]?.term?.message), langCode) ||
                        errors?.customTermCompany?.[i]?.term?.message}
                    </p>
                  )}
                </div>
              </div>
              <TermOptionList
                control={control}
                register={register}
                errors={errors}
                index={i}
                setValue={setValue}
                getValues={getValues}
                key={`TermOptionList_${i}`}
                langCode={langCode}></TermOptionList>
            </>
          );
        })}
        <div className="px-[26px] pb-[30px]">
          <button
            className="flex w-full cursor-pointer items-center justify-center rounded-[5px] bg-blue-main p-[5px] disabled:opacity-[0.5]"
            onClick={() => append({ term: '', options: [] })}
            type="button">
            <img src={addCircle} alt="arrow" className="mr-[5px]" title="arrow"></img>
            <span className="text-[12px] text-white">{t('add_custom_term', langCode) || 'Add Custom Term'}</span>
          </button>
        </div>
      </div>
    </>
  );
};
export const TermOptionList = ({
  control,
  register,
  errors,
  setValue,
  index,
  getValues,
  langCode,
}: {
  control: Control<CompanyDefaultValueType>;
  register: Function;
  setValue: Function;
  errors: any;
  index: number;
  getValues: any;
  langCode?: any;
}) => {
  const { fields, append, remove } = useFieldArray({ name: `customTermCompany.${index}.options`, control });
  const [fieldData, setFieldData] = useState<any>(fields || []);
  const { t }: any = useTranslation();

  const fieldIndex = index;
  useEffect(() => {
    if (fields.length === 0) {
      append({ option: '' });
    }
  }, []);
  useEffect(() => {
    setFieldData(fields);
  }, [fields]);
  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: TODO_ANY) => {
      const data: any = fieldData;
      const [rem] = data.splice(oldIndex, 1);
      data.splice(newIndex, 0, rem);
      const customTerm: any = getValues().customTermCompany;
      customTerm[index].options = data;
      setValue('customTermCompany', customTerm);
      setFieldData(data);
      setIsDragging(false);
    },
    [fieldData],
  );
  const [isHoveringLists, setIsHoveringLists] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const updateBeforeSortStart = () => {
    setIsDragging(true);
  };
  return (
    <>
      <SortableList
        data={fields}
        updateBeforeSortStart={updateBeforeSortStart}
        isDragging={isDragging}
        onSortEnd={handleSortEnd}
        setIsHoveringLists={setIsHoveringLists}
        control={control}
        register={register}
        errors={errors}
        index={index}
        append={append}
        remove={remove}
        langCode={langCode}
      />
      {/* {fields.map((item, i) => {
        return (
          <div className="mb-5 flex items-center gap-2.5 px-[26px]">
            <div className="flex items-center self-stretch rounded-md bg-gray-title-bg px-3 text-gray-text">
              {i + 1}
            </div>
            <div className="flex-1">
              <div className="w-full flex-shrink-0 pl-1">
                <div className="relative">
                  <input
                    className="placeholder-text-grey-text flex  w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none "
                    type={'input'}
                    name={`customTermCompany.[${index}].options.[${i}].option`}
                    id={`customTermCompany.[${index}].options.[${i}].option`}
                    placeholder={'Option'}
                    {...register(`customTermCompany.${index}.options.${i}.option`)}
                    key={item.id}
                  />
                  {fields.length > 1 && (
                    <button
                      className="absolute top-[13px] right-[15px] cursor-pointer rounded bg-green-main"
                      type="button"
                      onClick={() => remove(i)}>
                      <img src={removeImg} alt='Remove'/>
                    </button>
                  )}
                </div>
                {errors?.customTermCompany?.[index]?.options?.[i]?.option && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {errors?.customTermCompany?.[index]?.options?.[i].option.message}
                  </p>
                )}
              </div>
            </div>
          </div> );
      })} */}
      <div className="px-[26px] pb-[40px]">
        <button
          className="mt-[14px] flex w-full cursor-pointer items-center justify-center rounded-[5px] border-1 border-blue-main p-[5px] disabled:opacity-[0.5]"
          onClick={() => append({ option: '' })}
          type="button">
          <img src={addCircleBlue} alt="arrow" className="mr-[5px]" title="arrow"></img>
          <span className="text-[12px] text-blue-main">{`${
            t('add_options_for_custom_term', langCode) || 'Add Option For Custom Term'
          } ${index + 1}`}</span>
        </button>
      </div>
    </>
  );
};
