export const DisplayImage = ({
  image,
  circular,
  label,
  smallCircular,
  height = '100px',
  width = '100px',
  isUploading = false,
}: {
  image: string;
  circular?: any;
  label?: string;
  smallCircular?: any;
  height?: string;
  width?: string;
  isUploading?: boolean;
}) => {
  if (circular) {
    return (
      <>
        <div className={` m-auto flex h-[100px] w-[100px] items-center justify-center rounded-[100%] bg-gray-200`}>
          <img className="h-[100px] w-[100px] rounded-[100%] object-cover" src={image} alt={label} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className={`${
            smallCircular && `rounded-[100%]`
          } flex h-[50px] w-[50px]   items-center justify-center  bg-gray-300`}>
          <img
            className={`${smallCircular && `rounded-[100%]`}  h-[50px] w-[50px] object-cover`}
            src={image}
            alt={label}
          />
        </div>
      </>
    );
  }
};
