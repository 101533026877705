import React, { useState, useEffect, useContext } from 'react';
import { TODO_ANY } from 'typings/common';
import { Checkbox, Tooltip } from '@mui/material';
import { Filter, Input, SmallLoader, Spinner } from 'components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { filterArray, TableContent } from '../utils';
import { getAPIData } from 'utils/api';
import { ToastTypes, useToast } from 'context/toast';
import classNames from 'classnames';
import UploadLocal from '../UploadLocal/UploadLocal';
import { useTranslation } from 'context/translation';
import { LanguageListsContext, SelectedLangContext } from 'context/user';
export const UploadPopUp = ({
  show,
  setShow = () => {},
  fileSize = () => {},
  fileTitle = () => {},
  fileUrl = () => {},
  srtFileName = () => {},
  srtFileSize = () => {},
  setValue,
  setImageId = () => {},
  url,
  controlName,
  clearErrors = () => {},
  remove = () => {},
  fileType,
  setFileType = () => {},
  langCode,
}: {
  show: any;
  setShow: Function;
  fileSize?: Function;
  fileTitle?: Function;
  srtFileName?: Function;
  srtFileSize?: Function;
  fileUrl: Function;
  setValue: any;
  url?: any;
  setImageId?: Function;
  controlName: any;
  clearErrors?: Function;
  remove?: Function;
  fileType?: any;
  setFileType?: Function;
  langCode?: any;
}) => {
  const typeMedia = fileType;
  const [inputValue, setInputValue] = useState('');
  const [filterMedia, setfilterMedia] = useState(fileType);
  const [data, setData] = useState<any>({});
  const [totalPage, setTotalPage] = useState<any>(1);
  const [err, setErr] = useState(true);
  const [total, setTotal] = useState<any>('');
  const [mediaData, setMediaData] = useState<any>([]);
  const [mediaDataRaw, setMediaDataRaw] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [smallLoader, setSmallLoader] = useState<boolean>(false);
  const [selectFilter, setSelectFilter] = useState<any>('');
  const arr = filterArray;
  const { callToast } = useToast();
  const { t }: any = useTranslation();
  const [paginationParams, setPaginationParams] = useState<any>({
    page: 1,
    search: '',
    limit: 50,
  });
  const [selectedTab, setSelectedTab] = useState<string>('existing');
  const { langLists } = useContext(LanguageListsContext);
    const { selectedLanguage,  } = useContext(SelectedLangContext);
  useEffect(() => {
    if (!url) {
      setData({});
    }
  }, [url]);
  const selectedImage = (e: TODO_ANY) => {
    const obj = {
      id: e?.id,
      imgSource: e?.fileData?.imgUrl,
      size: e?.size,
      name: e?.fileData?.name,
      type: e?.fileData?.type,
      videoUrl: e?.fileData?.videoUrl,
    };

    setData(obj);
  };
  const getMediaList = async () => {
    if (paginationParams.page == 1) {
      setLoading(true);
    } else {
      setSmallLoader(true);
    }
    try {
      const selectedLang=langLists.find((lang:any)=>lang.langCode===langCode)
      let res = await getAPIData({
        url: 'media',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          type: filterMedia,
          languageId:selectedLang?.id??selectedLanguage?.id
        },
      });
      // console.log(TableContent(res?.data?.data), 'dara')
      setTotal(res?.data?.total);
      setTotalPage(Math.ceil(res?.data?.total / paginationParams.limit));
      // setMediaData(TableContent(res?.data?.data))
      if (paginationParams.page == 1) {
        if (!paginationParams.search) {
          setMediaDataRaw(res?.data?.data);
        }
        const data: any = TableContent(res?.data?.data);
        setMediaData(data);
      } else if (paginationParams.page > 1) {
        setMediaDataRaw([...mediaDataRaw, ...res?.data?.data]);
        const data: any = TableContent([...mediaDataRaw, ...res?.data?.data]);
        setMediaData(data);
      }
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setLoading(false);
    setSmallLoader(false);
  };
  useEffect(() => {
    getMediaList();
  }, [paginationParams, filterMedia]);
  const SaveData = () => {
    setShow(false);
    fileSize(data?.size);
    fileTitle(data?.name);
    fileUrl(data?.imgSource);
    setImageId(data?.id);
    setValue(controlName[0], data?.imgSource);
    if (typeMedia == 'video') {
      let obj = {
        url: data?.videoUrl,
        title: t('content_video', langCode) || 'Content Video',
        fileName: data?.name,
        fileSize: data?.size,
        thumbnail: data?.imgSource,
      };
      setValue(controlName[0], obj);
    }
    if (controlName[1]) {
      setValue(controlName[1], data?.name);
    }
    remove(false);
    clearErrors(controlName[0]);
    setFileType(data?.type);
  };
  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };
  const onNextPage = () => {
    handlePaginationChange(paginationParams?.page + 1);
  };

  const handleInputChange = (val: string) => {
    setInputValue(val);
  };

  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  return (
    <>
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg">
        <DialogContent>
          <div className="min-w-[500px]">
            <p className="text-2xl font-bold">
              {langCode ? t('featured_image', langCode) || 'Featured Image' : t('featured_image') || 'Featured Image'}
            </p>
            <div className="mt-3 mb-5 flex items-center justify-between">
              <div className="mt-2 ml-2 flex gap-5 text-gray-text">
                <div
                  className={classNames('cursor-pointer', selectedTab === 'existing' && 'border-b-2 border-green-main')}
                  onClick={() => {
                    setSelectedTab('existing');
                    setData({});
                    setErr(true);
                  }}>
                  {t('choose_existing', langCode) || 'Choose Existing'}
                </div>
                <div
                  className={classNames('cursor-pointer', selectedTab === 'new' && 'border-b-2 border-green-main')}
                  onClick={() => {
                    setSelectedTab('new');
                    setData({});
                    setErr(true);
                  }}>
                  {langCode ? t('upload_new', langCode) || 'Upload New' : t('upload_new') || 'Upload New'}
                </div>
              </div>
              <div className="flex items-center">
                {selectedTab === 'existing' && (
                  <div className="items-right mr-2 flex">
                    <Input
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder={t('search_media', langCode) || 'Search Media'}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          handleClick();
                        }
                      }}
                      onClick={handleClick}
                      type="search"></Input>
                    {typeMedia == 'all' && (
                      <Filter
                        width="200px"
                        children={
                          <div className="p-5">
                            {arr.map((e: any, i: any) => {
                              return (
                                <section className="flex items-center">
                                  <label className="customCheck flex cursor-pointer text-[14px] text-gray-text">
                                    <input
                                      type="checkbox"
                                      onChange={(a) =>
                                        a.target.checked ? setSelectFilter(e?.content) : setSelectFilter('all')
                                      }
                                      checked={e?.content == selectFilter}
                                    />
                                    <span>{e?.content[0].toUpperCase() + e?.content.slice(1)}</span>
                                  </label>
                                </section>
                              );
                            })}
                          </div>
                        }
                        handleReset={() => {
                          setSelectFilter('all');
                        }}
                        handleApply={() => {
                          setPaginationParams({ ...paginationParams, page: 1 });
                          setfilterMedia(selectFilter);
                        }}
                        langCode={langCode}
                      />
                    )}
                  </div>
                )}

                {selectedTab == 'existing' && (
                  <div>
                    <button
                      className="min-w-[70px] rounded-md border-1 border-green-main bg-transparent px-2.5 py-1.5 text-sm"
                      onClick={() => setShow(false)}>
                      {t('close', langCode) || 'Close'}
                    </button>
                    <button
                      disabled={err}
                      className={classNames(
                        'ml-2 min-w-[70px] rounded-md border-1 px-2.5 py-1.5 text-sm text-white',
                        err ? 'border-gray-main bg-gray-main' : 'cursor-pointer border-green-main bg-green-main',
                      )}
                      onClick={() => SaveData()}>
                      {t('save', langCode) || 'Save'}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {selectedTab === 'existing' && (
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="flex flex-wrap gap-4">
                      {mediaData.map((each: any, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            selectedImage(each);
                            setErr(false);
                          }}
                          className="relative w-[130px] cursor-pointer">
                          {each?.id == data?.id && (
                            <span className="absolute top-[2px] right-[2px] flex h-[20px] w-[20px] items-center justify-center bg-blue-main p-1 text-white">
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </span>
                          )}
                          <div
                            className={`rounded-t ${
                              each?.id == data?.id
                                ? 'border-2 border-b-1 border-red-main border-b-blue-main'
                                : 'border-1 border-blue-main'
                            }`}>
                            {each?.fileData?.type == 'image' ? (
                              <img
                                loading="lazy"
                                src={each?.fileData?.imgUrl}
                                className="h-[130px] w-[130px] object-contain"
                                alt="img"
                              />
                            ) : (
                              <img
                                alt="media video"
                                loading="lazy"
                                className="h-[130px] w-[130px] object-contain"
                                src={each?.fileData?.imgUrl}
                              />
                            )}
                          </div>
                          <div
                            className={classNames(
                              'h-13 w-[130px] rounded-b bg-gray-border p-1 text-xs text-gray-text',
                              each?.id == data?.id
                                ? 'border-x-2 border-b-2 border-red-main'
                                : 'border-x-1 border-b-1 border-gray-subject-text',
                            )}>
                            <Tooltip title={each?.fileData?.name}>
                              <p className="media-filename">{each?.fileData?.name}</p>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                      <div className="flex-1"></div>
                    </div>
                    <div className="flex justify-center">
                      {smallLoader ? (
                        <SmallLoader langCode={langCode} />
                      ) : (
                        <div className="flex items-center justify-center">
                          <button
                            disabled={paginationParams.page >= totalPage}
                            onClick={() => onNextPage()}
                            className={`mx-1.5 cursor-pointer rounded-md ${
                              paginationParams.page >= totalPage ? 'bg-gray-main' : 'bg-blue-main'
                            } mt-10 px-5 py-1.5 text-sm text-white`}>
                            {t('load_more', langCode) || 'Load More'}
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {selectedTab == 'new' && (
              <UploadLocal
                tab={setSelectedTab}
                setShow={setShow}
                title={fileTitle}
                errSet={setErr}
                srtTitle={srtFileName}
                srtSize={srtFileSize}
                size={fileSize}
                dataUrl={url}
                setContentId={setImageId}
                setDataUrl={fileUrl}
                setFormValue={setValue}
                controlName={controlName}
                mediaType={fileType}
                langCode={langCode}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
