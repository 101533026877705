import uuid from 'react-uuid';
import { TODO_ANY } from 'typings/common';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { useTranslation } from 'context/translation';

type checkbox = {
  content?: TODO_ANY;
  register?: TODO_ANY;
  name?: TODO_ANY;
};

function Checkbox({ content, register = () => {}, name }: checkbox) {
  const id = uuid();
  const { t }: any = useTranslation();

  return (
    <div className="flex items-center gap-2.5">
      <MuiCheckbox
        id={id}
        className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-main"
        style={{ color: '#20A8D8', transform: 'scale(0.8)' }}
        {...register(name)}
      />
      <label htmlFor={id} className="text-base font-semibold text-gray-text">
        {t(content) || content}
      </label>
    </div>
  );
}

export default Checkbox;
