import React, { useContext, useEffect, useState } from 'react';

const TranslationContext = React.createContext('en');
const Translations = {
  en: {
    Login: 'Login',
    required_pass: 'Password is required',
    required_email_username: 'Email or Username is required',
    confirm_pass: 'Please Confirm Your Password',
    pass_not_match: 'Passwords does not match',
    user_not_exist: 'User does not exist',
    user_loggedIn: 'User logged in successfully!',
    new_pass: 'New Password',
    confirm_new_pass: 'Confirm New Password',
    set_pass: 'Set Password',
    username: 'Username',
    password: 'Password',
    forgot_pass: 'Forgot Password',
    set_new_pass: 'Set New Password',
    for: 'for',
    pass_pattern:
      'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    add_new: 'Add New',
    add_image: 'Add Image',
    loading: 'Loading ...',
    cancel: 'Cancel',
    upload_video: 'Upload Video',
    remove: 'Remove',
    file_size: 'File size : ',
    file_name: 'File name : ',
    image_uploading: 'Image is uploading',
    email_sent: "A temporary username and password have been sent to the user's email",
    resend_link: 'Send Password Reset Link',
    password_reset: 'Password Reset',
    user_fields: 'User Fields',
    reset_link: 'Password reset link has been sent',
    no_data: 'No Data Found',
    user_delete: 'User deleted successfully',
    user_deleting: 'Deleting user...',
    badge_deleting: 'Deleting badge...',
    yes: 'Yes',
    no: 'No',
    badge_removed: 'Badge removed successfully',
    add_badge: 'Add New Badge',
    badge_add_success: 'Badge added successfully',
    badge_add_success: 'Badge updated successfully',
    badge_name: 'Badge Name',
    badge_share_text: 'Badge Share Text',
    badge_congrats_text: 'Badge Congrats Text',
    description: 'Description',
    monthly_badge_challenge: 'Is Monthly Badge Challenge?',
    end_date_greater_start_date: 'End date should be greater than start date',
    search: 'Search...',
    search_users: 'Search Users',
    all: 'ALL',
    end_date: 'End Date',
    search_badges: 'Search Badges',
    draft: 'Draft',
    published: 'Published',
    title: 'Title',
    category: 'Category',
    monthly_badge: 'Is Monthly Badge',
    actions: 'Actions',
    category_name: 'Category Name',
    parent_company: 'Parent Company',
    visible_start_date: 'Visible Start Date',
    visible_end_date: 'Visible End Date',
    deleting_category: 'Deleting category...',
    delete_category: 'Category deleted successfully',
    categories: 'Categories',
    add_category: 'Add Category',
    start_date_required: 'Start date is required',
    end_date_required: 'End date is required',
    name: 'Name',
    custom_company_only: 'For Custom Company Only',
    viewable_custom_company: 'Viewable Custom Company',
    selected_custom_category:
      'This cannot be modified here, change custom company for selected category in categories section.',
    assign_custom_company: 'Assign a Custom Company',
    selected_company: 'Only selected company can view this category and its contents',
    pick_start_date: 'Pick Start Date',
    pick_end_date: 'Pick End Date',
    edit_category: 'Edit Category',
    cannot_get_companies_data: 'cannot get companies data',
    add_new_company: 'Add New Company',
    quote_author: 'Quote Author',
    date_time: 'Date&Time picker ',
    want_to_delete: 'Do you want to delete',
    cancel: 'Cancel',
    delete: 'Delete',
    drag: 'Drag',
    action: 'Action',
    word_count: 'Word Count:',
    last_edited_by: 'Last edited by John Smith on July 5, 2022 at 3:47 pm',
    add_filter: 'Add Filter',
    filters: 'Filters',
    apply: 'Apply',
    reset: 'Reset',

    company_updated: 'Company updated successfully',
    company_added: 'Company added successfully',
    enable: 'Enable',
    save: 'Save',
    add: 'Add',
    edit_group: 'Edit Group',
    add_new_group: 'Add New Group',
    group_name: 'Group Name',
    companies: 'Companies',
    quiz_question: 'Quiz Question',
    question_text: 'Question Text',
    true: 'True',
    false: 'False',
    add_question: 'Add Question',
    logo: 'Logo',
    alter_image: 'Click the image to edit or update',
    upload_image: 'Upload Image',
    select_all: 'Select All',
    custom: 'Custom',
    created: 'Created:',
    per_page: 'Per Page',
    of: 'of',
    logout_success: 'Logout Successfully',
    content: 'Content',
    sequences: 'Sequences',
    interrupters: 'Interrupters',
    groups: 'Groups',
    badges: 'Badges',
    surveys: 'Surveys',
    pages: 'Pages',
    media: 'Media',
    users: 'Users',
    settings: 'Settings',
    logout: 'Logout',
    upload_picture: 'Upload Picture',
    start_date: 'Start Date',
    end_date: 'End Date',
    username: 'Username',
    email: 'Email',
    role: 'Role',
    category_fields: 'Category Fields',
    company_id: 'Company ID',
    company_name: 'Company Name',
    admin_user: 'Admin Username',
    enabled: 'Enabled',
    deleting_company: 'Deleting company...',
    company_removed: 'Company removed successfully',
    add_company: 'Add Company',
    search_companies: 'Search Companies',
    in_review: 'In Review',
    content_type: 'Content Type',
    status: 'Status',
    deleting_content: 'Deleting Content...',
    deleted_content: 'Content deleted successfully',
    add_content: 'Add Content',
    content_under_group: 'Is Content under any Group',
    content_under_sequence: 'Is Content under any Sequence',
    search_content: 'Search Content',
    edit_content: 'Edit Content',
    content_added: 'Content added successfully',
    content_updated: 'Content updated successfully',
    viewable_groups: 'Viewable Groups',
    select_group:
      'Select groups that can view this content, if none selected, this content will be viewable to anyone.',
    exceptions: 'Exceptions',
    select_companies: 'Select companies that cannot view this content',
    viewable_custom_company: 'Viewable Custom Company',
    selected_company_category: 'This cannot be modified here, change custom company for selected categories section.',
    minute: 'minute',
    minutes: 'minutes',
    keywords_search: 'Keywords Search',
    misspellings_synonyms:
      'Enter words that maybe common misspellings or synonyms of words that would be relevant for this piece of content, hit Enter to add.',
    type: 'Type',
    quiz_fields: 'Quiz Fields',
    select: 'Select',
    status_required: 'Status is required.',
    published_on: 'Published on:',
    feature_images: 'Feature Images',
    edit: 'Edit',
    publish_now: 'Publish Now',
    content_not_found: 'Content Not Found',
    oops: 'Oops!',
    unexpected_error: 'Sorry, an unexpected error has occurred.',
    code_required: 'Code is required',
    code_length: 'Code should be of 6 Characters',
    login: 'Login',
    submit: 'Submit',
    resend_code: 'resend Code',
    verify_code: 'Verification code sent successfully',
    reset_pass: 'Password reset successfully',
    email_required: 'Email is required',
    valid_email: 'Please enter a valid email.',
    deleting_group: 'Deleting group...',
    group_removed: 'Group removed successfully',
    add_group: 'Add Group',
    search_group: 'Search Groups',
    interrupt_date: 'Interrupt Date',
    interrupt_content_title: 'Interrupt Content Title',
    team_leaders: 'Team Leader Only',
    deleting_interrupter: 'Deleting interrupter...',
    interrupter_deleted: 'Interrupter deleted successfully.',
    interrupters: 'Interrupters',
    add_interrupter: 'Add Interrupter',
    from_date: 'From Date',
    to_date: 'To Date',
    clear_filter: 'Clear Filter',
    edit_interrupters: 'Edit Interrupters',
    add_interrupters: 'Add Interrupters',
    content_search: 'Content Search',
    interrupter: 'Interrupter',
    edited: 'edited',
    created: 'created',
    success: 'successfully!',
    interrupter_fields: 'Interrupter Fields',
    date: 'Date',
    filter: 'filter',
    libraries: 'Libraries',
    add_library: 'Add Library',
    all_date: 'All Dates',
    day: 'day',
    edit_library: 'Edit Library',
    add_new_library: 'Add New Library',
    library_fields: 'Library Fields',
    publish: 'Publish',
    load_more: 'Load More',
    choose_existing: 'Choose Existing',
    upload_new: 'Upload New',
    search_media: 'Search Media',
    close: 'Close',
    existing: 'existing',
    featured_image: 'Featured Image',
    new: 'new',
    tab_all: 'all',
    file: 'File',
    media_type: 'Media Type',
    alternative_text: 'Alternative Text',
    upload_to: 'Upload To',
    not_used_yet: 'Not Used Yet',
    file_size_exceed: 'File size exceed',
    file_type_not_support: 'File Type Not Supported',
    content_video: 'Content Video',
    content_srt: 'Content Srt',
    media_added: 'Media added successfully',
    file_required: 'This field is required',
    add_media: 'Add Media',
    drag_and_drop: 'Drag & drop files, or',
    browse: 'browse',
    max_image_video_size: 'Max Image Size: 20MB & Max Video Size: 50MB',
    mb: 'MB',
    remove_file: 'Remove File',
    video_media: 'Video Media',
    edit_media: 'Edit Media',
    image_size_exceed: 'Image size exceed',
    image_updated: 'Image updated successfully',
    download_started: 'Download Started',
    download_success: 'Download Successfully',
    something_went_wrong: 'Something went wrong',
    download_file: 'Download File',
    label_file_name: 'File Name',
    not_used_yet: 'Not Used Yet',
    deleting_media: 'Deleting media...',
    media_deleted: 'Media deleted successfully',
    media_library: 'Media Library',
    file_uploaded: 'File uploaded successfully',
    upload_srt: 'Upload SRT',
    srt_file: 'SRT File',
    deleting_page: 'Deleting page...',
    page_removed: '{Page removed successfully',
    search_pages: 'Search Pages',
    page_updated: 'Page updated successfully',
    page_added: 'Page added successfully',
    page_title: 'Page Title',
    slug: 'Slug',
    count: 'Count',
    premium: 'Premium',
    add_premium: 'Add Premium',
    edit_premium: 'Edit Premium',
    aaa: 'aaa',
    bbb: 'bbb',
    parent_item: 'Parent Item',
    quizzes: 'Quizzes',
    add_quiz: 'Add Quiz',
    search_quiz: 'Search Quiz',
    sequence_content_title: 'Sequence Content Title',
    deleting_sequence: 'Deleting sequence...',
    sequence_deleted: 'Sequence deleted successfully',
    add_one_above: 'Add one above',
    add_one_below: 'Add one below',
    edit_sequence: 'Edit Sequence',
    add_sequence: 'Add Sequence',
    search_content_sequence: 'Search for a content that will be in the sequence.',
    in_sequence: 'in sequence',
    general_settings: 'General Settings',
    user_updated: 'User updated successfully',
    user_fields: 'User Fields',
    publish_time_required: 'Publish time is required.',
    question: 'Question',
    question_type: 'Question Type',
    option: 'option',
    add_option: 'Add Option',
    template: 'Template',
    is_template: 'Is template',
    publishedOn: 'Published on',
    yet_to_publish: 'Yet To Be Published',
    deleting_survey: 'Deleting survey...',
    survey_deleted: 'Survey deleted successfully',
    add_survey: 'Add Survey',
    search_surveys: 'Search Surveys',
    survey_updated: 'Survey updated successfully',
    survey_added: 'Survey Added successfully',
    survey_title: 'Survey Title',
    add_survey_title: 'Add Survey Title',
    survey_fields: 'Survey Fields',
    is_survey_template: 'Is Survey a Template',
    survey_question: 'Survey Questions',
    search_tags: 'Search Tags',
    tag: 'Tag',
    add_tag: 'Add Tag',
    edit_tag: 'Edit Tag',
    archive_title: 'Archive Title',
    archive_subtitle: 'archive_subtitle',
    first_name: 'First Name',
    last_name: 'Last Name',
    edit_user: 'Edit User',
    profile_picture: 'Profile Picture',
    add_user: 'Add User',
    'd&i_statement_title': 'D&I Title Statement',
    'd&i_statement': 'D&I Statement',
    admin_username: 'Admin Username',
    temporary_password: 'Temporary Password',
    company_fields: 'Company Fields',
    edit_company: 'Edit Company',
    add_custom_term: 'Add Custom Term',
    add_options_for_custom_term: 'Add Options For Custom Term',
    no_image_selected: 'No image selected',
    available_to_team_leader_and_above_only: 'Available to Team Leader and Above Only',
    content_fields: 'Content Fields',
    duration: 'Duration',
    quote: 'Quote',
    text_only: 'Text Only',
    video: 'Video',
    quiz: 'Quiz',
    quote_fields: 'Quote Fields',
    video_fields: 'Video Fields',
    video_file: 'Video File',
    answer: 'Answer',
    question_is_required: 'Question is required',
    answer_is_required: 'Answer is required',
    video_is_required: 'Video is required',
    quote_is_required: 'Quote is required',
    quote_author_is_required: 'Quote Author is required',
    at_least_1_question_is_required: 'At least 1 question is required',
    'title_is_required.': 'Title is required.',
    "whiteSpace',_'only_white_spaces_are_not_allowed": "whiteSpace', 'Only white spaces are not allowed",
    statusId_is_required: 'StatusId is required',
    minimum_value_should_be_1: 'Minimum value should be 1',
    this_field_is_required: 'This field is required',
    image_is_required: 'Image is required',
    'share_text_is_required.': 'Share Text is required.',
    'congrats_text_is_required.': 'Congrats Text is required.',
    end_date_should_be_greater_than_start_date: 'End date should be greater than start date',
    this_field_is_required: 'This field is required',
    estimated_time_to_complete_the_content: 'Estimate time to complete the content',
    text_field: 'Text Field',
    order: 'Order',
    name_is_required: 'Name is required',
    all_changes_will_be_lost: 'All changes will be lost',
    continue: 'Continue',
    select_content_type: 'Select Content Type',
    contents: 'Contents',
    duplicate: 'Duplicate',
    none: 'none',
  },
  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  fr: {
    Login: 'Login',
    required_pass: 'Password is required',
    required_email_username: 'Email or Username is required',
    confirm_pass: 'Please Confirm Your Password',
    pass_not_match: 'Passwords does not match',
    user_not_exist: 'User does not exist',
    user_loggedIn: 'User logged in successfully!',
    new_pass: 'New Password',
    confirm_new_pass: 'Confirm New Password',
    set_pass: 'Set Password',
    username: 'Username',
    password: 'Password',
    forgot_pass: 'Forgot Password',
    set_new_pass: 'Set New Password',
    for: 'for',
    pass_pattern:
      'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    add_new: 'Add New',
    add_image: 'Add Image',
    loading: 'Loading ...',
    cancel: 'Cancel',
    upload_video: 'Upload Video',
    remove: 'Remove',
    file_size: 'File size : ',
    file_name: 'File name : ',
    image_uploading: 'Image is uploading',
    email_sent: "A temporary username and password have been sent to the user's email",
    resend_link: 'Send Password Reset Link',
    password_reset: 'Password Reset',
    user_fields: 'User Fields',
    reset_link: 'Password reset link has been sent',
    no_data: 'No Data Found',
    user_delete: 'User deleted successfully',
    user_deleting: 'Deleting user...',
    badge_deleting: 'Deleting badge...',
    yes: 'Yes',
    no: 'No',
    badge_removed: 'Badge removed successfully',
    add_badge: 'Add New Badge',
    badge_add_success: 'Badge added successfully',
    badge_add_success: 'Badge updated successfully',
    badge_name: 'Badge Name',
    badge_share_text: 'Badge Share Text',
    badge_congrats_text: 'Badge Congrats Text',
    description: 'Description',
    monthly_badge_challenge: 'Is Monthly Badge Challenge?',
    end_date_greater_start_date: 'End date should be greater than start date',
    search: 'Search...',
    search_users: 'Search Users',
    all: 'ALL',
    end_date: 'End Date',
    search_badges: 'Search Badges',
    draft: 'Draft',
    published: 'Published',
    title: 'Title',
    category: 'Category',
    monthly_badge: 'Is Monthly Badge',
    actions: 'Actions',
    category_name: 'Category Name',
    parent_company: 'Parent Company',
    visible_start_date: 'Visible Start Date',
    visible_end_date: 'Visible End Date',
    deleting_category: 'Deleting category...',
    delete_category: 'Category deleted successfully',
    categories: 'Categories',
    add_category: 'Add Category',
    start_date_required: 'Start date is required',
    end_date_required: 'End date is required',
    name: 'Name',
    custom_company_only: 'For Custom Company Only',
    viewable_custom_company: 'Viewable Custom Company',
    selected_custom_category:
      'This cannot be modified here, change custom company for selected category in categories section.',
    assign_custom_company: 'Assign a Custom Company',
    selected_company: 'Only selected company can view this category and its contents',
    pick_start_date: 'Pick Start Date',
    pick_end_date: 'Pick End Date',
    edit_category: 'Edit Category',
    cannot_get_companies_data: 'cannot get companies data',
    add_new_company: 'Add New Company',
    quote_author: 'Quote Author',
    date_time: 'Date&Time picker ',
    want_to_delete: 'Do you want to delete',
    cancel: 'Cancel',
    delete: 'Delete',
    drag: 'Drag',
    action: 'Action',
    word_count: 'Word Count:',
    last_edited_by: 'Last edited by John Smith on July 5, 2022 at 3:47 pm',
    add_filter: 'Add Filter',
    filters: 'Filters',
    apply: 'Apply',
    reset: 'Reset',

    company_updated: 'Company updated successfully',
    company_added: 'Company added successfully',
    enable: 'Enable',
    save: 'Save',
    add: 'Add',
    edit_group: 'Edit Group',
    add_new_group: 'Add New Group',
    group_name: 'Group Name',
    companies: 'Companies',
    quiz_question: 'Quiz Question',
    question_text: 'Question Text',
    true: 'True',
    false: 'False',
    add_question: 'Add Question',
    logo: 'Logo',
    alter_image: 'Click the image to edit or update',
    upload_image: 'Upload Image',
    select_all: 'Select All',
    custom: 'Custom',
    created: 'Created:',
    per_page: 'Per Page',
    of: 'of',
    logout_success: 'Logout Successfully',
    content: 'Content',
    sequences: 'Sequences',
    interrupters: 'Interrupters',
    groups: 'Groups',
    badges: 'Badges',
    surveys: 'Surveys',
    pages: 'Pages',
    media: 'Media',
    users: 'Users',
    settings: 'Settings',
    logout: 'Logout',
    upload_picture: 'Upload Picture',
    start_date: 'Start Date',
    end_date: 'End Date',
    username: 'Username',
    email: 'Email',
    role: 'Role',
    category_fields: 'Category Fields',
    company_id: 'Company ID',
    company_name: 'Company Name',
    admin_user: 'Admin Username',
    enabled: 'Enabled',
    deleting_company: 'Deleting company...',
    company_removed: 'Company removed successfully',
    add_company: 'Add Company',
    search_companies: 'Search Companies',
    in_review: 'In Review',
    content_type: 'Content Type',
    status: 'Status',
    deleting_content: 'Deleting Content...',
    deleted_content: 'Content deleted successfully',
    add_content: 'Add Content',
    content_under_group: 'Is Content under any Group',
    content_under_sequence: 'Is Content under any Sequence',
    search_content: 'Search Content',
    edit_content: 'Edit Content',
    content_added: 'Content added successfully',
    content_updated: 'Content updated successfully',
    viewable_groups: 'Viewable Groups',
    select_group:
      'Select groups that can view this content, if none selected, this content will be viewable to anyone.',
    exceptions: 'Exceptions',
    select_companies: 'Select companies that cannot view this content',
    viewable_custom_company: 'Viewable Custom Company',
    selected_company_category: 'This cannot be modified here, change custom company for selected categories section.',
    minute: 'minute',
    minutes: 'minutes',
    keywords_search: 'Keywords Search',
    misspellings_synonyms:
      'Enter words that maybe common misspellings or synonyms of words that would be relevant for this piece of content, hit Enter to add.',
    type: 'Type',
    quiz_fields: 'Quiz Fields',
    select: 'Select',
    status_required: 'Status is required.',
    published_on: 'Published on:',
    feature_images: 'Feature Images',
    edit: 'Edit',
    publish_now: 'Publish Now',
    content_not_found: 'Content Not Found',
    oops: 'Oops!',
    unexpected_error: 'Sorry, an unexpected error has occurred.',
    code_required: 'Code is required',
    code_length: 'Code should be of 6 Characters',
    login: 'Login',
    submit: 'Submit',
    resend_code: 'resend Code',
    verify_code: 'Verification code sent successfully',
    reset_pass: 'Password reset successfully',
    email_required: 'Email is required',
    valid_email: 'Please enter a valid email.',
    deleting_group: 'Deleting group...',
    group_removed: 'Group removed successfully',
    add_group: 'Add Group',
    search_group: 'Search Groups',
    interrupt_date: 'Interrupt Date',
    interrupt_content_title: 'Interrupt Content Title',
    team_leaders: 'Team Leader Only',
    deleting_interrupter: 'Deleting interrupter...',
    interrupter_deleted: 'Interrupter deleted successfully.',
    interrupters: 'Interrupters',
    add_interrupter: 'Add Interrupter',
    from_date: 'From Date',
    to_date: 'To Date',
    clear_filter: 'Clear Filter',
    edit_interrupters: 'Edit Interrupters',
    add_interrupters: 'Add Interrupters',
    content_search: 'Content Search',
    interrupter: 'Interrupter',
    edited: 'edited',
    created: 'created',
    success: 'successfully!',
    interrupter_fields: 'Interrupter Fields',
    date: 'Date',
    filter: 'filter',
    libraries: 'Libraries',
    add_library: 'Add Library',
    all_date: 'All Dates',
    day: 'day',
    edit_library: 'Edit Library',
    add_new_library: 'Add New Library',
    library_fields: 'Library Fields',
    publish: 'Publish',
    load_more: 'Load More',
    choose_existing: 'Choose Existing',
    upload_new: 'Upload New',
    search_media: 'Search Media',
    close: 'Close',
    existing: 'existing',
    featured_image: 'Featured Image',
    new: 'new',
    tab_all: 'all',
    file: 'File',
    media_type: 'Media Type',
    alternative_text: 'Alternative Text',
    upload_to: 'Upload To',
    not_used_yet: 'Not Used Yet',
    file_size_exceed: 'File size exceed',
    file_type_not_support: 'File Type Not Supported',
    content_video: 'Content Video',
    content_srt: 'Content Srt',
    media_added: 'Media added successfully',
    file_required: 'This field is required',
    add_media: 'Add Media',
    drag_and_drop: 'Drag & drop files, or',
    browse: 'browse',
    max_image_video_size: 'Max Image Size: 20MB & Max Video Size: 50MB',
    mb: 'MB',
    remove_file: 'Remove File',
    video_media: 'Video Media',
    edit_media: 'Edit Media',
    image_size_exceed: 'Image size exceed',
    image_updated: 'Image updated successfully',
    download_started: 'Download Started',
    download_success: 'Download Successfully',
    something_went_wrong: 'Something went wrong',
    download_file: 'Download File',
    label_file_name: 'File Name',
    not_used_yet: 'Not Used Yet',
    deleting_media: 'Deleting media...',
    media_deleted: 'Media deleted successfully',
    media_library: 'Media Library',
    file_uploaded: 'File uploaded successfully',
    upload_srt: 'Upload SRT',
    srt_file: 'SRT File',
    deleting_page: 'Deleting page...',
    page_removed: '{Page removed successfully',
    search_pages: 'Search Pages',
    page_updated: 'Page updated successfully',
    page_added: 'Page added successfully',
    page_title: 'Page Title',
    slug: 'Slug',
    count: 'Count',
    premium: 'Premium',
    add_premium: 'Add Premium',
    edit_premium: 'Edit Premium',
    aaa: 'aaa',
    bbb: 'bbb',
    parent_item: 'Parent Item',
    quizzes: 'Quizzes',
    add_quiz: 'Add Quiz',
    search_quiz: 'Search Quiz',
    sequence_content_title: 'Sequence Content Title',
    deleting_sequence: 'Deleting sequence...',
    sequence_deleted: 'Sequence deleted successfully',
    add_one_above: 'Add one above',
    add_one_below: 'Add one below',
    edit_sequence: 'Edit Sequence',
    add_sequence: 'Add Sequence',
    search_content_sequence: 'Search for a content that will be in the sequence.',
    in_sequence: 'in sequence',
    general_settings: 'General Settings',
    user_updated: 'User updated successfully',
    user_fields: 'User Fields',
    publish_time_required: 'Publish time is required.',
    question: 'Question',
    question_type: 'Question Type',
    option: 'option',
    add_option: 'Add Option',
    template: 'Template',
    is_template: 'Is template',
    publishedOn: 'Published on',
    yet_to_publish: 'Yet To Be Published',
    deleting_survey: 'Deleting survey...',
    survey_deleted: 'Survey deleted successfully',
    add_survey: 'Add Survey',
    search_surveys: 'Search Surveys',
    survey_updated: 'Survey updated successfully',
    survey_added: 'Survey Added successfully',
    survey_title: 'Survey Title',
    add_survey_title: 'Add Survey Title',
    survey_fields: 'Survey Fields',
    is_survey_template: 'Is Survey a Template',
    survey_question: 'Survey Questions',
    search_tags: 'Search Tags',
    tag: 'Tag',
    add_tag: 'Add Tag',
    edit_tag: 'Edit Tag',
    archive_title: 'Archive Title',
    archive_subtitle: 'archive_subtitle',
    first_name: 'First Name',
    last_name: 'Last Name',
    edit_user: 'Edit User',
    profile_picture: 'Profile Picture',
    add_user: 'Add User',
    'd&i_statement_title': 'D&I Title Statement',
    'd&i_statement': 'D&I Statement',
    admin_username: 'Admin Username',
    temporary_password: 'Temporary Password',
    company_fields: 'Company Fields',
    edit_company: 'Edit Company',
    add_custom_term: 'Add Custom Term',
    add_options_for_custom_term: 'Add Options For Custom Term',
    no_image_selected: 'No image selected',
    available_to_team_leader_and_above_only: 'Available to Team Leader and Above Only',
    content_fields: 'Content Fields',
    duration: 'Duration',
    quote: 'Quote',
    text_only: 'Text Only',
    video: 'Video',
    quiz: 'Quiz',
    quote_fields: 'Quote Fields',
    video_fields: 'Video Fields',
    video_file: 'Video File',
    answer: 'Answer',
    question_is_required: 'Question is required',
    answer_is_required: 'Answer is required',
    video_is_required: 'Video is required',
    quote_is_required: 'Quote is required',
    quote_author_is_required: 'Quote Author is required',
    at_least_1_question_is_required: 'At least 1 question is required',
    'title_is_required.': 'Title is required.',
    "whiteSpace',_'only_white_spaces_are_not_allowed": "whiteSpace', 'Only white spaces are not allowed",
    statusId_is_required: 'StatusId is required',
    minimum_value_should_be_1: 'Minimum value should be 1',
    this_field_is_required: 'This field is required',
    image_is_required: 'Image is required',
    'share_text_is_required.': 'Share Text is required.',
    'congrats_text_is_required.': 'Congrats Text is required.',
    end_date_should_be_greater_than_start_date: 'End date should be greater than start date',
    this_field_is_required: 'This field is required',
    estimated_time_to_complete_the_content: 'Estimate time to complete the content',
    text_field: 'Text Field',
    order: 'Order',
    name_is_required: 'Name is required',
    all_changes_will_be_lost: 'All changes will be lost',
    continue: 'Continue',
    select_content_type: 'Select Content Type',
    contents: 'Contents',
    duplicate: 'Duplicate',
    none: 'none',
  },
  es: {
    Login: 'acceso',
    required_pass: 'se requiere contraseña',
  },
  pt: {
    Login: 'Conecte-se',
    required_pass: 'Senha requerida',
  },
  de: {
    Login: 'Anmeldung',
    required_pass: 'Passwort wird benötigt',
  },
  ja: {
    Login: 'ログイン',
    required_pass: 'パスワードが必要',
  },
};

export default function TranslationProvider({ children }) {
  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('selectedLang'))?.langCode || 'en');

  const t = (key, lan) =>
    Translations?.[lan ? lan : language || 'en']?.[key]
      ? Translations?.[lan ? lan : language || 'en']?.[key]
      : Translations?.[lan ? lan : language || 'en']?.[key?.toLowerCase()] || '';

  // useEffect(() => {
  //   localStorage.setItem('selectedLang', language);
  // }, [language]);

  const value = { setLanguage: setLanguage, language: language, t: t };
  return <TranslationContext.Provider value={value}> {children} </TranslationContext.Provider>;
}

export const useTranslation = () => useContext(TranslationContext);
