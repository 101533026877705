import { Tooltip } from '@mui/material';
import { SmallLoader, Spinner } from 'components';
import { useTranslation } from 'context/translation';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const MediaGrid = ({
  data,
  loader,
  total,
  paginationParam,
  smallLoader,
  setPaginationParam = () => {},
}: {
  data: any;
  loader: any;
  total: any;
  smallLoader: any;
  paginationParam: any;
  setPaginationParam: Function;
}) => {
  const navigate = useNavigate();
  const { t }: any = useTranslation();
  const handlePaginationChange = (page: number) => {
    setPaginationParam({
      page: page,
      search: '',
      limit: 50,
    });
  };
  const onNextPage = () => {
    handlePaginationChange(paginationParam?.page + 1);
  };
  const totalPage = Math.ceil(total / 50);

  const handleEditMedia = useCallback(
    (id: string | number) => {
      navigate(`/media/edit/${id}`);
    },
    [navigate],
  );

  return loader ? (
    <Spinner />
  ) : !data?.length ? (
    <div className="flex justify-center">{t('no_data') || 'No Data Found'}</div>
  ) : (
    <>
      <div className="flex flex-wrap gap-4">
        {data.map((each: any, index: number) => (
          <div className="w-[130px] cursor-pointer" key={index} onClick={() => handleEditMedia(each?.id)}>
            <div className="rounded-t border-1 border-blue-main">
              {each?.fileData?.type == 'image' ? (
                <img
                  loading="lazy"
                  src={each?.fileData?.imgUrl}
                  className="h-[130px] w-[130px] object-contain"
                  alt="image"
                />
              ) : (
                <img
                  loading="lazy"
                  alt={t('video_content') || 'video Content'}
                  className="h-[130px] w-[130px] object-contain"
                  src={each?.fileData?.imgUrl}
                />
              )}
            </div>
            <div className="h-13 w-[130px] rounded-b border-x-1 border-b-1 border-gray-subject-text bg-gray-border p-1 text-xs text-gray-text">
              <Tooltip title={each?.fileData?.name}>
                <p className="media-filename">{each?.fileData?.name}</p>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {smallLoader ? (
          <SmallLoader />
        ) : (
          <button
            disabled={paginationParam.page >= totalPage}
            onClick={() => onNextPage()}
            className={`mx-1.5 cursor-pointer rounded-md ${
              paginationParam.page >= totalPage ? 'bg-gray-main' : 'bg-blue-main'
            } mt-10 px-5 py-1.5 text-sm text-white`}>
            {t('load_more') || 'Load More'}
          </button>
        )}
      </div>
    </>
  );
};

export default MediaGrid;
