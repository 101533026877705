import { TODO_ANY } from 'typings/common';

export const createDummyData = (columns: TODO_ANY) => {
  let dataArray = [];
  for (let i = 0; i < Math.ceil(Math.random() * 8); i++) {
    let newObject: TODO_ANY = {};
    for (let j = 0; j < columns.length; j++) {
      newObject[columns[j].key] = `${columns[j].key} ${i}`;
      newObject.id = j;
    }

    dataArray.push(newObject);
  }
  return dataArray;
};
