import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Pagination, Input, Spinner } from '../../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../../components/common/table';
import { TableActionButton } from '../../../components/common/button';
import { createDummyData } from '../../../utils/createDummyData';
import { TODO_ANY } from 'typings/common';
import { ToastTypes, useToast } from 'context/toast';
import { deleteAPIData, getAPIData } from 'utils/api';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import { DefaultBadges } from 'enums/constant.enum';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

function BadgeList() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const [total, setTotal] = useState(0);
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const [errPage, setErrPage] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const { callToast } = useToast();
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [badgesData, setBadgesData] = useState<any>([]);
  const { t }: any = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const handleToEditBadge = useCallback(
    (id: string | number) => {
      navigate(`/badges/edit/${id}`);
    },
    [navigate],
  );

  const tabs = useMemo(
    () => [
      { label: t('all') || 'All', num: 1 },
      { label: t('published') || 'Published', num: 1 },
      { label: t('draft') || 'Draft', num: 1 },
    ],
    [],
  );
  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const handleToAddContent = useCallback(() => {
    navigate('/badges/add');
  }, [navigate]);
  const onNextPage = () => {
    handlePaginationChange(paginationParams?.page - 1);
  };
  const onPrevPage = () => {
    handlePaginationChange(paginationParams?.page + 1);
  };

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: t('category') || 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 500,
    },
    {
      title: t('monthly_badge') || 'Is Monthly Badge',
      dataIndex: 'isMonthly',
      key: 'isMonthly',
      width: 250,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: any) => (
        <TableActions
          icons={[
            { iconClassName: 'fas fa-edit', onClick: () => handleToEditBadge(record?.id) },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                setShowDel(true);
                setDeleteId(record);
              },
              disabled: [
                DefaultBadges.Journey,
                DefaultBadges.Favorites,
                DefaultBadges.Actions,
                DefaultBadges.Sharing,
                DefaultBadges.Begin,
                DefaultBadges.Quest,
                DefaultBadges.Leader,
                DefaultBadges.LevelUp,
              ].includes(record.title),
            },
            {
              iconClassName: 'fa fa-clone',
              onClick: () => {
                handleDuplicateBadge(record.id);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleDuplicateBadge = (id: string | number) => {
    navigate(`/badges/duplicate/${id}`);
  };

  const handleInputChange = (val: string) => {
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const badgeTableData = (data: TODO_ANY) => {
    let A = [];
    for (let key of data) {
      A.push({
        id: key?.id,
        title: key?.name,
        category: key?.category?.name,
        isMonthly: key?.isMonthlyBadge ? t('yes') || 'Yes' : t('no') || 'No',
      });
    }
    return A;
  };
  const getBadgesData = async () => {
    setLoading(true);
    try {
      let res = await getAPIData({
        url: 'badge',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          languageId : selectedLanguage?.id
        },
      });
      setBadgesData(badgeTableData(res?.data?.result));
      setTotal(res?.data?.total);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      console.error(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getBadgesData();
    }
  }, [paginationParams]);

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('badge_deleting') || 'Deleting badge...');
      await deleteAPIData({ url: `badge/${id}` });
      callToast(ToastTypes.SUCCESS, t('badge_removed') || 'Badge removed successfully');
      getBadgesData();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };

  return (
    <section className="Badges">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.title}
          type="badge"
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText={t('badges') || 'Badges'}
        buttonText={t(convertToKey('Add Badge')) || 'Add Badge'}
        disableBtn={loading}
      />
      {/* <TabsLayer tabs={tabs} /> */}
      <FunctionLayer
        leftPortion={
          <>
            {/* <Select
              value={dateValue}
              options={[
                { label: 'All Dates', value: '0' },
                { label: '1 day', value: '1' },
              ]}
              onChange={setDateValue}
            />

            <TableActionButton onClick={() => {}} title="Filter" /> */}
          </>
        }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              onClick={handleClick}
              placeholder={t('search_badges') || 'Search Badges'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              onNext={onNextPage}
              onPrev={onPrevPage}
              type="badge"
              searchedValue={paginationParams.search}
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={badgesData} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default BadgeList;
