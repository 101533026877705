import moment from 'moment';
import { InputBoxType } from '../../enums/companyForm.enum';
import { getSignedUrl, uploadFile } from 'utils/uploadFile';
import uuid from 'react-uuid';
import { addOffSet } from 'utils/ConvertDate';
import { TODO_ANY } from 'typings/common';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
export interface ICategory {
  id: string;
  level: number;
  name: string;
  order_sequence: number;
  parent_id: null | string;
  position: number | null;
  root: null | number;
  company_id?: null | string;
  company_name?: null | string;
}

export const arrToString = (arr: any) => {
  let arrString = '';
  if (!arr.length) {
    return '----------';
  }
  if (arr.length === 1) {
    return arr.toString();
  }
  arr.forEach((data: any, index: number) => {
    if (index < 2 && data) {
      arrString += data + ',';
    }
  });
  return arrString + '...';
};
export const contentTableData = (arr: any) => {
  const categories = (content: any) => content?.contentCategory?.map((data: any) => data?.category?.name);
  const publishDate = (date: any) =>
    date ? `Published on ${moment(date).format('L')} at ${moment(date).format('LT')}` : 'Yet To Be Published';
  const tableContent = arr?.map((data: any) => {
    const status = data?.status?.status === 'Published' ? `${publishDate(data?.publishTime)}` : data?.status?.status;
    return {
      id: data.id,
      title: data?.name,
      categories: arrToString(categories(data)),
      contentType: data?.contentType?.type,
      date: publishDate(data?.publishTime),
      companies: arrToString(data?.companies.map((c: any) => c.name)),
      status: status + ` (updated at: ${moment(data?.updatedAt).format('YYYY-MM-DD h:mma')})`,
    };
  });

  return tableContent;
};

export const getStatusText = (id: any, statusData: any) => {
  const status = statusData?.find((data: any) => data?.id === id);
  if (status) {
    return status.status;
  }
  return '--';
};
export const getPublishTime = (data: any) => {
  if (data) {
    return moment(data).format('LLL');
  } else {
    return '--';
  }
};

export const initialDefaultValues = {
  name: '',
  textField: '',
  duration: 1,
  leaderOnly: false,
  quote: '',
  quoteAuthor: '',
  publishTime: null,
  imageUrl: null,
  statusId: null,
  categories: [],
  groups: [],
  exceptions: [],
  contentTypeId: 1,
  keywords: [],
  video: {
    url: '',
    title: '',
    fileName: '',
    fileSize: '',
    thumbnail: '',
  },
  srt: null,
  questions: [],
};

export const editorInputs = {
  type: InputBoxType.Paragraph,
  name: 'textField',
  id: 'textField',
  placeholder: '',
  label: 'Text Field',
  required: false,
  btnLabel: '',
};

export const handleDefaultValues = (values: any) => {
  return {
    name: values?.name,
    textField: values?.textField,
    duration: values?.duration || 1,
    leaderOnly: values?.leaderOnly,
    forDailyInclusion: values?.forDailyInclusion,
    quote: values?.quote ?? '',
    quoteAuthor: values?.quoteAuthor ?? '',
    publishTime: new Date(values?.publishTime),
    imageUrl: values?.image?.url ?? null,
    statusId: values?.statusId ?? '',
    scheduleDays: values?.scheduleDays || 1,
    categories: values?.contentCategory.map((data: any) => data?.id),
    groups: values?.contentGroup?.map((data: any) => data?.groupId),
    exceptions: values?.contentCompanyException?.map((data: any) => data?.company?.id),
    contentTypeId: values?.contentTypeId,
    keywords: values?.contentKeywords || [],
    video: values?.video?.url
      ? {
          url: values?.video?.url ?? '',
          title: values?.video?.title ?? '',
          fileName: values?.video?.fileName ?? '',
          fileSize: values?.video?.fileSize ?? '',
          thumbnail: values?.video?.thumbnail ?? '',
        }
      : null,
    srt: values?.srt?.url
      ? {
          url: values?.srt?.url,
          title: values?.srt?.testing,
          fileName: values?.srt?.fileName,
          fileSize: values?.srt?.fileSize,
        }
      : null,
    questions: values?.quizQuestion,
  };
};
export const handleDefaultValuesForBadges = (data: any) => {
  return {
    name: data?.name,
    isMonthlyBadge: data?.isMonthlyBadge,
    categoryId: +data?.categoryId,
    startDate: addOffSet(data?.startDate),
    endDate: addOffSet(data?.endDate),
    interrupterContentId: +data?.interrupterContentId,
    badgeImgUrl: data?.image?.url,
    description: data?.description,
    shareText: data?.shareText,
    congratsText: data?.congratsText,
    groupIds: data?.badgeGroup?.map((e: any) => e?.groupId),
    companyExceptionIds: data?.badgeCompanyException?.map((e: any) => e?.companyId),
  };
};

export const nestingMultiSelectOption = (data: any) => {
  const parentObj: any = {};
  data.forEach((data: any) => {
    if (!data.parentId) {
      parentObj[data.id] = { name: data.name, id: data.id, child: [] };
    }
  });

  data.forEach((el: any) => {
    if (parentObj[el.parentId]) {
      parentObj[el.parentId].child.push({ id: el.id, name: el?.name });
    } else {
      if (el.parentId) {
        parentObj[el.parentId] = {
          name: el?.parent?.name ?? '',
          id: el?.parentId,
          child: [{ id: el.id, name: el?.name }],
        };
        data.push({ id: el.parentId, name: el?.parent?.name });
      }
    }
  });
  const parentKeys = Object.keys(parentObj);
  return data.map((data: any) =>
    parentKeys.includes(data.id || data.parentId)
      ? parentObj[data.id] ?? parentObj[data.parentId]
      : { id: data.id, name: data?.name },
  );
};

export const getUrls = (str: any) => {
  var m;
  let urls = [];
  let rex = /<img src="([\w\W]+?)">/g;
  while ((m = rex?.exec(str))) {
    urls.push(m[1]);
  }
  return urls ?? [];
};
export function dataURLtoFile(dataUrl: any, filename: any) {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr?.[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const isValidUrl = (url: string) => {
  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  if (url?.match(regex)) {
    return true;
  }
  return false;
};
export const filterPayload = async (data: any, type: any, isEdit: boolean) => {
  let urls = getUrls(data?.textField);
  urls = urls.filter((data: any) => !isValidUrl(data));

  for (const url of urls) {
    const file = dataURLtoFile(url, 'img.png');
    const key = `${uuid()}.${file.type.split('/')[1]}`;
    const signedUrl = await getSignedUrl(file, key);
    await uploadFile(file, signedUrl.data.result.url);
    data.textField = data?.textField.replace(url, signedUrl.data.result.cloudFrontBaseUrl + '/' + key);
  }

  switch (type.type) {
    case 'Text Only': {
      const { video, questions, quote, quoteAuthor, srt, ...filteredData } = data;
      return filteredData;
    }
    case 'Video': {
      let srt = data.srt;
      if (!srt) {
        const { questions, quote, quoteAuthor, srt, ...filteredData } = data;
        return filteredData;
      } else {
        const { questions, quote, quoteAuthor, ...filteredData } = data;
        return filteredData;
      }
    }
    case 'Quiz': {
      const { video, quote, quoteAuthor, srt, ...filteredData } = data;
      return filteredData;
    }
    case 'Quote': {
      const { video, questions, srt, ...filteredData } = data;
      return filteredData;
    }
    default: {
      const { video, questions, quote, quoteAuthor, srt, ...filteredData } = data;
      return filteredData;
    }
  }
};
export const STATUS_TYPE_CONTENT = {
  Published: 2,
  Draft: 3,
};

export function convertToNestedOptions(arr: ICategory[]) {
  const parentData = [];
  const nestedData: { [key: string]: ICategory[] } = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].parent_id == null) {
      parentData.push({ ...arr[i] });
    } else {
      const parentId = arr[i].parent_id ?? '';
      if (!nestedData[parentId]) {
        nestedData[parentId] = [];
      }
      nestedData[parentId].push({ ...arr[i] });
    }
  }
  return { parentData, nestedData };
}

export enum NestedSelectionType {
  SINGLE_SELECT = 'single select',
  MULTI_SELECT = 'multi select',
}
export const SlectedKeyWordsID = (data: TODO_ANY, bool: boolean) => {
  let Arr = [],
    dataA = [];
  for (let key of data) {
    Arr.push(key?.keyword?.id);
    dataA.push({
      id: key?.keyword?.id,
      name: key?.keyword?.name,
    });
  }
  return bool ? Arr : dataA;
};

export const downloadXLSX = (excelData: any) => {
  if (excelData) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'Content_' + new Date().getTime();
    const wb = XLSX.utils.book_new();
    Object.keys(excelData).forEach((key) => {
      let filteredData = excelData[key].map((el: any) => {
        let div = document.createElement('div');
        div.innerHTML = el?.textField;
        delete el['publishTime'];
        return {
          ...el,
          textField: div.innerText,
        };
      });
      let sheets = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(sheets, [
        ['Title', 'Category', 'Content Type', 'Text Field', 'Status', 'Parent Category'],
      ]);
      XLSX.utils.sheet_add_json(sheets, filteredData, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(wb, sheets, key.toUpperCase());
    });
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
};

export const convertToKey = (label?: string) => {
  return label?.split(' ').join('_').toLocaleLowerCase();
};

export function isSubtitleFile(fileName: any) {
  const subtitleExtensions = ['.srt', '.vtt', '.sub' /* Add more extensions if needed */];
  const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

  return subtitleExtensions.includes('.' + fileExtension.toLowerCase());
}

function isValidUrlString(string: any) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}
export const removeExtension = (str: any) => {
  if (!isValidUrlString(str)) {
    return str;
  } else {
    str = new URL(str).pathname;
    str = str.slice(1);
    str = str.replace(/\.[^/.]+$/, '');

    return str;
  }
};

export const remainingLanguageIdsLeftForItem = (itemInDifferentLangsId: any, langExistsId: any) => {
  return langExistsId.filter((x: number) => !itemInDifferentLangsId.includes(x));
};

export const upperCaseFirstLetter = (str = '') => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};
