import ReactSelect from 'react-select';
import cx from 'classnames';
import { TODO_ANY } from 'typings/common';
import { useTranslation } from 'context/translation';

type select = {
  value: string | null | object;
  options: TODO_ANY;
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
};

function Select({ value, options, placeholder, onChange, fullWidth, className }: select) {
  const { t }: any = useTranslation();

  const handleChange = (value: TODO_ANY) => {
    onChange(value);
  };
  return (
    <ReactSelect
      // defaultValue={value}
      className={cx(
        {
          'w-48': !fullWidth,
          'w-full': fullWidth,
        },
        `${className}`,
      )}
      value={value}
      placeholder={t(placeholder) || placeholder}
      onChange={handleChange}
      options={options}></ReactSelect>
  );
}

export default Select;
