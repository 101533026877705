import { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination, Input, TopLayer, FunctionLayer, Spinner, Filter } from 'components';
import { TODO_ANY } from 'typings/common';
import { deleteAPIData, getAPIData, putAPIData } from 'utils/api';
import { getTableData } from './categoryDto';
import MyTable from 'components/DraggableTable';
import { ToastTypes, useToast } from 'context/toast';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import MultiSelectContainerNested from 'components/MultiSelectDropdown/NestedSelect';
import { convertToNestedOptions, ICategory, NestedSelectionType } from 'pages/Content/utils';
import { useTranslation } from 'context/translation';
function CatagoriesList() {
  const { authUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const [data, setData]: any = useState([]);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { callToast } = useToast();
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [isDragg, setIsDragg] = useState<any>(false);
  const [errPage, setErrPage] = useState<any>(false);
  const [urlData, setUrlData] = useState<any>({
    urlcategoryFilter: searchParams.get('categoryFilter') ?? '',
  });
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, {
    tab: undefined,
    startDate: undefined,
    endDate: undefined,
    leaderFilter: undefined,
    contentType: undefined,
    sequenceFilter: undefined,
    groupFilter: undefined,
    categoryFilter: urlData.urlcategoryFilter,
    mediaFilter: undefined,
  });
  const [parentOptions, setParentOptions] = useState<any>([]);
  const [nestedOptions, setNestedOptions] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [nestedLoading, setNestedLoading] = useState<boolean>(false);
  const [filterCategoryData, setFilterCategoryData] = useState<any>(
    searchParams.get('categoryFilter')?.split(',') ?? [],
  );
  const [categoryTitle, setCategoryTitle] = useState<any>('');
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [customCategorySelected, setCustomCategorySelected] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState(0);
  const { t }: any = useTranslation();

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getCategories();
    }
  }, [paginationParams]);

  useEffect(() => {
    let count = 0;
    if (filterCategoryData?.length !== 0) {
      count += 1;
    }
    setFilterCount(count);
  }, [filterCategoryData]);

  const getCategories = async () => {
    setLoading(true);
    let catIds = null;
    if (filterCategoryData.length) {
      catIds = [...new Set(filterCategoryData)].join(',');
    }
    try {
      let res = await getAPIData({
        url: 'categories',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          categoryIds: catIds,
          languageId : selectedLanguage?.id
        },
      });
      setData(getTableData(res?.data?.result));
      setTotal(res?.data?.total);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    setLoading(false);
  };

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const columns = [
    {
      title: t('category_name') || 'Category Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: t('parent_company') || 'Parent Category',
      dataIndex: 'parentName',
      key: 'parentName',
      width: 250,
    },
    {
      title: t('visible_start_date') || 'Visible Start Date',
      dataIndex: 'visibleStartDate',
      key: 'visibleStartDate',
      width: 250,
    },
    {
      title: t('visible_end_date') || 'Visible End Date',
      dataIndex: 'visibleEndDate',
      key: 'visibleEndDate',
      width: 250,
    },
  ];

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_category') || 'Deleting category...');
      await deleteAPIData({ url: `categories/${id}` });
      callToast(ToastTypes.SUCCESS, t('delete_category') || 'Category deleted successfully');
      getCategories();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      setLoading(false);
    }
  };

  const handleAddCategories = useCallback(() => {
    navigate('/categories/add');
  }, [navigate]);

  const handleEditCategories = useCallback(
    (id: string | number) => {
      navigate(`/categories/edit/${id}`);
    },
    [navigate],
  );

  const handleInputChange = (val: string) => {
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const getAfterPosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex > oldIndex ? newIndex : newIndex - 1;
    return items[index]?.id;
  };
  const getBeforePosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex < oldIndex ? newIndex : newIndex + 1;
    return items[index]?.id;
  };
  var dataTable = data;
  const handleDragChange = async (items: TODO_ANY, oldIndex: TODO_ANY, newIndex: TODO_ANY) => {
    if (oldIndex === newIndex) {
      return;
    }
    let data = {};

    if (newIndex + 1 === dataTable.length) {
      data = {
        afterId: getAfterPosition(dataTable, newIndex, oldIndex),
      };
    } else {
      data = {
        beforeId: getBeforePosition(dataTable, newIndex, oldIndex),
      };
    }
    setIsDragg(true);
    try {
      await putAPIData({
        url: `categories/position/${dataTable[oldIndex].id}`,
        data: data,
      });
      await getCategories();
      setIsDragg(false);
    } catch (e: any) {
      console.error(e);
      setIsDragg(false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const onNextPage = () => {
    handlePaginationChange(paginationParams?.page - 1);
  };
  const onPrevPage = () => {
    handlePaginationChange(paginationParams?.page + 1);
  };
  useEffect(() => {
    getNestedCategories();
  }, []);
  const getNestedCategories = async () => {
    try {
      setNestedLoading(true);
      let res = await getAPIData({
        url: 'categories/nested',
        data: { page: 1, searchText: '', limit: 1000,languageId : selectedLanguage?.id },
      });
      setNestedLoading(false);
      setCategoriesData(res.data.data);
      const { parentData, nestedData } = convertToNestedOptions(res.data.data);
      setParentOptions(parentData);
      setNestedOptions(nestedData);
    } catch (error) {
      setNestedLoading(false);
      console.error(error);
    }
  };

  const selectAll = () => {
    const values = categoriesData.map((el: any) => {
      return el.id;
    });
    // setValue('categories', values);
    setFilterCategoryData(values);
  };

  const deselectAll = () => {
    // setValue('categories', []);
    setFilterCategoryData([]);
  };
  const addToSelectedCategories = (category: ICategory) => {
    let values = category.company_id ? [] : [...filterCategoryData];
    setCustomCategorySelected(!!category.company_id);
    if (category.company_id && category.company_name) {
      setSelectedCompany({ id: category.company_id, name: category.company_name });
    }
    let updated = [];
    for (let id of values) {
      const foundCat = categoriesData.find((c: ICategory) => c.id === id);
      if (!foundCat.company_id) {
        updated.push(id);
      }
    }
    updated.push(category.id);
    // setValue('categories', updated);
    setFilterCategoryData(updated);
    // selectParentCategory(category);
  };
  const removeFromSelectedCategories = (category: ICategory) => {
    setCustomCategorySelected(!category.company_id);
    let values = [...filterCategoryData];
    values = values.filter((el: string) => {
      return el !== category.id;
    });
    // setValue('categories', values);
    setFilterCategoryData(values);
  };

  return (
    <section className="Category">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.name}
          type="category"
        />
      )}
      <TopLayer
        titleText={t('categories') || 'Categories'}
        buttonText={t('add_category') || 'Add Category'}
        onButtonClicked={handleAddCategories}
        disableBtn={loading && !isDragg}
      />
      <FunctionLayer
        leftPortion={<></>}
        rightPortion={
          <>
            <div className="flex items-center">
              <Filter
                count={filterCount}
                width="550px"
                children={
                  <div className="px-5 py-2">
                    <div className="mb-[20px]">
                      <div className="mb-2 font-bold text-gray-text">{t('categories') || 'Categories'}</div>
                      <div className="flex flex-col">
                        {parentOptions && (
                          <MultiSelectContainerNested
                            type={NestedSelectionType.MULTI_SELECT}
                            loading={nestedLoading}
                            parentOptions={parentOptions}
                            selectedOptions={filterCategoryData}
                            selectAll={selectAll}
                            addToSelectedCategories={addToSelectedCategories}
                            removeFromSelectedCategories={removeFromSelectedCategories}
                            allOptions={categoriesData}
                            deselectAll={deselectAll}
                            selectChild={true}
                            nestedOptions={nestedOptions}></MultiSelectContainerNested>
                        )}
                      </div>
                    </div>
                  </div>
                }
                handleReset={() => {
                  setCategoryTitle('');
                  setFilterCategoryData([]);
                }}
                handleApply={() => {
                  setPaginationParams((data: any) => {
                    return { ...data, page: 1 };
                  });
                  setUrlData({
                    urlcategoryFilter: filterCategoryData.join(','),
                  });
                  setCategoryTitle('');
                  getCategories();
                }}
              />
            </div>
            <Input
              value={inputValue}
              onClick={handleClick}
              onChange={handleInputChange}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              placeholder={t('search_categories') || 'Search Categories'}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams?.limit)}
              pageSize={total}
              onNext={onNextPage}
              onPrev={onPrevPage}
              type="category"
              searchedValue={paginationParams.search}
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading && !isDragg ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <MyTable
            dataSource={data}
            showDrag={isDragg}
            columns={columns}
            sortDetails={''}
            tableActions={[
              {
                iconClassName: 'fas fa-edit',
                type: 'edit',
                info: t('edit') || 'Edit',
                onClick: (el: TODO_ANY) => handleEditCategories(el.id),
              },
              {
                iconClassName: 'fas fa-trash-alt',
                type: 'delete',
                info: t('delete') || 'Delete',
                onClick: (el: TODO_ANY) => {
                  setShowDel(true);
                  setDeleteId(el);
                },
              },
            ]}
            handleDragChange={handleDragChange}
          />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default CatagoriesList;
