import moment from 'moment';
import { addOffSet } from 'utils/ConvertDate';

export interface CATEGORY_TABLE_DATA_TYPE {
  parent: any;
  name: string;
  visibleStartDate: string;
  visibleEndDate: string;
  id: string;
  rowPosition: string;
}
export const getTableData = (data: CATEGORY_TABLE_DATA_TYPE[]) => {
  let tableData = [];
  for (let el of data) {
    tableData.push({
      name: el.name,
      parentName: el?.parent?.name,
      visibleStartDate: el.visibleStartDate ? moment(addOffSet(el.visibleStartDate)).format('LL') : '',
      visibleEndDate: el.visibleEndDate ? moment(addOffSet(el.visibleEndDate)).format('LL') : '',
      id: el.id,
      rowPosition: el.rowPosition,
    });
  }
  return tableData;
};
