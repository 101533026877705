import classNames from 'classnames';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
import { TODO_ANY } from 'typings/common';

export const TopLayer = ({
  onButtonClicked,
  titleText,
  buttonText = 'add text field',
  className = '',
  onCancelClicked,
  disableBtn = false,
  langCode,
}: {
  titleText?: string;
  buttonText?: string;
  onButtonClicked?: () => void;
  className?: string;
  onCancelClicked?: () => void;
  disableBtn?: boolean;
  langCode?: any;
}) => {
  const { t }: any = useTranslation();
  return (
    <div className={classNames('flex items-center gap-5', className)}>
      <p className="text-2xl font-bold">{titleText}</p>
      {onButtonClicked && buttonText && (
        <button
          className={classNames(
            'min-w-[70px] rounded-md px-2.5 py-1.5 text-sm text-white',
            disableBtn ? 'bg-gray-subject-text' : 'bg-green-main',
          )}
          onClick={onButtonClicked}
          disabled={disableBtn}>
          {langCode ? t(convertToKey(buttonText), langCode) || buttonText : t(convertToKey(buttonText)) || buttonText}
        </button>
      )}
      {onCancelClicked && (
        <button
          className={classNames(
            'min-w-[70px] rounded-md border-1 border-green-main bg-transparent px-2.5 py-1.5 text-sm',
          )}
          onClick={onCancelClicked}>
          {langCode ? t('cancel', langCode) || 'Cancel' : t('cancel') || 'Cancel'}
        </button>
      )}
    </div>
  );
};

export const TabsLayer = ({
  tabs,
}: {
  tabs: {
    label: string;
    num: number;
    tabType?: string;
    action?: any;
    value?: any;
    default?: boolean;
  }[];
}) => {
  return (
    <div className="mb-5 mt-10 flex gap-2 text-gray-text">
      {tabs?.map((item) => (
        <div className="cursor-pointer font-medium" key={item.label}>
          {item.tabType ? (
            <label className="customRadio flex cursor-pointer text-[14px] text-gray-text">
              <input name="tab" value={item.value} type={item?.tabType} onChange={item.action} checked={item.default} />
              <span>
                {item?.label} ({item?.num})
              </span>
            </label>
          ) : (
            <>
              {item.label}
              <span className="ml-1 font-light">({item.num})</span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export const FunctionLayer = ({
  leftPortion,
  rightPortion,
}: {
  leftPortion?: JSX.Element;
  rightPortion: JSX.Element;
}) => {
  return (
    <div className="mb-10 mt-5 flex items-center justify-between">
      <div className="flex items-center gap-5">{leftPortion}</div>
      <div className="flex items-center gap-5">{rightPortion}</div>
    </div>
  );
};

export const TableActions = ({
  icons,
}: {
  icons: {
    iconClassName?: string;
    iconChild?: JSX.Element;
    type?: string;
    handleIconClick?: TODO_ANY;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
  }[];
}) => {
  return (
    <div className="flex items-center gap-2.5">
      {icons.map((each, index) => {
        return (
          <button
            key={index}
            className={`flex h-7 w-7 items-center justify-center rounded text-white ${
              each?.disabled ? 'bg-gray-main disabled:opacity-100' : 'bg-blue-main'
            }`}
            disabled={each?.disabled}
            onClick={each.onClick}>
            <i className={each.iconClassName}>{each.iconChild}</i>
          </button>
        );
      })}
    </div>
  );
};
