export const surveyFormDefaultValues: {
  title: string;
  companyId: string;
  statusId: string;
  publishTime: any;
  questions: any[];
  statusData?: any;
  isTemplate: boolean;
} = {
  title: '',
  companyId: '',
  statusId: '',
  publishTime: null,
  questions: [
    {
      question: '',
      typeId: '1',
      surveyOption: [],
    },
  ],
  isTemplate: false,
};
export const options = {
  root: null,
  rootMargin: '-30px',
  threshold: 1,
};

export const QUESTION_TYPES = {
  Single: '1',
  MultipleChoice: '2',
};

export const STATUS_TYPE = {
  Published: 'Published',
  Draft: 'Draft',
  Review : 'Review'
};

export const TAB_TYPE = {
  All: 'All',
  Draft: 'Draft',
  Published: 'Published',
  Review :'Review'
};
