import videoImg from '../../../assets/images/videoImg.png';
import SRT from '../../../assets/images/srt.svg';
import { ToastTypes, useToast } from 'context/toast';
import { useEffect, useState } from 'react';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import Thumbnail from '../../../assets/images/video_thumbnail.jpeg';
import uuid from 'react-uuid';
import { Spinner } from 'components/common';
import { UploadPopUp } from 'pages/Media/ImageUpload/imageUpload';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
// import UploadPopUp from 'pages/Media/ImageUpload/imageUpload';
const VideoContent = ({
  setValue,
  getValues,
  controlName,
  isEdit = false,
  block = () => {},
  videoId,
  setVideoId = () => {},
  errors,
  pageName,
  langCode,
}: {
  setValue: Function;
  getValues: Function;
  controlName: any;
  isEdit?: boolean;
  videoId?: any;
  setVideoId?: Function;
  block?: Function;
  errors?: any;
  pageName: string;
  langCode?: any;
}) => {
  const { callToast } = useToast();
  const [video, setVideo] = useState<any>('');
  const [srtFile, setSrt] = useState<any>('');
  const [videoFileName, setVideoFileName] = useState<any>('');
  const [videofileSize, setVideoFileSize] = useState<any>(0);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [srtFileName, setSrtFileName] = useState<any>('');
  const [srtFileSize, setSrtFileSize] = useState<any>(0);

  const [srtUploading, setSrtUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);

  const [videoThumbnail, setVideoThumbnail] = useState('');
  const [uid, setUid] = useState(uuid);
  useEffect(() => {
    // if (isEdit) {
    // console.log(getValues()[controlName?.video], 'lkasdf')
    setVideo(getValues()[controlName?.video] ? `${getValues()[controlName?.video]?.thumbnail}` : '');
    setVideoFileName(getValues()[controlName?.video]?.fileName ?? '');
    // const videoSize = getValues()[controlName?.video]?.fileSize
    //   ? Math.floor(+getValues()[controlName?.video]?.fileSize / (1024 * 1024))
    //   : 0;
    // console.log(videoSize, 'Size')
    setVideoFileSize(getValues()[controlName?.video]?.fileSize ?? 0);

    // setSrt(getValues()[controlName?.srt] ? `${getValues()[controlName?.srt]}` : '');
    // setSrtFileName(getValues()[controlName?.srt]?.fileName ?? '');
    // const srtSize = getValues()[controlName?.srt]?.fileSize
    //   ? Math.floor(+getValues()[controlName?.srt]?.fileSize / 1024)
    //   : 0;
    // setSrtFileSize(srtSize);
    setVideoThumbnail(
      getValues()[controlName?.video]?.thumbnail ? getValues()[controlName?.video]?.thumbnail : Thumbnail,
    );
    setVideoThumbnail(Thumbnail);

    setSrt(getValues()[controlName?.srt] ? `${getValues()[controlName?.srt]}` : '');
    setSrtFileName(getValues()[controlName?.srt]?.fileName ?? '');
    const srtSize = getValues()[controlName?.srt]?.fileSize
      ? Math.floor(+getValues()[controlName?.srt]?.fileSize / 1024)
      : 0;
    setSrtFileSize(srtSize);
    setVideoThumbnail(
      getValues()[controlName?.video]?.thumbnail ? getValues()[controlName?.video]?.thumbnail : Thumbnail,
    );
    setVideoThumbnail(Thumbnail);

    // }
  }, []);
  const removeVideo = () => {
    setValue(controlName?.video ?? 'video', null);
    setValue(controlName?.srt ?? 'srt', null);
    setVideo('');
    setVideoId('');
    setVideoFileName('');
    setVideoFileSize(0);
    // removeSrt();
  };
  const { t }: any = useTranslation();

  return (
    <div className="border-grey rounded-b-[5px] border">
      <UploadPopUp
        show={showPopUp}
        srtFileName={setSrtFileName}
        srtFileSize={setSrtFileSize}
        setShow={setShowPopUp}
        fileTitle={setVideoFileName}
        fileSize={setVideoFileSize}
        setImageId={setVideoId}
        url={video}
        fileUrl={setVideo}
        setValue={setValue}
        controlName={[controlName?.video ?? 'video', controlName?.srt ?? 'srt']}
        fileType={'video'}
        langCode={langCode}
      />
      <div className="border-grey border-b-1 px-[26px] pt-[32px] pb-[30px]">
        <div className="w-full flex-shrink-0">
          <div className="flex items-center">
            <label className="mr-[26px] block min-w-[80px] text-lg font-bold text-gray-text">
              {langCode ? t('video_file', langCode) || 'Video File' : t('video_file') || 'Video File'}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <span className="ml-[10px] flex min-w-[118px] cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
              <span className="relative text-[12px] text-white">
                {langCode ? t('upload_video', langCode) || 'Upload Video' : t('upload_video') || 'Upload Video'}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPopUp(true);
                  }}
                  id="videoPicker"
                  className="absolute top-0 right-0 h-full w-full opacity-0"></button>
                {/* <input
                  id="videoPicker"
                  type="file"
                  className="absolute top-0 right-0 h-full w-full opacity-0"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => uploadVideo(e)}
                /> */}
              </span>
            </span>
            {videoUploading && <Spinner />}
          </div>
        </div>
        {errors?.[controlName?.video]?.url && !videoFileName && (
          <p className="mt-2 text-sm italic text-red-600">
            {t(convertToKey(errors?.[controlName?.video]?.url?.message), langCode) ||
              errors?.[controlName?.video]?.url?.message}
          </p>
        )}
        {/* Video  Upload section */}
        {videoFileName && (
          <div className="border-grey mt-[18px] flex items-center justify-between rounded-[5px] border p-[15px]">
            <div className="flex max-w-[80%] items-center">
              <div className="mr-[15px] h-[62px] w-[75px]">
                <img className="h-full w-full rounded-[5px]" src={video || videoThumbnail} alt="video"></img>
              </div>
              <div>
                {/* <p className="text-gray text-[14px] font-medium">
              How To Use This App{' '}
              <i
                className="fa fa-external-link ml-[5px] cursor-pointer text-[12px] text-green-main"
                aria-hidden="true"></i>
            </p> */}
                <p className="text-gray text-[14px] font-medium">
                  {t('file_name') || 'File name :'}{' '}
                  <span className="text-[14px] font-light text-gray-subject-text">{videoFileName}</span>
                </p>
                <p className="text-gray text-[14px] font-medium">
                  {' '}
                  {t('file_size') || 'File size :'}{' '}
                  <span className="text-[14px] font-light text-gray-subject-text">
                    {Math.floor(+videofileSize / (1024 * 1024))} MB
                  </span>
                </p>
              </div>
            </div>
            <span className="ml-[10px] flex min-w-[86px] cursor-pointer items-center justify-center rounded-[5px] bg-red-main  py-[5px] px-[10px]">
              <span
                className="text-[12px] text-white"
                onClick={() => {
                  removeVideo();
                }}>
                {langCode ? t('remove', langCode) || 'Remove' : t('remove') || 'Remove'}
              </span>
            </span>
          </div>
        )}

        {/* SRT File Upload section */}
      </div>
    </div>
  );
};

export default VideoContent;
