import FormGroup from 'components/Forms/GroupForm';
function AddGroup() {
  return (
    <div className="addCompany">
      <FormGroup />
    </div>
  );
}

export default AddGroup;
