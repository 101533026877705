import React, { useContext, useState } from 'react';
import { TODO_ANY } from 'typings/common';

const UserContext = React.createContext();

export default function UserProvider({ children }) {
  const [authUser, setAuthUser] = useState();
  const value = { setAuthUser: setAuthUser, authUser: authUser };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useAuth = () => useContext(UserContext);

export const LanguageListsContext = React.createContext();

export const LanguageListsProvider = ({ children }) => {
  const [langLists, setLangLists] = useState([]);
  return <LanguageListsContext.Provider value={{ langLists, setLangLists }}>{children}</LanguageListsContext.Provider>;
};

export const SelectedLangContext = React.createContext();

export const SelectedLangProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('selectedLang')) || '');
  return (
    <SelectedLangContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </SelectedLangContext.Provider>
  );
};
