import DeleteModal from 'components/DeleteModal';
import { ToastTypes, useToast } from 'context/toast';
import { useTranslation } from 'context/translation';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { deleteAPIData, getAPIData } from 'utils/api';
import { Header, Input, Pagination, Select, Spinner, Table } from '../../components';
import { TableActionButton } from '../../components/common/button';
import { FunctionLayer, TableActions, TabsLayer, TopLayer } from '../../components/common/table';
import { getTableData } from './companyDto';
function Company() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { callToast } = useToast();
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [errPage, setErrPage] = useState<any>(false);
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceOnChange = useRef(debounce(onChange, 400)).current;
  // function onChange(val: string) {
  //   setInputValue(val);
  //   setPaginationParams({
  //     page: paginationParams.page,
  //     search: val,
  //     limit: paginationParams.limit,
  //   });
  // }
  // useEffect(() => {
  //   return () => {
  //     debounceOnChange.cancel();
  //   };
  // }, [debounceOnChange]);
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getCompanies();
    }
  }, [paginationParams]);

  const getCompanies = async () => {
    setLoading(true);
    try {
      let res = await getAPIData({
        url: 'companies',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          languageId: selectedLanguage?.id,
        },
      });
      setData(getTableData(res?.data?.result));
      setTotal(res?.data?.total);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      console.error(e);
    }
    setLoading(false);
  };

  const tabs = useMemo(
    () => [
      { label: 'All', num: 1 },
      { label: 'Mine', num: 1 },
      { label: 'Published', num: 1 },
      { label: 'Draft', num: 1 },
      { label: 'Trash', num: 1 },
    ],
    [],
  );

  const handleToAddContent = useCallback(() => {
    navigate('/companies/add');
  }, [navigate]);

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('company_id') || 'Company ID',
      dataIndex: 'companyId',
      key: 'companyId',
      width: 250,
    },
    {
      title: t('company_name') || 'Company Name',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: t('admin_user') || 'Admin Username',
      dataIndex: 'adminEmail',
      key: 'adminEmail',
      width: 250,
    },
    {
      title: t('enabled') || 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 250,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: TODO_ANY) => (
        <TableActions
          icons={[
            { iconClassName: 'fas fa-edit', onClick: () => handleEditCompany(record.id) },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                setShowDel(true);
                setDeleteId(record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleEditCompany = useCallback(
    (id: string | number) => {
      navigate(`/companies/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, 'Deleting company...');
      await deleteAPIData({
        url: `companies/${id}`,
      });
      callToast(ToastTypes.SUCCESS, 'Company removed successfully');
      getCompanies();
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  const handleInputChange = (val: string) => {
    // debounceOnChange(val);
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  return (
    <section className="Companies">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.title}
          type="company"
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText={t('companies') || 'Companies'}
        buttonText={t('add_company') || 'Add Company'}
        disableBtn={loading}
      />

      {/* <TabsLayer tabs={tabs} /> */}
      <FunctionLayer
        // leftPortion={
        //   <>
        //     <Select
        //       value={dateValue}
        //       options={[
        //         { label: 'All Dates', value: '0' },
        //         { label: '1 day', value: '1' },
        //       ]}
        //       onChange={setDateValue}
        //     />

        //     <TableActionButton onClick={() => {}} title="Filter" />
        //   </>
        // }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              onClick={handleClick}
              placeholder={t('search_companies') || 'Search Companies'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="company"
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={data} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default Company;
