import React, { useContext, useEffect } from 'react';
import { useCallback, useState } from 'react';
import { Table, Pagination, Input, TopLayer, FunctionLayer, TableActions, Spinner } from 'components';
import { createDummyData } from 'utils/createDummyData';
import { useNavigate } from 'react-router-dom';
import { getAPIData, deleteAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import { ToastTypes, useToast } from 'context/toast';
import DeleteModal from 'components/DeleteModal';
import { SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { useTranslation } from 'context/translation';

const companyNamesCharLimit = 90;

function AddGroup() {
  const { authUser } = useAuth();
  const [errPage, setErrPage] = useState<any>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [groupState, setGroupState] = useState<Object>([]);
  const [total, setTotal] = useState(0);
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const [showDel, setShowDel] = useState(false);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [deleteRecord, setDeleteRecord] = useState<any>({});
  const { t }: any = useTranslation();
  const { callToast } = useToast();

  const goToAddGroups = useCallback(() => {
    navigate('/groups/add');
  }, [navigate]);

  const handleEditGroup = useCallback(
    (id: string | number) => {
      navigate(`/groups/edit/${id}`);
    },
    [navigate],
  );

  const arrToString = (arr: any) => {
    let arrString = '';
    if (!arr.length) {
      return '---';
    }
    if (arr.length === 1) {
      return arr.toString();
    }
    arrString = arr.join(', ');
    if (arrString.length > companyNamesCharLimit) {
      return arrString.slice(0, companyNamesCharLimit) + '...';
    }
    return arrString;
  };

  const groupByCompany = (groupState: any) => {
    let refinedGroup: any = [];
    groupState.forEach((data: any) => {
      let companiesList: any = [];
      data.companies.forEach((company: any) => {
        companiesList.push(company.name);
      });

      refinedGroup.push({ data, companiesList: companiesList });
    });
    return refinedGroup.map((data: any) => {
      return { name: data.data.name, companies: arrToString(data.companiesList), id: data.data.id };
    });
  };

  const getGroupsData = async () => {
    try {
      setLoading(true);
      const payload = {
        url: 'miscellaneous/group',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          limit: paginationParams.limit,
          languageId: selectedLanguage?.id,
        },
      };
      const res = await getAPIData(payload);
      setGroupState(res?.data?.result);
      groupByCompany(res?.data?.result);
      setTotal(res?.data?.total);
      setLoading(false);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      setLoading(false);
    }
  };
  const handleInputChange = (val: string) => {
    setInputValue(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };

  const deleteGroup = async (id: string) => {
    try {
      callToast(ToastTypes.INFO, t('deleting_group') || 'Deleting group...');
      await deleteAPIData({ url: `miscellaneous/group/${id}` });
      getGroupsData();
      callToast(ToastTypes.SUCCESS, t('group_removed') || 'Group removed successfully');
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setShowDel(false);
    setDeleteRecord({});
  };

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getGroupsData();
    }
  }, [paginationParams]);

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('name') || 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: t('companies') || 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      width: 600,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: TODO_ANY) => (
        <TableActions
          icons={[
            {
              iconClassName: 'fas fa-edit',
              onClick: () => {
                handleEditGroup(record.id);
              },
            },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                setShowDel(true);
                setDeleteRecord(record);
                // deleteGroup(record?.id);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <section className="Category">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => deleteGroup(deleteRecord?.id)}
          deleteTitle={t(deleteRecord?.name) || deleteRecord?.name}
          type="group"
        />
      )}
      <TopLayer
        titleText={t('groups') || 'Groups'}
        buttonText={t('add_group') || 'Add Group'}
        onButtonClicked={goToAddGroups}
        disableBtn={loading}
      />
      <FunctionLayer
        leftPortion={<></>}
        rightPortion={
          <>
            <Input
              value={inputValue}
              onClick={handleClick}
              onChange={handleInputChange}
              placeholder={t('search_groups') || 'Search Groups'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="group"
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <Table dataSource={groupByCompany(groupState)} columns={columns} />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default AddGroup;
