import { Chip, TextField } from '@mui/material';
import { SmallLoader, Spinner, TitleBlock, TopLayer, Switch } from 'components';
import { ToastTypes, useToast } from 'context/toast';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaForInterrupters } from 'validations/contentFormValidation';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { options } from 'constants/survey.constant';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { debounce } from 'lodash';
import { addOffSet } from 'utils/ConvertDate';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import { convertToKey } from 'pages/Content/utils';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';
const initialDefaultValues = {
  contentId: '',
};

function Interrupters() {
  const { id } = useParams();
  const { authUser } = useAuth();
  const inputRef = useRef<any>(null);
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(
    () =>
      isEditPage
        ? t('edit_interrupters', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Interrupters'
        : t('add_interrupters', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Interrupters',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const titleButtonText = useMemo(
    () =>
      isEditPage
        ? t('save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const [title, setTitle] = useState('');
  const [selectedInterruperType, setSelectedInterruperType] = useState('1');
  const [selectedContentType, setSelectedContentType] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [searchContent, setSearchContent] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [interrupterData, setInterrupterData] = useState<any>([]);
  const [interrupterDataRaw, setInterrupterDataRaw] = useState<any>([]);
  const [loader, setLoader] = useState({
    interrupterInitialLoader: false,
    interrupterInfiniteLoader: false,
  });
  const [content, setContent] = useState<any>([]);
  const [prevContent, setPrevContent] = useState('');
  const [defaultValues, setDefaultValues] = useState<any>(initialDefaultValues);
  const [prevContentVal, setPrevContentVal] = useState('');
  const [interrupterState, setInterrupterState] = useState<any>([]);
  const { callToast } = useToast();

  const handleCategoriesList = useCallback(() => {
    navigate('/interrupters');
  }, [navigate]);
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      if (isEditPage) {
        getInterrupters();
      }
      getSequences();
    }
  }, [currentSelected?.id || selectedLanguage?.id]);
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaForInterrupters),
  });
  const containerRef = useRef(null);

  function onChange(val: string) {
    setPaginationParams({
      ...paginationParams,
      page: 1,
      search: val,
    });
    setTotal(-1);
  }

  const handleSearchChange = (val: string) => {
    debounceOnChange(val);
    setSearchContent(val);
  };

  const debounceOnChange = useRef(debounce(onChange, 400)).current;
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    const ref = containerRef.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef, options, interrupterData]);

  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (interrupterData.length > 0) {
      if (entry.isIntersecting && interrupterData.length < total) {
        setPaginationParams({
          page: paginationParams.page + 1,
          search: paginationParams.search,
        });
      }
    }
  };
  useEffect(() => {
    inputRef?.current?.focus();
  }, [interrupterData]);

  const getFormData = (data: any) => {
    let temp = convertToMidNight(new Date(new Date(data.date).setHours(0, 0, 0, 0)));
    data = {
      ...data,
      date: temp,
    };
    createInterrupter(data);
  };
  const convertToMidNight = (data: TODO_ANY) => {
    if (data) {
      return moment(data).utcOffset(0, true).format();
    }
  };
  const getInterrupters = async () => {
    setLoading(true);
    try {
      let res = await getAPIData({
        url: `interrupter/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setValue('contentId', res?.data?.contentId);
      setInterrupterState(res?.data);
      setValue('isTeamPlay', res?.data?.isTeamPlay || false);
      if (res?.data?.date) {
        setValue('date', addOffSet(res?.data?.date));
      }
      setTitle(res?.data?.content?.name);
      setSelectValue(res?.data?.contentId);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const createInterrupter = async (data: TODO_ANY) => {
    try {
      setLoading(true);
      if (isEditPage) {
        await putAPIData({
          url: `interrupter/${id}`,
          data: data,
        });
      } else {
        await postAPIData({
          url: 'interrupter',
          data: data,
        });
      }
      handleCategoriesList();
      callToast(
        ToastTypes.SUCCESS,
        `${t('interrupter', currentSelected?.langCode || selectedLanguage?.langCode) || 'Interrupter'} ${
          isEditPage
            ? t('edited', currentSelected?.langCode || selectedLanguage?.langCode) || 'edited'
            : t('created', currentSelected?.langCode || selectedLanguage?.langCode) || 'created'
        } ${t('success', currentSelected?.langCode || selectedLanguage?.langCode) || 'successfully!'}`,
      );
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getSequences();
  }, [paginationParams, currentSelected?.id || selectedLanguage?.id]);
  const getSequences = async () => {
    setContentLoading(true);
    if (paginationParams.page === 1) {
      setLoader({ ...loader, interrupterInitialLoader: true });
    } else {
      setLoader({ ...loader, interrupterInfiniteLoader: true });
    }
    try {
      let res = await getAPIData({
        url: 'content',
        data: {
          page: paginationParams.page,
          searchText: paginationParams.search,
          limit: 12,
          isTitleSearch: true,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setInterrupterData(res?.data?.result);
      if (paginationParams.page === 1) {
        setInterrupterDataRaw(res?.data?.result);
        const data: any = getAllSequences(res?.data?.result);
        setContent(data);
      } else if (paginationParams.page > 1) {
        setInterrupterDataRaw([...interrupterDataRaw, ...res?.data?.result]);
        const data: any = getAllSequences([...interrupterDataRaw, ...res?.data?.result]);
        setContent(data);
      }
      setLoader({ ...loader, interrupterInfiniteLoader: false, interrupterInitialLoader: false });
      // setContent(getAllSequences(res?.data?.result));
      setValue('contentId', selectValue);
      setTotal(res?.data?.total);
    } catch (e) {
      setLoader({ ...loader, interrupterInfiniteLoader: false, interrupterInitialLoader: false });
      console.error(e);
    }
    setContentLoading(false);
  };
  useEffect(() => {
    const data: any = getAllSequences([...interrupterData]);
    setContent([...data]);
  }, [paginationParams.search]);
  const getAllSequences = (data: TODO_ANY) => {
    let arr = [];
    for (let key of data) {
      arr.push({ id: key?.id, name: key?.name });
    }
    return arr;
  };
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  return (
    <section>
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(getFormData)}
          buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
          titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
          className="mb-8"
          onCancelClicked={handleCategoriesList}
          disableBtn={loading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(initialDefaultValues);
            setTitle('');
            setSelectValue('');
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
          }}
          deleteTitle={'All changes will be lost'}
          type=""
          confirmation={true}
          buttonTitle={'Continue'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <TitleBlock
              title={
                t('interrupter_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Interrupter Fields'
              }>
              <div className="border-b-1 px-5 py-7">
                <p className="mb-2 text-[18px] font-bold text-gray-text">
                  {t('interrupt_date', currentSelected?.langCode || selectedLanguage?.langCode) || 'Interrupter Date'}
                  <span style={{ color: 'red' }}>&nbsp;*</span>
                </p>
                <Controller
                  name="date"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          orientation="portrait"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                        />
                      </LocalizationProvider>
                    );
                  }}
                />
                {errors?.date && (
                  <p className="mt-2 text-sm italic text-red-600">{errors?.date?.message?.toString()}</p>
                )}
              </div>
              <div className="border-b-1 border-slate-100 p-[22px]">
                <Switch
                  control={control}
                  register={register}
                  name="isTeamPlay"
                  checked={interrupterData?.isTeamPlay || false}
                  label="Is Manager Team Play"
                  description="If toggled on, this daily tip will be sticky until user opens the app."
                />
              </div>
              <div className="border-b-1 border-slate-100 p-[22px]">
                <div className="mb-[17px]">
                  <h2 className="text-[18px] font-bold text-gray-text">
                    {t('content_search', currentSelected?.langCode || selectedLanguage?.langCode) || 'Content Search'}
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </h2>
                  <p className="text-[14px] font-light text-gray-subject-text">
                    {t(
                      convertToKey('Search for a content that will be interrupting the sequence'),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ) || 'Search for a content that will be interrupting the sequence.'}
                  </p>
                </div>
                <div>
                  <div className="placeholder-text-grey-text flex w-full items-center gap-1 rounded-t-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none">
                    {title && (
                      <Chip
                        label={t(title) || title}
                        onDelete={() => {
                          setTitle('');
                          setSelectValue('');
                          setValue('contentId', '');
                        }}
                      />
                    )}
                    <input
                      autoFocus
                      ref={inputRef}
                      className=" placeholder-text-grey-text w-full outline-none disabled:bg-white"
                      value={searchContent}
                      onChange={(e: any) => {
                        handleSearchChange(e.target.value);
                      }}
                      placeholder={t('search', currentSelected?.langCode || selectedLanguage?.langCode) || 'Search...'}
                      disabled={!content?.length && !paginationParams.search}
                    />
                  </div>
                  <div className="mt-[-2px] rounded-b-md border-l-1 border-r-1 border-b-1 p-5">
                    <div className="scrollbarCustom mt-[-2px] max-h-[434px] overflow-y-auto">
                      {loader.interrupterInitialLoader ? (
                        <SmallLoader langCode={currentSelected?.langCode || selectedLanguage?.langCode} />
                      ) : (
                        <div>
                          {content?.length ? (
                            <div>
                              {content.map((data: { id: string; name: string }) => {
                                setValue('contentId', selectValue);
                                return (
                                  <section className="flex items-center">
                                    <label className="customRadio flex cursor-pointer text-[14px] text-gray-text">
                                      <Controller
                                        name={`contentId`}
                                        control={control}
                                        render={({ field, ...props }) => {
                                          return (
                                            <input
                                              type="radio"
                                              value={data?.id}
                                              onChange={(a: any) => {
                                                field.onChange(data?.id);
                                                setSelectValue(data?.id);
                                                setTitle(data?.name);
                                                setSearchContent('');
                                                if (paginationParams.search) {
                                                  setPaginationParams({
                                                    page: 1,
                                                    search: '',
                                                  });
                                                }
                                              }}
                                              checked={data?.id === selectValue}
                                              name="contentId"
                                            />
                                          );
                                        }}
                                      />
                                      <span className="pl-3">{data?.name}</span>
                                    </label>
                                  </section>
                                );
                              })}
                              <div style={{ marginBottom: '10px' }} ref={containerRef}></div>
                              {loader.interrupterInfiniteLoader && (
                                <SmallLoader langCode={currentSelected?.langCode || selectedLanguage?.langCode} />
                              )}
                            </div>
                          ) : (
                            <>
                              {t('no_data', currentSelected?.langCode || selectedLanguage?.langCode) || 'No data found'}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors?.contentId && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {t(
                      convertToKey(errors?.contentId?.message?.toString()),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ) || errors?.contentId?.message?.toString()}
                  </p>
                )}
              </div>
            </TitleBlock>
          </div>
        </div>
      )}
    </section>
  );
}

export default Interrupters;
