import { InputBoxType } from 'enums/companyForm.enum';

export const addCompanyInput = (t: any, lang: any) => {
  return [
    {
      type: InputBoxType.Input,
      name: 'companyId',
      id: 'companyId',
      placeholder: '',
      label: t('company_id', lang) || 'Company ID',
      required: true,
      btnLabel: '',
    },
    {
      type: InputBoxType.CustomTerms,
      name: 'customTerms',
      id: 'customTerms',
      placeholder: '',
      label: '',
      required: true,
      btnLabel: '',
    },

    {
      type: InputBoxType.Input,
      name: 'd&iStatementTitle',
      id: 'd&iStatementTitle',
      placeholder: '',
      label: t('d&i_statement_title', lang) || 'D&I Statement Title',
      required: true,
      btnLabel: '',
    },
  ];
};

export const titleInput = (t: any, lang: any) => {
  return {
    type: InputBoxType.Input,
    name: 'title',
    id: 'title',
    placeholder: '',
    label: t('company_name', lang) || 'Title',
    required: true,
    btnLabel: '',
  };
};
export const adminEmailInput = (t: any, lang: any) => {
  return {
    type: InputBoxType.Input,
    name: 'adminEmailInput',
    id: 'AdminEmailInput',
    placeholder: '',
    label: t('admin_username', lang) || 'Admin Username',
    required: true,
    btnLabel: '',
  };
};

export const tempPasswordInput = (t: any, lang: any) => {
  return {
    type: InputBoxType.Input,
    name: 'tempPasswordInput',
    id: 'TempPasswordInput',
    placeholder: '',
    label: t('temporary_password', lang) || 'Temporary Password',
    required: true,
    btnLabel: '',
  };
};

export const dAndIStatementInput = (t: any, lang: any) => {
  return {
    type: InputBoxType.Paragraph,
    name: 'd&iStatement',
    id: 'd&iStatement',
    placeholder: '',
    label: t('d&i_statement', lang) || 'D&I Statement',
    required: true,
    btnLabel: '',
  };
};

export const defaultValues = {
  title: '',
  companyId: '',
  customTermCompany: [],
  'd&iStatementTitle': '',
  'd&iStatement': '',
  logoUrl: '',
  adminEmailInput: '',
  tempPasswordInput: '',
  enabled: true,
};
