import { useMemo } from 'react';
import { TODO_ANY } from 'typings/common';
import { ReactComponent as AlignVertical } from './assets/align-vertically.svg';
import { ReactComponent as CalendarEdit } from './assets/calendar-edit.svg';
import { ReactComponent as DocumentText } from './assets/document-text.svg';
import { ReactComponent as Eye } from './assets/eye.svg';
import { ReactComponent as Great } from './assets/great.svg';
import { ReactComponent as Image } from './assets/image.svg';
import { ReactComponent as Link } from './assets/link.svg';
import { ReactComponent as Photo } from './assets/photo.svg';
import { ReactComponent as Quote } from './assets/quote.svg';
import { ReactComponent as SliderVertical } from './assets/slider-vertical.svg';
import { ReactComponent as Star } from './assets/star.svg';
import { ReactComponent as Video } from './assets/video.svg';
import { ReactComponent as Voice } from './assets/voice.svg';

type icon = {
  type: IconType;
  className?: string | undefined;
};

enum IconType {
  ALIGN_VERTICAL = 'align-vertical',
  CALENDAR_EDIT = 'calendar-edit',
  DOCUMENT_TEXT = 'document-text',
  EYE = 'eye',
  GREAT = 'great',
  IMAGE = 'image',
  LINK = 'link',
  PHOTO = 'photo',
  QUOTE = 'quote',
  SLIDER_VERTICAL = 'slider-vertical',
  STAR = 'star',
  VIDEO = 'video',
  VOICE = 'voice',
}

const {
  ALIGN_VERTICAL,
  CALENDAR_EDIT,
  DOCUMENT_TEXT,
  EYE,
  GREAT,
  IMAGE,
  LINK,
  PHOTO,
  QUOTE,
  SLIDER_VERTICAL,
  STAR,
  VIDEO,
  VOICE,
} = IconType;

function Icon({ type, className }: icon) {
  const IconTypeMap: TODO_ANY = useMemo(
    () => ({
      [ALIGN_VERTICAL]: AlignVertical,
      [CALENDAR_EDIT]: CalendarEdit,
      [DOCUMENT_TEXT]: DocumentText,
      [EYE]: Eye,
      [GREAT]: Great,
      [IMAGE]: Image,
      [LINK]: Link,
      [PHOTO]: Photo,
      [QUOTE]: Quote,
      [SLIDER_VERTICAL]: SliderVertical,
      [STAR]: Star,
      [VIDEO]: Video,
      [VOICE]: Voice,
    }),
    [],
  );
  const Component: TODO_ANY = IconTypeMap[type];

  return <Component className={className} />;
}

Icon.Type = IconType;
export default Icon;
