import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Layout, Spinner } from './components';
import { useContext, useEffect, useState } from 'react';
import { getAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { ToastTypes, useToast } from 'context/toast';
import { useTranslation } from 'context/translation';
import { AsyncLocalStorage } from 'async_hooks';

export const AppContainer = () => {
  const { authUser, setAuthUser } = useAuth();
  const { callToast } = useToast();
  const { language, setLanguage }: any = useTranslation();
  const [loading, setLoading] = useState(true);
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const navigate = useNavigate();
  useEffect(() => {
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    const cognitoUser: TODO_ANY = userPool.getCurrentUser();
    if (!cognitoUser) {
      setLoading(false);
      navigate('/auth/login');
    }
    cognitoUser?.getSession((err: TODO_ANY, session: TODO_ANY) => {
      if (err) {
        localStorage.removeItem('userData');
        setLoading(false);
        navigate('/auth/login');
      }

      if (session === undefined) {
        localStorage.removeItem('userData');
        setLoading(false);
        navigate('/auth/login');
      }

      if (!session.isValid()) {
        localStorage.removeItem('userData');
        setLoading(false);
        navigate('/auth/login');
      }
      if (session.isValid()) {
        let data = localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData') || '');
        if (!data?.token) {
          setLoading(false);
          navigate('/auth/login');
        } else {
          getUserById(data.userId);
        }
      }
    });
    getLangLists();
  }, []);

  const getUserById = async (userId: string) => {
    try {
      let res = await getAPIData({ url: `user/${userId}` });
      setAuthUser(res?.data);
      if (window.location.pathname === '/') {
        navigate('/content');
      }
      setLoading(false);
    } catch (err: any) {
      localStorage.removeItem('userData');
      navigate('/auth/login');
      setLoading(false);
    }
  };
  const selectedData = (lists: any, e: string) => {
    for (let key of lists) {
      if (key?.langCode === e) {
        setSelectedLanguage(key);
        setLanguage(key?.langCode);
        localStorage.setItem('selectedLang', JSON.stringify(key));
        return;
      }
    }
  };
  const getLangLists = async () => {
    try {
      const resp = await getAPIData({ url: `miscellaneous/language` });
      setLangLists(resp?.data);
      localStorage?.setItem('@langLists', JSON.stringify(resp?.data));
      selectedData(resp?.data, selectedLanguage?.langCode || 'en');
    } catch (err: any) {
      callToast(ToastTypes.ERROR, err?.response?.data?.message || err?.message);
    }
  };
  if (loading) {
    return (
      <div className="bg-gray flex min-h-[100vh] items-center justify-center bg-gray-main">
        <Spinner />
      </div>
    );
  }
  if (!authUser?.id) {
    return (
      <>
        <div className="bg-gray flex min-h-[100vh] items-center justify-center bg-gray-main">
          <Outlet />
          <ToastContainer />
        </div>
      </>
    );
  }
  return (
    <Layout>
      <Outlet />
      <ToastContainer />
    </Layout>
  );
};

export default AppContainer;
