import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, ContentCell, TopLayer, Spinner, TitleBlock, Switch, Header } from 'components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { postAPIData, getAPIData, putAPIData } from 'utils/api';
import moment from 'moment';

import { TODO_ANY } from 'typings/common';
import { ToastTypes, useToast } from 'context/toast';
import { categorySchema } from 'validations/category.validation';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import dropdown from '../../../assets/images/dropdown.svg';
import {
  convertToKey,
  convertToNestedOptions,
  ICategory,
  NestedSelectionType,
  upperCaseFirstLetter,
} from 'pages/Content/utils';
import MultiSelectContainerNested from 'components/MultiSelectDropdown/NestedSelect';
import { addOffSet, convertToMidNight } from 'utils/ConvertDate';
import SingleSelectDropdown from 'components/SingleSelectDropdown/singleSelectDropdown';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';

function Categories() {
  const { t }: any = useTranslation();
  const { id } = useParams();
  const { authUser } = useAuth();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>(null);
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(
    () =>
      isEditPage
        ? t('edit_category', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Category'
        : t('add_category', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Category',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const titleButtonText = useMemo(() => (isEditPage ? 'Save' : 'Add'), [isEditPage]);
  const [data, setData] = useState<any | null>(null);
  const [currentName, setCurrentName] = useState('');
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(false);
  const { callToast } = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(categorySchema),
  });

  const [parentOptions, setParentOptions] = useState<any>([]);
  const [nestedOptions, setNestedOptions] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [nestedLoading, setNestedLoading] = useState<boolean>(false);
  const [showCompany, setShowCompany] = useState(false);
  const [categoryState, setCategoryState] = useState<any>({});
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [isModalAction, setIsModalAction] = useState(false);
  const [companyPaginationParams, setCompanyPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [companies, setCompanies] = useState<any[]>([]);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState<boolean>(false);

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getInitialData();
    }
  }, [id, currentSelected?.id || selectedLanguage?.id]);

  const getCompaniesAndSelected = async (data: TODO_ANY) => {
    if (companyLoading) {
      return;
    }
    setCompanyLoading(true);
    try {
      const res = await getAPIData({
        url: 'companies',
        data: { ...data, limit: 15, languageId: currentSelected?.id || selectedLanguage?.id },
      });
      const companies = res.data.result;
      setTotalCompanies(res.data?.total);
      setCompanies((prev: any[]) => {
        return [
          ...(companyPaginationParams.page !== 1 ? prev : []),
          ...companies.map((company: TODO_ANY) => {
            return { label: company.name, value: company.id };
          }),
        ];
      });
      setCompanyLoading(false);
    } catch (err) {
      setCompanyLoading(false);
      console.error(
        t('cannot_get_companies_data', currentSelected?.langCode || selectedLanguage?.langCode) ||
          'cannot get companies data',
        err,
      );
    }
  };

  const getCategoryById = async () => {
    try {
      let res = await getAPIData({
        url: `categories/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setCategoryState(res?.data);
      setShowCompany(!!res?.data?.companyId);
      setSelectedCompany({
        label: res?.data?.company?.name,
        value: res?.data?.company?.id,
      });
      setValue('customOnly', !!res?.data?.companyId);
      setValue('companyId', res?.data?.companyId);
      setCurrentName(res?.data?.name);
      setValue('visibleStartDate', addOffSet(res?.data?.visibleStartDate));
      setStartDate(addOffSet(res?.data?.visibleStartDate));
      setValue('visibleEndDate', addOffSet(res?.data?.visibleEndDate));
      setEndDate(addOffSet(res?.data?.visibleEndDate));
      setValue('name', res?.data?.name);
      setValue('parentCategory', res?.data?.parentId);
      setValue(
        'name',
        res?.data?.categoryLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.name ?? '',
      );
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const parentId = getValues('parentCategory');
    if (parentId && categoriesData?.length) {
      const found = categoriesData.find((c: any) => c.id === parentId);
      if (found.company_id && found.company) {
        setCustomState(found);
      }
    }
  }, [categoriesData]);

  const handleCategoriesList = useCallback(() => {
    navigate('/categories');
  }, [navigate]);

  const getInitialData = async () => {
    setLoading(true);
    await getCategories();
    if (id) {
      await getCategoryById();
    }
    setLoading(false);
  };

  const createCategory = async (data: TODO_ANY) => {
    if (!data?.parentCategory) {
      callToast(ToastTypes.ERROR, 'Parent Category is not selected');
      setIsModalAction(false);
      return;
    }

    if (data?.customOnly && !data?.companyId) {
      callToast(ToastTypes.ERROR, 'Company is not selected');
      setIsModalAction(false);
      return;
    }

    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }

    let userid = localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData') || '');
    let categoryData: any = {
      name: data?.name,
      parentId: data?.parentCategory?.toString() || null,
      languageId: currentSelected?.id || selectedLanguage?.id,
      categoryCreator : userid.userId,
      createdBy:userid.userId,
      updatedBy:userid.userId,
    };

    if (startDate && endDate) {
      categoryData.visibleStartDate = convertToMidNight(new Date(data?.visibleStartDate).setHours(0, 0, 0, 0));
      categoryData.visibleEndDate = convertToMidNight(new Date(data?.visibleEndDate).setHours(23, 59, 59, 999));
    } else {
      categoryData.visibleStartDate = null;
      categoryData.visibleEndDate = null;
    }
    if (data?.customOnly && data?.companyId) {
      categoryData.companyId = data?.companyId;
    } else {
      categoryData.companyId = null;
    }

    try {
      setLoading(true);
      let res;
      if (isEditPage) {
        res = await putAPIData({
          url: `categories/${id}`,
          data: categoryData,
        });
      } else {
        res = await postAPIData({
          url: 'categories',
          data: categoryData,
        });
      }

      if (isModalAction) {
        selectedData(currentSelectedLangId);
        setIsModalAction(false);
        navigate(`/categories/edit/${id ?? res?.data?.id}`);
      } else {
        handleCategoriesList();
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0]) || e?.message);
      console.error(e);
    }
  };

  const getCategories = async () => {
    try {
      let res = await getAPIData({
        url: 'categories',
        data: {
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      let arr: any = [];
      for (let key of res?.data?.result) {
        arr.push({ id: key?.id, name: key?.name });
      }
      setData(arr);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    getNestedCategories();
  }, [currentSelected?.id || selectedLanguage?.id]);
  useEffect(() => {
    if (!startDate && endDate) {
      setErr(true);
      setError('visibleStartDate', {
        type: 'manual',
        message:
          t('start_date_required', currentSelected?.langCode || selectedLanguage?.langCode) || 'Start date is required',
      });
    }
    if (startDate && !endDate) {
      setErr(true);
      setError('visibleEndDate', {
        type: 'manual',
        message:
          t('end_date_required', currentSelected?.langCode || selectedLanguage?.langCode) || 'End date is required',
      });
    }
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setErr(false);
      clearErrors('visibleStartDate');
      clearErrors('visibleEndDate');
    }
  }, [startDate, endDate]);
  const getNestedCategories = async () => {
    try {
      setNestedLoading(true);
      let res = await getAPIData({
        url: 'categories/nested',
        data: {
          page: 1,
          searchText: '',
          limit: 1000,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setNestedLoading(false);
      setCategoriesData(res.data.data);
      const { parentData, nestedData } = convertToNestedOptions(res.data.data);
      setParentOptions(parentData);
      setNestedOptions(nestedData);
    } catch (error) {
      setNestedLoading(false);
      console.error(error);
    }
  };

  const setCustomState = (category: ICategory) => {
    setShowCompany(true);
    setSelectedCompany({ label: category.company_name, value: category.company_id });
    setValue('customOnly', true);
    setDisableSwitch(true);
    setValue('companyId', category.company_id);
  };

  const unsetCustomState = () => {
    setShowCompany(false);
    setSelectedCompany({});
    setValue('customOnly', false);
    setDisableSwitch(false);
    setValue('companyId', null);
  };

  const addToSelectedCategories = (category: ICategory) => {
    const values = category.id;
    if (category.company_id && category.company_name) {
      setCustomState(category);
    } else {
      unsetCustomState();
    }
    setValue('parentCategory', values);
  };

  const removeFromSelectedCategories = (category: ICategory) => {
    if (category.company_id && category.company_name) {
      unsetCustomState();
    }
    setValue('parentCategory', undefined);
  };
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  function callbackFunc(entries: TODO_ANY) {
    const [entry] = entries;
    if (companies.length > 0) {
      if (entry.isIntersecting && companies.length < totalCompanies) {
        setCompanyPaginationParams({
          page: companyPaginationParams.page + 1,
          search: companyPaginationParams.search,
        });
      }
    }
  }

  function onCompanySearch(val: string) {
    setCompanyPaginationParams({
      page: 1,
      search: val,
    });
  }

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getCompaniesAndSelected({
      page: companyPaginationParams.page,
      searchText: companyPaginationParams.search,
    });
  }, [companyPaginationParams.page, companyPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };

  return (
    <section>
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(createCategory)}
          buttonText={titleButtonText}
          titleText={pageTitle}
          className="mb-8"
          onCancelClicked={handleCategoriesList}
          disableBtn={loading || err}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
        <LanguageDropdown
          langLists={langLists}
          value={currentSelected?.langCode || selectedLanguage?.langCode}
          onChange={(e: any) => {
            setCurrentSelectedLangId(e);
            setIsModalAction(true);
            setResetConfirmation(true);
          }}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset({
              customOnly: '',
              companyId: '',
              visibleStartDate: '',
              visibleEndDate: '',
              parentCategory: '',
              name: '',
            });
            setShowCompany(false);
            selectedData(currentSelectedLangId);
            setIsModalAction(false);
            setResetConfirmation(false);
          }}
          type=""
          confirmation={true}
          buttonTitle={'Continue without saving'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          deleteTitle={'How would you like to proceed '}
          button3="Save & Continue"
          button3Action={() => {
            setResetConfirmation(false);
            createCategory(getValues());
          }}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <TitleBlock
          title={t('category_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Category Fields'}>
          <form className="flex flex-col gap-6 py-7" onSubmit={handleSubmit(createCategory)}>
            <Input
              className="border-b-1 border-slate-100 px-5 pb-10"
              label={t('name', currentSelected?.langCode || selectedLanguage?.langCode) || 'Name'}
              size="medium"
              register={register}
              name="name"
              required={true}
              errors={errors}
              langCode={currentSelected?.langCode || selectedLanguage?.langCode}
            />
            <ContentCell
              title={t('parent_category', currentSelected?.langCode || selectedLanguage?.langCode) || 'Parent Category'}
              required={true}
              className="border-b-1 border-slate-100 px-5 pt-0 pb-10">
              {parentOptions && categoriesData && (
                <MultiSelectContainerNested
                  loading={nestedLoading}
                  parentOptions={parentOptions}
                  selectedOptions={watch('parentCategory') ? [watch('parentCategory')] : []}
                  selectAll={() => {}}
                  type={NestedSelectionType.SINGLE_SELECT}
                  addToSelectedCategories={addToSelectedCategories}
                  removeFromSelectedCategories={removeFromSelectedCategories}
                  allOptions={categoriesData}
                  deselectAll={() => {}}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  nestedOptions={nestedOptions}></MultiSelectContainerNested>
              )}

              {errors['parentCategory'] && !getValues('parentCategory') && (
                <p className="text-sm text-red-600">
                  {t(
                    convertToKey(errors['parentCategory'].message?.toString()),
                    currentSelected?.langCode || selectedLanguage?.langCode,
                  ) || errors['parentCategory'].message?.toString()}
                </p>
              )}
            </ContentCell>
            <div className="border-b-1 border-slate-100 px-[22px] pb-[22px]">
              <Switch
                control={control}
                action={setShowCompany}
                register={register}
                name="customOnly"
                checked={categoryState?.companyId || false}
                label={
                  t('custom_company_only', currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'For Custom Company Only'
                }
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                disabled={disableSwitch}
              />
            </div>
            {disableSwitch && (
              <div className="border-b-1 px-5 pb-7">
                <p className="text-lg font-bold text-gray-text">
                  {t('viewable_custom_company', currentSelected?.langCode || selectedLanguage?.langCode) ||
                    'Viewable Custom Company'}
                </p>
                <p className="text-[14px] font-light text-gray-subject-text">
                  {t('selected_custom_category', currentSelected?.langCode || selectedLanguage?.langCode) ||
                    'This cannot be modified here, change custom company for selected category in categories section.'}
                </p>
                <div className="text-grey-text mt-2.5 flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-sm">
                  <div className="text-grey-text flex h-[32px] items-center justify-center rounded-full bg-gray-title-bg px-5">
                    {t(selectedCompany.label, currentSelected?.langCode || selectedLanguage?.langCode) ||
                      selectedCompany.label}
                  </div>
                </div>
              </div>
            )}
            {showCompany && !disableSwitch && (
              <SingleSelectDropdown
                controlName="companyId"
                selected={selectedCompany}
                setSelected={setSelectedCompany}
                setValue={setValue}
                handleSearch={onCompanySearch}
                control={control}
                callbackFunc={callbackFunc}
                errors={errors}
                dataSource={companies}
                title={
                  t('assign_custom_company', currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'Assign a Custom Company'
                }
                required={true}
                isLoading={companyLoading}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                className="pt-0"
                description={
                  t('selected_company', currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'Only selected company can view this category and its contents'
                }
              />
            )}
            <div className="flex flex-col px-5">
              <label className="mb-3 text-lg font-bold text-gray-text">
                {t(convertToKey('Visible Start Date'), currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'Visible Start Date'}
              </label>
              <Controller
                name="visibleStartDate"
                control={control}
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={
                          t('pick_start_date', currentSelected?.langCode || selectedLanguage?.langCode) ||
                          'Pick Start Date'
                        }
                        orientation="portrait"
                        value={startDate}
                        minDate={!isEditPage ? new Date() : null}
                        maxDate={endDate ? new Date(+new Date(endDate) - 86400000) : null}
                        onChange={(newValue: any) => {
                          setStartDate(newValue);
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              {errors['visibleStartDate'] && (
                <p className="text-sm text-red-600">
                  {
                    (t(
                      convertToKey(errors['visibleStartDate'].message?.toString()),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ),
                    errors['visibleStartDate'].message?.toString())
                  }
                </p>
              )}
            </div>
            <div className="flex flex-col px-5">
              <label className="mb-3 text-lg font-bold text-gray-text">
                {t(convertToKey('Visible End Date'), currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'Visible End Date'}
              </label>
              <Controller
                name="visibleEndDate"
                control={control}
                // defaultValue={new Date(+new Date() + 86400000)}
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={
                          t('pick_end_date', currentSelected?.langCode || selectedLanguage?.langCode) || 'Pick End Date'
                        }
                        orientation="portrait"
                        minDate={new Date(+new Date(startDate) + 86400000)}
                        value={endDate}
                        disabled={!startDate && !endDate}
                        onChange={(newValue: any) => {
                          setEndDate(newValue);
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              {errors['visibleEndDate'] && (
                <p className="text-sm text-red-600">
                  {t(
                    convertToKey(errors['visibleEndDate'].message?.toString()),
                    currentSelected?.langCode || selectedLanguage?.langCode,
                  ) || errors['visibleEndDate'].message?.toString()}
                </p>
              )}
            </div>
          </form>
        </TitleBlock>
      )}
    </section>
  );
}

export default Categories;
