import { useTranslation } from 'context/translation';
import React from 'react';
import dropdown from '../../assets/images/dropdown.svg';
const LanguageDropdown = ({ langLists, onChange, value }: { langLists?: any; onChange?: any; value?: any }) => {
  const { language, setLanguage }: any = useTranslation();
  return (
    <div className="relative">
      <select
        className="flex w-full w-full min-w-[140px] cursor-pointer appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none"
        onChange={(e: any) => onChange(e.target.value)}
        value={value || language}>
        {langLists?.map((e: any, i: any) => {
          return (
            <option value={e?.langCode} key={i}>
              {e?.language?.[0]?.toUpperCase() + e?.language?.substring(1) || ''}
            </option>
          );
        })}
      </select>
      <img
        src={dropdown}
        alt="arrow"
        className="pointer-events-none absolute top-[13px] right-[15px]"
        title="arrow"></img>
    </div>
  );
};

export default LanguageDropdown;
