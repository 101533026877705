import { useEffect } from 'react';
import { Input } from 'components';
import { TODO_ANY } from 'typings/common';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const SurveyQuestionOptions = ({
  control,
  register,
  errors,
  index,
  isEdit,
  getValues,
  watch,
  clearErrors,
  langCode
}: {
  control: Control<any>;
  register: Function;
  errors: any;
  index: number;
  isEdit: boolean;
  getValues: Function;
  watch: Function;
  clearErrors: Function;
  langCode?:any
}) => {
  const { fields, append, remove } = useFieldArray({ name: `questions.${index}.surveyOption`, control });
  const { t }: any = useTranslation();

  const handleRemoveOption = (optIndex: number) => {
    remove(optIndex);
  };
  const handleAddOption = () => {
    append({ text: '' });
    clearErrors();
  };
  useEffect(() => {
    if (!isEdit || watch(`questions.${index}.surveyOption`)?.length === 0) {
      append({ text: '' });
      clearErrors();
    }
  }, [isEdit, index, watch]);

  return (
    <div>
      {fields?.map((option: TODO_ANY, optIndex: number) => {
        return (
          <div className="mt-3 flex items-center justify-center" key={optIndex}>
            <div className="mr-1 flex items-center rounded-md bg-gray-title-bg py-3 px-5">{optIndex + 1}</div>
            <div className="mr-2 flex items-center rounded-md bg-gray-title-bg py-3 px-5">
              {(langCode ? t('option', langCode) : t('option')) || 'option'}
            </div>
            <div className="mr-2 w-full">
              <Input
                size="large"
                name={`questions.${index}.surveyOption.${optIndex}.text`}
                register={register}
                required={true}
                langCode={langCode}
              />
            </div>
            {errors?.questions?.[index]?.surveyOption?.[optIndex]?.text && (
              <p className="mt-2 text-sm italic text-red-600">
                {(langCode
                  ? t(convertToKey(errors?.questions?.[index]?.surveyOption?.[optIndex]?.text?.message), langCode)
                  : t(convertToKey(errors?.questions?.[index]?.surveyOption?.[optIndex]?.text?.message))) ||
                  errors?.questions?.[index]?.surveyOption?.[optIndex]?.text?.message}
              </p>
            )}
            {optIndex === 0 ? (
              <div className="h-5 w-8" />
            ) : (
              <div className="mx-1 my-1 flex h-5 w-6 items-center justify-center rounded-md bg-green-500">
                <button
                  className="flex h-3.5 w-3.5 items-center justify-center rounded-full border-1 border-white bg-transparent text-white "
                  onClick={() => {
                    handleRemoveOption(optIndex);
                  }}
                  type="button">
                  <i className="fa fa-minus text-[10px]" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
        );
      })}
      <button
        className="mt-3 flex w-full items-center justify-center rounded-md border-1 border-green-500 py-1 text-sm"
        onClick={() => {
          handleAddOption();
        }}
        type="button">
        <div className="mr-2 flex h-3.5 w-3.5 items-center justify-center rounded-full border-1 border-black p-2">
          <i className="fa fa-plus text-[10px]" aria-hidden="true"></i>
        </div>
        {(langCode ? t('add_option', langCode) : t('add_option')) || 'Add Option'}
      </button>
    </div>
  );
};
export default SurveyQuestionOptions;
