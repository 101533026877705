import { Spinner } from 'components';
import { ToastTypes, useToast } from 'context/toast';
import React, { useState } from 'react';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import SRT from 'assets/images/srt.svg';
import uuid from 'react-uuid';
import { saveAs } from 'file-saver';
import { useTranslation } from 'context/translation';
import { isSubtitleFile } from 'pages/Content/utils';
import { getThreeCharcterLanguageCode } from 'constants/threeCodeLanguage';
let commonName: number;
const SrtUpload = ({ edit, srtKey, srtValue, setSrtValue, langCode }: any) => {
  const { callToast } = useToast();
  const [srtFile, setSrt] = useState<any>(srtValue?.url || '');
  const [srtFileName, setSrtFileName] = useState<any>(srtValue?.fileName || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [srtFileSize, setSrtFileSize] = useState<any>(srtValue?.fileSize || 0);
  const [srtUploading, setSrtUploading] = useState(false);
  const { t }: any = useTranslation();

  const uploadSrt = async (e: any, langCode: any) => {
    // if (video === '') {
    //   callToast(ToastTypes.ERROR, 'Upload video first');
    //   return;
    // }
    const file = e?.target?.files[0];
    const fileSize = file.size;
    const maxSize = 2 * 1024 * 1024;
    if (maxSize < fileSize) {
      callToast(ToastTypes.ERROR, 'File size exceed');
      setSrt('');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSrt(reader?.result);
    };
    try {
      setSrtUploading(true);
      const checkIsItSubTitle = isSubtitleFile(file?.name);
      let key = '';
      if (checkIsItSubTitle && langCode !== 'en') {
        let threeCharLangCode = getThreeCharcterLanguageCode(langCode);
        if (edit) {
          let splitSrtKey = srtKey.split('__')[0];
          key = `${splitSrtKey}__${threeCharLangCode}.${getFileExtension(file.name)}`;
        } else {
          key = `${srtKey}__${threeCharLangCode}.${getFileExtension(file.name)}`;
        }
      } else {
        key = `${srtKey}.${getFileExtension(file?.name)}`;
      }
      setSrtUploading(false);

      const signedUrlData = await getSignedUrl(file, key);
      //   block(true);
      await uploadFile(file, signedUrlData?.data?.result?.url);

      const fileData = {
        url: `${key}`,
        type: 'srt',
        fileName: file?.name.split('.')[0],
        fileSize: fileSize,
      };
      setSrtValue(fileData);
      //   block(false);
      callToast(
        ToastTypes.SUCCESS,
        (langCode ? t('file_uploaded', langCode) : t('file_uploaded')) || 'File uploaded successfully',
      );
      setSrtFileName(file?.name);
      setSrtFileSize(file?.size);
      setSrtUploading(false);
    } catch (e: any) {
      setSrtUploading(false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const removeSrt = () => {
    setSrtValue({});
    setSrt('');
    setSrtFileName('');
    setSrtFileSize(0);
  };
  const downloadSRT = async () => {
    setIsLoading(true);
    callToast(
      ToastTypes.INFO,
      (langCode ? t('download_started', langCode) : t('download_started')) || 'Download Started',
    );
    try {
      const downloadResult = await fetch(`${process.env.REACT_APP_CLOUDFRONT_URL}${srtFile}`);
      const blob = await downloadResult.blob();
      saveAs(blob, `${srtFileName}.srt`);
      callToast(
        ToastTypes.SUCCESS,
        (langCode ? t('download_success', langCode) : t('download_success')) || 'Downloaded Successfully',
      );
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <div className="py-[30px]">
        <div className="w-full flex-shrink-0">
          <div className="flex items-center">
            <label className="mr-[26px] block min-w-[80px] text-lg font-bold text-gray-text">
              {(langCode ? t('srt_file', langCode) : t('srt_file')) || 'SRT File'}
            </label>
            <span className="ml-[10px] flex min-w-[118px] cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
              <span className="relative text-[12px] text-white">
                {(langCode ? t('upload_srt', langCode) : t('upload_srt')) || 'Upload SRT'}
                <input
                  id="srtPicker"
                  type="file"
                  className="absolute top-0 right-0 h-full w-full opacity-0"
                  accept=".srt"
                  onChange={(e) => uploadSrt(e, langCode)}
                />
              </span>
            </span>
            {srtUploading && <Spinner />}
          </div>
        </div>
        {/* SRT File Upload section */}
        {srtFile !== '' && (
          <div className="border-grey mt-[18px] flex items-center justify-between rounded-[5px] border p-[15px]">
            <div className="flex max-w-[80%] items-center">
              <div className="mr-[15px] flex h-[62px] w-[75px] items-center justify-center">
                <img className="w-[37px] rounded-[5px]" src={SRT}></img>
              </div>
              <div>
                {/* <p className="text-gray text-[14px] font-medium">
              How To Use This App{' '}
              <i
                className="fa fa-external-link ml-[5px] cursor-pointer text-[12px] text-green-main"
                aria-hidden="true"></i>
            </p> */}
                <p className="text-gray text-[14px] font-medium">
                  {(langCode ? t('file_name', langCode) : t('file_name')) || 'File name : '}{' '}
                  <span className="text-[14px] font-light text-gray-subject-text">{srtFileName} </span>
                </p>
                <p className="text-gray text-[14px] font-medium">
                  File size : <span className="text-[14px] font-light text-gray-subject-text">{srtFileSize} KB</span>
                </p>
              </div>
            </div>
            <span className="ml-[10px] flex min-w-[86px] cursor-pointer items-center justify-center">
              {edit && (
                <>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <span
                      className="mr-[5px] rounded-[5px] bg-blue-main py-[5px] px-[10px] text-[12px] text-white"
                      onClick={downloadSRT}>
                      {(langCode ? t('download', langCode) : t('download')) || 'Download'}
                    </span>
                  )}
                </>
              )}
              {/* <span className="text-[12px] text-white bg-red-main py-[5px] px-[10px] rounded-[5px]" onClick={removeSrt}>
                Remove
              </span> */}
            </span>
          </div>
        )}

        {/*  Quiz Questions loop */}
      </div>
    </div>
  );
};

export default SrtUpload;
