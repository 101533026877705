import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
export const SurveyDuration = ({
  control,
  errors,
  setValue,
  getValues,
  setError,
  clearErrors,
  langCode,
}: {
  control: any;
  errors: any;
  setValue: any;
  getValues?: any;
  setError?: any;
  clearErrors: any;
  langCode?: any;
}) => {
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  const { t }: any = useTranslation();

  return (
    <div className="flex items-center py-6">
      <label className="mr-4 text-lg font-bold text-gray-text">
        {(langCode ? t('start_date', langCode) : t('start_date')) || 'Start Date'}
        {<span style={{ color: 'red' }}>&nbsp;*</span>}
      </label>
      <Controller
        name="startDate"
        control={control}
        render={({ field, ...props }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                orientation="portrait"
                value={field.value}
                onChange={(newValue) => {
                  if (!newValue) {
                    clearErrors('startDate');
                    clearErrors('endDate');
                  }
                  if (getValues().endDate) {
                    if (new Date(getValues().endDate).setHours(0, 0, 0, 0) > new Date(newValue).setHours(0, 0, 0, 0)) {
                      clearErrors('startDate');
                      clearErrors('endDate');
                    } else {
                      setError('endDate', {
                        type: 'manual',
                        message:
                          (langCode ? t('end_date_greater_start_date', langCode) : t('end_date_greater_start_date')) ||
                          'End date should be greater than start date',
                      });
                    }
                  }
                  field.onChange(newValue);
                }}
                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
              />
            </LocalizationProvider>
          );
        }}
      />
      {errors?.['startDate'] && (
        <p className="text-sm text-red-600">
          {(langCode
            ? t(convertToKey(errors?.['startDate'].message?.toString()), langCode)
            : t(convertToKey(errors?.['startDate'].message?.toString()))) || errors?.['startDate'].message?.toString()}
        </p>
      )}

      <div className="ml-5 flex items-center">
        <label className="mr-4 text-lg font-bold text-gray-text">
          {(langCode ? t('end_date', langCode) : t('end_date')) || 'End Date'}
          {<span style={{ color: 'red' }}>&nbsp;*</span>}
        </label>
        <Controller
          name="endDate"
          control={control}
          defaultValue={new Date(+new Date().getTime() + 86400000)}
          render={({ field, ...props }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  orientation="portrait"
                  value={field.value}
                  onChange={(newValue) => {
                    if (getValues().startDate) {
                      if (
                        new Date(getValues().startDate).setHours(0, 0, 0, 0) < new Date(newValue).setHours(0, 0, 0, 0)
                      ) {
                        clearErrors('startDate');
                        clearErrors('endDate');
                      } else {
                        setError('endDate', {
                          type: 'manual',
                          message:
                            (langCode
                              ? t('end_date_greater_start_date', langCode)
                              : t('end_date_greater_start_date')) || 'End date should be greater than start date',
                        });
                      }
                    }
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                />
              </LocalizationProvider>
            );
          }}
        />
        {errors?.['endDate'] && (
          <p className="text-sm text-red-600">
            {(langCode
              ? t(convertToKey(errors?.['endDate'].message?.toString()), langCode)
              : t(errors?.['endDate'].message?.toString())) || errors?.['endDate'].message?.toString()}
          </p>
        )}
      </div>
    </div>
  );
};
