import { useEffect, useRef, useState } from 'react';
import galleryImport from '../../assets/images/gallery-import.svg';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import { useToast } from '../../context/toast';
import { ToastTypes } from 'context/toast';
import uuid from 'react-uuid';
import { Spinner } from 'components/common';
import classNames from 'classnames';
import { UploadPopUp } from 'pages/Media/ImageUpload/imageUpload';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';
const LogoPicker = ({
  setValue,
  getValues,
  type = 'logo',
  name,
  setImageId = () => {},
  required = false,
  errors = {},
  title = '',
  disableBtn = () => {},
  isBlock = false,
  langCode,
}: {
  setValue: Function;
  getValues: any;
  type?: string;
  name: string;
  setImageId?: Function;
  required?: boolean;
  errors?: any;
  title?: string;
  disableBtn?: Function;
  isBlock?: boolean;
  langCode?: any;
}) => {
  const [image, setImage] = useState<any>(getValues()[name] || '');
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const { t }: any = useTranslation();

  const inputRef = useRef<any>(null);
  const uploadImage = async (e: any) => {
    const file = e?.target?.files[0];
    const imageSize = file.size;
    const maxSize = 10 * 1024 * 1024;
    if (maxSize < imageSize) {
      callToast(ToastTypes.ERROR, 'Image size exceed');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(e?.target?.files[0]);
    reader.onloadend = () => {
      setImage(reader?.result);
    };
    try {
      const key = `${uuid()}/image/${Date.now()}/image_data.${getFileExtension(file.name)}`;
      const signedUrlData = await getSignedUrl(file, key);
      disableBtn(true);
      const res = await uploadFile(file, signedUrlData?.data?.result?.url);
      setValue(name, key);
      disableBtn(false);
    } catch (e: any) {
      disableBtn(false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };

  const clearImage = (e: any) => {
    e.preventDefault();

    if (isBlock) {
      return;
    }
    setImage('');
    setImageId('');
    // inputRef.current.value = '';
    setValue(name, '');
  };
  const { callToast } = useToast();
  return (
    <div className="px-[26px] pt-[32px] pb-[30px]">
      {/* <input
        id="imagePicker"
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: 'none' }}
        onChange={(e) => uploadImage(e)}
      /> */}
      <UploadPopUp
        show={showPopUp}
        setShow={setShowPopUp}
        url={image}
        setImageId={setImageId}
        fileUrl={setImage}
        setValue={setValue}
        controlName={[name]}
        fileType={'image'}
        langCode={langCode}
      />
      <button
        id="imagePicker"
        style={{ display: 'none' }}
        onClick={(e: any) => {
          e.preventDefault();
          setShowPopUp(true);
        }}></button>
      {type === 'logo' && (
        <>
          <label className="mb-[20px] block text-lg font-bold text-gray-text">
            {t('logo', langCode) || 'Logo'}
            <span className="text-red-500">{required && '*'}</span>
          </label>
          <>
            <div className="flex flex-wrap items-center">
              <p className="text-gray-text"> {!image && (t('no_image_selected', langCode) || 'No image selected')} </p>

              <span className="rounded-full">
                {image && (
                  <div className="relative">
                    {isBlock && (
                      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-50">
                        <Spinner />
                      </div>
                    )}
                    <img className="h-[150px] w-[150px] rounded-full  border-2 border-solid" src={image} alt="icon" />{' '}
                    <button onClick={clearImage} className="absolute top-[10px] right-[10px]">
                      <i
                        className={`fa fa-times-circle text-[18px] text-red-600 ${isBlock && 'opacity-50'}`}
                        aria-hidden="true"></i>
                    </button>
                  </div>
                )}
              </span>
              {!image && (
                <>
                  <label htmlFor="imagePicker">
                    <span className="ml-[10px] flex cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
                      <img src={galleryImport} alt="arrow" className="mr-[8px]" title="arrow"></img>
                      <span className="text-[12px] text-white">{t('add_image', langCode) || 'Add image'}</span>
                    </span>
                  </label>
                  <div className="w-full">
                    {(errors[name] || image) && (
                      <p className="mt-2 text-sm italic text-red-600">
                        {' '}
                        {t(convertToKey(errors[name].message), langCode) || errors[name].message}{' '}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        </>
      )}
      {type === 'image' && (
        <>
          {image ? (
            <>
              <div className="p-5 px-1">
                <div className="relative flex flex-col items-start gap-5 px-4">
                  <label htmlFor="imagePicker">
                    <div
                      className={classNames(
                        'relative h-[100px] w-40 cursor-pointer rounded-md',
                        !image && 'bg-slate-200',
                      )}>
                      {isBlock && (
                        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-50">
                          <Spinner />
                        </div>
                      )}
                      <img className="h-[100px] rounded-[5px]" src={image} alt="preview" />
                    </div>
                  </label>
                  <p className="text-[14px] font-light text-gray-text">
                    {t('alter_image', langCode) || 'Click the image to edit or update'}
                  </p>
                  <button
                    className={`rounded-md bg-red-main px-5 py-1 text-sm text-white ${isBlock && 'opacity-50'}`}
                    onClick={clearImage}>
                    {t('remove', langCode) || 'Remove'}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <label htmlFor="imagePicker">
                <span className="ml-[10px] flex cursor-pointer items-center justify-center rounded-[5px] bg-blue-main  py-[5px] px-[10px]">
                  <span className="text-[12px] text-white">
                    {langCode ? t('upload_image', langCode) || 'Upload image' : t('upload_image') || 'Upload image'}
                  </span>
                </span>
              </label>
              <div>
                {(errors[name] || image) && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {t(convertToKey(errors[name].message), langCode) || errors[name].message}
                  </p>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LogoPicker;
