import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { TODO_ANY } from 'typings/common';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

type input = {
  value?: string;
  placeholder?: string;
  prefix?: TODO_ANY;
  type?: 'search';
  onChange?: (value: string) => void;
  onClick?: () => void;
  size?: 'default' | 'large' | 'medium' | undefined;
  label?: string;
  className?: string;
  lessPadding?: boolean;
  inputType?: string;
  name?: string;
  textarea?: boolean;
  register?: any;
  control?: any;
  errors?: any;
  required?: boolean;
  disabled?: boolean;
  onKeyUp?: (event: any) => void;
  langCode?: any;
};

function Input({
  value,
  placeholder,
  onChange = () => {},
  onClick,
  prefix,
  size = 'default',
  type,
  label,
  className = '',
  lessPadding,
  control,
  inputType,
  textarea,
  name,
  register = () => {},
  errors,
  required = false,
  disabled,
  onKeyUp,
  langCode,
}: input) {
  const { t }: any = useTranslation();

  const SizeMap = useMemo(
    () => ({
      default: classNames('flex items-center py-1.5 border-solid border-1 rounded', {
        'px-5': !lessPadding,
        'pl-3': lessPadding,
        'pr-1': lessPadding,
        'border-green-main': !lessPadding,
        'text-gray-500 bg-slate-100 cursor-not-allowed': disabled,
      }),
      large: classNames(
        'flex items-center w-full rounded-md border-1 border-solid border-gray-200 py-3 text-lg leading-6',
        {
          'px-6': !lessPadding,
          'pl-6': lessPadding,
          'pr-1': lessPadding,
          'text-gray-500 bg-slate-100 cursor-not-allowed': disabled,
          'bg-white': !disabled,
        },
      ),
      medium: classNames(
        'flex items-center w-full rounded-md border-1 border-solid border-gray-200 py-3 text-lg leading-6',
        {
          'px-6': !lessPadding,
          'pl-6': lessPadding,
          'pr-1': lessPadding,
          'text-gray-500 bg-slate-100 cursor-not-allowed': disabled,
          'bg-white': !disabled,
        },
      ),
    }),
    [lessPadding],
  );
  const textAreaTitle = t(value) || value || '';
  const handleChange = (event: TODO_ANY) => {
    onChange(event.target.value);
  };
  return (
    <div className={className}>
      {label && (
        <div className="w-full flex-shrink-0">
          <p className="mb-2 text-lg font-bold text-gray-text">
            {langCode ? t(label, langCode) || label : t(label) || label}
            {required && <span className="text-red-500">&nbsp;*</span>}
          </p>
        </div>
      )}
      <div className={SizeMap[size]}>
        {textarea && (
          <textarea
            className="w-full text-gray-text outline-none disabled:bg-transparent"
            onChange={handleChange}
            placeholder={t(placeholder) || placeholder}
            type={inputType ?? 'text'}
            disabled={disabled}
            {...register(name)}
            {...control}>
            {textAreaTitle}
          </textarea>
        )}
        {!textarea && (
          <input
            className="w-full text-gray-text outline-none disabled:bg-transparent"
            onChange={handleChange}
            placeholder={t(placeholder) || placeholder}
            type={inputType ?? 'text'}
            disabled={disabled}
            {...register(name)}
            {...control}
            onKeyUp={onKeyUp}
            value={value}></input>
        )}
        {type === 'search' ? <i onClick={onClick} className="fas fa-search cursor-pointer text-gray-text" /> : prefix}
      </div>
      {errors?.[name ?? ''] && (
        <p className="mt-2 text-sm italic text-red-600">
          {langCode
            ? t(convertToKey(errors?.[name ?? '']?.message), langCode) || errors?.[name ?? '']?.message
            : t(convertToKey(errors?.[name ?? '']?.message)) || errors?.[name ?? '']?.message}
        </p>
      )}
    </div>
  );
}

export default Input;
