import { Input, Select } from 'components';
import { QUESTION_TYPES } from 'constants/survey.constant';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import SurveyQuestionOptions from './surveyQuestionOptions';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const SurveyQuestion = ({
  control,
  register,
  errors,
  getValues,
  watch,
  questionOptions,
  isEdit,
  // isTemplateSelected,
  setValue,
  clearErrors,
  langCode,
}: {
  control: Control<any>;
  register: Function;
  errors: any;
  getValues: Function;
  watch: Function;
  questionOptions: any[];
  isEdit: boolean;
  // isTemplateSelected: boolean;
  setValue: Function;
  clearErrors: Function;
  langCode?: any;
}) => {
  const { fields, append, remove } = useFieldArray({ name: `questions`, control });
  const { t }: any = useTranslation();

  const handleRemoveQuestion = (index: number) => {
    remove(index);
    clearErrors();
  };
  const handleAddQuestion = () => {
    append({ question: '', typeId: '1', surveyOption: [] });
    clearErrors();
  };
  return (
    <>
      {fields.map((item: any, index: number) => (
        <div className="mt-5 mb-5 flex items-center gap-2.5" key={index}>
          <div className="flex items-center self-stretch rounded-md bg-gray-title-bg px-3 text-gray-text">
            {index + 1}
          </div>
          <div className="flex-1">
            <div className="mb-5">
              <div className="mb-2.5 text-left">
                {(langCode ? t('question', langCode) : t('question')) || 'Question'}{' '}
                <span className="text-red-500">*</span>
              </div>
              <div className="">
                <Input size="large" name={`questions.${index}.question`} register={register} langCode={langCode} />
              </div>
              {errors?.questions?.[index]?.question && (
                <p className="mt-2 text-sm italic text-red-600">
                  {(langCode
                    ? t(convertToKey(errors?.questions?.[index]?.question?.message), langCode)
                    : t(convertToKey(errors?.questions?.[index]?.question?.message))) ||
                    errors?.questions?.[index]?.question?.message}
                </p>
              )}
            </div>
            <div className="mb-5">
              <div className="mb-2.5 text-left">
                {(langCode ? t('question_type', langCode) : t('question_type')) || 'Question Type'}{' '}
                <span className="text-red-500">*</span>
              </div>
              <div className="">
                <Controller
                  name={`questions.${index}.typeId`}
                  control={control}
                  defaultValue={null}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Select
                          value={getSelectedType(field?.value, questionOptions)}
                          options={questionOptions}
                          onChange={(val: any) => {
                            field.onChange(val?.value ?? QUESTION_TYPES.Single);
                            if (val?.value === QUESTION_TYPES.Single) {
                              setValue(`questions.${index}.surveyOption`, []);
                            }
                          }}
                          fullWidth></Select>
                      </>
                    );
                  }}
                />

                {errors?.questions?.[index]?.typeId && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {(langCode
                      ? t(convertToKey(errors?.questions?.[index]?.typeId?.message), langCode)
                      : t(errors?.questions?.[index]?.typeId?.message)) || errors?.questions?.[index]?.typeId?.message}
                  </p>
                )}
                {watch(`questions.${index}.typeId`) === QUESTION_TYPES.MultipleChoice && (
                  <SurveyQuestionOptions
                    control={control}
                    register={register}
                    errors={errors}
                    index={index}
                    isEdit={isEdit}
                    getValues={getValues}
                    watch={watch}
                    langCode={langCode}
                    clearErrors={clearErrors}></SurveyQuestionOptions>
                )}
              </div>
            </div>
          </div>
          {index !== 0 ? (
            <div className="flex items-center self-stretch rounded-md bg-gray-title-bg px-2">
              <div className="flex h-5 w-5 items-center justify-center rounded-md bg-green-500">
                <button
                  className="flex h-3.5 w-3.5 items-center justify-center rounded-full border-1 border-white bg-transparent text-white "
                  onClick={() => {
                    handleRemoveQuestion(index);
                  }}
                  type="button">
                  <i className="fa fa-minus text-[10px]" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          ) : (
            <div className="w-9" />
          )}
        </div>
      ))}
      {errors?.questions && <p className="mt-2 text-sm italic text-red-600">{errors?.questions?.message}</p>}
      <div className="w-full text-right">
        <button
          className="mt-2.5 flex w-full items-center justify-center rounded-md bg-blue-main px-2.5 py-1 text-sm text-white"
          onClick={handleAddQuestion}
          type="button">
          <div className="mr-2 flex h-3.5 w-3.5 items-center justify-center rounded-full border-1 border-white p-2">
            <i className="fa fa-plus text-[10px]" aria-hidden="true"></i>
          </div>
          {(langCode ? t('add_question', langCode) : t('add_question')) || 'Add Question'}
        </button>
      </div>
    </>
  );
};
function getSelectedType(typeId: string, questionOptions: any[]) {
  return questionOptions.find((el: any) => el?.value === typeId) ?? questionOptions[0];
}
export default SurveyQuestion;
