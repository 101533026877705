import { useTranslation } from 'context/translation';
import { TODO_ANY } from 'typings/common';

type textarea = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  rows?: number | undefined;
};

function Textarea({ value, placeholder, onChange, rows = 6 }: textarea) {
  const handleChange = (event: TODO_ANY) => {
    onChange(event.target.value);
  };
  const { t }: any = useTranslation();

  return (
    <textarea
      className="w-full rounded-md border-1 border-solid border-gray-200 px-6 py-3 text-lg leading-6"
      rows={rows}
      value={value}
      onChange={handleChange}
      placeholder={t(placeholder) || placeholder}></textarea>
  );
}

export default Textarea;
