import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function getToken() {
  let data = localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData') || '');
  return data?.token;
}
export function getAPIData(obj) {
  return axios.get(API_BASE_URL + obj.url, {
    params: {
      ...obj.data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export function postAPIData(obj) {
  return axios.post(
    API_BASE_URL + obj.url,
    {
      ...obj.data,
    },
    { headers: { Authorization: `Bearer ${getToken()}` } },
  );
}

export function putAPIData(obj) {
  return axios.put(API_BASE_URL + obj.url, obj.data, {
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export function deleteAPIData(obj) {
  return axios.delete(API_BASE_URL + obj.url, {
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export function putAPIToUploadFile(obj) {
  try {
    return axios.put(obj.url, obj.data, {
      headers: {
        ...obj.headers,
      },
    });
  } catch (err) {
    console.error(err);
  }
}
