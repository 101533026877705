import InputBox from 'components/common/formInput';
import { addUserFields, userDeafultValues } from 'constants/addUser.constant';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userFormValidation } from 'validations/addUser.validation';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import dropdown from '../../assets/images/dropdown.svg';
import { InputLabelPosition } from 'enums/input.enum';
import { getRoles } from '@testing-library/react';
import { Options } from 'typings/form';
import { ToastTypes, useToast } from 'context/toast';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { Header, Spinner, TopLayer } from 'components';
import { useTranslation } from 'context/translation';
import LanguageDropdown from 'components/LanguageDropdown';
import { convertToKey } from 'pages/Content/utils';
import { getEnglishId } from 'pages/Media/utils';

const GeneralSettings = () => {
  const { authUser } = useAuth();
  const { t }: any = useTranslation();
  const { language, setLanguage }: any = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const pageTitle = t('general_settings') || 'General Settings';
  const titleButtonText = t('save') || 'Save';
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [roles, setRoles] = useState<Options[]>([]);
  const saveLogo = (val: any) => {
    setValue('imageUrl', val);
  };
  const [imageId, setImageId] = useState<any>('');
  const { callToast } = useToast();
  const { setAuthUser } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: userDeafultValues,
    resolver: yupResolver(userFormValidation),
  });

  const [isImageUploaded, setIsImagesUploaded] = useState(false);
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const removeURL = (data: any) => {
    if (data?.imageUrl) data.imageUrl = data?.imageUrl?.split('.net/')[1];
    if (data?.srt?.url) data.srt.url = data?.srt?.url?.split('.net/')[1];
    if (data?.video?.thumbnail) data.video.thumbnail = data?.video?.thumbnail?.split('.net/')[1];
    if (data?.video?.url) data.video.url = data?.video?.url?.split('.net/')[1];
    return data;
  };

  const addEditUser = async (data: TODO_ANY) => {
    if (isImageUploaded) {
      callToast(ToastTypes.INFO, t('image_uploading') || 'Image is uploading');
      return;
    }
    const payload = removeURL({ ...data });
    if (imageId) {
      payload.imageId = imageId;
    }
    payload.languageId = currentSelectedLangId?.id;
    delete payload.imageUrl;
    try {
      setLoading(true);
      let res;
      const obj = { url: 'user/' + authUser?.id, data: payload };
      res = await putAPIData(obj);
      setAuthUser(res?.data);
      selectedData(currentSelectedLangId?.id);
      setLanguage(currentSelectedLangId?.langCode);
      callToast(ToastTypes.SUCCESS, t('user_updated') || 'User updated successfully!');
    } catch (err: any) {
      callToast(ToastTypes.ERROR, err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (authUser?.id) {
      getRoles();
      getUserById();
    }
  }, [authUser?.id, selectedLanguage]);
  const getRoles = async () => {
    try {
      setLoading(true);
      const res = await getAPIData({
        url: `user/roles`,
        data: { languageId: getEnglishId() ?? currentSelectedLangId?.id ?? selectedLanguage?.id },
      });
      const role = res?.data?.map((el: any) => {
        return { label: el?.role, value: el?.id };
      });
      setRoles(role);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const getUserById = async () => {
    try {
      let res = await getAPIData({ url: `user/${authUser?.id}` });
      setValue('username', res?.data?.username);
      setValue('email', res?.data?.email);
      setValue('firstName', res?.data?.firstName);
      setValue('roleId', res?.data?.roleId);
      setValue('imageUrl', res?.data?.media?.url);
      setValue('lastName', res?.data?.lastName);
      setImageId(res?.data?.imageId);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const getLangID = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelectedLangId(key);
        return key?.id;
      }
    }
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.id == e) {
        setSelectedLanguage(key);
        localStorage.setItem('selectedLang', JSON.stringify(key));
        return;
      }
    }
  };
  return (
    <>
      {/* <div className="flex justify-between">
        {' '} */}
      <TopLayer
        onButtonClicked={handleSubmit(addEditUser)}
        buttonText={t(titleButtonText) || titleButtonText}
        titleText={pageTitle || pageTitle}
        className="mb-8"
        disableBtn={loading || isImageUploaded}
        langCode={selectedLanguage?.langCode}
      />

      <form onSubmit={handleSubmit(addEditUser)}>
        {loading ? (
          <div className="flex items-center justify-center">
            <Spinner />{' '}
          </div>
        ) : (
          <div className="mt-[60px] mb-[20px]">
            <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
              <span className="text-lg font-bold text-gray-text">
                {t('user_fields', selectedLanguage?.langCode) || 'User Fields'}
              </span>
            </div>
            <div className=" rounded-b-[5px] border ">
              <div className="flex">
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, selectedLanguage?.langCode)?.[0]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    disable={true}
                  />
                </div>
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, selectedLanguage?.langCode)?.[1]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    disable={true}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, selectedLanguage?.langCode)?.[2]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                  />
                </div>
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, selectedLanguage?.langCode)?.[3]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                  />
                </div>
              </div>
              <div className="flex justify-between border-b-1 border-slate-100">
                <div className="w-1/2 px-[26px] pt-[32px] pb-[30px]">
                  <label className={'mb-[20px] block text-lg font-bold text-gray-text'}>
                    {t('Role') || 'Role'}
                    <span className="text-red-500">&nbsp;*</span>
                  </label>
                  <InputBox
                    input={{ ...addUserFields(t, selectedLanguage?.langCode)?.[4], options: roles }}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    // labelPosition={InputLabelPosition.Left}
                    rollSelect={true}
                  />
                </div>
              </div>

              <div className="border-b-1 border-slate-100 px-[26px] pt-[32px] pb-[30px]">
                <InputBox
                  input={addUserFields(t, selectedLanguage?.langCode)?.[5]}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  control={control}
                  disable={true}
                  isBlock={isImageUploaded}
                  block={setIsImagesUploaded}
                  setImageId={setImageId}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default GeneralSettings;
