import { CognitoUserPool } from 'amazon-cognito-identity-js';
import BadgeIcon from 'assets/images/icons/BadgeIcon';
import CompanyIcon from 'assets/images/icons/CompanyIcon';
import ContentIcon from 'assets/images/icons/ContentIcon';
import InterrupterIcon from 'assets/images/icons/InterrupterIcon';
import PageIcon from 'assets/images/icons/PageIcon';
import SequenceIcon from 'assets/images/icons/SequenceIcon';
import SettingsIcon from 'assets/images/icons/SettingsIcon';
import UserIcon from 'assets/images/icons/UserIcon';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { ToastTypes, useToast } from 'context/toast';
import { useAuth } from 'context/user';
import MediaIcon from 'assets/images/icons/MediaIcon';
import { useTranslation } from 'context/translation';

function Sidebar() {
  const navigate = useNavigate();
  const { callToast } = useToast();
  const { authUser, setAuthUser } = useAuth();
  const { t }: any = useTranslation();

  const handleLogoutClick = () => {
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    const cognitoUser: TODO_ANY = userPool.getCurrentUser();
    cognitoUser.signOut();
    const selectedLang: string = localStorage.getItem('selectedLang') || 'en';
    localStorage.clear();
    localStorage.setItem('selectedLang', selectedLang);
    callToast(ToastTypes.SUCCESS, t('logout_success') || 'Logout Successful!');
    setAuthUser({});
    setTimeout(() => {
      navigate('/auth/login');
    }, 0);
  };

  const sideBarItems = [
    {
      name: t('content') || 'Content',
      routeTo: '/content',
      iconClassName: '',
      iconChild: <ContentIcon />,
    },
    {
      name: t('categories') || 'Categories',
      routeTo: '/categories',
      subroutes: [],
      iconClassName: 'fa fa-align-justify',
    },
    {
      name: t('sequences') || 'Sequences',
      routeTo: '/sequences',
      subroutes: [],
      iconClassName: '',
      iconChild: <SequenceIcon />,
    },
    {
      name: t('interrupters') || 'Interrupters',
      routeTo: '/interrupters',
      subroutes: [],
      iconClassName: '',
      iconChild: <InterrupterIcon />,
    },
    {
      name: t('companies') || 'Companies',
      routeTo: '/companies',
      subroutes: [],
      iconClassName: '',
      iconChild: <CompanyIcon fontSize={10} />,
    },
    {
      name: t('groups') || 'Groups',
      routeTo: '/groups',
      subroutes: [],
      iconClassName: 'fa fa-users',
    },
    {
      name: t('badges') || 'Badges',
      routeTo: '/badges',
      subroutes: [],
      iconClassName: '',
      iconChild: <BadgeIcon />,
    },
    {
      name: t('surveys') || 'Surveys',
      routeTo: '/surveys',
      subroutes: [],
      iconClassName: 'fa fa-superpowers',
    },
    {
      name: t('pages') || 'Pages',
      routeTo: '/pages',
      subroutes: [],
      iconClassName: '',
      iconChild: <PageIcon />,
    },
    {
      name: t('media') || 'Media',
      routeTo: '/media',
      subroutes: [],
      iconClassName: '',
      iconChild: <MediaIcon />,
    },
    {
      name: t('users') || 'Users',
      routeTo: '/users',
      iconClassName: '',
      iconChild: <UserIcon />,
      subroutes: [],
    },
    {
      name: t('settings') || 'Settings',
      routeTo: '/settings',
      subroutes: [],
      iconClassName: '',
      iconChild: <SettingsIcon />,
    },
    {
      name: t('logout') || 'Logout',
      routeTo: '/logout',
      handleClick: handleLogoutClick,
      iconClassName: 'fa fa-sign-out',
    },
  ];
  return (
    <div className="h-full w-60 flex-shrink-0 bg-sidebar-bg pt-5 text-sidebar-text">
      {sideBarItems.map((each: any) => {
        const hasSubroutes = each.subroutes && each.subroutes.length > 0;

        return (
          <NavLink
            key={each.routeTo}
            to={!each.handleClick && each.routeTo}
            onClick={each.handleClick}
            className={({ isActive }) =>
              classNames('relative flex gap-4 bg-opacity-10 pl-6 text-lg', {
                'items-start': hasSubroutes && !each.handleClick && isActive,
                'items-center': !(hasSubroutes && isActive),
                'bg-white': !each.handleClick && isActive,
              })
            }>
            {({ isActive }) => {
              return (
                <>
                  <div
                    className={classNames('flex w-10 items-center justify-center', {
                      'mt-2': hasSubroutes && isActive,
                    })}>
                    <i
                      className={classNames(each.iconClassName, {
                        'text-blue-main': !each.handleClick && isActive,
                        'mt-1': hasSubroutes && isActive,
                      })}>
                      {each.iconChild}
                    </i>
                  </div>
                  <div className={classNames('flex-1')}>
                    <p
                      className={classNames('h-full py-3', {
                        'text-white': !each.handleClick && isActive,
                        'text-left': true,
                        'flex-1': true,
                        'border-r-4': !each.handleClick && isActive,
                        'border-green-main': !each.handleClick && isActive,
                      })}>
                      {t(each.name) || each.name}
                    </p>
                  </div>
                </>
              );
            }}
          </NavLink>
        );
      })}
    </div>
  );
}

export default Sidebar;
