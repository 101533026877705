import { useCallback, useMemo, useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, TopLayer, TitleBlock, Spinner, Switch, Header } from 'components';
import { TODO_ANY } from 'typings/common';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { surveyFormValidation } from 'validations/survey.validation';
import SurveyQuestion from '../Components/surveyQuestion';
import { ToastTypes, useToast } from 'context/toast';
import SurveyPublish from '../Components/surveyPublish';
import { surveyFormDefaultValues } from 'constants/survey.constant';
import SingleSelectDropdown from 'components/SingleSelectDropdown/singleSelectDropdown';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { SurveyDuration } from '../Components/surveyDuration';
import moment from 'moment';
import { rmSync } from 'fs';
import { template } from 'lodash';
import { getFormControlLabelUtilityClasses } from '@mui/material';
import { addOffSet, convertToMidNight, removeOffSet } from 'utils/ConvertDate';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';
import { upperCaseFirstLetter } from 'pages/Content/utils';

function Survey({ duplicate }: { duplicate: boolean }) {
  const { id } = useParams();
  const { t }: any = useTranslation();
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const isDuplicate = useMemo(() => !!duplicate, [duplicate]);
  const pageTitle = useMemo(
    () => (isDuplicate ? 'Add Survey' : isEditPage ? 'Edit Survey' : 'Add Survey'),
    [isEditPage, id],
  );
  const titleButtonText = useMemo(() => (isDuplicate ? 'Add' : isEditPage ? 'Save' : 'Add'), [isEditPage, id]);
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [companyPaginationParams, setCompanyPaginationParams] = useState({
    page: 1,
    search: '',
  });

  const [defaultValues, setDefaultValues] = useState<any>(surveyFormDefaultValues);
  const [companies, setCompanies] = useState<any[]>([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { callToast } = useToast();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const [isModalAction, setIsModalAction] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<any>([]);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [isPublishSaved, setIsPublishSaved] = useState(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [surveyToBeDuplicate, setSurveyToBeDuplicate] = useState<any>({});

  const {
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(surveyFormValidation),
  });

  const callbackFuncCompany = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (companies.length > 0) {
      if (entry.isIntersecting && companies.length < totalCompanies) {
        setCompanyPaginationParams({
          page: companyPaginationParams.page + 1,
          search: companyPaginationParams.search,
        });
      }
    }
  };

  const onCompanySearch = (val: string) => {
    setCompanyPaginationParams({
      page: 1,
      search: val,
    });
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getCompaniesAndSelected({
      page: companyPaginationParams.page,
      searchText: companyPaginationParams.search,
    });
  }, [companyPaginationParams.page, companyPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);

  const getSurveyById = async () => {
    try {
      let res = await getAPIData({
        url: `survey/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });

      setSurveyToBeDuplicate(res?.data);

      isDuplicate ? setValue('title', res?.data?.title + ' -copy') : setValue('title', res?.data?.title);
      isDuplicate ? setValue('statusId', '3') : setValue('statusId', res?.data?.statusId);
      isDuplicate ? setValue('publishTime', null) : setValue('publishTime', addOffSet(res?.data?.publishTime));
      const companyId = res.data.companyId ? res.data.companyId : '';
      setValue('companyId', companyId);
      const startDate = res.data.startDate ? res.data.startDate : new Date();
      setValue('startDate', addOffSet(startDate));
      const tmr = new Date(startDate);
      tmr.setDate(tmr.getDate() + 1);
      const endDate = res.data.endDate ? res.data.endDate : tmr;
      setValue('endDate', addOffSet(endDate));
      setSelectedCompany({
        label: res?.data?.company?.name,
        value: res?.data?.company?.id,
      });
      setIsTemplate(res?.data?.isTemplate);
      setValue('isTemplate', res?.data?.isTemplate);
      let questions;
      isDuplicate
        ? (questions = res?.data?.surveyQuestion.map((el: any) => {
            let options;
            if (el?.surveyOption) {
              options = el.surveyOption.map((op: any) => {
                return { text: op.text };
              });
            }
            return { question: el?.question, surveyOption: options ?? [], typeId: el?.typeId ?? '' };
          }))
        : (questions = res?.data?.surveyQuestion.map((el: any) => {
            return {
              id: el?.id,
              question: el?.question,
              surveyOption: el?.surveyOption ?? [],
              typeId: el?.typeId ?? '',
            };
          }));
      setValue('questions', questions);
      setValue(
        'title',
        res?.data?.surveyLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.title ?? '',
      );
      setCompanyId(res?.data?.companyId);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const getStatus = async () => {
    try {
      const res = await getAPIData({
        url: 'miscellaneous/status',
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      setStatusData(res?.data);
      const statusData: any = res?.data.map((status: any) => {
        return { id: status.id, status: status.status };
      });
      setValue('statusData', statusData);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const getInitialData = async () => {
    setLoading(true);
    await getQuestionType();
    await getStatus();
    if (id) {
      await getSurveyById();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getInitialData();
  }, [currentSelected?.id || selectedLanguage?.id]);

  const getCompaniesAndSelected = async (data: TODO_ANY) => {
    if (companyLoading) {
      return;
    }
    setCompanyLoading(true);
    try {
      const res = await getAPIData({
        url: 'companies',
        data: { ...data, limit: 15, languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      const companies = res.data.result;
      setTotalCompanies(res.data?.total);
      setCompanies((prev: any[]) => {
        return [
          ...(companyPaginationParams.page !== 1 ? prev : []),
          ...companies.map((company: TODO_ANY) => {
            return { label: company.name, value: company.id };
          }),
        ];
      });
      setCompanyLoading(false);
    } catch (err) {
      setCompanyLoading(false);
      console.error('cannot get companies data', err);
    }
  };

  const getQuestionType = async () => {
    try {
      const res = await getAPIData({
        url: 'survey/question-type',
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setQuestionOptions(res?.data.map((el: any) => ({ label: el?.type, value: el?.id })));
    } catch (err) {
      setLoading(false);
      console.error('cannot get companies data', err);
    }
  };
  const handleSurveyList = useCallback(() => {
    navigate('/surveys');
  }, [navigate]);

  const submitSurvey = async (data: any) => {
    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }
    let payload: any;
    const { statusData, ...remData } = data;
    if (remData.startDate && remData.endDate && !remData.isTemplate) {
      remData.startDate = convertToMidNight(new Date(data?.startDate).setHours(0, 0, 0, 0));
      remData.endDate = convertToMidNight(new Date(data?.endDate).setHours(23, 59, 59, 999));
    } else {
      remData.startDate = null;
      remData.endDate = null;
    }
    if (remData.isTemplate) {
      remData.companyId = null;
    }
    payload = remData;
    if (payload.publishTime) payload.publishTime = removeOffSet(payload.publishTime);
    payload.languageId = currentSelected?.id || selectedLanguage?.id;
    try {
      let res: any;
      setLoading(true);
      if (isEditPage && !isDuplicate) {
        const obj = { url: 'survey/' + id, data: payload };
        res = await putAPIData(obj);
        callToast(ToastTypes.SUCCESS, t('survey_updated') || 'Survey updated successfully');
      } else {
        const obj = { url: 'survey', data: payload };
        res = await postAPIData(obj);
        if (id) {
          let remainingLanguagesToBeDuplicate = surveyToBeDuplicate?.surveyLang?.reduce((acc: any, el: any) => {
            if (el?.languageId !== payload?.languageId) {
              return [...acc, el];
            }
            return acc;
          }, []);
          for (let i = 0; i < remainingLanguagesToBeDuplicate.length; i++) {
            let { title, languageId } = remainingLanguagesToBeDuplicate[i];
            let surveyQuestionForLanguage = surveyToBeDuplicate?.surveyQuestionAll?.reduce((acc: any, el: any) => {
              if (el?.languageId == languageId) {
                return [
                  ...acc,
                  {
                    question: el.question,
                    typeId: el.typeId,
                    surveyOption: el?.surveyOption?.map((item: any) => {
                      return {
                        text: item.text,
                      };
                    }),
                  },
                ];
              }
              return acc;
            }, []);

            await putAPIData({
              url: `survey/${res?.data?.id}`,
              data: {
                ...payload,
                title: title,
                languageId: languageId,
                questions: surveyQuestionForLanguage,
              },
            });
          }
        }
        callToast(ToastTypes.SUCCESS, t('survey_added') || 'Survey Added successfully');
      }
      setLoading(false);
      if (isModalAction) {
        reset(surveyFormDefaultValues);
        selectedData(currentSelectedLangId);
        setIsModalAction(false);
        navigate(`/surveys/edit/${res?.data?.id ?? id}`);
      } else {
        navigate('/surveys');
      }
    } catch (err: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(err?.response?.data?.message[0] ?? '') ?? err);
    }
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  return (
    <section>
      <div className="flex justify-between">
        {' '}
        <TopLayer
          onButtonClicked={handleSubmit(submitSurvey)}
          buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
          titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
          className="mb-8"
          onCancelClicked={handleSurveyList}
          disableBtn={!isPublishSaved || isLoading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
        <LanguageDropdown
          langLists={langLists}
          value={currentSelected?.langCode || selectedLanguage?.langCode}
          onChange={(e: any) => {
            setCurrentSelectedLangId(e);
            setResetConfirmation(true);
            setIsModalAction(true);
          }}
        />
      </div>
      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(surveyFormDefaultValues);
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
            setIsModalAction(false);
          }}
          deleteTitle={'How would you like to proceed '}
          type=""
          confirmation={true}
          buttonTitle={'Continue without saving'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          button3="Save & Continue"
          button3Action={() => {
            setResetConfirmation(false);
            submitSurvey(getValues());
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(submitSurvey)}>
          <div className="flex gap-12">
            <div className="flex-1">
              <div className="mb-8 w-full">
                <Input
                  label={t('survey_title', currentSelected?.langCode || selectedLanguage?.langCode) || 'Survey Title'}
                  required={true}
                  placeholder={
                    t('add_survey_title', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add survey title'
                  }
                  size="large"
                  name="title"
                  register={register}
                  errors={errors}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
                {/* {errors?.title && <p className="mt-2 text-sm italic text-red-600">{errors?.title?.message}</p>} */}
              </div>
              <TitleBlock
                title={t('survey_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Survey Fields'}>
                <div className="border-b-1 border-slate-100 p-[22px]">
                  <Switch
                    control={control}
                    action={setIsTemplate}
                    register={register}
                    required={true}
                    name="isTemplate"
                    checked={!!isTemplate}
                    label={
                      t('is_survey_template', currentSelected?.langCode || selectedLanguage?.langCode) ||
                      'Is Survey a Template?'
                    }
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
                {!isTemplate && (
                  <>
                    <SingleSelectDropdown
                      controlName="companyId"
                      selected={selectedCompany}
                      setSelected={setSelectedCompany}
                      setValue={setValue}
                      handleSearch={onCompanySearch}
                      control={control}
                      callbackFunc={callbackFuncCompany}
                      errors={errors}
                      dataSource={companies}
                      title={t('company') || 'Company'}
                      required={false}
                      langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      isLoading={companyLoading}></SingleSelectDropdown>
                    <div className="border-b-1 px-5 py-7">
                      <SurveyDuration
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        getValues={getValues}
                        setError={setError}
                        clearErrors={clearErrors}
                        langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      />
                    </div>
                  </>
                )}
                <div className="border-b-1 px-5 py-7">
                  <p className="mb-2.5">
                    {t('survey_question', currentSelected?.langCode || selectedLanguage?.langCode) ||
                      'Survey Questions'}
                    <span className="text-red-500">&nbsp;*</span>
                  </p>
                  <SurveyQuestion
                    control={control}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    watch={watch}
                    questionOptions={questionOptions}
                    isEdit={isEditPage}
                    setValue={setValue}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                    clearErrors={clearErrors}></SurveyQuestion>
                </div>
              </TitleBlock>
            </div>
            <div className="flex w-80 flex-shrink-0 flex-col gap-10">
              <TitleBlock
                title={t('publish', currentSelected?.langCode || selectedLanguage?.langCode) || 'Publish'}
                required={true}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}>
                <SurveyPublish
                  control={control}
                  watch={watch}
                  statusData={statusData}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setIsPublishSaved={setIsPublishSaved}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  errors={errors}></SurveyPublish>
              </TitleBlock>
            </div>
          </div>
        </form>
      )}
    </section>
  );
}
export default Survey;
