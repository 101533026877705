import * as yup from 'yup';
export const userFormValidation = yup.object().shape({
  username: yup.string().required('Username is required').matches(
    /^[a-z0-9\\._-]{2,20}$/,
    "Username must contain at least 2 characters and should be in lower case with no spaces."
  ),
  email: yup.string().required('Eamil Id is required').email('Invalid email'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  roleId: yup.string().required('Role is required'),
  imageUrl: yup.string(),
});
