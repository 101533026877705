import { TODO_ANY } from 'typings/common';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useRef, useEffect, useState } from 'react';
import { Spinner } from 'components';
import { useTranslation } from 'context/translation';
const options = {
  root: null,
  rootMargin: '-30px',
  threshold: 1,
};

function MultiSelectContainer({
  data,
  selectedOptions,
  handleSelect,
  handleRemove,
  displayName,
  handleSearchChange,
  hasNoMoreData,
  updatePage,
  register,
  selectAllHandler,
  inputValue,
  selectAll,
  langCode,
  loading,
}: TODO_ANY) {
  const containerRef = useRef(null);
  const { t }: any = useTranslation();

  const [searchContent, setSearchContent] = useState<any>(inputValue);
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef?.current);
    };
  }, [containerRef, options, data]);
  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (entry.isIntersecting && !hasNoMoreData) {
      updatePage();
    }
  };

  return (
    <>
      <div className="rounded-md border-1 border-solid ">
        <div className="rounded-t-[5px] border-b-1 border-solid px-6 pt-3 text-[16px] ">
          <div className="scrollbarCustom flex max-h-[145px] flex-wrap overflow-y-auto">
            {selectedOptions?.map((el: TODO_ANY) => {
              return (
                <span className="mr-[10px] pb-3" key={el.id}>
                  <Chip label={t(el.name) || el.name} onDelete={() => handleRemove(el.id)} />
                </span>
              );
            })}
            <input
              className="pb-3 outline-none disabled:bg-white"
              placeholder={langCode ? t('search', langCode) || 'Search...' : t('search') || 'Search...'}
              type={'text'}
              value={searchContent}
              onChange={(e) => {
                handleSearchChange(e?.target?.value);
                setSearchContent(e?.target?.value);
              }}
            />
          </div>
        </div>
        <div className="scrollbarCustom mx-[22px] my-[17px] max-h-[234px] overflow-y-auto">
          {data?.length ? (
            <FormGroup className="checkboxCustom">
              {data?.length > 2 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        selectAllHandler();
                        if (searchContent) {
                          setSearchContent('');
                          handleSearchChange('');
                        }
                      }}
                      checked={selectAll}
                    />
                  }
                  label={langCode ? t('select_all', langCode) || 'Select all' : t('select_all') || 'Select all'}
                />
              )}
            </FormGroup>
          ) : (
            !loading && <>{langCode ? t('no_data', langCode) || 'No data found' : t('no_data') || 'No data found'}</>
          )}

          {data?.length !== 0 &&
            data?.map((el: TODO_ANY) => {
              let isChecked = selectedOptions.filter((sEl: TODO_ANY) => sEl.id === el.id)?.length;
              return (
                <FormGroup className="checkboxCustom" key={el.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          if (searchContent) {
                            setSearchContent('');
                            handleSearchChange('');
                          }
                          isChecked ? handleRemove(el.id) : handleSelect(el.id);
                        }}
                        checked={isChecked > 0}
                      />
                    }
                    label={t(el?.name) || el?.name}
                  />
                </FormGroup>
              );
            })}
          {loading && <Spinner />}
          {data?.length !== 0 && <div ref={containerRef}></div>}
        </div>
      </div>
    </>
  );
}

export default MultiSelectContainer;
