import { Tooltip } from '@mui/material';
import { useTranslation } from 'context/translation';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import arrowUp from '../../assets/images/insert-above-icon.svg';
import arrowDown from '../../assets/images/insert-below-icon.svg';

const RowHandler = SortableHandle((showDrag) => {
    const { t } = useTranslation();

  return (
  <Tooltip title={t('drag')||"Drag"}>
    <div className={!showDrag?.showDrag ? "handle flex h-7 w-7 items-center justify-center rounded bg-blue-main text-white" :
     "handle flex h-7 w-7 items-center justify-center rounded bg-gray-main text-white" }>
      <i class="fa fa-arrows" aria-hidden="true"></i>
    </div>
  </Tooltip>
)});

const TableRow = ({ className, showDrag, rows, tableActions, dataItem }) => {
  return (
    <tr>
      {rows.map((el) => {
        return <td className="p-[20px] text-[14px] text-gray-text"> {el=='Invalid date' ? '' : el? el : '---'}</td>;
      })}
      <td className="actions gap-2 p-[20px] text-[14px] text-gray-text">
        <RowHandler showDrag={showDrag} />
        {tableActions.map((sEl) => {
          return (
            <Tooltip title={sEl.info}>
              <button
                key={sEl.type}
                className="flex h-7 w-7 items-center justify-center rounded bg-blue-main text-white"
                onClick={() => sEl?.onClick(dataItem)}>
                {sEl?.iconClassName ? (
                  <i className={sEl.iconClassName}></i>
                ) : sEl.btnIcon == 'Up' ? (
                  <img src={arrowUp} alt="arrow" width="20" />
                ) : sEl.btnIcon == 'Down' ? (
                  <img src={arrowDown} alt="arrow" width="20" />
                ) : (
                  ''
                )}
              </button>
            </Tooltip>
          );
        })}
      </td>
    </tr>
  );
};

export default TableRow;
