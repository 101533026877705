import { useEffect, useState } from 'react';
import { Icon } from 'components';

import { Controller } from 'react-hook-form';
import dropdown from '../../../assets/images/dropdown.svg';
import MaterialUIPickers from 'components/DatePicker';
import { Checkbox as MuiCheckbox } from '@mui/material';
import moment from 'moment';
import { STATUS_TYPE } from 'constants/survey.constant';
import { getValue } from '@mui/system';
import classNames from 'classnames';
import { useTranslation } from 'context/translation';
const SurveyPublish = ({
  control,
  watch,
  statusData,
  setValue,
  errors,
  clearErrors,
  setIsPublishSaved,
  langCode,
}: {
  control: any;
  watch: Function;
  statusData: any[];
  setValue: Function;
  errors: any;
  clearErrors: Function;
  setIsPublishSaved: Function;
  langCode?: any;
}) => {
  const [edit, setEdit] = useState({
    status: false,
    publish: false,
  });
  const [isPublishImmediate, setIsPublishImmediate] = useState(false);
  const [publishTime, setPublishTime] = useState(new Date());
  const { t }: any = useTranslation();

  useEffect(() => {
    const isDraft = getSelectedStatus(watch('statusId'), statusData)?.status === STATUS_TYPE.Draft;
    if (Object.values(edit).every((data: any) => data === false) || (edit.status === false && isDraft)) {
      setIsPublishSaved(true);
    } else {
      setIsPublishSaved(false);
    }
  }, [edit]);

  return (
    <div className="px-5">
      <div
        className={classNames(
          'flex items-start justify-between gap-2.5 border-solid border-gray-border py-3',
          getSelectedStatus(watch('statusId'), statusData)?.status === STATUS_TYPE.Published && 'border-b-1',
        )}>
        <Icon type={Icon.Type.GREAT} className="text-blue-main" />
        <div className="flex-1 text-sm font-semibold">
          <span className=" text-green-deep ">Status: </span>
          {!edit?.status && (
            <span className="text-blue-main">{getSelectedStatus(watch('statusId'), statusData)?.status ?? '-'}</span>
          )}
          {edit?.status && (
            <span className="relative inline-block ">
              <Controller
                control={control}
                name={'statusId'}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <select
                    className="flex w-full appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-1 px-1 pr-[30px] text-[14px] text-blue-main outline-none"
                    onChange={(val: any) => {
                      onChange(val);
                      let statusText = getSelectedStatus(watch('statusId'), statusData)?.status;
                      if (statusText === STATUS_TYPE.Published) {
                        setEdit({ status: true, publish: true });
                        clearErrors('statusId');
                        if (!watch('publishTime')) {
                          setValue('publishTime', new Date());
                        }
                      }
                      if (statusText === STATUS_TYPE.Draft || statusText === STATUS_TYPE.Review) {
                        setValue('publishTime', null);
                        setIsPublishImmediate(false);
                        setEdit((prev) => {
                          return { ...prev, publish: false };
                        });
                      }
                    }}
                    value={value}>
                    <option value="">Select</option>
                    {statusData?.map((status: any) => {
                      return <option value={status?.id}>{status?.status}</option>;
                    })}
                  </select>
                )}
              />

              <img
                src={dropdown}
                alt="arrow"
                className="pointer-events-none absolute top-[8px] right-[8px] h-[15px] w-[15px]"
                title="arrow"></img>
            </span>
          )}
          {errors?.statusId && (
            <p className="mt-2 text-sm italic text-red-600">
              {(langCode ? t('status_required', langCode) : t('status_required')) || 'Status is required.'}
            </p>
          )}
        </div>
        <button
          onClick={() => {
            setEdit((prev) => {
              return { ...prev, status: !prev?.status ?? false };
            });
          }}
          className="rounded-md border-1 border-green-main bg-transparent px-3 py-0.5 leading-5 text-blue-main"
          type="button">
          {edit?.status ? 'Save' : 'Edit'}
        </button>
      </div>

      {getSelectedStatus(watch('statusId'), statusData)?.status === STATUS_TYPE.Published && (
        <div>
          <div className="flex items-start justify-between gap-2.5  py-3">
            <Icon type={Icon.Type.CALENDAR_EDIT} className="text-blue-main" />
            <div className="flex-1 text-sm font-semibold">
              <p className=" text-green-deep">Published on:</p>
              {!edit?.publish && (
                <p className="text-blue-main">
                  {watch('publishTime') ? moment(watch('publishTime')).format('LLL') : '-'}
                </p>
              )}

              {edit?.publish && (
                <>
                  <span className="block py-2">
                    <label className="flex items-center text-[12px] text-gray-text">
                      <MuiCheckbox
                        className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-main"
                        style={{ color: '#20A8D8', transform: 'scale(0.8)' }}
                        defaultChecked={isPublishImmediate}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsPublishImmediate(true);
                            setValue('publishTime', new Date());
                            clearErrors('publishTime');
                          } else {
                            setIsPublishImmediate(false);

                            // setValue('publishTime', null);
                          }
                        }}
                      />
                      <span className="mx-[2px]">Publish Now</span>
                    </label>
                  </span>
                </>
              )}
            </div>
            <button
              disabled={getSelectedStatus(watch('statusId'), statusData)?.status === STATUS_TYPE.Draft}
              onClick={() => {
                setEdit((prev) => {
                  return { ...prev, publish: !prev?.publish ?? false };
                });
              }}
              type="button"
              className="rounded-md border-1 border-green-main bg-transparent px-3 py-0.5 leading-5 text-blue-main ">
              {edit?.publish
                ? (langCode ? t('save', langCode) : t('save')) || 'Save'
                : (langCode ? t('edit', langCode) : t('edit')) || 'Edit'}
            </button>
          </div>
          {!isPublishImmediate && edit.publish && (
            <div className="my-1 flex">
              <MaterialUIPickers
                setValue={setValue}
                name="publishTime"
                publishTime={watch('publishTime')}
                setPublishTime={setPublishTime}
                clearErrors={clearErrors}
                langCode={langCode}
              />
            </div>
          )}
          {errors?.publishTime && <p className="mt-2 text-sm italic text-red-600">Publish time is required.</p>}
        </div>
      )}
    </div>
  );
};

function getSelectedStatus(typeId: string, statusOptions: any[]) {
  return statusOptions.find((el: any) => el?.id === typeId) ?? undefined;
}

export default SurveyPublish;
