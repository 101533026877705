import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { ICategory, NestedSelectionType } from 'pages/Content/utils';
import { SmallLoader } from 'components';
import moment from 'moment';
import { useTranslation } from 'context/translation';

const MultiSelectContainerNested = ({
  parentOptions,
  nestedOptions,
  selectedOptions,
  selectChild,
  selectAll,
  addToSelectedCategories,
  removeFromSelectedCategories,
  allOptions,
  deselectAll,
  loading,
  type,
  langCode,
}: {
  parentOptions: ICategory[];
  nestedOptions: any;
  selectedOptions: string[];
  selectChild?: any;
  selectAll: Function;
  addToSelectedCategories: Function;
  removeFromSelectedCategories: Function;
  allOptions: ICategory[];
  deselectAll: Function;
  loading: boolean;
  type: NestedSelectionType;
  langCode?: any;
}) => {
  const { t }: any = useTranslation();

  function checkIfNestingOccurs(id: string) {
    return nestedOptions[id] ? nestedOptions[id] : [];
  }

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSelectedIds([...new Set(selectedOptions)]);
  }, [selectedOptions]);

  const getOption = (id: string) => {
    return allOptions?.find((el: ICategory) => el.id === id) ?? undefined;
  };

  const searchHandler = (val: string) => {
    setSearch(val);
  };

  const dataCount = () => {
    let count = 0;
    for (let i = 0; i < parentOptions.length; i++) {
      count += checkIfAnyChildHasNameCount(parentOptions[i]);
    }
    return count;
  };

  const checkIfAnyChildHasNameCount = (el: ICategory) => {
    let count = 0;
    if (el.name.toLowerCase().includes(search.toLowerCase())) {
      count++;
    }

    const arr = checkIfNestingOccurs(el.id);
    for (let i = 0; i < arr.length; i++) {
      if (checkIfAnyChildHasNameCount(arr[i]) > 0) {
        count += checkIfAnyChildHasNameCount(arr[i]) + 1;
      }
    }
    return count;
  };

  const checkIfAnyChildHasName = (el: ICategory) => {
    if (!search) return true;
    if (el.name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    const arr = checkIfNestingOccurs(el.id);
    for (let i = 0; i < arr.length; i++) {
      if (checkIfAnyChildHasName(arr[i])) {
        return true;
      }
    }
    if (el.parent_id) {
      return checkIfParentHasName(el.id, el.parent_id);
    }
    return false;
  };

  function findInNestedOptions(id: string, parent_id: string) {
    let parent;
    for (const option in nestedOptions) {
      parent = checkIfNestingOccurs(option).find((el: ICategory) => el.id === parent_id);
      if (parent && parent.name.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (parent) {
        return false;
      }
    }
    return false;
  }

  function checkIfParentHasName(id: string, parent_id: string) {
    const parent = parentOptions.find((el) => el.id === parent_id);
    if (parent && parent.name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (parent) {
      return false;
    }
    return findInNestedOptions(id, parent_id);
  }
  const SelectChildOfChilds = (data: any) => {
    if (!data.length) return;
    for (let key of data) {
      // console.log(key?.id, 'ID')
      selectedOptions.push(key?.id);
      if (checkIfNestingOccurs(key?.id).length) {
        SelectChildOfChilds(checkIfNestingOccurs(key?.id));
      }
    }
    return;
  };
  const nestedSelect = (data: any) => {
    if (selectChild) {
      selectedOptions.push(data?.id);
      let arr = checkIfNestingOccurs(data?.id);
      SelectChildOfChilds(arr);
    }
    return addToSelectedCategories(data);
  };
  function nestedDiv(id: string, isVisible: boolean) {
    return checkIfNestingOccurs(id).map((el: any) => {
      let isChecked = selectedIds.filter((id: string) => id === el.id).length;
      let childHasSearchedValues = checkIfAnyChildHasName(el);
      isVisible = isVisible || (search ? el.name.toLowerCase().includes(search.toLowerCase()) : true);
      if (!childHasSearchedValues) {
        return <React.Fragment key={el.id}></React.Fragment>;
      }
      return (
        <FormGroup className="checkboxCustom" key={el.id}>
          <div style={{ marginLeft: (el.level ?? 0) * 10 + 'px' }}>
            {isVisible && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      isChecked ? removeFromSelectedCategories(el) : nestedSelect(el);
                      setSearch('');
                    }}
                    checked={isChecked > 0}
                  />
                }
                label={
                  <div>
                    {t(el?.name) || el?.name}
                    {el?.company_id && (
                      <span className="italic text-orange-main">
                        &nbsp;({langCode ? t('custom', langCode) || 'custom' : t('custom') || 'custom'})
                      </span>
                    )}
                    &nbsp;
                    <span className="italic text-gray-subject-text">
                      ({langCode ? t('created', langCode) || 'created' : t('created') || 'created'}:{' '}
                      {moment(el?.created_at).format('YYYY-MM-DD h:mma')})
                    </span>
                  </div>
                }
              />
            )}
            <span>{nestedDiv(el.id, isVisible)}</span>
          </div>
        </FormGroup>
      );
    });
  }

  if (!parentOptions) return <></>;

  return (
    <>
      <div className="rounded-md border-1 border-solid ">
        <div className="rounded-t-[5px] border-b-1 border-solid px-6 pt-3 text-[16px] ">
          <div className="scrollbarCustom flex max-h-[145px] flex-wrap overflow-y-auto">
            {selectedIds?.map((el: string) => {
              return (
                <span className="mr-[10px] pb-3" key={el}>
                  <Chip
                    label={getOption(el)?.name ?? ''}
                    onDelete={() => removeFromSelectedCategories(getOption(el))}
                  />
                </span>
              );
            })}
            <input
              className="pb-3 outline-none"
              placeholder={langCode ? t('search', langCode) || 'Search' : t('search') || 'Search'}
              value={search}
              type={'text'}
              onChange={(e) => {
                searchHandler(e.target.value);
              }}
            />
          </div>
        </div>

        {loading ? (
          <div className="scrollbarCustom mx-[22px] my-[17px] max-h-[234px] overflow-y-auto">
            <div className="mx-[22px]">
              <SmallLoader langCode={langCode} />
            </div>
          </div>
        ) : dataCount() === 0 ? (
          <div className="scrollbarCustom mx-[22px] my-[17px] max-h-[234px] overflow-y-auto">
            <div>
              {langCode ? t('no_data_found', langCode) || 'No data Found' : t('no_data_found') || 'No data Found'}
            </div>
          </div>
        ) : (
          <div className="scrollbarCustom mx-[22px] my-[17px] max-h-[234px] overflow-y-auto">
            {dataCount() > 1 && type === NestedSelectionType.MULTI_SELECT && (
              <FormGroup className="checkboxCustom">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        if (allOptions.length === selectedOptions.length) {
                          deselectAll();
                        } else {
                          selectAll();
                        }
                      }}
                      checked={allOptions.length === selectedOptions.length}
                    />
                  }
                  label={langCode ? t('select_all', langCode) || 'Select All' : t('select_all') || 'Select All'}
                />
              </FormGroup>
            )}

            {parentOptions.map((el: any) => {
              let isChecked = selectedIds.filter((id: string) => id === el.id).length;
              let childHasSearchedValues = checkIfAnyChildHasName(el);
              let isVisible = search ? el.name.toLowerCase().includes(search.toLowerCase()) : true;
              if (!childHasSearchedValues) {
                return <React.Fragment key={el.id}></React.Fragment>;
              }
              return (
                <FormGroup className="checkboxCustom" key={el.id}>
                  <div style={{ marginLeft: (el.level ?? 0) * 10 + 'px' }}>
                    {isVisible && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              isChecked ? removeFromSelectedCategories(el) : nestedSelect(el);
                              // handleClick(el);
                              setSearch('');
                            }}
                            checked={isChecked > 0}
                          />
                        }
                        label={
                          <div>
                            {el?.name}
                            {el?.company_id && <span className="italic text-orange-main">&nbsp;(custom)</span>}&nbsp;
                            <span className="italic text-gray-subject-text">
                              ({langCode ? t('created', langCode) || 'created' : t('created') || 'created'}:{' '}
                              {moment(el?.created_at).format('YYYY-MM-DD h:mma')})
                            </span>
                          </div>
                        }
                      />
                    )}
                    <span>{nestedDiv(el.id, isVisible)}</span>
                  </div>
                </FormGroup>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MultiSelectContainerNested;
