import { QUESTION_TYPES, STATUS_TYPE } from 'constants/survey.constant';
import * as yup from 'yup';

const questionValidation = {
  id: yup.string(),
  question: yup
    .string()
    .required('Question text cannot be empty')
    .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
      return !!value?.trim();
    }),
  typeId: yup.string().required('Question Type cannot be empty'),
  surveyOption: yup.array().nullable(),
};

export const questionsValidation = yup
  .object()
  .shape(questionValidation)
  .when((value, schema) => {
    if (value.typeId === QUESTION_TYPES.MultipleChoice) {
      return schema.shape({
        ...questionValidation,
        surveyOption: yup
          .array()
          .of(
            yup.object().shape({
              text: yup
                .string()
                .required('Option Text cannot be empty')
                .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
                  return !!value?.trim();
                }),
            }),
          )
          .min(1, 'Minimum 1 option is required'),
      });
    } else {
      return schema.shape(questionValidation);
    }
  });
export const surveyFormValidation = yup
  .object()
  .shape({
    title: yup
      .string()
      .required('Title is required.')
      .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
        return !!value?.trim();
      }),
    companyId: yup.string(),
    statusId: yup.string().required('Please select a status.'),
    startDate: yup.date().default(() => new Date()),
    endDate : yup
    .date().required('This field is required')
    .when('startDate', (startDate, schema) => startDate && schema?.min(startDate,"End date should be greater than start date")),
    questions: yup.array().of(questionsValidation).min(1, 'Minimum 1 question is required'),
  })
  .when((values, schema) => {
    let statusText = values?.statusData.find((data: any) => data.id === values.statusId)?.status;
    if (statusText === STATUS_TYPE.Published) {
      return schema.shape({
        publishTime: yup
          .date()
          .required('This field is required')
          .nullable()
          .default(() => new Date()),
      });
    }
  })
  // .when((values, schema) => {
  //   if (values.startDate) {
  //     return schema.shape({
  //       startDate: yup.date().nullable(),
  //       endDate: yup
  //         .date()
  //         .required('End date is required')
  //         .typeError('Invalid date')
  //         .when(
  //           'startDate',
  //           (startDate, schema) =>
  //             startDate &&
  //             schema?.min(
  //               new Date(+new Date(startDate).getTime() + 86400000),
  //               'End date should be greater than start date',
  //             ),
  //         )
  //         .nullable(),
  //     });
  //   }
  //   // if (values.endDate) {
  //   //   return schema.shape({
  //   //     endDate: yup.date().nullable().typeError('Invalid date'),
  //   //     startDate: yup.date().required('Start date is required').typeError('Invalid date').nullable(),
  //   //   });
  //   // }
  // });
