import { InputBoxType } from 'enums/companyForm.enum';

export const addUserFields = (t: any, lang: any) => {
  return [
    {
      type: InputBoxType.Input,
      name: 'username',
      id: 'username',
      placeholder: '',
      label: t('username', lang),
      required: true,
      btnLabel: '',
      inputType: 'text',
    },
    {
      type: InputBoxType.Input,
      name: 'email',
      id: 'email',
      placeholder: '',
      label: t('email', lang),
      required: true,
      btnLabel: '',
      inputType: 'text',
    },
    {
      type: InputBoxType.Input,
      name: 'firstName',
      id: 'firstname',
      placeholder: '',
      label: t('first_name', lang) || 'First Name',
      required: true,
      btnLabel: '',
      inputType: 'text',
    },
    {
      type: InputBoxType.Input,
      name: 'lastName',
      id: 'lastname',
      placeholder: '',
      label: t('last_name', lang) || 'Last Name',
      required: true,
      btnLabel: '',
      inputType: 'text',
    },
    {
      type: InputBoxType.Select,
      name: 'roleId',
      id: 'roleId',
      placeholder: '',
      label: t('role', lang) || 'Role',
      required: true,
      btnLabel: '',
      inputType: 'text',
      options: [
        { label: 'option 1', value: 1 },
        { label: 'option 2', value: 2 },
        { label: 'option 3', value: 3 },
        { label: 'option 4', value: 4 },
      ],
    },
    {
      type: InputBoxType.Image,
      name: 'imageUrl',
      id: 'imageUrl',
      placeholder: '',
      label: t('profile_picture', lang) || 'Profile Picture',
      required: false,
      btnLabel: '',
      inputType: 'file',
    },
  ];
};
export const userDeafultValues = {
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  roleId: '',
  imageUrl: '',
};
