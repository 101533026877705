export enum DefaultPages {
  Terms = 'Terms of Service',
  Privacy = 'Privacy Policy',
}

export enum DefaultBadges {
  Journey = 'Journey',
  Actions = 'Actions',
  Quest = 'Quest',
  Favorites = 'Favorites',
  Sharing = 'Sharing',
  Begin = 'Begin Again',
  Leader = 'Inclusive Leader',
  LevelUp = 'Level Up',
}
