import cx from 'classnames';
import { useTranslation } from 'context/translation';
import { ComponentDivProps, TODO_ANY } from 'typings/common';

interface ContentCellProps extends ComponentDivProps {
  title: string;
  subject?: string;
  isVertical?: boolean | undefined;
  otherComponent?: TODO_ANY;
  required?: boolean;
  className?: string;
  subjectClassname?: string;
}

function ContentCell({
  title,
  subject,
  children,
  isVertical,
  otherComponent,
  required,
  className = '',
  subjectClassname = '',
}: ContentCellProps) {
  const { t }: any = useTranslation();

  return (
    <div
      className={cx(
        'flex gap-2 border-b-1 border-solid border-gray-border p-6',
        {
          'flex-row items-center': isVertical,
          'flex-col ': !isVertical,
        },
        className,
      )}>
      <div
        className={cx({
          'w-56 flex-shrink-0': isVertical,
        })}>
        <p className="text-lg font-bold text-gray-text">
          {t(title) || title}
          {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
        </p>
        {subject && <p className={`mt-2 text-sm text-gray-subject-text ${subjectClassname}`}></p>}
      </div>
      <div className="w-full">
        {children}
        {otherComponent && <div className="mt-4">{otherComponent} </div>}
      </div>
    </div>
  );
}

export default ContentCell;
