import { debounce } from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Chip from '@mui/material/Chip';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { TODO_ANY } from 'typings/common';
import { SmallLoader } from 'components';
import classNames from 'classnames';
import { useTranslation } from 'context/translation';
import { convertToKey } from 'pages/Content/utils';

const SingleSelectDropdown = ({
  controlName,
  selected,
  setSelected,
  setValue,
  handleSearch,
  control,
  errors,
  dataSource,
  title,
  required,
  isLoading,
  callbackFunc,
  className = '',
  description,
  langCode,
}: {
  controlName: string;
  selected: any;
  setSelected: Function;
  setValue: Function;
  handleSearch: (val: string) => void;
  control: any;
  errors: any;
  dataSource: any[];
  title: string;
  required: boolean;
  callbackFunc: (entries: TODO_ANY) => void;
  isLoading?: boolean;
  className?: string;
  description?: string;
  langCode?: any;
}) => {
  const debounceOnChange = useRef(debounce(handleSearch, 400)).current;
  const [searchText, setSearchText] = useState('');
  const { t }: any = useTranslation();

  const handleSearchInputChange = (val: string) => {
    setSearchText(val);
    debounceOnChange(val);
  };
  const containerRef = useIntersectionObserver(callbackFunc, dataSource);
  useEffect(() => {
    return () => {
      debounceOnChange.cancel();
    };
  }, [debounceOnChange]);
  return (
    <div className={classNames('border-b-1 px-5 py-7', className)}>
      <p className="text-lg font-bold text-gray-text">
        {t(title, langCode) || title} {required && <span className="text-red-500">*</span>}
      </p>
      {description && (
        <p className="text-[14px] font-light text-gray-subject-text">{t(description, langCode) || description}</p>
      )}
      <div>
        <div className="placeholder-text-grey-text mt-2.5 flex w-full items-center gap-1 rounded-t-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none">
          {selected?.label && (
            <Chip
              label={t(selected?.label, langCode) || selected?.label}
              onDelete={() => {
                setSelected({});
                setValue(controlName, '');
                handleSearchInputChange('');
              }}
            />
          )}
          <input
            className=" placeholder-text-grey-text w-full outline-none disabled:bg-white"
            onChange={(e: any) => {
              handleSearchInputChange(e.target.value);
            }}
            disabled={false}
            placeholder={t('search', langCode) || 'Search...'}
            value={searchText}
          />
        </div>
        <div className="mt-[-2px] rounded-b-md border-l-1 border-r-1 border-b-1 p-5">
          <div className="scrollbarCustom  max-h-[234px] overflow-y-auto ">
            {searchText && isLoading ? (
              <SmallLoader langCode={langCode} />
            ) : (
              <>
                {dataSource?.length ? (
                  <div>
                    {dataSource?.map((data: { label: string; value: string }, index: number) => {
                      return (
                        <section className="flex items-center">
                          <label className="customRadio flex cursor-pointer text-[14px] text-gray-text">
                            <Controller
                              name={controlName}
                              control={control}
                              render={({ field, ...props }) => {
                                return (
                                  <input
                                    type="radio"
                                    value={data?.value}
                                    onChange={() => {
                                      field.onChange(data?.value);
                                      setSelected(data);
                                      setSearchText('');
                                      handleSearchInputChange('');
                                    }}
                                    name={`${controlName}Radio`}
                                    checked={data?.value === field.value}
                                  />
                                );
                              }}
                            />
                            <span className="pl-3">{t(data.label) || data.label}</span>
                          </label>
                        </section>
                      );
                    })}
                    <div ref={containerRef} style={{ height: '10px' }}>
                      {isLoading ? <SmallLoader langCode={langCode} /> : ''}
                    </div>
                  </div>
                ) : (
                  <>{t('no_data', langCode) || 'No data found'}</>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {errors?.[controlName] && (
        <p className="mt-2 text-sm italic text-red-600">
          {t(convertToKey(errors?.[controlName]?.message), langCode) || errors?.[controlName]?.message}
        </p>
      )}
    </div>
  );
};

export default SingleSelectDropdown;
