import { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import QuizForm from 'components/Forms/QuizForm';
import CustomEditor from 'components/CustomEditor';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import LogoPicker from 'components/LogoPicker';
import { TitleBlock, Input, Icon, TopLayer, Switch, Spinner, Header } from 'components';
import arrowUpSolid from '../../../assets/images/arrow-upSolid.svg';
import arrowDownSolid from '../../../assets/images/arrow-downSolid.svg';
import dropdown from '../../../assets/images/dropdown.svg';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { useForm } from 'react-hook-form';
import MultiSelectContainer from 'components/MultiSelectDropdown/custom';
import { TODO_ANY } from 'typings/common';
import {
  convertToNestedOptions,
  editorInputs,
  ICategory,
  NestedSelectionType,
  SlectedKeyWordsID,
  upperCaseFirstLetter,
} from '../utils';
import { contentFormValidation } from 'validations/contentFormValidation';
import { initialDefaultValues } from '../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleDefaultValues, getStatusText, getPublishTime } from '../utils';
import VideoContent from 'components/Content/VideoContent';
import QuoteContent from 'components/Content/QuoteContent';
import { nestingMultiSelectOption } from 'pages/Content/utils';
import { ToastTypes, useToast } from '../../../context/toast';
import { filterPayload } from '../utils';

import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import MultiSelectContainerNested from 'components/MultiSelectDropdown/NestedSelect';
import { removeOffSet } from 'utils/ConvertDate';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import SurveyPublish from 'pages/Survey/Components/surveyPublish';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';

function Content({ duplicate }: { duplicate: boolean }) {
  const { id } = useParams();
  const { authUser } = useAuth();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [contentToBeDuplicate, setContentToBeDuplicate] = useState<any>({});
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const isDuplicate = useMemo(() => !!duplicate, [duplicate, id]);
  const { callToast } = useToast();
  const [keyWordsSearch, setKeyWordsSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [contentState, setContentState] = useState<any>([]);
  const [contentTypeData, setContentTypeData] = useState<any>([]);
  const titleButtonText = useMemo(() => (isDuplicate ? 'Add' : isEditPage ? 'Save' : 'Add'), [isEditPage, id]);
  const [type, setType] = useState({ id: 0, type: '' });
  const [defaultValues, setDefaultValues] = useState<any>(initialDefaultValues);
  const [statusData, setStatusData] = useState<any>([]);
  const [statusText, setStatusText] = useState<any>();
  const [disableBtn, setDisableBtn] = useState(false);
  const [publishTime, setPublishTime] = useState(new Date());
  const [publishContent, setPublishContent] = useState<any>('');
  const [keywordsData, setKeywordsData] = useState<any>({
    input: '',
    list: [],
  });

  const [isPublishSaved, setIsPublishSaved] = useState(false);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const [isModalAction, setIsModalAction] = useState<boolean>(false);
  const [parentOptions, setParentOptions] = useState<any>([]);
  const [nestedOptions, setNestedOptions] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [nestedLoading, setNestedLoading] = useState<boolean>(false);
  const [selectLoadings, setSelectLoadings] = useState({
    categoryLoading: false,
    groupLoading: false,
    companyLoading: false,
  });
  const selectLoadingHandler = (type: string, val: boolean) => {
    setSelectLoadings((prev) => {
      return { ...prev, [type]: val };
    });
  };
  const [edit, setEdit] = useState({
    status: false,
    publish: false,
  });
  const [selectedKeyword, setSelectedKeyword] = useState<any>([]);
  const [dropDown, setdropDown] = useState({
    category: true,
    visibility: true,
    content: true,
    publish: true,
    imageUpload: true,
    video: true,
    quiz: true,
    quote: true,
  });

  const [multiSelectCategoryOptions, setMultiSelectCategoryOptions] = useState([]);
  const [categorySelections, setCategorySelections]: TODO_ANY = useState([]);
  const [categoryPaginationParams, setCategoryPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [categoryTotal, setCategoryTotal] = useState(null);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [hasNoMoreCategoryData, setHasNoMoreCategoryData] = useState(false);
  const [selectCategoryAll, setSelectCategoryAll] = useState(false);

  const [multiSelectGroupOptions, setMultiSelectGroupOptions] = useState([]);
  const [groupSelections, setGroupSelections]: TODO_ANY = useState([]);
  const [groupPaginationParams, setGroupPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [groupTotal, setGroupTotal] = useState(null);

  const [groupData, setGroupData] = useState<any[]>([]);
  const [hasNoMoreGroupData, setHasNoMoreGroupData] = useState(false);
  const [selectGroupAll, setSelectGroupAll] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [multiSelectCompanyOptions, setMultiSelectCompanyOptions] = useState([]);
  const [companySelections, setCompanySelections]: TODO_ANY = useState([]);
  const [companyPaginationParams, setCompanyPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [imageId, setImageId] = useState<any>('');
  const [videoId, setVideoId] = useState<any>('');
  const [companyTotal, setCompanyTotal] = useState(null);
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [hasNoMoreCompanyData, setHasNoMoreCompanyData] = useState(false);
  const [selectCompanyAll, setSelectCompanyAll] = useState(false);
  const [newKeyWordsArray, setNewKeyWordsArray] = useState<any>([]);
  const [customCategorySelected, setCustomCategorySelected] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const { t }: any = useTranslation();
  //functions

  const pageTitle = useMemo(
    () =>
      isDuplicate
        ? t('add_content', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Content'
        : isEditPage
        ? t('edit_content', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Content'
        : t('add_content', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Content',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode, id],
  );

  const handleDropDown = (key: string) => {
    setdropDown((dropDown: any) => {
      return { ...dropDown, [key]: !dropDown[key] };
    });
  };

  const getCategoryData = async () => {
    if (categoryData?.length === categoryTotal) {
      setHasNoMoreCategoryData(true);
    }
    selectLoadingHandler('categoryLoading', true);
    try {
      let res = await getAPIData({
        url: 'categories',
        data: {
          page: categoryPaginationParams.page,
          searchText: categoryPaginationParams.search,
          limit: 50,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setCategoryData(res?.data?.result);
      setCategoryTotal(res?.data?.total);
      const options: any = nestingMultiSelectOption(res?.data?.result);
      setMultiSelectCategoryOptions(options);
      selectLoadingHandler('categoryLoading', false);
    } catch (e: any) {
      selectLoadingHandler('categoryLoading', false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  const getGroupData = async () => {
    if (groupData?.length === groupTotal) {
      setHasNoMoreGroupData(true);
    }
    selectLoadingHandler('groupLoading', true);
    try {
      let res = await getAPIData({
        url: 'miscellaneous/group',
        data: {
          page: groupPaginationParams.page,
          searchText: groupPaginationParams.search,
          limit: 100,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setGroupData(res?.data?.result);
      setGroupTotal(res?.data?.total);
      const options: any = nestingMultiSelectOption(res?.data?.result);
      setMultiSelectGroupOptions(options);
      selectLoadingHandler('groupLoading', false);
    } catch (e: any) {
      selectLoadingHandler('groupLoading', false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      console.error(e);
    }
  };

  const getStatus = async () => {
    try {
      const res = await getAPIData({
        url: 'miscellaneous/status',
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      setStatusData(res?.data);
      const data = res?.data?.find((data: any) => data?.id === contentState?.statusId);
      setStatusText(data);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };

  const getCompanyData = async () => {
    if (companiesData?.length === companyTotal) {
      setHasNoMoreCompanyData(true);
    }
    selectLoadingHandler('companyLoading', true);

    try {
      let res = await getAPIData({
        url: 'companies',
        data: {
          page: companyPaginationParams.page,
          searchText: companyPaginationParams.search,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
        limit: 50,
      });
      setCompaniesData(res?.data?.result);
      setCompanyTotal(res?.data?.total);
      const options: any = nestingMultiSelectOption(res?.data?.result);
      setMultiSelectCompanyOptions(options);
      selectLoadingHandler('companyLoading', false);
    } catch (e: any) {
      selectLoadingHandler('companyLoading', false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  const getContentData = async () => {
    try {
      const res = await getAPIData({
        url: `content/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setContentState(res?.data);
      setContentToBeDuplicate(res?.data);
      reset(handleDefaultValues(res?.data));
      setDefaultValues(handleDefaultValues(res?.data));
      setType(res?.data?.contentType);
      if (res?.data?.contentCategory?.length === 1) {
        const selectedCat = res.data.contentCategory[0];
        if (selectedCat.companyId && selectedCat.company) {
          setCustomCategorySelected(true);
          setSelectedCompany(selectedCat.company);
        }
      }
      setValue('keywords', SlectedKeyWordsID(res?.data?.contentKeyword, true));
      setSelectedKeyword(SlectedKeyWordsID(res?.data?.contentKeyword, false));
      setImageId(res?.data?.imageId);
      setVideoId(res?.data?.videoId);
      if (res?.data?.srt?.id) {
        setVideoId([res?.data?.videoId, res?.data?.srt?.id]);
      }
      if (!isDuplicate) {
        setPublishContent(res?.data?.status?.status);
        setPublishTime(new Date(res?.data?.publishTime) || new Date());
      } else {
        setValue('publishTime', null);
        setValue('statusId', null);
      }
      setValue('contentType', res?.data?.contentType?.type);
      setValue('contentTypeId', res?.data?.contentType?.id);
      setValue(
        'name',
        res?.data?.contentLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.name ?? '',
      );

      setValue(
        'quote',
        res?.data?.contentLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.quote ?? '',
      );

      setValue(
        'quoteAuthor',
        res?.data?.contentLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.quote ?? '',
      );

      setValue(
        'textField',
        res?.data?.contentLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.textField ?? '',
      );
    } catch (e: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    reset,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: false,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(contentFormValidation),
  });
  const handleContentList = useCallback(() => {
    navigate('/content');
  }, [navigate]);

  const getFormData = (data: any) => {
    let A = [];

    for (let key of newKeyWordsArray) A.push(key?.name);
    data = { ...data, newKeywords: A, languageId: currentSelected?.id };
    if (isEditPage) {
      return editContent(data);
    } else {
      return addContent(data);
    }
  };
  const getContentType = async () => {
    try {
      const res = await getAPIData({
        url: 'content/type',
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      setContentTypeData(res?.data);
      const findText = res?.data.find((data: any) => data.type === 'Text Only');
      setType({ id: findText?.id, type: findText?.type });
      setValue('contentType', findText?.type ?? 'Text Only');
      setValue('contentTypeId', findText?.id);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getCategoryData();
    }
  }, [categoryPaginationParams.page, categoryPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getGroupData();
    }
  }, [groupPaginationParams.search, groupPaginationParams.page, currentSelected?.id || selectedLanguage?.id]);

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getCompanyData();
    }
  }, [companyPaginationParams.page, companyPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getInitialData();
    }
  }, [currentSelected?.id || selectedLanguage?.id]);

  const getInitialData = async () => {
    setLoading(true);
    await getContentType();
    if (isEditPage) {
      await getContentData();
    }
    await getStatus();
    setLoading(false);
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    if (isEditPage && categoryData && contentState) {
      setCategoriesHandler(contentState, categoryData);
    }
    if (isEditPage && companiesData && contentState) {
      setCompanyExceptionHandler(contentState, companiesData);
    }

    if (isEditPage && groupData && contentState) {
      setGroupSelectionHandler(contentState, groupData);
    }
  }, [categoryData, contentState, currentSelected?.id || selectedLanguage?.id]);

  const setCategoriesHandler = (contentStateData: any = [], categoryData: any) => {
    const selectedCategories = contentStateData?.contentCategory?.map((data: any) => data?.id) ?? [];
    const selectedCategoriesData = categoryData?.filter((el: any) => {
      return selectedCategories.includes(el?.id);
    });
    if (selectedCategoriesData?.length) {
      setCategorySelections(selectedCategoriesData);
    }
  };

  const setCompanyExceptionHandler = (contentStateData: any = [], categoryData: any) => {
    const selectedCompanyException =
      contentStateData?.contentCompanyException?.map((data: any) => data.company?.id) ?? [];
    const selectedCompanyExceptionData = categoryData?.filter((el: any) => {
      return selectedCompanyException.includes(el?.id);
    });
    if (selectedCompanyExceptionData?.length) {
      setCompanySelections(selectedCompanyExceptionData);
    }
  };
  //

  //

  const setGroupSelectionHandler = (contentStateData: any = [], groupData: any) => {
    const selectedGroups = contentStateData?.contentGroup?.map((data: any) => data?.group?.id) ?? [];
    const selectedGroupsData = groupData?.filter((el: any) => {
      return selectedGroups.includes(el.id);
    });
    if (selectedGroupsData?.length) {
      setGroupSelections(selectedGroupsData);
    }
  };

  const getKeywords = async (str: any) => {
    if (str === '') {
      setKeywordsData({ ...keywordsData, list: [] });
      return;
    }
    try {
      const res = await getAPIData({
        url: 'miscellaneous/keyword',
        data: {
          limit: 20,
          page: 1,
          searchText: str?.trim(),
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      const refinedList = res?.data.map((data: any) => {
        return { id: data?.id, name: data?.name };
      });
      setKeywordsData({ ...keywordsData, list: refinedList });
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const FilterChips = (data: TODO_ANY) => {
    let A = [];
    for (let key of newKeyWordsArray) A.push(key?.id);
    let Filter = [];
    for (let key of data) {
      if (!A.includes(key?.id)) {
        Filter.push(key);
      }
    }
    return Filter;
  };

  const keywordAction = (data: any, flag: boolean) => {
    if (!flag) {
      let filterChips = selectedKeyword.filter((item: any) => item.id !== data.id);
      setSelectedKeyword([...filterChips]);
      setKeywordsData({ list: [], input: '' });
      setNewKeyWordsArray(newKeyWordsArray.filter((e: any) => e.id !== data.id));
      filterChips = FilterChips(filterChips);
      console.log('filter chips--------><', filterChips);
      setValue('keywords', [...filterChips.map((e: any) => e?.id)]);
    } else {
      setKeywordsData({ list: [], input: '' });
      setSelectedKeyword([...selectedKeyword, data]);
      setValue('keywords', [...getValues().keywords, data.id]);
    }
  };
  const removeURL = (data: any) => {
    if (data?.imageUrl) data.imageUrl = data?.imageUrl?.split('.net/')[1];
    if (data?.srt?.url) data.srt.url = data?.srt?.url?.split('.net/')[1];
    if (data?.video?.thumbnail) data.video.thumbnail = data?.video?.thumbnail?.split('.net/')[1];
    if (data?.video?.url) data.video.url = data?.video?.url?.split('.net/')[1];
    return data;
  };
  const editContent = async (data: any) => {
    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }
    const publishContent = statusData.find((item: any) => item.id == data.statusId)?.status;

    if (!data?.publishTime && publishContent == 'Published') {
      data.publishTime = new Date();
    } else if (!(publishContent == 'Published')) {
      data.publishTime = null;
    }
    try {
      setLoading(true);
      let payload = await filterPayload(data, type, isEditPage);
      if (payload?.video?.fileSize == '') {
        payload.video.fileSize = null;
      }
      if (payload?.srt?.fileSize == '') {
        payload.srt.fileSize = null;
      }
      payload = removeURL(payload);

      if (imageId) {
        payload.imageId = imageId;
      } else {
        payload.imageId = null;
      }

      if (typeof videoId == 'object' && videoId) {
        if (videoId?.length > 1) {
          payload.videoId = videoId[0];
          payload.srtId = videoId[1];
        } else {
          payload.videoId = videoId[0];
        }
      } else {
        if (videoId) {
          payload.videoId = videoId;
        }
      }
      payload.languageId = currentSelected?.id || selectedLanguage?.id;
      delete payload.imageUrl;
      delete payload.video;
      delete payload.srt;
      let duplicateContentRes: any;
      if (isDuplicate) {
        if (contentToBeDuplicate?.contentType?.type == 'Quiz') {
          let quizQuestions = contentToBeDuplicate.quizQuestion?.map((el: any) => {
            return { text: el.text, answer: el.answer };
          });
          payload.questions = quizQuestions;
        } else {
          delete payload.questions;
        }

        if (contentToBeDuplicate?.contentType?.type == 'Video') {
          payload.videoId = contentToBeDuplicate?.video?.id;
        }

        duplicateContentRes = await postAPIData({
          url: 'content',
          data: payload,
        });

        if (id) {
          let remainingLanguagesToBeDuplicate = contentToBeDuplicate?.contentLang?.reduce((acc: any, el: any) => {
            if (el?.languageId !== payload?.languageId) {
              return [...acc, el];
            }
            return acc;
          }, []);

          for (let i = 0; i < remainingLanguagesToBeDuplicate?.length; i++) {
            const { name, quote, quoteAuthor, textField, languageId } = remainingLanguagesToBeDuplicate[i];

            if (contentToBeDuplicate?.contentType?.type == 'Quiz') {
              const quizContentRes: any = await getAPIData({
                url: `content/${id}`,
                data: { languageId: languageId },
              });

              let quizQuestions = duplicateContentRes?.data?.quizQuestion?.map((el: any, index: any) => {
                return {
                  ...el,
                  text: quizContentRes?.data?.quizQuestion[index].text,
                  answer: quizContentRes?.data?.quizQuestion[index].answer,
                };
              });
              payload.questions = quizQuestions;
            }

            if (contentToBeDuplicate?.contentType?.type == 'Video') {
              payload.videoId = contentToBeDuplicate?.video?.id ?? contentToBeDuplicate?.videoId;
            }
            const res = await putAPIData({
              url: `content/${duplicateContentRes?.data?.id}`,
              data: {
                ...payload,
                name: name,
                quote: quote,
                quoteAuthor: quoteAuthor,
                textField: textField,
                languageId: languageId,
              },
            });
          }
        }

        callToast(ToastTypes.SUCCESS, 'Content added successfully');
      } else {
        await putAPIData({
          url: `content/${id}`,
          data: payload,
        });
        callToast(ToastTypes.SUCCESS, 'Content updated successfully');
      }
      setLoading(false);
      if (isModalAction) {
        reset(initialDefaultValues);
        selectedData(currentSelectedLangId);
        setIsModalAction(false);
        navigate(`/content/edit/${duplicateContentRes?.data?.id ?? id} `);
        setNewKeyWordsArray([]);
      } else {
        navigate('/content');
      }
    } catch (e: any) {
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0] ?? '') || e?.message);
      setIsModalAction(false);
      setResetConfirmation(false);
      setLoading(false);
    }
  };

  const addContent = async (data: any) => {
    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }
    const publishContent = statusData.find((item: any) => item?.id === data?.statusId)?.status;
    if (
      !data?.publishTime &&
      publishContent == t('published', currentSelected?.langCode || selectedLanguage?.langCode)
    ) {
      data.publishTime = new Date();
    } else if (!(publishContent == t('published', currentSelected?.langCode || selectedLanguage?.langCode))) {
      data.publishTime = null;
    }

    try {
      setLoading(true);
      const payload: any = await filterPayload(data, type, isEditPage);
      if (imageId) {
        payload.imageId = imageId;
      }
      if (typeof videoId == 'object' && videoId) {
        if (videoId?.length > 1) {
          payload.videoId = videoId[0];
          payload.srtId = videoId[1];
        } else {
          payload.videoId = videoId[0];
        }
      } else {
        if (videoId) {
          payload.videoId = videoId;
        }
      }
      delete payload.imageUrl;
      delete payload.video;
      delete payload.srt;
      payload.languageId = currentSelected?.id || selectedLanguage?.id;

      const res = await postAPIData({
        url: 'content',
        data: payload,
      });
      if (isModalAction) {
        reset(initialDefaultValues);
        selectedData(currentSelectedLangId);
        setNewKeyWordsArray([]);
        setIsModalAction(false);
        navigate(`/content/edit/${res?.data?.id} `);
      } else {
        navigate('/content');
      }

      setLoading(false);
      callToast(
        ToastTypes.SUCCESS,
        t('content_added', currentSelected?.langCode || selectedLanguage?.langCode) || 'Content added successfully',
      );
    } catch (e: any) {
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0]) ?? e?.message);
      setLoading(false);
    }
  };

  const isLoading = loading;

  useEffect(() => {
    getNestedCategories();
  }, [currentSelected?.id || selectedLanguage?.id]);

  const getNestedCategories = async () => {
    try {
      setNestedLoading(true);
      let res = await getAPIData({
        url: 'categories/nested',
        data: { page: 1, searchText: '', limit: 1000, languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setNestedLoading(false);
      setCategoriesData(res.data.data);
      const { parentData, nestedData } = convertToNestedOptions(res.data.data);
      setParentOptions(parentData);
      setNestedOptions(nestedData);
    } catch (error) {
      setNestedLoading(false);
      console.error(error);
    }
  };

  const selectAll = () => {
    const values = categoriesData.map((el: any) => {
      return el.id;
    });
    setValue('categories', values);
  };

  const deselectAll = () => {
    setValue('categories', []);
  };

  const addToSelectedCategories = (category: ICategory) => {
    let values = category.company_id ? [] : [...getValues('categories')];
    setCustomCategorySelected(!!category.company_id);
    if (category.company_id && category.company_name) {
      setSelectedCompany({ id: category.company_id, name: category.company_name });
    }
    let updated = [];
    for (let id of values) {
      const foundCat = categoriesData.find((c: ICategory) => c.id === id);
      if (!foundCat.company_id) {
        updated.push(id);
      }
    }
    updated.push(category.id);
    setValue('categories', updated);
    // selectParentCategory(category);
  };

  const selectParentCategory = (category: ICategory) => {
    if (category.parent_id == null) {
      return;
    }
    if (category.parent_id) {
      const parentCategory = categoriesData.find((el: ICategory) => {
        return el.id === category.parent_id;
      });
      if (parentCategory) {
        addToSelectedCategories(parentCategory);
      }
    }
  };

  const removeFromSelectedCategories = (category: ICategory) => {
    setCustomCategorySelected(!category.company_id);
    let values = [...getValues('categories')];
    values = values.filter((el: string) => {
      return el !== category.id;
    });
    setValue('categories', values);
  };

  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };

  return (
    <section>
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(getFormData)}
          buttonText={titleButtonText}
          titleText={pageTitle}
          className="mb-8"
          onCancelClicked={handleContentList}
          disableBtn={!isPublishSaved || isLoading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
        <LanguageDropdown
          langLists={langLists}
          value={currentSelected?.langCode || selectedLanguage?.langCode}
          onChange={(e: any) => {
            setCurrentSelectedLangId(e);
            console.log(getValues()?.statusId);
            setResetConfirmation(true);
            setIsModalAction(true);
          }}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(initialDefaultValues);
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
            setIsModalAction(false);
          }}
          deleteTitle={'How would you like to proceed '}
          type=""
          confirmation={true}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          buttonTitle={'Continue without saving'}
          button3="Save & Continue"
          button3Action={() => {
            setResetConfirmation(false);
            getFormData(getValues());
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <div className="mb-8 w-full">
              <Input
                label={t('title', currentSelected?.langCode || selectedLanguage?.langCode) || 'Title'}
                control={{ ...register('name') }}
                name="name"
                size="large"
                required={true}
                errors={errors}
                placeholder=""
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>
            <div className="flex flex-col gap-5">
              <div className="mb-[20px]">
                <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                  <span className="text-lg font-bold text-gray-text">
                    {t('categories', currentSelected?.langCode || selectedLanguage?.langCode) || 'Categories'}
                  </span>
                </div>
                {dropDown.category && (
                  <div className="border-grey rounded-b-[5px] border p-[22px]">
                    <div className="flex flex-col">
                      {parentOptions && (
                        <MultiSelectContainerNested
                          type={NestedSelectionType.MULTI_SELECT}
                          loading={nestedLoading}
                          parentOptions={parentOptions}
                          selectedOptions={watch('categories')}
                          selectAll={selectAll}
                          addToSelectedCategories={addToSelectedCategories}
                          removeFromSelectedCategories={removeFromSelectedCategories}
                          allOptions={categoriesData}
                          deselectAll={deselectAll}
                          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                          nestedOptions={nestedOptions}></MultiSelectContainerNested>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                  <span className="text-lg font-bold text-gray-text">
                    {t('Visibility', currentSelected?.langCode || selectedLanguage?.langCode)}
                  </span>
                </div>
                {!customCategorySelected && dropDown.visibility && (
                  <div className="border-grey rounded-b-[5px] border ">
                    <div className="border-b-1 border-slate-100 p-[22px] ">
                      <div className="mb-[17px]">
                        <h2 className="text-[18px] font-bold text-gray-text">
                          {t('viewable_groups', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Viewable Groups'}
                        </h2>
                        <p className="text-[14px] font-light text-gray-subject-text">
                          {t('select_group', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Select groups that can view this content, if none selected, this content will be viewable to anyone.'}
                        </p>
                      </div>
                      <MultiSelectContainer
                        data={multiSelectGroupOptions}
                        selectedOptions={groupSelections}
                        hasNoMoreData={hasNoMoreGroupData}
                        selectAll={selectGroupAll}
                        multiSelectOptions={multiSelectGroupOptions}
                        setSelections={setGroupSelections}
                        selections={groupSelections}
                        setPaginationParams={setGroupPaginationParams}
                        paginationParams={groupPaginationParams}
                        name="groups"
                        setValue={setValue}
                        contentData={groupData}
                        setSelectAll={setSelectGroupAll}
                        loading={selectLoadings.groupLoading}
                        langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      />
                    </div>
                    <div className=" p-[22px]">
                      <div className="mb-[17px]">
                        <h2 className="text-[18px] font-bold text-gray-text">
                          {t('exceptions', currentSelected?.langCode || selectedLanguage?.langCode) || 'Exceptions'}
                        </h2>
                        <p className="text-[14px] font-light text-gray-subject-text">
                          {t('select_companies', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Select companies that cannot view this content'}
                        </p>
                      </div>

                      <MultiSelectContainer
                        data={multiSelectCompanyOptions}
                        selectedOptions={companySelections}
                        hasNoMoreData={hasNoMoreCompanyData}
                        selectAll={selectCompanyAll}
                        multiSelectOptions={multiSelectCompanyOptions}
                        setSelections={setCompanySelections}
                        selections={companySelections}
                        setPaginationParams={setCompanyPaginationParams}
                        paginationParams={companyPaginationParams}
                        name="exceptions"
                        setValue={setValue}
                        contentData={companiesData}
                        setSelectAll={setSelectCompanyAll}
                        loading={selectLoadings.companyLoading}
                        langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      />
                    </div>
                  </div>
                )}
                {customCategorySelected && selectedCompany && (
                  <div className="border-grey rounded-b-[5px] border">
                    <div className="border-b-1 px-5 py-7">
                      <p className="text-lg font-bold text-gray-text">
                        {t('viewable_custom_company', currentSelected?.langCode || selectedLanguage?.langCode) ||
                          'Viewable Custom Company'}
                      </p>
                      <p className="text-[14px] font-light text-gray-subject-text">
                        {t('selected_company_category', currentSelected?.langCode || selectedLanguage?.langCode) ||
                          'This cannot be modified here, change custom company for selected category in categories section.'}
                      </p>
                      <div className="text-grey-text mt-2.5 flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-sm">
                        <div className="text-grey-text flex h-[32px] items-center justify-center rounded-full bg-gray-title-bg px-5">
                          {t(selectedCompany.name, currentSelected?.langCode || selectedLanguage?.langCode) ||
                            selectedCompany.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-[20px]">
                <CustomEditor
                  input={editorInputs}
                  setValue={setValue}
                  getValues={getValues}
                  header={false}
                  control={control}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              </div>

              <div className="mb-[20px]">
                <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                  <span className="text-lg font-bold text-gray-text">
                    {t('content_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Content Fields'}
                  </span>
                </div>
                {dropDown.content && (
                  <div className="border-grey rounded-b-[5px] border ">
                    <div className=" border-b-1 border-slate-100 p-[22px]">
                      <div className="mb-[17px]">
                        <h2 className="text-[18px] font-bold text-gray-text">
                          {t('duration', currentSelected?.langCode || selectedLanguage?.langCode) || 'Duration'}
                        </h2>
                        <p className="text-[14px] font-light text-gray-subject-text">
                          {t(
                            'estimated_time_to_complete_the_content',
                            currentSelected?.langCode || selectedLanguage?.langCode,
                          ) || 'Estimated time to complete the content'}
                        </p>
                      </div>
                      <div className="relative">
                        <select
                          {...register('duration')}
                          defaultValue={'1'}
                          className="flex w-full w-full cursor-pointer appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none ">
                          <option value={'1'}>
                            1 {t('minute', currentSelected?.langCode || selectedLanguage?.langCode) || 'minute'}{' '}
                          </option>
                          <option value={'2'}>
                            2 {t('minutes', currentSelected?.langCode || selectedLanguage?.langCode) || 'minutes'}{' '}
                          </option>
                          <option value={'3'}>
                            3 {t('minutes', currentSelected?.langCode || selectedLanguage?.langCode) || 'minutes'}{' '}
                          </option>
                          <option value={'4'}>
                            4 {t('minutes', currentSelected?.langCode || selectedLanguage?.langCode) || 'minutes'}{' '}
                          </option>
                          <option value={'5'}>
                            5 {t('minutes', currentSelected?.langCode || selectedLanguage?.langCode) || 'minutes'}{' '}
                          </option>
                        </select>
                        <img
                          src={dropdown}
                          alt="arrow"
                          className="pointer-events-none absolute top-[13px] right-[15px]"
                          title="arrow"></img>
                      </div>
                    </div>
                    <div className="border-b-1 border-slate-100 p-[22px]">
                      <div className="mb-[17px]">
                        <h2 className="text-[18px] font-bold text-gray-text">
                          {t('keywords_search', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Keywords Search'}
                        </h2>
                        <p className="text-[14px] font-light text-gray-subject-text">
                          {t('misspellings_synonyms', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Enter words that maybe common misspellings or synonyms of words that would be relevant for this piece of content, hit Enter to add.'}
                        </p>
                      </div>
                      <div>
                        <div className="placeholder-text-grey-text   w-full rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none">
                          <div className="scrollbarCustom  flex max-h-[100px] flex-wrap items-center  gap-1 overflow-y-auto">
                            {selectedKeyword?.map((data: any) => {
                              return (
                                <Chip
                                  label={data.name}
                                  onDelete={() => {
                                    keywordAction(data, false);
                                  }}
                                />
                              );
                            })}

                            <input
                              className=" placeholder-text-grey-text  outline-none"
                              value={keyWordsSearch}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter' && e.target.value.trim()) {
                                  let text = e.target.value.trim();
                                  if (keywordsData?.list?.length) {
                                    let x: any = keywordsData.list.find(
                                      (data: { id: string; name: string }) => data.name === text,
                                    );
                                    if (
                                      x ||
                                      selectedKeyword.find((data: { id: string; name: string }) => data.name === text)
                                    ) {
                                      if (!selectedKeyword.find((data: any) => data.name === text)) {
                                        keywordAction({ id: x?.id, name: x?.name }, true);
                                        setKeyWordsSearch('');
                                      }
                                    } else {
                                      if (!newKeyWordsArray.find((data: any) => data.name === text)) {
                                        let x = `${new Date().getTime()}`;
                                        setNewKeyWordsArray([...newKeyWordsArray, { id: x, name: text }]);
                                        setSelectedKeyword([...selectedKeyword, { id: x, name: text }]);
                                        setKeyWordsSearch('');
                                      }
                                    }
                                  } else {
                                    if (
                                      !newKeyWordsArray.find((data: any) => data?.name == text) &&
                                      !selectedKeyword.find((data: { id: string; name: string }) => data?.name == text)
                                    ) {
                                      let x = `${new Date().getTime()}`;
                                      setNewKeyWordsArray([...newKeyWordsArray, { id: x, name: text }]);
                                      setSelectedKeyword([...selectedKeyword, { id: x, name: text }]);
                                      setKeyWordsSearch('');
                                    }
                                  }
                                }
                              }}
                              onChange={(e: any) => {
                                setKeyWordsSearch(e.target.value);
                                setKeywordsData({ ...keywordsData, input: e.target.value });
                                getKeywords(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {keywordsData?.list?.length > 0 && (
                          <div className="scrollbarCustom mt-[-2px] max-h-[234px] overflow-y-auto border-l-1 border-r-1 border-b-1 p-5">
                            {keywordsData?.list?.map((data: { id: string; name: string }) => {
                              const isChecked = selectedKeyword.filter((item: any) => item?.id === data?.id)?.length;
                              return (
                                <section className="flex items-center">
                                  <label className="mr-2 flex text-[14px] text-gray-text">
                                    <MuiCheckbox
                                      id={data.id}
                                      value={data.id}
                                      className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-main"
                                      style={{ color: '#20A8D8', transform: 'scale(0.8)' }}
                                      checked={isChecked > 0 && true}
                                      onChange={(e) => keywordAction(data, e?.target?.checked)}
                                    />
                                  </label>
                                  <span>{data.name}</span>
                                </section>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="border-b-1 border-slate-100 p-[22px]">
                      <Switch
                        control={control}
                        register={register}
                        name="leaderOnly"
                        checked={contentState?.leaderOnly || false}
                        label="Available to Team Leader and Above Only"
                        langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      />
                    </div>
                    <div className="p-[22px] ">
                      <div className="flex items-center justify-between py-[15px]">
                        <h2 className="text-[18px] font-bold text-gray-text">
                          {t('type', currentSelected?.langCode || selectedLanguage?.langCode) || 'Type'}
                        </h2>
                        <div className="relative w-[calc(100%-80px)]">
                          <select
                            {...register('contentTypeId')}
                            onChange={(data: any) => {
                              setValue('contentTypeId', data.target.value);
                              const findType: any = contentTypeData.find((item: any) => item.id === data.target.value);
                              setValue('contentType', findType?.type ?? 'Text Only');

                              setType(findType);
                            }}
                            className="flex w-full cursor-pointer appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-[16px] text-lg leading-6 outline-none">
                            {contentTypeData?.map((type: any) => {
                              return (
                                <option value={type?.id} id={type?.id}>
                                  {type?.type}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            src={dropdown}
                            alt="arrow"
                            className="pointer-events-none absolute top-[13px] right-[15px]"
                            title="arrow"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {type?.type === 'Quote' ? (
                <div className="mb-[20px]">
                  <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                    <span className="text-lg font-bold text-gray-text">
                      {t('quote_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Quote Fields'}{' '}
                    </span>
                    <span className="flex" onClick={() => handleDropDown('quote')}>
                      <span className="mx-[2px] flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-[4px] bg-green-main">
                        <img src={dropDown.quiz ? arrowUpSolid : arrowDownSolid} alt="arrow" title="arrow"></img>
                      </span>
                    </span>
                  </div>
                  {dropDown?.quote && (
                    <QuoteContent
                      register={register}
                      errors={errors}
                      langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                    />
                  )}
                </div>
              ) : type?.type === 'Video' ? (
                <div className="mb-[20px]">
                  <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                    <span className="text-lg font-bold text-gray-text">
                      {t('video_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Video Fields'}
                    </span>
                    <span className="flex" onClick={() => handleDropDown('video')}>
                      <span className="mx-[2px] flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-[4px] bg-green-main">
                        <img src={dropDown.video ? arrowUpSolid : arrowDownSolid} alt="arrow" title="arrow"></img>
                      </span>
                    </span>
                  </div>
                  {dropDown?.video && (
                    <VideoContent
                      setValue={setValue}
                      getValues={getValues}
                      videoId={videoId}
                      setVideoId={setVideoId}
                      isEdit={isEditPage}
                      controlName={{ video: 'video', srt: 'srt' }}
                      errors={errors}
                      pageName="content"
                      langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                    />
                  )}
                </div>
              ) : type?.type === 'Quiz' ? (
                <div className="mb-[20px]">
                  <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
                    <span className="text-lg font-bold text-gray-text">
                      {t('quiz_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'Quiz Fields'}
                    </span>

                    <span className="flex" onClick={() => handleDropDown('quiz')}>
                      <span className="mx-[2px] flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-[4px] bg-green-main">
                        <img src={dropDown.quiz ? arrowUpSolid : arrowDownSolid} alt="arrow" title="arrow"></img>
                      </span>
                    </span>
                  </div>
                  {dropDown?.quiz && (
                    <QuizForm
                      register={register}
                      control={control}
                      option={defaultValues.quizQuestions}
                      errors={errors}
                      langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                    />
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="flex w-80 flex-shrink-0 flex-col gap-10">
            <TitleBlock
              title={t('publish', currentSelected?.langCode || selectedLanguage?.langCode) || 'Publish'}
              required={true}
              langCode={currentSelected?.langCode || selectedLanguage?.langCode}>
              <SurveyPublish
                control={control}
                watch={watch}
                statusData={statusData}
                setValue={setValue}
                clearErrors={clearErrors}
                setIsPublishSaved={setIsPublishSaved}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                errors={errors}></SurveyPublish>
            </TitleBlock>
            <TitleBlock
              title={t('feature_images', currentSelected?.langCode || selectedLanguage?.langCode) || 'Feature Images'}
              action={() => handleDropDown('imageUpload')}
              close={dropDown.imageUpload}>
              {dropDown.imageUpload && (
                <LogoPicker
                  setValue={setValue}
                  getValues={getValues}
                  setImageId={setImageId}
                  type="image"
                  name="imageUrl"
                  isBlock={disableBtn}
                  disableBtn={setDisableBtn}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              )}
            </TitleBlock>
          </div>
        </div>
      )}
    </section>
  );
}

export default Content;
