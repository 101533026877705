import { useEffect, useState } from 'react';
import cx from 'classnames';
import { TODO_ANY } from 'typings/common';
import { useTranslation } from 'context/translation';

type tabs = {
  dataSource: {
    id: string;
    label: string;
    children: TODO_ANY;
  }[];
};

function Tabs({ dataSource }: tabs) {
  const [currentTab, setCurrentTab] = useState('');
  const { t }: any = useTranslation();

  useEffect(() => {
    if (dataSource) {
      setCurrentTab(dataSource[0].id);
    }
  }, [dataSource]);
  return (
    <div>
      <div className="flex w-full overflow-x-scroll">
        {dataSource.map((item) => (
          <button
            onClick={() => setCurrentTab(item.id)}
            key={item.id}
            className={cx('w-32 flex-shrink-0 rounded-t-md border-1 border-solid text-center', {
              'border-blue-main bg-blue-main text-white': currentTab === item.id,
              'border-gray-border bg-transparent text-gray-text': currentTab !== item.id,
            })}>
            {t(item.label) || item.label}
          </button>
        ))}
      </div>
      {dataSource.map((item) => (
        <div
          key={item.id}
          className={cx('', {
            'visible h-auto p-5 ': currentTab === item.id,
            'invisible h-0': currentTab !== item.id,
          })}>
          {item.children}
        </div>
      ))}
      <div></div>
    </div>
  );
}

export default Tabs;
