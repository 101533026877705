import * as yup from 'yup';
export const categorySchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .test('whiteSpace', 'Only white spaces are not allowed', (value: string | undefined) => {
      return !!value?.trim();
    }),
  parentCategory: yup.string().required('Parent Category is required'),
  companyId: yup
    .string()
    .nullable()
    .when('customOnly', {
      is: (customOnly: boolean | undefined) => !!customOnly,
      then: yup.string().nullable().required('Please select a company'),
    }),
  // visibleStartDate: yup.date().nullable()?.min(new Date(), `Start Date Should be on or after ${new Date().toLocaleDateString()}`),
  // visibleEndDate: yup
  //   .date()
  //   .nullable()
  //   .when(
  //     'visibleStartDate',
  //     (visibleStartDate, schema) =>
  //       visibleStartDate && schema.min(visibleStartDate, 'End date should be greater than start date'),
  //   ),
});
