import { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Pagination, Input } from '../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../components/common/table';
import { TableActionButton } from '../../components/common/button';
import { createDummyData } from '../../utils/createDummyData';
import { useTranslation } from 'context/translation';

function Quiz() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const { t }: any = useTranslation();

  const tabs = useMemo(
    () => [
      { label: t('all') || 'All', num: 9 },
      { label: t('published') || 'Published', num: 9 },
    ],
    [],
  );

  const handleToAddContent = useCallback(() => {
    navigate('/add-content');
  }, [navigate]);

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },
    {
      title: t('date') || 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 1000,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: () => (
        <TableActions
          icons={[
            { iconClassName: 'far fa-eye', onClick: () => {} },
            { iconClassName: 'fas fa-edit', onClick: () => {} },
            { iconClassName: 'fas fa-trash-alt', onClick: () => {} },
          ]}
        />
      ),
    },
  ];

  const dataSource = createDummyData(columns);

  return (
    <section className="Quizs">
      <TopLayer
        onButtonClicked={handleToAddContent}
        titleText={t('quizzes') || 'Quizzes'}
        buttonText={t('add_quiz') || 'Add Quiz'}
      />
      <TabsLayer tabs={tabs} />
      <FunctionLayer
        leftPortion={
          <>
            <Select
              value={dateValue}
              options={[
                { label: t('all_date') || 'All Dates', value: '0' },
                { label: `1 ${'day'}`, value: '1' },
              ]}
              onChange={setDateValue}
            />

            <TableActionButton onClick={() => {}} title={t('filter') || 'Filter'} />
          </>
        }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={setInputValue}
              placeholder={t('search_quiz') || 'Search quiz'}
              type="search"></Input>
            <Pagination currentPage={1} totalPage={50} pageSize={256} onNext={() => {}} onPrev={() => {}}></Pagination>
          </>
        }
      />
      <Table dataSource={dataSource} columns={columns} />
    </section>
  );
}

export default Quiz;
