import moment from "moment";
import { TODO_ANY } from "typings/common";

 export const convertToMidNight = (data: TODO_ANY) => {
    if (data) {
      return moment(data).utcOffset(0, true).format();
    }
    return new Date()
  };
  export const removeOffSet=(data: TODO_ANY)=>{
    if(data){
      return new Date(new Date(data).getTime() -  new Date(data).getTimezoneOffset()*60*1000)
    }
  }
  export const addOffSet=(data: TODO_ANY)=>{
    if(data){
      return new Date(new Date(data).getTime() +  new Date(data).getTimezoneOffset()*60*1000)
    }
  }