import { Checkbox } from '@mui/material';
import { ColumnsType, DefaultRecordType } from 'rc-table/lib/interface';
import { Spinner, Table } from '../../../components';
import { TableActions } from '../../../components/common/table';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { createDummyData } from '../../../utils/createDummyData';
import { ToastTypes, useToast } from 'context/toast';
import { TODO_ANY } from 'typings/common';
import { useTranslation } from 'context/translation';
function MediaList({
  mediaType,
  data,
  loader,
  onclick,
}: {
  mediaType: any;
  data: any;
  loader: any;
  onclick: (id: any) => void;
}) {
  const { t }: any = useTranslation();
  const navigate = useNavigate();
  const handleEditMedia = useCallback(
    (id: string | number) => {
      navigate(`/media/edit/${id}`);
    },
    [navigate],
  );
  const columns: ColumnsType<DefaultRecordType> = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('file') || 'File',
      dataIndex: 'fileData',
      key: 'fileData',
      width: 250,
      render: (data: TODO_ANY) => {
        return (
          <div className="flex gap-5">
            <div className="flex h-16 w-16 items-center justify-center rounded-sm border-1 border-blue-main">
              {data.type == 'image' ? (
                <img src={data?.imgUrl} className="max-h-full max-w-full" alt="img"></img>
              ) : (
                <img loading="lazy" className="max-h-full max-w-full" src={data?.imgUrl} />
              )}
            </div>
            <div className="flex flex-col justify-center">
              <div className="font-semibold text-blue-main">{data?.name}</div>
              {/* <div>apiw05j0o-16855.15.6902155.jpg</div> */}
            </div>
          </div>
        );
      },
    },
    {
      title: t('media_type') || 'Media Type',
      dataIndex: 'mediaType',
      key: 'mediaType',
      width: 250,
    },
    {
      title: t('alternative_text') || 'Alternative Text',
      dataIndex: 'altText',
      key: 'altText',
      width: 250,
    },
    // {
    //   title: 'Comment',
    //   dataIndex: 'Comment',
    //   key: 'Comment',
    //   width: 250,
    // },
    // {
    //   title: 'Date',
    //   dataIndex: 'date',
    //   key: 'date',
    //   width: 250,
    // },
    {
      title: t('upload_to') || 'Uploaded To',
      dataIndex: 'author',
      key: 'author',
      width: 250,
      render: (data: TODO_ANY) => (
        <>
          {!data.length ? (
            <div>{t('not_used_yet') || 'Not Used Yet'}</div>
          ) : (
            <>
              {data?.map((e: any, i: any) => {
                return (
                  <div
                    className="cursor-pointer text-blue-main"
                    onClick={() => {
                      navigate(`/${e?.content}/edit/${e?.id}`);
                    }}>
                    {e?.name}
                  </div>
                );
              })}
            </>
          )}
        </>
      ),
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (data: TODO_ANY) => (
        <TableActions
          icons={[
            // { iconClassName: 'far fa-eye', onClick: () => {} },
            {
              iconClassName: 'fas fa-edit',
              onClick: () => {
                handleEditMedia(data?.id);
              },
            },
            {
              iconClassName: 'fas fa-trash-alt',
              onClick: () => {
                onclick(data?.id);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <section className="Companies">
      {loader ? (
        <Spinner />
      ) : !data?.length ? (
        <div className="flex justify-center">{t('no_data') || 'No Data Found'}</div>
      ) : (
        <Table dataSource={data} columns={columns} />
      )}
    </section>
  );
}

export default MediaList;
