import { useCallback, useState } from 'react';
import { Table, Pagination, Input, TopLayer, FunctionLayer, TableActions } from 'components';
import { createDummyData } from 'utils/createDummyData';
import { useNavigate } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { useTranslation } from 'context/translation';

function TagsList() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const { t }: any = useTranslation();

  const handleAddTags = useCallback(() => {
    navigate('/tags/add');
  }, [navigate]);

  const handleEditTags = useCallback(
    (id: string | number) => {
      navigate(`/tags/edit/${id}`);
    },
    [navigate],
  );

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('name') || 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: t('description') || 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: t('slug') || 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: 300,
    },
    {
      title: t('count') || 'Count',
      dataIndex: 'count',
      key: 'count',
      width: 300,
    },
    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: TODO_ANY) => (
        <TableActions
          icons={[
            { iconClassName: 'far fa-eye', onClick: () => {} },
            {
              iconClassName: 'fas fa-edit',
              onClick: () => {
                handleEditTags(record.id);
              },
            },
            { iconClassName: 'fas fa-trash-alt', onClick: () => {} },
          ]}
        />
      ),
    },
  ];

  const dataSource = createDummyData(columns);

  return (
    <section className="Category">
      <TopLayer
        titleText={t('tags') || 'Tags'}
        buttonText={t('add_tag') || 'Add Tag'}
        onButtonClicked={handleAddTags}
      />

      <FunctionLayer
        leftPortion={<></>}
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={setInputValue}
              placeholder={t('search_tags') || 'Search tags'}
              type="search"></Input>
            <Pagination currentPage={1} totalPage={50} pageSize={256} onNext={() => {}} onPrev={() => {}}></Pagination>
          </>
        }
      />
      <Table dataSource={dataSource} columns={columns} />
    </section>
  );
}

export default TagsList;
