import DeleteModal from 'components/DeleteModal';
import MyTable from 'components/DraggableTable';
import { ToastTypes, useToast } from 'context/toast';
import { useTranslation } from 'context/translation';
import { SelectedLangContext, useAuth } from 'context/user';
import { usePaginationParams } from 'hooks/usePageinationParams';
import { debounce } from 'lodash';
import { getTableSequenceData } from 'pages/Company/companyDto';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';
import { deleteAPIData, getAPIData, putAPIData } from 'utils/api';
import { Input, Pagination, Spinner } from '../../../components';
import { FunctionLayer, TopLayer } from '../../../components/common/table';

function Sequence() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [title, setTitle] = useState<string>('');
  const [data, setData] = useState<any>([]);
  const [sort, setSort] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDel, setShowDel] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [isDragg, setIsDragg] = useState<any>(false);
  const [paginationParams, setPaginationParams] = usePaginationParams(setInputValue, undefined);
  const [errPage, setErrPage] = useState<any>(false);
  const { callToast } = useToast();
  const { t }: any = useTranslation();

  const [sortData, setSortData] = useState<any>({ value: '', order: 'ASC' });
  const tabs = useMemo(
    () => [
      { label: t('all') || 'All', num: 1 },
      { label: t('published') || 'Published', num: 1 },
    ],
    [],
  );

  const handleToAddSequences = useCallback(() => {
    navigate('/sequences/add');
  }, [navigate]);

  const handleToAddSequencesUp = useCallback(
    (id: string | number, up: boolean) => {
      up ? navigate(`/sequences/addup/${id}`) : navigate(`/sequences/adddown/${id}`);
    },
    [navigate],
  );

  const handleToEditSequences = useCallback(
    (id: string | number) => {
      navigate(`/sequences/edit/${id}`);
    },
    [navigate],
  );
  const getSequences = async () => {
    setLoading(true);
    try {
      let res = await getAPIData({
        url: 'sequence',
        data: {
          page: paginationParams.page || 1,
          searchText: paginationParams.search,
          orderKey: sortData?.value,
          orderDirection: sortData?.order,
          limit: paginationParams.limit,
          languageId: selectedLanguage?.id,
        },
      });
      setData(getTableSequenceData(res?.data?.result));
      setTotal(res?.data?.total);
    } catch (e: any) {
      console.error(e);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getSequences();
    }
  }, [paginationParams, sortData]);

  const handlePaginationChange = (page: number) => {
    setPaginationParams({
      page: page,
      search: `${paginationParams.search}`,
      limit: paginationParams.limit,
    });
  };
  const handleInputChange = (val: string) => {
    setInputValue(val);
    // debounceOnChange(val);
  };
  const handleClick = () => {
    setPaginationParams({
      page: 1,
      search: inputValue,
      limit: paginationParams.limit,
    });
  };
  const columns = [
    // {
    //   title: '',
    //   key: 'checkbox',
    //   width: 50,
    //   rowDrag: true,
    // },
    {
      title: t('order') || 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 800,
      // sort: 'asc',
    },
    {
      title: t('sequence_content_title') || 'Sequence Content Title',
      dataIndex: 'title',
      key: 'title',
      width: 800,
      // sort: 'asc',
    },
  ];

  // let dataSource = createDummyData(columns); //Demo Data

  const handleEditSequence = useCallback(
    (id: string | number) => {
      navigate(`/sequence/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteClick = async (id: string) => {
    setShowDel(false);
    setLoading(true);
    try {
      callToast(ToastTypes.INFO, t('deleting_sequence') || 'Deleting sequence...');
      await deleteAPIData({ url: `sequence/${id}` });
      callToast(ToastTypes.SUCCESS, t('sequence_deleted') || 'Sequence deleted successfully');
      getSequences();
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);

      setLoading(false);
    }
  };

  const getBeforePosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex > oldIndex ? newIndex : newIndex - 1;
    return items[index]?.id;
  };
  const getAfterPosition = (items: TODO_ANY, newIndex: number, oldIndex: number) => {
    if (!items.length) {
      return 0;
    }
    const index = newIndex < oldIndex ? newIndex : newIndex + 1;
    return items[index]?.id;
  };
  var dataTable = data;
  const handleDragChange = useCallback(
    async (items: TODO_ANY, oldIndex: TODO_ANY, newIndex: TODO_ANY) => {
      if (oldIndex === newIndex) {
        return;
      }
      let x = dataTable[oldIndex]?.id;
      setIsDragg(true);
      try {
        await putAPIData({
          url: `sequence/${items[oldIndex]?.id}`,
          data: {
            beforeId: getAfterPosition(dataTable, newIndex, oldIndex),
            afterId: getBeforePosition(dataTable, newIndex, oldIndex),
            contentId: x,
            languageId: selectedLanguage?.id,
          },
        });
        await getSequences();
        setIsDragg(false);
      } catch (e: any) {
        callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
        console.error(e);
        setIsDragg(false);
      }
    },
    [data],
  );

  return (
    <section className="Sequence">
      {showDel && (
        <DeleteModal
          showModal={setShowDel}
          deleteConfirm={() => handleDeleteClick(deleteId?.id)}
          deleteTitle={deleteId?.title}
          type="sequence content"
        />
      )}
      <TopLayer
        onButtonClicked={handleToAddSequences}
        titleText={t('sequences') || 'Sequences'}
        buttonText={data.length || paginationParams?.search || loading ? '' : 'Add Sequence'}
        disableBtn={loading && !data.length}
      />
      <FunctionLayer
        rightPortion={
          <>
            <Input
              value={inputValue}
              onClick={handleClick}
              onChange={handleInputChange}
              placeholder={t('search_content') || 'Search Content'}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClick();
                }
              }}
              type="search"></Input>
            <Pagination
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams?.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="sequence"
              limit={paginationParams.limit}></Pagination>
          </>
        }
      />

      {loading && !isDragg ? (
        <div className="flex items-center justify-center">
          <Spinner />{' '}
        </div>
      ) : errPage || !data.length ? (
        <>{t('no_data') || 'No Data Found'}</>
      ) : (
        <>
          <MyTable
            dataSource={data}
            showDrag={isDragg}
            columns={columns}
            sortDetails={setSortData}
            tableActions={[
              {
                iconClassName: 'fas fa-edit',
                type: 'edit',
                info: t('edit') || 'Edit',
                onClick: (el: TODO_ANY) => handleToEditSequences(el.id),
              },
              {
                iconClassName: 'fas fa-trash-alt',
                type: 'delete',
                info: t('delete') || 'Delete',
                onClick: (el: TODO_ANY) => {
                  setShowDel(true);
                  setDeleteId(el);
                },
              },
              {
                iconClassName: '',
                btnIcon: 'Up',
                info: t('add_one_above') || 'Add one above',
                onClick: (el: TODO_ANY) => {
                  handleToAddSequencesUp(el.id, true);
                },
              },
              {
                iconClassName: '',
                btnIcon: 'Down',
                info: t('add_one_below') || 'Add one below',
                onClick: (el: TODO_ANY) => {
                  handleToAddSequencesUp(el.id, false);
                },
              },
            ]}
            handleDragChange={handleDragChange}
          />
          <div className="flex justify-end pt-5">
            <Pagination
              belowTable={true}
              currentPage={paginationParams?.page}
              showError={setErrPage}
              newCurrentPage={setPaginationParams}
              totalPage={Math.ceil(total / paginationParams.limit)}
              pageSize={total}
              searchedValue={paginationParams.search}
              onNext={() => handlePaginationChange(paginationParams?.page - 1)}
              onPrev={() => handlePaginationChange(paginationParams?.page + 1)}
              type="user"
              limit={paginationParams.limit}></Pagination>
          </div>
        </>
      )}
    </section>
  );
}

export default Sequence;
