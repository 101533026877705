import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import MultiSelectContainer from 'components/MultiSelectDropdown';
import { TODO_ANY } from 'typings/common';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { ToastTypes, useToast } from 'context/toast';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { Header, Spinner, TopLayer } from 'components';
import classNames from 'classnames';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import { convertToKey } from 'pages/Content/utils';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';
const groupFormValidation = yup.object().shape({
  name: yup.string().required('Name is required'),
  includes: yup.array().required('Company is required'),
});
function FormGroup() {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { t }: any = useTranslation();
  const { id } = useParams();
  const [selections, setSelections]: TODO_ANY = useState([]);
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companiesData, setCompaniesData] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [total, setTotal] = useState<any>(null);
  const [hasNoMoreData, setHasNoMoreData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const { callToast } = useToast();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
  });

  const debounceOnChange = useRef(debounce(onChange, 400)).current;
  const titleButtonText = useMemo(
    () =>
      id
        ? t('Save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('Add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [id, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const pageTitle = useMemo(
    () =>
      id
        ? t('edit_group', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Group'
        : t('add_new_group', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add New Group',
    [id, currentSelected?.langCode || selectedLanguage?.langCode],
  );

  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      getCompanies({
        page: paginationParams.page,
        searchText: paginationParams.search,
      });
    }
  }, [paginationParams, currentSelected?.langCode || selectedLanguage?.langCode]);

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    if (companiesData.length && selections.length && companiesData.length === selections.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [companiesData, selections]);

  const {
    control,
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(groupFormValidation),
  });

  const getCompanies = async (data: TODO_ANY) => {
    if (total && companiesData?.length === total) {
      setHasNoMoreData(true);
      return;
    } else {
      setHasNoMoreData(false);
    }
    if (companyLoading) {
      return;
    }
    setCompanyLoading(true);
    try {
      let res = await getAPIData({
        url: 'companies',
        data: {
          limit: 10,
          ...data,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });

      const resDATA: any = [...(paginationParams.page !== 1 ? companiesData : []), ...res?.data?.result];
      setCompaniesData(resDATA);
      setTotal(res?.data?.total);
      const options = resDATA?.map((data: any) => {
        return { name: data?.name, id: data?.id };
      });
      setMultiSelectOptions(options);
      setCompanyLoading(false);
      if (res?.data?.result?.length === 0) {
        setHasNoMoreData(true);
        return;
      }
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
      setCompanyLoading(false);
    }
  };

  const getGroupData = async () => {
    try {
      if (!id) {
        return;
      }
      setLoading(true);
      const res = await getAPIData({
        url: `miscellaneous/group/${id}`,
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      // setValue('name', res.data?.name);
      setValue('name', res?.data?.name ?? '');
      setValue(
        'includes',
        res.data?.companies.map((el: any) => el?.id),
      );
      setSelections(
        res.data?.companies.map((el: any) => {
          return { name: el?.name, id: el?.id };
        }),
      );
      if (res.data?.companies.length === multiSelectOptions.length) {
        setSelectAll(true);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, err?.response?.data?.message || err?.message);
    }
  };

  const handleMultiSelectSelection = (selectedId: TODO_ANY) => {
    const selectionData = [...selections, multiSelectOptions.filter((el: TODO_ANY) => el?.id === selectedId)[0]];
    setSelections(selectionData);
    const selectedIds = selectionData.map((data) => data.id);
    setValue('includes', selectedIds);
  };
  const handleMultiSelectRemove = (selectedId: TODO_ANY) => {
    let updatedSelections = JSON.parse(JSON.stringify(selections));
    updatedSelections.splice(
      selections.findIndex((el: TODO_ANY) => el.id === selectedId),
      1,
    );
    setSelections(updatedSelections);
    const selectedIds = updatedSelections.map((data: any) => data.id);
    setValue('includes', selectedIds);
  };

  function onChange(val: string) {
    setPaginationParams({
      ...paginationParams,
      page: 1,
      search: val,
    });
    setMultiSelectOptions([]);
    setTotal(-1);
  }

  const handleSearchChange = (val: string) => {
    debounceOnChange(val);
    setInputValue(val);
  };

  const updatePage = () => {
    setPaginationParams({
      page: paginationParams.page + 1,
      search: paginationParams.search,
    });
  };

  const abort = () => {
    navigate('/groups');
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getGroupData();
  }, [currentSelected?.langCode || selectedLanguage?.langCode]);

  const selectAllHandler = () => {
    if (selectAll) {
      const options: any = [];
      setSelections(options);
      setValue('includes', options);
    } else {
      const options = multiSelectOptions.map((el: TODO_ANY) => {
        return { name: el?.name, id: el?.id };
      });
      setSelections(options);
      setValue(
        'includes',
        options?.map((el: any) => el.id),
      );
    }
  };

  const addGroup = async () => {
    const exclude = multiSelectOptions
      .filter((data: any) => !getValues()?.includes?.includes(data?.id))
      .map((el: any) => {
        return el.id;
      });
    if (id) {
      const res = await putAPIData({
        url: `miscellaneous/group/${id}`,
        data: {
          name: getValues().name,
          includes: getValues()?.includes,
          excludes: exclude,
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
    } else {
      const res = await postAPIData({
        url: 'miscellaneous/group',
        data: {
          name: getValues().name,
          includes: getValues()?.includes,
          excludes: exclude,
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
    }
    abort();
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  const isLoading = loading;

  return (
    <div className="addCompany">
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(addGroup)}
          buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
          titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
          className="mb-8"
          disableBtn={isLoading}
          onCancelClicked={abort}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />{' '}
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            setMultiSelectOptions([]);
            setSelections([]);
            reset({
              includes: '',
              name: '',
            });
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
          }}
          deleteTitle={'All changes will be lost'}
          type=""
          confirmation={true}
          buttonTitle={'Continue'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <form onSubmit={handleSubmit(addGroup)}>
            <div className="my-[45px]">
              <div className="flex items-center justify-between rounded-t-lg pb-[14px]">
                <span className="text-lg font-bold text-gray-text">
                  {t('group_name', currentSelected?.langCode || selectedLanguage?.langCode) || 'Group Name'}
                  <span className="text-red-500">&nbsp;*</span>
                </span>
              </div>
              <input
                placeholder=""
                className={`text_bold placeholder-text-grey-text mt-2 flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none`}
                {...register('name')}
              />
              {errors['name'] && (
                <p className="mt-2 text-sm italic text-red-600">
                  {t(
                    convertToKey(errors?.['name']?.message?.toString()),
                    currentSelected?.langCode || selectedLanguage?.langCode,
                  ) || errors?.['name']?.message?.toString()}
                </p>
              )}
            </div>

            <div className="flex items-center justify-between rounded-t-lg bg-gray-title-bg py-[14px] px-[26px]">
              <span className="text-lg font-bold text-gray-text">
                {t('companies', currentSelected?.langCode || selectedLanguage?.langCode) || 'Companies'}
                <span className="text-red-500">&nbsp;*</span>
              </span>
            </div>
            <div className="">
              <MultiSelectContainer
                data={multiSelectOptions}
                selectedOptions={selections}
                handleSelect={handleMultiSelectSelection}
                handleRemove={handleMultiSelectRemove}
                handleSearchChange={handleSearchChange}
                updatePage={updatePage}
                hasNoMoreData={hasNoMoreData}
                register
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                selectAll={selectAll}
                loading={companyLoading}
                selectAllHandler={selectAllHandler}
              />
              {errors['includes'] && (
                <p className="mt-2 text-sm italic text-red-600">
                  {t(
                    convertToKey(errors?.['includes']?.message?.toString()),
                    currentSelected?.langCode || selectedLanguage?.langCode,
                  ) || errors?.['includes']?.message?.toString()}
                </p>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default FormGroup;
