import classNames from 'classnames';
import { useTranslation } from 'context/translation';
import { MouseEventHandler } from 'react';

export const TableActionButton = ({
  onClick,
  title = 'Filter',
  className = '',
  disabled,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  disabled?: boolean;
  className?: string;
}) => {
  const { t }: any = useTranslation();

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        disabled
          ? classNames(className, 'mx-1.5 rounded-md bg-gray-main px-5 py-1.5 text-white')
          : classNames(className, 'mx-1.5 cursor-pointer rounded-md bg-blue-main px-5 py-1.5 text-white')
      }>
      {t(title) || title}
    </button>
  );
};
