import classNames from 'classnames';
import Checkbox from 'components/Checkbox';
import { useTranslation } from 'context/translation';

const renderTag = (data: any) => {
  return (
    <>
      {data.map((item: any) => (
        <div style={{ marginLeft: `${item.withoutMargin ? 0 : 26}px` }}>
          <Checkbox content={item.label} key={item.label} />
          {item.children && renderTag(item.children)}
        </div>
      ))}
    </>
  );
};

const CheckboxGroup = ({ data, className, title }: { data: any[]; className?: string; title?: string }) => {
  const { t }: any = useTranslation();
  return (
    <div className={classNames(className)}>
      <p className="mb-2.5">{title}</p>
      <div className="group/content relative">
        <div className="h-60 overflow-scroll border-1 border-solid border-gray-border pl-2.5 pt-2.5">
          {renderTag(data)}
        </div>
        <button
          onClick={() => {}}
          className="group/button group invisible absolute right-2.5 top-2.5 z-10 flex h-5 w-5 items-center justify-center rounded-md border-solid border-gray-text bg-green-main text-xs text-white group-hover/content:visible">
          +
          <p className="absolute -top-8 z-20 hidden whitespace-nowrap rounded-md bg-slate-800 py-1 px-2.5 text-white group-hover/button:block">
            {t('add_new') || 'Add New'}
          </p>
        </button>
      </div>
    </div>
  );
};

export default CheckboxGroup;
