import { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Pagination, Input } from '../../../components';
import { TopLayer, TabsLayer, FunctionLayer, TableActions } from '../../../components/common/table';
import { TableActionButton } from '../../../components/common/button';
import { createDummyData } from '../../../utils/createDummyData';
import { useTranslation } from 'context/translation';

function LibraryList() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState('1');
  const { t }: any = useTranslation();

  const tabs = useMemo(
    () => [
      { label: t('all') || 'All', num: 1 },
      { label: t('published') || 'Published', num: 1 },
    ],
    [],
  );

  const handleToAddLibrary = useCallback(() => {
    navigate('/libraries/add');
  }, [navigate]);

  const handleToEditLibrary = useCallback(
    (id: string | number) => {
      navigate(`/libraries/edit/${id}`);
    },
    [navigate],
  );

  const columns = [
    {
      title: '',
      key: 'checkbox',
      width: 50,
    },
    {
      title: t('title') || 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },
    {
      title: t('date') || 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 1000,
    },

    {
      title: t('actions') || 'Actions',
      dataIndex: '',
      key: 'Actions',
      width: 200,
      render: (record: any) => (
        <TableActions
          icons={[
            { iconClassName: 'far fa-eye', onClick: () => {} },
            {
              iconClassName: 'fas fa-edit',
              onClick: () => {
                handleToEditLibrary(record.id);
              },
            },
            { iconClassName: 'fas fa-trash-alt', onClick: () => {} },
          ]}
        />
      ),
    },
  ];

  const dataSource = createDummyData(columns);

  return (
    <section className="libraries">
      <TopLayer
        onButtonClicked={handleToAddLibrary}
        titleText={t('libraries') || 'Libraries'}
        buttonText={t('add_library') || 'Add Library'}
      />
      <TabsLayer tabs={tabs} />
      <FunctionLayer
        leftPortion={
          <>
            <Select
              value={dateValue}
              options={[
                { label: t('all_dates') || 'All Dates', value: '0' },
                { label: `1 ${t('day') || 'day'}`, value: '1' },
              ]}
              onChange={setDateValue}
            />

            <TableActionButton onClick={() => {}} title={t('filter') || 'Filter'} />
          </>
        }
        rightPortion={
          <>
            <Input
              value={inputValue}
              onChange={setInputValue}
              placeholder={t('search_libraries') || 'Search libraries'}
              type="search"></Input>
            <Pagination currentPage={1} totalPage={50} pageSize={256} onNext={() => {}} onPrev={() => {}}></Pagination>
          </>
        }
      />
      <Table dataSource={dataSource} columns={columns} />
    </section>
  );
}

export default LibraryList;
