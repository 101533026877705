import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, TopLayer, TitleBlock, Icon, ContentCell, Switch, Spinner, SmallLoader, Header } from 'components';
import Title from 'components/Block/Title';
import ImageUpload from 'components/Block/ImageUpload';
import CheckboxGroup from 'components/Block/CheckboxGroup';
import InputBox from 'components/common/formInput';
import { InputBoxType } from 'enums/companyForm.enum';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contentFormValidation, schemaForFormVal } from 'validations/contentFormValidation';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Chip, TextField } from '@mui/material';
import title from 'material-ui/svg-icons/editor/title';
import { ToastTypes, useToast } from 'context/toast';
import { getTableSequenceData } from 'pages/Company/companyDto';
import { TODO_ANY } from 'typings/common';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import MultiSelectContainer from 'components/MultiSelectDropdown/custom';
import {
  convertToKey,
  convertToNestedOptions,
  handleDefaultValues,
  handleDefaultValuesForBadges,
  ICategory,
  NestedSelectionType,
  nestingMultiSelectOption,
  upperCaseFirstLetter,
} from 'pages/Content/utils';
import moment from 'moment';
import { options } from 'constants/survey.constant';
import { options as options1 } from 'constants/survey.constant';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import MultiSelectContainerNested from 'components/MultiSelectDropdown/NestedSelect';
import { DefaultBadges } from 'enums/constant.enum';
import { convertToMidNight } from 'utils/ConvertDate';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';

const initialDefaultValues = {
  name: '',
  badgeImgUrl: '',
  shareText: '',
  congratsText: '',
  description: '',
  isMonthlyBadge: false,
  interrupterContentId: '',
  categoryId: '',
};

function Badge({ duplicate }: { duplicate: boolean }) {
  const { id } = useParams();
  const { authUser } = useAuth();
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const [isModalAction, setIsModalAction] = useState<boolean>(false);
  const [badgeToBeDuplicate, setBadgeToBeDuplicate] = useState<any>({});
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const isDuplicate = useMemo(() => !!duplicate, [duplicate, id]);
  const pageTitle = useMemo(
    () =>
      isDuplicate
        ? t('add_badge', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add New Badge'
        : isEditPage
        ? t('edit_badge', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Badge'
        : t('add_badge', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add New Badge',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode, id],
  );
  const titleButtonText = useMemo(
    () =>
      isEditPage
        ? t('save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode, id],
  );
  // const [title, setTitle] = useState('');
  const [isImageUploaded, setIsImagesUploaded] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [defaultValues, setDefaultValues] = useState<any>(initialDefaultValues);
  const [total, setTotal] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<any>([]);
  const [searchContent, setSearchContent] = useState('');
  // const []
  const [selectValue, setSelectValue] = useState('');
  // const [selectNestingOptions, setSelectNestingOptions]= useState('')
  // const [nestingOptionsTitle, setNestingOptionsTitle]= useState('')
  const [addedSequences, setAddedSequences] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [interrupterLoading, setInterrupterLoading] = useState<any>(false);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const [groupLoading, setGroupLoading] = useState<boolean>(false);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);

  const [loader, setLoader] = useState({
    companiesInitalLoader: false,
    comapaniesInfitineLoader: false,
    interrupterInitialLoader: false,
    interrupterInfiniteLoader: false,
    gropuinitialLoader: false,
    groupInfiniteScroller: false,
    categoryInitialLoader: false,
    categoryInfiniteLoader: false,
  });
  const { callToast } = useToast();
  const [groupTotal, setGroupTotal] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [hasNoMoreGroupData, setHasNoMoreGroupData] = useState(false);
  const [selectGroupAll, setSelectGroupAll] = useState(false);
  const [multiSelectCompanyOptions, setMultiSelectCompanyOptions] = useState([]);
  const [companySelections, setCompanySelections]: TODO_ANY = useState([]);
  const [companyPaginationParams, setCompanyPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [imageId, setImageId] = useState<any>('');
  const [badgeState, setBadgeState] = useState<any>([]);
  const [contentState, setContentState] = useState<any>([]);
  const [nestingOptionsTitle, setNestingOptionsTitle] = useState('');
  const [nestingOptionsSelected, setNestingOptionsSelected] = useState('');
  const [statusData, setStatusData] = useState<any>([]);
  const [statusText, setStatusText] = useState<any>();
  const [multiSelectGroupOptions, setMultiSelectGroupOptions] = useState([]);
  const [groupSelections, setGroupSelections]: TODO_ANY = useState([]);
  const [groupPaginationParams, setGroupPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const selectLoadingHandler = (type: string, val: boolean) => {
    setSelectLoadings({ ...selectLoadings, [type]: val });
  };
  const [multiSelectCategoryOptions, setMultiSelectCategoryOptions] = useState<any>([]);
  const [categorySelections, setCategorySelections]: TODO_ANY = useState([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [totalCategoryData, setTotalCategoryData] = useState('');
  const [companyTotal, setCompanyTotal] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [catDataRaw, setCatDataRaw] = useState<any>([]);
  const [interrupterData, setInterrupterData] = useState<any>([]);
  const [interrupterDataRaw, setInterrupterDataRaw] = useState<any>([]);
  const [type, setType] = useState({ id: 0, type: '' });
  const [hasNoMoreCompanyData, setHasNoMoreCompanyData] = useState(false);
  const [selectCompanyAll, setSelectCompanyAll] = useState(false);
  const [hasNoMoreCategoryData, setHasNoMoreCategoryData] = useState(false);
  const [selectLoadings, setSelectLoadings] = useState({
    categoryLoading: false,
    groupLoading: false,
    companyLoading: false,
  });
  const [toggleChecked, setToggleChecked] = useState(false);
  const [categoryPaginationParams, setCategoryPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const [interrupterPaginationParams, setInterrupterPaginationParams] = useState({
    page: 1,
    search: '',
  });
  const handleCategoriesList = useCallback(() => {
    navigate('/badges');
  }, [navigate]);
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleChecked(event.target.checked);
  };

  const [parentOptions, setParentOptions] = useState<any>([]);
  const [nestedOptions, setNestedOptions] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [nestedLoading, setNestedLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getCategoryData();
  }, [categoryPaginationParams.page, categoryPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getGroupData();
  }, [groupPaginationParams.page, groupPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getCompanyData();
  }, [companyPaginationParams.page, companyPaginationParams.search, currentSelected?.id || selectedLanguage?.id]);
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getInitialData();
  }, [currentSelected?.id || selectedLanguage?.id]);

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    if (isEditPage && companiesData && badgeState) {
      setCompanyExceptionHandler(badgeState, companiesData);
    }

    if (isEditPage && groupData && badgeState) {
      setGroupSelectionHandler(badgeState, groupData);
    }
    if (isEditPage && badgeState) {
      getStatus();
    }
  }, [companiesData, badgeState, currentSelected?.id || selectedLanguage?.id]);

  const setCategoriesHandler = (contentStateData: any = [], categoryData: any) => {
    const selectedCategories = contentStateData?.contentCategory?.map((data: any) => data?.id) ?? [];
    const selectedCategoriesData = categoryData?.filter((el: any) => {
      return selectedCategories.includes(el?.id);
    });
    if (selectedCategoriesData.length) {
      setCategorySelections(selectedCategoriesData);
    }
  };

  const setCompanyExceptionHandler = (contentStateData: any = [], categoryData: any) => {
    const selectedCompanyException =
      contentStateData?.badgeCompanyException?.map((data: any) => data.company?.id) ?? [];
    const selectedCompanyExceptionData = categoryData?.filter((el: any) => {
      return selectedCompanyException.includes(el?.id);
    });
    if (selectedCompanyExceptionData.length) {
      setCompanySelections(selectedCompanyExceptionData);
    }
  };

  const setGroupSelectionHandler = (contentStateData: any = [], groupData: any) => {
    const selectedGroups = contentStateData?.badgeGroup?.map((data: any) => data?.group?.id) ?? [];
    const selectedGroupsData = groupData?.filter((el: any) => {
      return selectedGroups.includes(el.id);
    });
    if (selectedGroupsData.length) {
      setGroupSelections(selectedGroupsData);
    }
  };
  // group

  const getGroupData = async () => {
    setGroupLoading(true);
    if (groupData?.length === groupTotal) {
      setHasNoMoreGroupData(true);
    }
    selectLoadingHandler('groupLoading', true);
    try {
      let res = await getAPIData({
        url: 'miscellaneous/group',
        data: {
          page: groupPaginationParams.page,
          searchText: groupPaginationParams.search,
          limit: 10,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setGroupData(res?.data?.result);
      setGroupTotal(res?.data?.total);
      const options: any = nestingMultiSelectOption(res?.data?.result);
      setMultiSelectGroupOptions(options);
      selectLoadingHandler('groupLoading', false);
      setGroupLoading(false);
    } catch (e: any) {
      setGroupLoading(false);
      selectLoadingHandler('groupLoading', false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  const getStatus = async () => {
    try {
      const res = await getAPIData({
        url: 'miscellaneous/status',
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      setStatusData(res?.data);
      const data = res?.data?.find((data: any) => data?.id === contentState?.statusId);
      setStatusText(data);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };

  // companies

  const getCompanyData = async () => {
    setCompanyLoading(true);
    if (companiesData?.length === companyTotal) {
      setHasNoMoreCompanyData(true);
    }
    selectLoadingHandler('companyLoading', true);
    try {
      let res = await getAPIData({
        url: 'companies',
        data: {
          page: companyPaginationParams.page,
          searchText: companyPaginationParams.search,
          limit: 10,
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      setCompaniesData(res?.data?.result);
      setCompanyTotal(res?.data?.total);
      const options: any = nestingMultiSelectOption(res?.data?.result);
      setMultiSelectCompanyOptions(options);
      selectLoadingHandler('companyLoading', false);
      setCompanyLoading(false);
    } catch (e: any) {
      setCompanyLoading(false);
      selectLoadingHandler('companyLoading', false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getSingleBadgeData();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaForFormVal),
  });

  const containerRef = useRef(null);
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    const observer = new IntersectionObserver(callbackFunc, options);
    const ref = containerRef.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef, options, categoryData, showSchedule]);

  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (categoryData.length > 0) {
      if (entry.isIntersecting && categoryData.length < totalCategoryData) {
        setCategoryPaginationParams({
          page: categoryPaginationParams.page + 1,
          search: categoryPaginationParams.search,
        });
      }
    }
  };
  //----
  const containerRef1 = useRef(null);
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    const observer = new IntersectionObserver(callbackFunc1, options1);
    const ref = containerRef1.current;
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [containerRef1, options1, interrupterData, showSchedule]);

  const callbackFunc1 = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (interrupterData.length > 0) {
      if (entry.isIntersecting && interrupterData.length < total) {
        setInterrupterPaginationParams({
          page: interrupterPaginationParams.page + 1,
          search: interrupterPaginationParams.search,
        });
      }
    }
  };
  const removeURL = (data: any) => {
    if (data?.badgeImgUrl) data.badgeImgUrl = data?.badgeImgUrl?.split('.net/')[1];
    return data;
  };
  const addContent = async (data: any) => {
    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }
    data = removeURL(data);
    data.languageId = currentSelected?.id || selectedLanguage?.id;
    try {
      setLoading(true);
      const getBadgeResponse = await postAPIData({
        url: 'badge',
        data: data,
      });
      if (id) {
        let remainingLanguagesToBeDuplicate = badgeToBeDuplicate?.badgeLang?.reduce((acc: any, el: any) => {
          if (el?.languageId !== data?.languageId) {
            return [...acc, el];
          }
          return acc;
        }, []);
        for (let i = 0; i < remainingLanguagesToBeDuplicate.length; i++) {
          let { congratsText, description, languageId, name, shareText } = remainingLanguagesToBeDuplicate[i];
          await putAPIData({
            url: `badge/${getBadgeResponse?.data?.id}`,
            data: {
              ...data,
              badgeId: getBadgeResponse?.data?.id,
              congratsText,
              description,
              languageId,
              name,
              shareText,
            },
          });
        }
      }

      if (isModalAction) {
        setIsModalAction(false);
        selectedData(currentSelectedLangId);
        navigate(`/badges/edit/${getBadgeResponse?.data?.id}`);
      } else {
        navigate('/badges');
      }
      setLoading(false);
      callToast(
        ToastTypes.SUCCESS,
        t('badge_add_success', currentSelected?.langCode || selectedLanguage?.langCode) || 'Badge added successfully',
      );
    } catch (e: any) {
      setIsModalAction(false);
      setResetConfirmation(false);
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0] ?? '') ?? e?.message);
      setLoading(false);
    }
  };
  const editContent = async (data: any) => {
    if (!isValid) {
      callToast(ToastTypes.ERROR, 'Please fill all the required fields');
      setIsModalAction(false);
      return;
    }

    let errData = { ...data };
    data = removeURL(data);
    data.languageId = currentSelected?.id || selectedLanguage?.id;
    try {
      setLoading(true);
      const res = await putAPIData({
        url: `badge/${id}`,
        data: data,
      });

      selectedData(currentSelectedLangId);

      callToast(
        ToastTypes.SUCCESS,
        t('badge_update_success', currentSelected?.langCode || selectedLanguage?.langCode) ||
          'Badge updated successfully',
      );
      setLoading(false);
      if (isModalAction) {
        setIsModalAction(false);
        navigate(`/badges/edit/${id}`);
      } else {
        navigate('/badges');
      }
    } catch (e: any) {
      setIsModalAction(false);
      setResetConfirmation(false);
      reset(errData);
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0] ?? '') ?? e?.message);
      setLoading(false);
    }
  };
  const getFormData = (data: any) => {
    data.startDate = convertToMidNight(new Date(data?.startDate).setHours(0, 0, 0, 0));
    data.endDate = convertToMidNight(new Date(data?.endDate).setHours(23, 59, 59, 999));
    if (!data.categoryId) {
      data.categoryId = null;
    }
    if (!data.interrupterContentId) {
      data.interrupterContentId = null;
    }
    if (!showSchedule) {
      data.interrupterContentId = null;
    }
    data.imageId = imageId;
    if (isEditPage && !isDuplicate) {
      return editContent(data);
    } else {
      return addContent(data);
    }
  };

  const getSingleBadgeData = async () => {
    try {
      let res = await getAPIData({
        url: `badge/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      setBadgeToBeDuplicate(res?.data);
      const nameForDup = { ...res.data, ...{ name: res.data.name + ' -copy' } };
      isDuplicate ? setBadgeState(nameForDup) : setBadgeState(res?.data);
      isDuplicate ? reset(handleDefaultValuesForBadges(nameForDup)) : reset(handleDefaultValuesForBadges(res?.data));
      setDefaultValues(handleDefaultValuesForBadges(res?.data));
      setTitle(res?.data?.content?.name);
      setNestingOptionsTitle(res?.data?.category?.name);
      setValue('badgeImgUrl', res?.data?.media?.url);
      setValue('categoryId', res?.data?.categoryId);
      setNestingOptionsSelected(res?.data?.categoryId);
      setValue('interrupterContentId', res?.data?.interrupterContentId);
      setValue('isMonthlyBadge', res?.data?.isMonthlyBadge);
      setValue(
        'name',
        res?.data?.badgeLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.name || '',
      );
      setValue(
        'congratsText',
        res?.data?.badgeLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.congratsText || '',
      );
      setValue(
        'shareText',
        res?.data?.badgeLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.shareText || '',
      );
      setValue(
        'description',
        res?.data?.badgeLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.description || '',
      );
      setShowSchedule(res?.data?.isMonthlyBadge);
      setImageId(res?.data?.imageId);
      setValue(
        'groupIds',
        res?.data?.badgeGroup?.map((e: any) => e?.groupId),
      );
      setValue(
        'companyExceptionIds',
        res?.data?.badgeCompanyException?.map((e: any) => e?.companyId),
      );
      setSelectValue(res?.data?.interrupterContentId);
      setShowSchedule(res?.data?.isMonthlyBadge);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getSequences();
  }, [interrupterPaginationParams]);

  const getSequences = async () => {
    setInterrupterLoading(true);
    if (interrupterPaginationParams.page == 1) {
      setLoader({ ...loader, interrupterInitialLoader: true });
    } else {
      setLoader({ ...loader, interrupterInfiniteLoader: true });
    }
    try {
      let res = await getAPIData({
        url: 'content',
        data: {
          page: interrupterPaginationParams.page,
          searchText: interrupterPaginationParams.search,
          limit: 12,
          isTitleSearch: true,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setInterrupterData(res?.data?.result);
      setLoader({ ...loader, interrupterInfiniteLoader: false, interrupterInitialLoader: false });
      // setContent();
      if (interrupterPaginationParams.page === 1) {
        setInterrupterDataRaw(res?.data?.result);
        const data: any = getAllSequences(res?.data?.result);
        setContent(data);
      } else if (interrupterPaginationParams.page > 1) {
        setInterrupterDataRaw([...interrupterDataRaw, ...res?.data?.result]);
        const data: any = getAllSequences([...interrupterDataRaw, ...res?.data?.result]);
        setContent(data);
      }
      setTotal(res?.data?.total);
      setInterrupterLoading(false);
    } catch (e: any) {
      setInterrupterLoading(false);
      setLoader({ ...loader, interrupterInfiniteLoader: false, interrupterInitialLoader: false });
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
    // setLoading(false);
  };
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    const data: any = getAllSequences([...interrupterData]);
    setContent([...data]);
  }, [interrupterPaginationParams.search]);

  const getAllSequences = (data: TODO_ANY) => {
    let arr = [];
    for (let key of data) {
      arr.push({ id: key?.id, name: key?.name });
    }
    return arr;
  };

  const getCategoryData = async () => {
    setCategoryLoading(true);
    if (companiesData?.length === companyTotal) {
      setHasNoMoreCategoryData(true);
    }
    selectLoadingHandler('categoryLoading', true);
    if (categoryPaginationParams.page == 1) {
      setLoader({ ...loader, categoryInitialLoader: true });
    } else {
      setLoader({ ...loader, categoryInfiniteLoader: true });
    }
    try {
      let res = await getAPIData({
        url: 'categories',
        data: {
          page: categoryPaginationParams.page,
          searchText: categoryPaginationParams.search,
          limit: 12,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      // const categoryData = res?.data?.result;
      setCategoryData(res?.data?.result);
      setLoader({ ...loader, categoryInfiniteLoader: false, categoryInitialLoader: false });
      setTotalCategoryData(res?.data?.total);
      if (categoryPaginationParams.page == 1) {
        if (!categoryPaginationParams.search) {
          setCatDataRaw(res?.data?.result);
        }
        const options: any = nestingMultiSelectOption([...res?.data?.result]);
        setMultiSelectCategoryOptions([...uniqueOptions(options)]);
      } else if (categoryPaginationParams.page > 1) {
        setCatDataRaw([...catDataRaw, ...res?.data?.result]);
        const options: any = nestingMultiSelectOption([...catDataRaw, ...res?.data?.result]);
        setMultiSelectCategoryOptions([...uniqueOptions(options)]);
      }
      selectLoadingHandler('categoryLoading', false);
    } catch (e: any) {
      selectLoadingHandler('categoryLoading', false);
      setLoader({ ...loader, categoryInfiniteLoader: false, categoryInitialLoader: false });
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    const options: any = nestingMultiSelectOption([...categoryData]);
    setMultiSelectCategoryOptions([...uniqueOptions(options)]);
  }, [categoryPaginationParams.search]);

  const uniqueOptions = (data: TODO_ANY) => {
    let Arr = [];
    for (let key of data) {
      if (key?.child?.length) {
        for (let nkey of key?.child) {
          Arr.push(nkey?.id);
        }
      }
    }
    let ultimateOptions = [];
    for (let key of data) {
      if (!Arr.includes(key?.id)) {
        ultimateOptions.push(key);
      }
    }
    return ultimateOptions;
  };

  useEffect(() => {
    getNestedCategories();
  }, [currentSelected?.id || selectedLanguage?.id]);

  const getNestedCategories = async () => {
    try {
      setNestedLoading(true);
      let res = await getAPIData({
        url: 'categories/nested',
        data: {
          page: 1,
          searchText: '',
          limit: 1000,
          languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
        },
      });
      setNestedLoading(false);
      setCategoriesData(res.data.data);
      const { parentData, nestedData } = convertToNestedOptions(res.data.data);
      setParentOptions(parentData);
      setNestedOptions(nestedData);
    } catch (error) {
      setNestedLoading(false);
      console.error(error);
    }
  };

  const addToSelectedCategories = (category: ICategory) => {
    const values = category.id;
    setValue('categoryId', values);
  };

  const removeFromSelectedCategories = (category: ICategory) => {
    setValue('categoryId', undefined);
  };
  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const isDefault = [
    DefaultBadges.Journey,
    DefaultBadges.Favorites,
    DefaultBadges.Actions,
    DefaultBadges.Sharing,
    DefaultBadges.Begin,
    DefaultBadges.Quest,
    DefaultBadges.Leader,
    DefaultBadges.LevelUp,
  ].includes(getValues('name'));
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  return (
    <section>
      <div className="flex justify-between">
        {' '}
        <TopLayer
          onButtonClicked={handleSubmit(getFormData)}
          buttonText={titleButtonText}
          titleText={pageTitle}
          className="mb-8"
          disableBtn={isImageUploaded || loading}
          onCancelClicked={handleCategoriesList}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
        <LanguageDropdown
          langLists={langLists}
          value={currentSelected?.langCode || selectedLanguage?.langCode}
          onChange={(e: any) => {
            setCurrentSelectedLangId(e);
            setResetConfirmation(true);
            setIsModalAction(true);
          }}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(handleDefaultValuesForBadges(initialDefaultValues));
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
            setIsModalAction(false);
          }}
          type=""
          confirmation={true}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          deleteTitle={'How would you like to proceed '}
          buttonTitle={'Continue without saving'}
          button3="Save & Continue"
          button3Action={() => {
            setResetConfirmation(false);
            setIsModalAction(true);
            getFormData(getValues());
          }}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <div className="mb-8 w-full">
              <Input
                label={t('badge_name', currentSelected?.langCode || selectedLanguage?.langCode) || 'Badge Name'}
                control={{ ...register('name') }}
                name="name"
                size="large"
                required={true}
                errors={errors}
                placeholder=""
                disabled={isEditPage && isDefault}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>

            <div className="pb-[60px]">
              <InputBox
                input={{
                  type: InputBoxType.Image,
                  name: 'badgeImgUrl',
                  id: 'badgeImgUrl',
                  placeholder: '',
                  label: t('badge_image', currentSelected?.langCode || selectedLanguage?.langCode) || 'Badge Image',
                  required: true,
                  btnLabel: '',
                  inputType: 'file',
                }}
                register={register}
                setValue={setValue}
                getValues={getValues}
                setImageId={setImageId}
                errors={errors}
                control={control}
                disable={isEditPage}
                block={setIsImagesUploaded}
                isBlock={isImageUploaded}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>

            <div className="mb-8 w-full">
              <Input
                label={
                  t('badge_share_text', currentSelected?.langCode || selectedLanguage?.langCode) || 'Badge Share Text'
                }
                control={{ ...register('shareText') }}
                name="shareText"
                size="large"
                required={true}
                errors={errors}
                placeholder=""
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>

            <div className="mb-8 w-full">
              <Input
                label={
                  t('badge_congrats_text', currentSelected?.langCode || selectedLanguage?.langCode) ||
                  'Badge Congrats Text'
                }
                control={{ ...register('congratsText') }}
                name="congratsText"
                size="large"
                required={true}
                errors={errors}
                placeholder=""
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>
            <div className="mb-8 w-full">
              <Input
                label={t('description', currentSelected?.langCode || selectedLanguage?.langCode) || 'Description'}
                textarea={true}
                control={{ ...register('description') }}
                name="description"
                size="large"
                required={false}
                errors={errors}
                placeholder=""
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>
            {!isDefault && (
              <>
                {parentOptions && (
                  <div>
                    <p className="mb-2 text-lg font-bold text-gray-text">
                      {t('Category', currentSelected?.langCode || selectedLanguage?.langCode) || 'Category'}
                    </p>
                    <MultiSelectContainerNested
                      loading={nestedLoading}
                      parentOptions={parentOptions}
                      selectedOptions={watch('categoryId') ? [watch('categoryId')] : []}
                      selectAll={() => {}}
                      type={NestedSelectionType.SINGLE_SELECT}
                      addToSelectedCategories={addToSelectedCategories}
                      removeFromSelectedCategories={removeFromSelectedCategories}
                      allOptions={categoriesData}
                      deselectAll={() => {}}
                      langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                      nestedOptions={nestedOptions}></MultiSelectContainerNested>
                  </div>
                )}
                {errors?.categoryId && showSchedule && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {t(
                      convertToKey(errors?.categoryId?.message?.toString()),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ) || errors?.categoryId?.message?.toString()}
                  </p>
                )}
                {/* <div className="mt-[30px]">
                  <div className="mb-[17px]">
                    <h2 className="text-[18px] font-bold text-gray-text">Viewable Groups</h2>
                    <p className="text-[14px] font-light text-gray-subject-text">
                      Select groups that can view this content
                    </p>
                  </div>
                  <MultiSelectContainer
                    data={multiSelectGroupOptions}
                    selectedOptions={groupSelections}
                    hasNoMoreData={hasNoMoreGroupData}
                    selectAll={selectGroupAll}
                    multiSelectOptions={multiSelectGroupOptions}
                    setSelections={setGroupSelections}
                    selections={groupSelections}
                    setPaginationParams={setGroupPaginationParams}
                    paginationParams={groupPaginationParams}
                    name="groupIds"
                    setValue={setValue}
                    contentData={groupData}
                    setSelectAll={setSelectGroupAll}
                    loading={selectLoadings.groupLoading}
                  />
                </div> */}
                {/* <div className="mt-[30px]">
                  <div className="mb-[17px]">
                    <h2 className="text-[18px] font-bold text-gray-text">Exceptions</h2>
                    <p className="text-[14px] font-light text-gray-subject-text">
                      Select companies that cannot view this content
                    </p>
                  </div>

                  <MultiSelectContainer
                    data={multiSelectCompanyOptions}
                    selectedOptions={companySelections}
                    hasNoMoreData={hasNoMoreCompanyData}
                    selectAll={selectCompanyAll}
                    multiSelectOptions={multiSelectCompanyOptions}
                    setSelections={setCompanySelections}
                    selections={companySelections}
                    setPaginationParams={setCompanyPaginationParams}
                    paginationParams={companyPaginationParams}
                    name="companyExceptionIds"
                    setValue={setValue}
                    contentData={companiesData}
                    setSelectAll={setSelectCompanyAll}
                    loading={selectLoadings.companyLoading}
                  />
                </div> */}
                <div className="mb-[30px] mt-[50px]">
                  <Switch
                    control={control}
                    action={setShowSchedule}
                    register={register}
                    required={true}
                    name="isMonthlyBadge"
                    checked={badgeState?.isMonthlyBadge || false}
                    label={
                      t('monthly_badge_challenge', currentSelected?.langCode || selectedLanguage?.langCode) ||
                      'Is Monthly Badge Challenge?'
                    }
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
                {showSchedule && (
                  <>
                    <div className="flex items-center py-6">
                      <label className="mr-4 text-lg font-bold text-gray-text">
                        {t('start_date', currentSelected?.langCode || selectedLanguage?.langCode) || 'Start Date'}
                        <span className="text-red-500">*</span>
                      </label>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field, ...props }) => {
                          return (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                orientation="portrait"
                                value={field.value}
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                  if (getValues().endDate) {
                                    if (
                                      new Date(getValues().endDate).setHours(0, 0, 0, 0) >
                                      new Date(newValue).setHours(0, 0, 0, 0)
                                    ) {
                                      clearErrors('startDate');
                                      clearErrors('endDate');
                                    } else {
                                      setError('endDate', {
                                        type: 'manual',
                                        message:
                                          t(
                                            'end_date_greater_start_date',
                                            currentSelected?.langCode || selectedLanguage?.langCode,
                                          ) || 'End date should be greater than start date',
                                      });
                                    }
                                  }
                                }}
                                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                              />
                            </LocalizationProvider>
                          );
                        }}
                      />
                      {errors?.['startDate'] && (
                        <p className="text-sm text-red-600">
                          {t(
                            convertToKey(errors?.['startDate'].message?.toString()),
                            currentSelected?.langCode || selectedLanguage?.langCode,
                          ) || errors?.['startDate'].message?.toString()}
                        </p>
                      )}
                      <div className="ml-5 flex items-center">
                        <label className="mr-4 text-lg font-bold text-gray-text">
                          {t('end_date', currentSelected?.langCode || selectedLanguage?.langCode) || 'End Date'}
                          <span className="text-red-500">*</span>
                        </label>
                        <Controller
                          name="endDate"
                          control={control}
                          defaultValue={new Date(+new Date().getTime() + 86400000)}
                          render={({ field, ...props }) => {
                            return (
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  orientation="portrait"
                                  value={field.value}
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                    if (getValues().startDate) {
                                      if (
                                        new Date(getValues().startDate).setHours(0, 0, 0, 0) <
                                        new Date(newValue).setHours(0, 0, 0, 0)
                                      ) {
                                        clearErrors('startDate');
                                        clearErrors('endDate');
                                      } else {
                                        setError('endDate', {
                                          type: 'manual',
                                          message:
                                            t(
                                              'end_date_greater_start_date',
                                              currentSelected?.langCode || selectedLanguage?.langCode,
                                            ) || 'End date should be greater than start date',
                                        });
                                      }
                                    }
                                  }}
                                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                                />
                              </LocalizationProvider>
                            );
                          }}
                        />
                        {errors?.['endDate'] && (
                          <p className="text-sm text-red-600">
                            {t(
                              convertToKey(errors?.['endDate'].message?.toString()),
                              currentSelected?.langCode || selectedLanguage?.langCode,
                            ) || errors?.['endDate'].message?.toString()}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-[30px]">
                      <p className="mb-2 text-lg font-bold text-gray-text">
                        {t(
                          convertToKey('Interrupter Content'),
                          currentSelected?.langCode || selectedLanguage?.langCode,
                        ) || 'Interrupter Content'}
                      </p>
                      <div className="placeholder-text-grey-text flex w-full items-center gap-1 rounded-t-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none">
                        {title && (
                          <Chip
                            label={title}
                            onDelete={() => {
                              setTitle('');
                              setSelectValue('');
                              setValue('interrupterContentId', '');
                              setInterrupterPaginationParams({
                                page: 1,
                                search: interrupterPaginationParams.search,
                              });
                            }}
                          />
                        )}
                        <input
                          // ref={interrupterRef}
                          className=" placeholder-text-grey-text w-full outline-none disabled:bg-white"
                          value={searchContent}
                          onChange={(e: any) => {
                            setSearchContent(e.target.value);
                            setInterrupterPaginationParams({
                              page: 1,
                              search: e.target.value,
                            });
                          }}
                          // disabled={!content?.length && !interrupterPaginationParams.search}
                          placeholder={
                            t('search', currentSelected?.langCode || selectedLanguage?.langCode) || 'Search...'
                          }
                        />
                      </div>
                      <div className="mt-[-2px] rounded-b-md border-l-1 border-r-1 border-b-1 p-5">
                        <div className="scrollbarCustom  max-h-[234px] overflow-y-auto ">
                          {loader.interrupterInitialLoader ? (
                            <SmallLoader langCode={currentSelected?.langCode || selectedLanguage?.langCode} />
                          ) : (
                            <div>
                              {content?.length ? (
                                <div>
                                  {content?.map((data: { id: string; name: string }) => {
                                    setValue('interrupterContentId', selectValue);
                                    return (
                                      <section className="flex items-center">
                                        <label className="customRadio flex cursor-pointer text-[14px] text-gray-text">
                                          <Controller
                                            name={`interrupterContentId`}
                                            control={control}
                                            render={({ field, ...props }) => {
                                              return (
                                                <input
                                                  type="radio"
                                                  value={data?.id}
                                                  onChange={(a: any) => {
                                                    field.onChange(data?.id);
                                                    setSelectValue(data?.id);
                                                    setTitle(data?.name);
                                                    setSearchContent('');
                                                    if (interrupterPaginationParams.search) {
                                                      setInterrupterPaginationParams({
                                                        page: 1,
                                                        search: '',
                                                      });
                                                    }
                                                  }}
                                                  checked={data?.id == selectValue}
                                                  name="interrupterContentId"
                                                />
                                              );
                                            }}
                                          />
                                          <span className="pl-3">{data?.name}</span>
                                        </label>
                                      </section>
                                    );
                                  })}
                                  <div style={{ marginBottom: '10px' }} ref={containerRef1}></div>
                                  {loader.interrupterInfiniteLoader && (
                                    <SmallLoader langCode={currentSelected?.langCode || selectedLanguage?.langCode} />
                                  )}
                                </div>
                              ) : (
                                <>
                                  {t('no_data', currentSelected?.langCode || selectedLanguage?.langCode) ||
                                    'No data found'}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {errors?.interrupterContentId && (
                      <p className="mt-2 text-sm italic text-red-600">
                        {t(
                          convertToKey(errors?.interrupterContentId?.message?.toString()),
                          currentSelected?.langCode || selectedLanguage?.langCode,
                        ) || errors?.interrupterContentId?.message?.toString()}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Badge;
