import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { TODO_ANY } from 'typings/common';
import dropdown from '../../assets/images/dropdown.svg';
import { useTranslation } from 'context/translation';

const perPage = [10, 20, 50, 100, 'All'];

type pagination = {
  currentPage: number;
  belowTable?: any;
  totalPage: number;
  pageSize: number;
  newCurrentPage?: TODO_ANY;
  showError?: Function;
  onNext: () => void;
  onPrev: () => void;
  type?: string;
  searchedValue?: string;
  tab?: TODO_ANY;
  paginationChange?: TODO_ANY;
  limit?: number | string;
};

function Pagination({
  currentPage = 1,
  belowTable,
  totalPage = 1,
  pageSize = 256,
  newCurrentPage,
  showError = () => {},
  onNext = () => {},
  onPrev = () => {},
  type = 'item',
  searchedValue,
  tab,
  paginationChange,
  limit = 10,
}: pagination) {
  const [err, setErr] = useState(false);
  const [inputVal, setInputVal] = useState<any>(1);
  const { t }: any = useTranslation();

  const onChange = useCallback(
    debounce((val: any, lim: any) => {
      newCurrentPage({
        limit: +lim,
        page: +val,
        search: searchedValue,
      });
    }, 400),
    [searchedValue],
  );
  useEffect(() => {
    setInputVal(+currentPage);
  }, []);
  useEffect(() => {
    if (currentPage > 0 && currentPage <= totalPage) {
      setErr(false);
    }
    setInputVal(+currentPage);
  }, [currentPage]);

  useEffect(() => {
    setInputVal(+currentPage);
  }, [tab, paginationChange]);
  useEffect(() => {
    if (inputVal > 0 && inputVal <= totalPage) {
      setErr(false);
    } else {
      setErr(true);
    }
  }, [inputVal, currentPage, totalPage]);
  const handleInputChange = (val: number) => {
    setInputVal(val);
    if (val > 0 && val <= totalPage) {
      setErr(false);
      onChange(val, limit);
    } else {
      setErr(true);
    }
  };

  const handleLimitChange = (lim: any) => {
    onChange(inputVal, lim === 'All' ? pageSize : lim);
  };

  const convertToPlural = (text: string) => {
    if (text[text.length - 1] === 'y' && text !== 'survey') {
      return text.slice(0, text.length - 1) + 'ies';
    }
    return text + 's';
  };

  useEffect(() => {
    if (err) {
      showError(true);
    } else {
      showError(false);
    }
  }, [err, showError]);
  return (
    <div className="flex items-center gap-2.5">
      {!belowTable && (
        <>
          <p className="text-sm text-gray-text">
            {pageSize}{' '}
            {pageSize > 1 || pageSize === 0
              ?t(convertToPlural(type)) || convertToPlural(type)
              :t(type) || type}
          </p>
          <div className="relative">
            <select
              className="ml-5 flex cursor-pointer appearance-none items-center rounded-md border-1 border-solid border-gray-200 bg-white py-2 pl-2 pr-10 text-[14px] outline-none"
              onChange={(e: any) => {
                handleLimitChange(e.target.value);
              }}
              value={limit > 100 ? 'All' : limit}>
              {perPage?.map((num: number | string, index: number) => {
                return (
                  <option value={num} key={index}>
                    {t(num +"") || num}
                  </option>
                );
              })}
            </select>
            <img
              src={dropdown}
              alt="arrow"
              className="pointer-events-none absolute top-[12px] right-2 h-[15px] w-[15px]"
              title="arrow"></img>
          </div>
          <p className="text-sm text-gray-text">{t('per_page') || 'per page'}</p>
        </>
      )}
      <button
        className="flex h-8 w-8 items-center justify-center rounded bg-green-main text-white disabled:opacity-25"
        disabled={currentPage <= 1}
        onClick={() => {
          onNext();
          setInputVal('');
          setErr(false);
        }}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <input
        min={1}
        max={totalPage}
        type="number"
        onChange={(e: any) => {
          if (+e.target.value > 0 && +e.target.value <= totalPage) {
            setErr(false);
          }
          handleInputChange(+e.target.value ? +e.target.value : 0);
        }}
        style={err ? { color: 'red', width: '50px' } : { color: 'black', width: '50px' }}
        className="inputCustom w-8 rounded border-1 border-solid border-green-main text-center"
        value={(inputVal + '').replace(/^0+/, '')}
        onBlur={() => {
          if (!inputVal) {
            setInputVal(+currentPage);
          }
        }}></input>
      <p className="whitespace-nowrap text-sm text-gray-text">
        {t('of') || 'of'} {totalPage}
      </p>
      <button
        className="flex h-8 w-8 items-center justify-center rounded bg-green-main text-white disabled:opacity-25"
        disabled={currentPage >= totalPage}
        onClick={() => {
          onPrev();
          setInputVal('');
          setErr(false);
        }}>
        <i className="fas fa-arrow-right"></i>
      </button>
    </div>
  );
}

export default Pagination;
