import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TODO_ANY } from 'typings/common';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../assets/images/loginLogo.jpg';
import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { ToastTypes, useToast } from 'context/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'context/translation';

function ForgotPassword() {
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const [showForm, setShowForm] = useState(false);

  const [code] = useState('');
  const [defaultValues, setDefaultValues] = useState<any>({
    password: '',
    code: '',
    username: '',
  });
  const [password] = useState('');
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser]: TODO_ANY = useState({});
  const { callToast } = useToast();
  const { t }: any = useTranslation();
  const PasswordValidationSchema = yup
    .object()
    .shape({})
    .when((values, schema) => {
      if (values.ShowForm) {
        return schema.shape({
          password: yup
            .string()
            .required('Password is required')
            .min(
              8,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[0-9]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[a-z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[A-Z]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            )
            .matches(
              /[!-\/:-@[-`{-~]/,
              t('pass_pattern') ||
                'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
          code: yup
            .string()
            .required(t('code_required') || 'Code is required')
            .min(6, t('code_length') || 'Code Should be of 6 Characters'),
        });
      } else {
        return schema.shape({
          username: yup
            .string()
            .required(t('email_required') || 'Email is Required')
            .email(t('valid_email') || 'Please enter a valid email')
            .matches(/^(?!.*@[^,]*,)/, t('valid_email') || 'Please enter a valid email'),
        });
      }
    });

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    unregister,
    setValue,
    reset,
    getValues,
  } = useForm({
    // mode: 'onSubmit',
    shouldUnregister: true,
    defaultValues: defaultValues,
    resolver: yupResolver(PasswordValidationSchema),
  });

  const onSubmit = async (data: TODO_ANY) => {
    setLoading(true);
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    setUserName(data?.username);
    let userData = { Username: data?.username, Pool: userPool };
    var cognitoUser = new CognitoUser(userData);
    setCognitoUser(cognitoUser);
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        setShowForm(true);
        setLoading(false);
      },
      onFailure: function (err) {
        console.error(err);
        setLoading(false);
      },
      inputVerificationCode() {
        setShowForm(true);
        setLoading(false);
      },
    });
  };
  const onCodeSubmit = async (data: TODO_ANY) => {
    setLoading(true);
    let poolData: TODO_ANY = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID, // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, // Your client id here
    };
    let userPool = new CognitoUserPool(poolData);
    let userData = { Username: username, Pool: userPool };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(data?.code, data?.password, {
      onFailure(err) {
        console.error(err);
        setLoading(false);
        callToast(ToastTypes.ERROR, err?.message);
      },
      onSuccess(res) {
        setLoading(false);
        setShowForm(false);
        callToast(ToastTypes.SUCCESS, t('reset_pass') || 'Password reset successfully');
        navigate('/auth/login');
      },
    });
  };
  useEffect(() => {
    setValue('ShowForm', showForm);
  }, [showForm]);
  if (loading) {
    return (
      <div className="flex min-h-[100vh] items-center justify-center">
        {' '}
        <CircularProgress color="success" />
      </div>
    );
  }
  if (showForm) {
    return (
      <div className="m-auto w-[90%] max-w-[350px] rounded-[5px] border-1 border-gray-text/[.3]   bg-white p-[42px] px-[45px]">
        <div className="mb-[15px] flex items-center justify-center">
          <img src={logo} className="max-w-[200px]"></img>
        </div>
        <form onSubmit={handleSubmit(onCodeSubmit)}>
          <p className="text-gray-title mb-[15px] text-center">{t('forgot_pass') || 'Forgot Password'}</p>
          <div className="mb-[15px]">
            <div className="relative">
              <input
                className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px]  pl-[50px] text-[14px] font-light outline-none"
                placeholder="Code"
                {...register('code')}
              />
              <i
                className="fa fa-lock absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
                aria-hidden="true"></i>
            </div>
            <span className="text-[12px] text-red-600">{errors?.['code'] && errors?.['code'].message?.toString()}</span>
            <div className="mt-1 flex justify-end">
              <a
                className="cursor-pointer text-[12px] text-green-main underline"
                onClick={() => {
                  setLoading(true);
                  cognitoUser.resendConfirmationCode((err: TODO_ANY, res: TODO_ANY) => {
                    if (err) {
                      callToast(ToastTypes.ERROR, err?.message);
                      setLoading(false);
                      return;
                    }
                    callToast(ToastTypes.SUCCESS, t('verify_code') || 'Verification code sent successfully!');
                    setLoading(false);
                  });
                }}>
                {t('resend_code') || ' Resend Code'}
              </a>
            </div>
          </div>
          <div className="mb-[15px]">
            <div className="relative">
              <input
                className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px]  pl-[50px] text-[14px] font-light outline-none"
                placeholder="New Password"
                {...register('password')}
                type={'password'}
              />
              <i
                className="fa fa-lock absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
                aria-hidden="true"></i>
            </div>
            <span className="text-[12px] text-red-600">
              {errors?.['password'] && errors?.['password'].message?.toString()}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="min-h-[35px] min-w-[85px] rounded-[5px] bg-green-main p-[5px] text-[14px] font-light text-white hover:bg-green-deep"
              type={'submit'}
              onClick={handleSubmit(onCodeSubmit)}>
              {t('submit') || 'Submit'}
            </button>
            <a className="cursor-pointer text-[12px] text-green-main underline" onClick={() => navigate('/auth/login')}>
              {t('login') || 'Login'}
            </a>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="m-auto w-[90%] max-w-[350px] rounded-[5px] border-1 border-gray-text/[.3]   bg-white p-[42px] px-[45px]">
      <div className="mb-[15px] flex items-center justify-center">
        <img src={logo} className="max-w-[200px]"></img>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-gray-title mb-[15px] text-center">{t('forgot_pass') || 'Forgot Password'}</p>
        <div className="mb-[15px]">
          <div className="relative">
            <input
              className="min-h-[35px] w-full rounded-[5px] border-1 border-gray-border p-[5px]  pl-[50px] text-[14px] font-light outline-none"
              placeholder="Email"
              {...register('username')}
            />
            <i
              className="fa fa-user-o absolute left-0 top-0 flex h-[100%] w-[40px] items-center justify-center border-r-1 border-gray-border bg-gray-main/50 text-[14px] text-gray-text/50"
              aria-hidden="true"></i>
          </div>
          <span className="text-[12px] text-red-600">
            {errors?.['username'] && errors?.['username'].message?.toString()}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="min-h-[35px] min-w-[85px] rounded-[5px] bg-green-main p-[5px] text-[14px] font-light text-white hover:bg-green-deep"
            type={'submit'}
            onSubmit={handleSubmit(onSubmit)}>
            {t('submit') || 'Submit'}
          </button>
          <a className="cursor-pointer text-[12px] text-green-main underline" onClick={() => navigate('/auth/login')}>
            {t('login') || 'Login'}
          </a>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
