import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Textarea, ContentCell, TopLayer } from 'components';
import { useTranslation } from 'context/translation';

function Tags() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t }: any = useTranslation();

  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(() => (isEditPage ? t('edit_tag') || 'Edit Tag' : t('add_tag') || 'Add Tag'), [isEditPage]);
  const titleButtonText = useMemo(() => (isEditPage ? t('save') || 'Save' : t('add') || 'Add'), [isEditPage]);
  const [title, setTitle] = useState('');

  const handleCategoriesList = useCallback(() => {
    navigate('/tags');
  }, [navigate]);

  return (
    <section>
      <TopLayer
        onButtonClicked={() => {}}
        buttonText={titleButtonText}
        titleText={pageTitle}
        className="mb-8"
        onCancelClicked={handleCategoriesList}
      />
      <div className="flex flex-col gap-5">
        <Input label={t('name') || 'Name'} size="medium" value={title} onChange={setTitle} />
        <Input label={t('slug') || 'Slug'} size="medium" value={title} onChange={setTitle} />
        <ContentCell title={t('description') || 'Description'} className="border-b-0 p-0">
          <Textarea value={title} onChange={setTitle} />
        </ContentCell>
        <Input label={t('archive_title') || 'Archive Title'} size="medium" value={title} onChange={setTitle} />
        <Input label={t('archive_subtitle') || 'Archive Subtitle'} size="medium" value={title} onChange={setTitle} />
      </div>
    </section>
  );
}

export default Tags;
