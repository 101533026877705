import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Spinner, TopLayer } from 'components';
import CustomEditor from 'components/CustomEditor';
import { pageFormTextEditorInput } from 'constants/pageForm.constant';
import { pageValidation } from 'validations/pageValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { ToastTypes, useToast } from 'context/toast';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { DefaultPages } from 'enums/constant.enum';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import { convertToKey, upperCaseFirstLetter } from 'pages/Content/utils';
import LanguageDropdown from 'components/LanguageDropdown';
import { getImageTranslatedPayload } from 'utils/uploadFile';
function Page() {
  const { id } = useParams();
  const { authUser } = useAuth();
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [isModalAction, setIsModalAction] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const navigate = useNavigate();
  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(
    () =>
      isEditPage
        ? t('edit_page', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Page'
        : t('add_page', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Page',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const titleButtonText = useMemo(
    () =>
      isEditPage
        ? t('save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );

  const [pageData, setPageData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { callToast } = useToast();

  const handlePageList = useCallback(() => {
    navigate('/pages');
  }, [navigate]);

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(pageValidation) });

  const addEditPage = async (data: any) => {
    data.title = data?.title?.trim();
    data.content = data?.content?.trim();
    if (data?.title === '') {
      setLoading(false);
      callToast(ToastTypes.ERROR, 'Title should not be empty');
      return;
    }

    if (data?.content === '') {
      setLoading(false);
      callToast(ToastTypes.ERROR, 'Content should not be empty');
      return;
    }

    let res: any;
    const optimizedTextField = await getImageTranslatedPayload(data?.content);
    data = { ...data, content: optimizedTextField, languageId: currentSelected?.id || selectedLanguage?.id };
    try {
      setLoading(true);
      if (isEditPage) {
        res = await putAPIData({ url: `pages/${id}`, data: data });
      } else {
        res = await postAPIData({ url: 'pages', data: data });
      }
      setLoading(false);
      if (res.status >= 200 && res.status < 300) {
        if (isEditPage) {
          callToast(
            ToastTypes.SUCCESS,
            t('page_updated', currentSelected?.langCode || selectedLanguage?.langCode) || 'Page updated successfully',
          );
        } else {
          callToast(
            ToastTypes.SUCCESS,
            t('page_added', currentSelected?.langCode || selectedLanguage?.langCode) || 'Page added successfully',
          );
        }

        if (isModalAction) {
          setIsModalAction(false);
          selectedData(currentSelectedLangId);
          navigate(`/pages/edit/${id ?? res?.data?.id}`);
        } else {
          navigate('/pages');
        }
      }
    } catch (err: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(err?.response?.data?.message[0]) || err?.message);
      console.error(err);
    }
  };

  const getPageData = async () => {
    try {
      setLoading(true);
      const res = await getAPIData({
        url: `pages/${id}`,
        data: { languageId: currentSelected?.id || selectedLanguage?.id },
      });
      if (res.status >= 200 && res.status < 300) {
        setPageData(res?.data);
        setValue(
          'title',
          res?.data?.pagesLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
            ?.title || '',
        );
        setValue(
          'content',
          res?.data?.pagesLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
            ?.content || '',
        );
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, err?.response?.data?.message || err?.message);
      console.error(err);
    }
  };

  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    if (isEditPage) {
      getPageData();
    }
  }, [currentSelected?.id || selectedLanguage?.id]);
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  return (
    <section>
      <form onSubmit={handleSubmit(addEditPage)}>
        <div className="flex justify-between">
          {' '}
          <TopLayer
            onButtonClicked={() => {}}
            buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
            titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
            className="mb-8"
            onCancelClicked={handlePageList}
            disableBtn={loading}
            langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          />
          <LanguageDropdown
            langLists={langLists}
            value={currentSelected?.langCode || selectedLanguage?.langCode}
            onChange={(e: any) => {
              setCurrentSelectedLangId(e);
              setIsModalAction(true);
              setResetConfirmation(true);
            }}
          />
        </div>

        {resetConfirmation && (
          <DeleteModal
            showModal={setResetConfirmation}
            deleteConfirm={() => {
              reset({
                title: '',
                content: '',
              });
              selectedData(currentSelectedLangId);
              setResetConfirmation(false);
            }}
            deleteTitle={'How would you like to proceed '}
            button3="Save & Continue"
            button3Action={() => {
              setResetConfirmation(false);
              addEditPage(getValues());
            }}
            type=""
            confirmation={true}
            buttonTitle={'Continue without saving'}
            langCode={currentSelected?.langCode || selectedLanguage?.langCode}
          />
        )}

        {loading ? (
          <Spinner />
        ) : (
          <div className="flex gap-12">
            <div className="flex-1">
              <div className="mb-[45px]">
                <div className="flex items-center justify-between rounded-t-lg">
                  <span className="mb-[20px] text-lg font-bold text-gray-text">
                    {t('page_title', currentSelected?.langCode || selectedLanguage?.langCode) || 'Page Title'}
                    <span className="text-red-500">&nbsp;*</span>
                  </span>
                </div>
                <input
                  placeholder=""
                  className="text_bold placeholder-text-grey-text flex w-full items-center rounded-md border-1 border-solid border-gray-200 bg-white py-3 px-6 text-lg leading-6 outline-none disabled:cursor-not-allowed disabled:bg-slate-100 disabled:text-gray-500"
                  {...register('title')}
                  disabled={isEditPage && [DefaultPages.Terms, DefaultPages.Privacy].includes(getValues('title'))}
                />
                {errors['title'] && (
                  <p className="mt-2 text-sm italic text-red-600">
                    {t(
                      convertToKey(errors?.['title']?.message?.toString()),
                      currentSelected?.langCode || selectedLanguage?.langCode,
                    ) || errors?.['title']?.message?.toString()}
                  </p>
                )}
              </div>
              <CustomEditor
                input={pageFormTextEditorInput}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                control={control}
                header={false}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
            </div>
          </div>
        )}
      </form>
    </section>
  );
}

export default Page;
