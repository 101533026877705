import { dataURLtoFile, getUrls, isValidUrl } from 'pages/Content/utils';
import uuid from 'react-uuid';
import { postAPIData, putAPIToUploadFile } from './api';

export function getFileExtension(filename: string) {
  const lastIndexOfDot = filename.lastIndexOf('.');
  return filename.substring(lastIndexOfDot + 1).toLowerCase();
}

export async function getSignedUrl(file: File, key: string) {
  const payload = {
    url: 'miscellaneous/signed-url',
    data: {
      key: key,
      // `${companyName}/logo/${Date.now()}/company_logo.${getFileExtension(file.name)}`,
      method: 'PUT',
      content: file.type,
    },
  };
  return await postAPIData(payload);
}

export async function uploadFile(file: File, url: string) {
  //   const data = new FormData();
  //   data.append('file', file);
  const payload = {
    url: url,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  };
  return putAPIToUploadFile(payload);
}

export const getImageTranslatedPayload = async (textField: any) => {
  let urls = getUrls(textField);
  urls = urls.filter((data: any) => !isValidUrl(data));

  for (const url of urls) {
    const file = dataURLtoFile(url, 'img.png');
    const key = `${uuid()}.${file?.type.split('/')[1]}`;
    const signedUrl = await getSignedUrl(file, key);
    await uploadFile(file, signedUrl?.data?.result?.url);
    textField = textField?.replace(url, signedUrl?.data?.result?.cloudFrontBaseUrl + '/' + key);
  }

  return textField;
};
