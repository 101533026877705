// import Multiselect from 'multiselect-react-dropdown';
import { TODO_ANY } from 'typings/common';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useRef, useEffect, useState } from 'react';
import { SmallLoader, Spinner } from 'components';
import { useTranslation } from 'context/translation';
const options = {
  root: null,
  rootMargin: '-30px',
  threshold: 1,
};

function MultiSelectContainer({
  data,
  selectedOptions,
  hasNoMoreData,
  selectAll,
  multiSelectOptions,
  setSelections,
  selections,
  setValue,
  setPaginationParams,
  paginationParams,
  name,
  setSelectAll,
  contentData,
  loading,
  langCode,
}: TODO_ANY) {
  const containerRef = useRef(null);
  const { t }: any = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunc, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);
  const [searchContent, setSearchContent] = useState<any>('');

  const callbackFunc = (entries: TODO_ANY) => {
    const [entry] = entries;
    if (entry.isIntersecting && !hasNoMoreData) {
      updatePage();
    }
  };

  const singleSelect = (selecteds: string[] = []) => {
    const selectionData: any = [
      ...selections,
      ...multiSelectOptions.filter((el: TODO_ANY) => selecteds.includes(el?.id)),
    ];
    const selectionDataUnique: any = [];
    const idsPushed: string[] = [];
    for (let i = 0; i < selectionData.length; i++) {
      if (!idsPushed.includes(selectionData[i]?.id)) {
        selectionDataUnique.push(selectionData[i]);
        idsPushed.push(selectionData[i]?.id);
      }
    }

    setSelections(selectionDataUnique);
    setValue(name, idsPushed);
  };

  const handleMultiSelectSelection = (selectedId: TODO_ANY) => {
    if (selectedId?.child && selectedId?.child.length) {
      let selectedIds = [selectedId.id, ...selectedId.child.map((data: any) => data.id)];
      singleSelect(selectedIds);
    } else {
      let parent;
      for (let i = 0; i < data.length; i++) {
        if (data[i].child) {
          let parentData = data[i].child.find((el: any) => el?.id === selectedId.id);
          if (parentData) {
            parent = data[i];
          }
        }
      }
      const ids = [selectedId?.id];
      if (parent?.child) {
        const selectedIds = selections.map((el: any) => el?.id);
        parent.child.forEach((el: any) => {
          if (selectedIds.includes(el?.id)) {
            ids.push(el?.id);
          }
        });

        if (ids.length === parent.child.length) {
          ids.push(parent?.id);
        }
        new Set(ids);
      }
      singleSelect([...ids]);
    }
  };

  const handleMultiSelectRemove = (selectedId: TODO_ANY) => {
    if (selectedId?.child && selectedId?.child.length) {
      let selectedIds = [selectedId?.id, ...selectedId.child.map((data: any) => data?.id)];
      handleSingleSelectRemove(selectedIds);
    } else {
      let parent;

      for (let i = 0; i < data.length; i++) {
        if (data[i].child) {
          let parentData = data[i].child.find((el: any) => el?.id === selectedId.id);
          if (parentData) {
            parent = data[i];
          }
        }
      }
      const ids = [selectedId?.id];
      if (parent) {
        ids.push(parent?.id);
      }

      handleSingleSelectRemove([...ids]);
    }
  };

  const handleSingleSelectRemove = (selectedIdsToRemove: string[] = []) => {
    let updatedSelections = JSON.parse(JSON.stringify(selections));
    updatedSelections = updatedSelections.filter((el: any) => {
      return !selectedIdsToRemove.includes(el?.id);
    });
    setSelections(updatedSelections);
    const selectedIds = updatedSelections.map((data: any) => data?.id);
    setValue(name, selectedIds);
  };

  const handleSearchChange = (val: string) => {
    setPaginationParams({
      page: 1,
      search: val,
    });
  };

  const updatePage = () => {
    // setPaginationParams({
    //   page: paginationParams.page + 1,
    //   search: paginationParams.search,
    // });
  };

  const selectAllHandler = () => {
    if (selectAll) {
      const options: any = [];
      setSelections(options);
      setValue(name, options);
    } else {
      const options = multiSelectOptions.map((el: TODO_ANY) => {
        return { name: el.name, id: el?.id };
      });
      setSelections(options);
      setValue(
        name,
        options.map((el: any) => el.id),
      );
    }
  };

  useEffect(() => {
    let selectionIds = selections.map((el: TODO_ANY) => el.id);
    let contentDataIds = contentData.map((el: TODO_ANY) => el.id);
    if (contentData.length && selections.length && contentDataIds.every((ai: TODO_ANY) => selectionIds.includes(ai))) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [contentData, selections]);

  return (
    <>
      <div className="rounded-md border-1 border-solid ">
        <div className="rounded-t-[5px] border-b-1 border-solid px-6 pt-3 text-[16px] ">
          <div className="scrollbarCustom flex max-h-[145px] flex-wrap overflow-y-auto">
            {selectedOptions?.map((el: TODO_ANY) => {
              return (
                <span className="mr-[10px] pb-3" key={el.id}>
                  <Chip label={el.name} onDelete={() => handleMultiSelectRemove(el)} />
                </span>
              );
            })}
            <input
              className="pb-3 outline-none"
              placeholder={langCode ? t('search', langCode) || 'Search...' : t('search', langCode) || 'Search...'}
              value={paginationParams.search}
              type={'text'}
              onChange={(e) => {
                handleSearchChange(e?.target?.value);
                setSearchContent(e.target.value);
              }}
            />
          </div>
        </div>
        {loading && (
          <div className="mx-[22px]">
            <SmallLoader langCode={langCode}/>
          </div>
        )}
        {!loading && (
          <div className="scrollbarCustom mx-[22px] my-[17px] max-h-[234px] overflow-y-auto" ref={containerRef}>
            {!data.length && (
              <div>{langCode ? t('no_data', langCode) || 'No Data found' : t('no_data') || 'No Data found'}</div>
            )}
            {data.length > 2 && (
              <FormGroup className="checkboxCustom">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        if (searchContent) {
                          setPaginationParams({
                            page: 1,
                            search: '',
                          });
                        }
                        selectAllHandler();
                      }}
                      checked={selectAll}
                    />
                  }
                  label={langCode ? t('select_all', langCode) || 'Select all' : t('select_all') || 'Select all'}
                />
              </FormGroup>
            )}
            {data?.map((el: TODO_ANY) => {
              let isChecked = selectedOptions.filter((sEl: TODO_ANY) => sEl.id === el.id)?.length;
              if (!el.child) {
                return '';
              }
              return (
                <FormGroup className="checkboxCustom" key={el.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          if (searchContent) {
                            setPaginationParams({
                              page: 1,
                              search: '',
                            });
                          }
                          isChecked ? handleMultiSelectRemove(el) : handleMultiSelectSelection(el);
                        }}
                        checked={isChecked > 0}
                      />
                    }
                    label={t(el?.name) || el?.name}
                  />
                  {el?.child &&
                    el?.child?.map((el: TODO_ANY) => {
                      let isChecked = selectedOptions.filter((sEl: TODO_ANY) => sEl.id === el.id)?.length;

                      return (
                        <FormGroup className="checkboxCustom" key={el.id}>
                          <FormControlLabel
                            style={{ marginLeft: '30px' }}
                            control={
                              <Checkbox
                                onChange={() => {
                                  if (searchContent) {
                                    setPaginationParams({
                                      page: 1,
                                      search: '',
                                    });
                                  }
                                  isChecked ? handleMultiSelectRemove(el) : handleMultiSelectSelection(el);
                                }}
                                checked={isChecked > 0}
                              />
                            }
                            label={t(el?.name) || el?.name}
                          />
                        </FormGroup>
                      );
                    })}
                </FormGroup>
              );
            })}
            <div ref={containerRef}></div>
          </div>
        )}
      </div>
    </>
  );
}

export default MultiSelectContainer;
