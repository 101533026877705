import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StyledEngineProvider } from '@mui/material/styles';
import { useTranslation } from 'context/translation';
export default function MaterialUIPickers({
  setValue,
  name,
  publishTime,
  setPublishTime,
  clearErrors = () => {},
  langCode,
}: {
  setValue: any;
  name: string;
  publishTime: any;
  setPublishTime: Function;
  clearErrors?: Function;
  langCode?: any;
}) {
  const handleChange = (newValue: any) => {
    setPublishTime(newValue);
    setValue(name, newValue['$d']);
    clearErrors('publishTime');
  };
  const { t }: any = useTranslation();

  const onKeyDown = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  return (
    <StyledEngineProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={t('date_time', langCode) || 'Date&Time picker'}
          value={publishTime}
          onChange={handleChange}
          renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} sx={{ width: '100%' }} />}
        />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}
