import * as yup from 'yup';

export const TermValidation = yup.object().shape({
  term: yup.string().required('Custom Term is required'),
  options: yup.array().of(
    yup.object().shape({
      option: yup.string().required('Option is required'),
    }),
  ),
});

export const companyFormValidation = yup.object().shape({
  title: yup.string().required('Title is required'),
  companyId: yup.string().required('Company ID is required'),
  customTermCompany: yup.array().of(TermValidation),
  'd&iStatementTitle': yup.string().required('D&I Statement title is required'),
  'd&iStatement': yup.string().required('D&I Statement is required'),
  adminEmailInput: yup
    .string()
    .test('no-empty-spaces', 'Username should not contain empty spaces', (value) => {
      return value ? !/\s/.test(value) : true;
    })
    .required('Admin username is required'),
  tempPasswordInput: yup
    .string()
    .required('Temporary password is required')
    .matches(/.*\d.*/, 'Must contain at least one number')
    .matches(/.*[!@#$%^&*].*/, 'Must contain at least one special character (!@#$%^&*)')
    .matches(/.*[A-Z].*/, 'Must contain at least one uppercase letter')
    .matches(/.*[a-z].*/, 'Must contain at least one lowercase letter'),
  logoUrl: yup.string().required('Logo is required').nullable(),
});
