import { useRouteError } from 'react-router-dom';
import { TODO_ANY } from 'typings/common';

function ErrorPage() {
  const error: TODO_ANY = useRouteError();
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <h1 className="mb-10 text-4xl font-medium">{'Oops!'}</h1>
      <p className="mb-5 text-xl">{'Sorry, an unexpected error has occurred.'}</p>
      <p className="text-gray-400">
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
}

export default ErrorPage;

